import config from '@/config';
import fetch from '@/fetch';

let cache;

const BASE_URL = config.url.contacerta + '/bank';

export default {
  get: (pix = true) => {
    if (cache) {
      return Promise.resolve(cache);
    }
    return fetch(`${BASE_URL}?pix=${pix}`).then(banks => {
      cache = banks;
      return cache;
    });
  }
};
