<template>
  <teleport to="#app-notification">
    <tr-alert-notification
      :full-width="true"
      type="warning"
      :close-button="true"
      @close="onClose"
    >
      <div
        class="cursor-pointer"
        @click="onClick"
      >
        {{ message }}
      </div>
    </tr-alert-notification>
  </teleport>
</template>

<script>
import pixKeyRepr from '@/commons/formatters/pixKeyRepr';

export default {
  name: 'waiting-claim-approval',
  props: {
    pixKeys: {
      required: true,
      type: Array
    }
  },
  emits: ['close', 'see-details'],
  computed: {
    message() {
      if (!this.pixKeys.length) {
        return '';
      }

      if (this.pixKeys.length === 1) {
        return `Identificamos um pedido de registro para a chave Pix ${pixKeyRepr(
          this.pixKeys[0]
        )}. Clique aqui para confirmar ou cancelar a solicitação.`;
      }

      return 'Identificamos pedidos de registro para suas chaves Pix. Clique aqui para confirmar ou cancelar as solicitações.';
    }
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    onClick() {
      this.$emit('see-details');
    }
  }
};
</script>
