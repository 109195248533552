<template>
  <router-view />
</template>

<script>
export default {
  name: 'my-account-settings-layout',
  emits: ['loading'],
  created() {
    this.$emit('loading', false);
  }
};
</script>
