<template>
  <top-notifications />
  <proxy-modal-container />
  <router-view />
  <pix-key-registration-dialog ref="pixKeyRegistrationDialog" />
</template>

<script>
import { roles } from '@transfeeradev/api-enums';
import ProxyModalContainer from '@/apps/payments/payments/components/container/ProxyModalContainer.vue';
import settingsResource from '@/apps/payments/resources/settings';
import cpfCnpjResource from '@/apps/payments/payments/dba/resources/cpfCnpj';
import bankResource from '@/apps/payments/payments/bank/resources/bank';
import bankAccountResource from '@/apps/payments/payments/bank/resources/bankAccount';
import checkpointResource from '@/apps/payments/resources/checkpoint';
import checkpoint from '@/commons/constants/checkpoint';
import loggedUserResource from '@/commons/resources/loggedUser';
import PixKeyRegistrationDialog from '@/apps/payments/payments/components/container/notifications/PixKeyRegistrationDialog.vue';
import { getMessage } from '@/commons/services/error';
import TopNotifications from '@/apps/payments/payments/components/presentational/TopNotifications.vue';

async function prepareCaches(next) {
  try {
    await Promise.all([
      cpfCnpjResource.getBlacklist(),
      settingsResource.get(),
      bankResource.get(),
      bankAccountResource.getTransfeeraBankAccounts()
    ]);
    next(vm => {
      vm.$emit('loading', false);
    });
  } catch (error) {
    next(vm => {
      vm.$emit('loading', false);
      vm.$toaster.error(getMessage(error));
    });
  }
}

export default {
  name: 'payments-layout',
  components: {
    ProxyModalContainer,
    PixKeyRegistrationDialog,
    TopNotifications
  },
  async beforeRouteEnter(to, from, next) {
    await prepareCaches(next);
  },
  emits: ['loading'],
  data: () => ({
    appRoles: roles.appRoles,
    isSandbox: process.env.VUE_APP_MODE === 'sandbox'
  }),
  async mounted() {
    this.getUserData();

    await this.showNotifications();
  },
  methods: {
    async showNotifications() {
      if (this.isSandbox) {
        return;
      }

      const result = await Promise.all([
        checkpointResource.exists(checkpoint.HAS_FILLED_FIRST_STEPS),
        checkpointResource.exists(checkpoint.PIX_KEY_REGISTRATION_DISMISSED),
        checkpointResource.exists(checkpoint.PIX_KEY_REGISTRATION_CTA_CLICKED)
      ]);
      const hasFilledFirstSteps = result[0].exists;
      const pixKeyRegistrationDismissed = result[1].exists;
      const pixKeyRegistrationCtaClicked = result[2].exists;

      if (this.userProfile === roles.appRoles.ADMIN.value) {
        if (hasFilledFirstSteps && !pixKeyRegistrationDismissed && !pixKeyRegistrationCtaClicked) {
          this.$refs.pixKeyRegistrationDialog.open();
        }
      }
    },
    getUserData() {
      const loggedUser = loggedUserResource.get();

      this.userProfile = loggedUser.profile;
    }
  }
};
</script>
