<template>
  <div>
    <user-details
      :applications="applications"
      :on-cancel="onCancel"
      :user="user"
      :on-remove="openUserRemovalConfirmation"
      :update-user="updateUser"
      :can-change-user="canChangeUser"
      :should-disable-profile-selection="shouldDisableProfileSelection"
    />
    <user-removal-confirmation-dialog
      ref="userRemovalConfirmation"
      :on-remove="removeUser"
      :user="user"
    />
  </div>
</template>

<script>
import UserDetails from '@/commons/components/presentational/company/UserDetails';
import userResource from '@/commons/resources/companyUser';
import loggedUserResource from '@/commons/resources/loggedUser';
import UserRemovalConfirmationDialog from '@/commons/components/presentational/company/UserRemovalConfirmationDialog';
import { getAvailableApps } from '../../userApplications';
import { getAppNameByTitle } from '@/commons/services/apps';

export default {
  name: 'user-details-container',
  components: {
    UserDetails,
    UserRemovalConfirmationDialog
  },
  props: {
    onCancel: {
      required: false,
      type: Function
    },
    userId: {
      required: false,
      type: [String, Number]
    },
    onChange: {
      required: true,
      type: Function
    }
  },
  data: () => ({
    user: {},
    canChangeUser: false,
    shouldDisableProfileSelection: true,
    applications: getAvailableApps()
  }),
  created() {
    userResource.get(this.userId).then(user => {
      this.user = {
        ...user,
        applications: user.applications.map(app => getAppNameByTitle(app.name))
      };

      this.canChangeUser = this._canChangeUser(this.user);
      this.shouldDisableProfileSelection = this._shouldDisableProfileSelection(this.user);
    });
  },
  methods: {
    removeUser() {
      this.$refs.userRemovalConfirmation.close();
      userResource.remove(this.userId).then(() => {
        this.$toaster.success('Usuário removido com sucesso!');
        this.onChange();
      });
    },
    updateUser() {
      userResource
        .updateUser(this.userId, {
          profile: this.user.profile,
          applications: this.user.applications
        })
        .then(() => {
          this.$toaster.success('Perfil do usuário alterado com sucesso!');
          this.onChange();
        });
    },
    openUserRemovalConfirmation() {
      this.$refs.userRemovalConfirmation.open();
    },
    _canChangeUser(user) {
      return !this._isUserTheLoggedUser(user);
    },
    _shouldDisableProfileSelection(user) {
      return this._isUserTheLoggedUser(user);
    },
    _isUserTheLoggedUser(user) {
      const loggedUser = loggedUserResource.get();
      return Boolean(loggedUser && loggedUser.id && user && loggedUser.id === user.id);
    }
  }
};
</script>
