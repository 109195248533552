<template>
  <tr-loading
    v-if="isLoading"
    classes="center-h-and-v width-100 vertical-offset5"
  />
  <validation-history-empty-search
    v-if="!isLoading && !validations.length"
  />
  <validation-history-table
    v-if="!isLoading && validations.length"
    :validations="validations"
  />
  <tr-numeric-pagination
    v-if="!isLoading && validations.length"
    :current-page="currentPage"
    :page-size="pagination.itemsPerPage"
    :total="pagination.totalItems"
    :on-current-page-change="onCurrentPageChange"
  />
</template>

<script>
import moment from 'moment';
import ValidationHistoryTable from '../presentational/ValidationHistoryTable.vue';
import ValidationHistoryEmptySearch from '../presentational/ValidationHistoryEmptySearch.vue';
import validationResource from '@/apps/contacerta/resources/validation';
import VALIDATION_STATUS from '@/apps/contacerta/validations/constants/validationStatus';
import getByProp from '@/commons/services/getByProp';
import bankResource from '@/apps/contacerta/resources/bank';

const ITEMS_PER_PAGE = 12;
export default {
  name: 'validation-history-container',
  components: {
    ValidationHistoryTable,
    ValidationHistoryEmptySearch
  },
  props: {
    searchText: {
      type: String,
      required: false
    },
    searchStatus: {
      type: String,
      required: false
    },
    searchType: {
      type: String,
      required: false
    },
    periodFilter: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      isLoading: true,
      validations: [],
      banks: [],
      pagination: {},
      currentPage: 0
    };
  },
  watch: {
    searchText() {
      this.getValidations();
    },
    searchType() {
      this.getValidations();
    },
    searchStatus() {
      this.getValidations();
    },
    periodFilter() {
      this.getValidations();
    }
  },
  created() {
    bankResource.get().then(banks => {
      this.banks = banks;
      this.getValidations();
    });
  },
  methods: {
    onCurrentPageChange(currentPage) {
      this.currentPage = currentPage;
      this.getValidations(false);
    },
    getValidations(resetPagination = true) {
      this.isLoading = true;
      if (resetPagination) {
        this.currentPage = 0;
      }
      this.validations = [];
      const filters = this._getFilters();
      validationResource.getValidations(filters).then(result => {
        this.validations = result.data.map(validation => {
          return {
            ...validation,
            bank: this._getBank(validation)
          };
        });
        const pagination = result.metadata?.pagination || {};
        this.pagination.itemsPerPage = pagination.itemsPerPage;
        this.pagination.totalItems = pagination.totalItems;
        this.isLoading = false;
      });
    },
    _getFilters() {
      const filters = {
        page: this.currentPage,
        itemsPerPage: ITEMS_PER_PAGE
      };

      if (this.searchType) {
        filters.type = this.searchType;
      }
      if (this.searchText) {
        filters.searchTerm = this.searchText;
      }
      if (this.searchStatus) {
        switch (VALIDATION_STATUS[this.searchStatus]) {
          case VALIDATION_STATUS.INVALID:
            filters.valid = false;
            break;
          case VALIDATION_STATUS.VALIDATED:
            filters.valid = true;
            break;
          case VALIDATION_STATUS.VALIDATING:
            filters.microDepositValidated = false;
            break;
        }
      }
      if (this.periodFilter) {
        filters.initialDate = moment(this.periodFilter.initialDate).format('YYYY-MM-DD');
        filters.endDate = moment(this.periodFilter.endDate).format('YYYY-MM-DD');
      }
      return filters;
    },
    _getBank(validation) {
      if (validation.bank_code || validation.bank_ispb) {
        return getByProp.get({
          propValue: validation.bank_code || validation.bank_ispb,
          propName: validation.bank_code ? 'code' : 'ispb',
          list: this.banks
        });
      }
    }
  }
};
</script>
