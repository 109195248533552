<template>
  <tr-drag-and-drop-area-state
    icon-name="ic-golf-course"
    icon-color="grey"
    title="Pode soltar sua planilha"
    description="Iremos importar suas transferências em um único lote"
  />
</template>

<script>
export default {
  name: 'sheet-import-drag-over-state'
};
</script>
