import config from '@/config';
import fetch from '@/fetch';

const BASE_URL = config.url.api.login;

export const authApp = ({ scope, refresh_token }) => {
  return fetch(`${BASE_URL}/authorize`, {
    method: 'POST',
    body: JSON.stringify({
      grant_type: 'refresh_token',
      refresh_token,
      scope
    })
  });
};
