<template>
  <div>
    <tr-add-card
      v-if="!bankAccount || !bankAccount.account"
      :on-add-click="onCardClick"
    />
    <tr-card
      v-if="bankAccount && bankAccount.account"
      :on-card-click="onCardClick"
      :image-src="bankImage"
      :break-bigger-word="14"
      subtitle="Conta Bancária"
    >
      <div>
        <tr-typography classes="vertical-offset1 inline-block">
          Ag:
          <tr-typography strong="true">
            {{ agency }}
          </tr-typography>
          <br>
          CC:
          <tr-typography
            strong="true"
          >
            {{ account }} -
            {{ accountDigit }}
          </tr-typography>
        </tr-typography>
      </div>
    </tr-card>
  </div>
</template>

<script>
import breakBiggerWord from '@/commons/formatters/breakBiggerWord';

export default {
  name: 'transfer-card',
  props: ['bankAccount', 'onCardClick', 'bankImage', 'hideName'],
  computed: {
    agency() {
      return breakBiggerWord(this.bankAccount.agency, 10);
    },
    account() {
      return breakBiggerWord(this.bankAccount.account, 10);
    },
    accountDigit() {
      return breakBiggerWord(this.bankAccount.account_digit, 1);
    }
  }
};
</script>
