<template>
  <tr-page-section
    title="Envie o que falta para processar todos seus lotes"
    :no-margin="true"
  >
    <balance-info-container />
  </tr-page-section>
  <tr-page-section title="Lotes aguardando recebimento">
    <batches-waiting-balance-table-container />
  </tr-page-section>
  <tr-footer-buttons-container classes="inline-block">
    <tr-button
      classes="pull-right"
      label="Ver seus lotes"
      variant="raised"
      color="primary"
      route-name="BatchesPage"
      :on-button-click="onRedirect"
    />
    <tr-button
      class="pull-right margin-right"
      variant="raised"
      label="Ver extrato"
      color="default"
      route-name="StatementPage"
    />
  </tr-footer-buttons-container>
</template>

<script>
import BalanceInfoContainer from '@/apps/payments/payments/statement/components/container/BalanceInfoContainer.vue';
import BatchesWaitingBalanceTableContainer from '@/apps/payments/payments/batch/components/container/BatchesWaitingBalanceTableContainer.vue';

export default {
  name: 'batches-waiting-to-receive',
  components: {
    BalanceInfoContainer,
    BatchesWaitingBalanceTableContainer
  },
  props: {
    onRedirect: {
      required: false,
      type: Function
    }
  }
};
</script>
