// @ts-check
import { getMessage } from '@/commons/services/error';
import { getCurrentInstance, reactive, ref, watchEffect } from 'vue';

/**
 * @callback fetcherCallback
 * @param {Object} options
 * @returns {Promise<PaginatedResponse<any>>}
 */

/**
 * @param {fetcherCallback} fetcher
 */
export const usePagination = fetcher => {
  const { $toaster } = getCurrentInstance().appContext.config.globalProperties;
  const data = ref();
  const loading = ref(false);
  const searchText = ref('');

  const paginationProps = reactive({
    currentPage: 0,
    pageSize: undefined,
    total: undefined,
    onCurrentPageChange: page => {
      paginationProps.currentPage = page;
    }
  });

  const makeRequest = async () => {
    loading.value = true;

    try {
      const response = await fetcher({
        page: paginationProps.currentPage
      });

      data.value = response.data;
      paginationProps.pageSize = response.metadata.pagination.itemsPerPage;
      paginationProps.total = response.metadata.pagination.totalItems;
    } catch (error) {
      $toaster.error(getMessage(error));
    } finally {
      loading.value = false;
    }
  };

  watchEffect(makeRequest);

  return {
    refresh: makeRequest,
    searchText,
    data,
    loading,
    paginationProps
  };
};
