import { billetStatus } from '@transfeeradev/api-enums';

export default {
  [billetStatus.CREATED]: 'grey',
  [billetStatus.SCHEDULED]: 'green',
  [billetStatus.PAID]: 'blue',
  [billetStatus.FINISHED]: 'blue',
  [billetStatus.FAILED]: 'red',
  [billetStatus.RETURNED]: 'yellow',
  [billetStatus.EM_REMESSA]: 'green'
};
