<template>
  <main>
    <router-link
      :to="{ name: 'PaymentLinkListPage' }"
    >
      <div
        class="payment-link-navigation"
      >
        <bg-icon
          size="sm"
          name="left-arrow"
          color="grey-dark"
        />
        <bg-text
          type="body-md"
          color="grey-dark"
        >
          Voltar
        </bg-text>
      </div>
    </router-link>
    <bg-text
      type="h5"
      color="grey-dark"
      weight="bold"
    >
      Criar Link de Pagamento
    </bg-text>
    <tr-form
      ref="form"
      :on-valid-submit="onSubmit"
    >
      <div class="payment-link-cards">
        <bg-card class="payment-link-card">
          <bg-text
            type="h6"
            color="grey-dark"
            weight="bold"
          >
            Informações do link
          </bg-text>
          <tr-text-field
            v-model="formModel.name"
            label="Nome do link"
            name="name"
            :required="true"
            error-message="Nome é obrigatório"
            placeholder="Link de Pagamentos"
            classes="col-lg-12 no-padding payment-link-card__title"
            :maxlength="50"
          />
          <div class="width-100 payment-link-card__amount-container">
            <tr-text-field
              v-model="formModel.amount"
              label="Valor"
              name="value"
              :required="true"
              error-message="Valor é obrigatório"
              placeholder="R$ 0,00"
              type="currency"
              classes="col-lg-6 no-padding"
            />
            <div class="col-lg-6 no-padding payment-link-form__expire-date-tooltip">
              <tr-text-field
                v-model="formModel.expirationDate"
                label="Data de expiração"
                name="expirationDate"
                :required="true"
                error-message="Data de expiração é obrigatório"
                type="date"
                placeholder="00/00/0000"
                classes="col-lg-12 no-padding"
              />
              <bg-icon
                v-tooltip="`A data de expiração é referente ao Link
                            de Pagamento. Para pagamentos via Pix,
                            o pagamento poderá ser efetuado em até
                            1 dia a partir da geração do QR Code.`"
                size="sm"
                name="info-outline"
                color="blue-semi-dark"
              />
            </div>
          </div>
          <tr-text-field
            v-model="formModel.description"
            label="Descrição (opcional)"
            name="description"
            type="textarea"
            placeholder="Descrição do link"
            :maxlength="100"
            classes="no-padding"
          />
          <bg-text
            type="h6"
            color="grey-dark"
            weight="bold"
            class="payment-link-card__payment-method-title"
          >
            Métodos de pagamento
          </bg-text>
          <fieldset>
            <legend>Selecione os métodos de pagamento</legend>
            <bg-text
              is="span"
              v-if="paymentMethodsError"
              type="body-sm"
              color="red-normal"
            >
              Método de pagamento é obrigatório
            </bg-text>
            <div>
              <input
                id="pix"
                v-model="formModel.paymentMethods"
                type="checkbox"
                class="cursor-pointer"
                style="margin-left: 10px"
                value="pix"
              >
              <bg-text
                is="label"
                class="margin-left-10"
                for="pix"
                type="body-md"
                color="grey-dark"
              >
                Pix
              </bg-text>
            </div>
            <div v-if="hasCardCharge">
              <input
                id="credit_card"
                v-model="formModel.paymentMethods"
                type="checkbox"
                class="cursor-pointer"
                style="margin-left: 10px"
                value="credit_card"
              >
              <bg-text
                is="label"
                class="margin-left-10"
                for="credit_card"
                type="body-md"
                color="grey-dark"
              >
                Cartão de crédito
              </bg-text>
            </div>
            <div v-if="hasBoletoCharge">
              <input
                id="boleto"
                v-model="formModel.paymentMethods"
                type="checkbox"
                class="cursor-pointer"
                style="margin-left: 10px"
                value="boleto"
              >
              <bg-text
                is="label"
                class="margin-left-10"
                for="boleto"
                type="body-md"
                color="grey-dark"
              >
                Boleto bancário
              </bg-text>
            </div>
          </fieldset>
          <installments-select
            v-if="isCreditCardChosen"
            :amount="formModel.amount"
            @installment-selected="onInstallmentChange"
          />
          <div
            v-if="isBoletoChosen"
            class="payment-link-card__boleto-expire-day"
          >
            <bg-text
              type="body-md"
              color="grey-dark"
            >
              Em quantos dias úteis o boleto vencerá após sua geração?
            </bg-text>
            <bg-icon
              v-tooltip="`O prazo é contado a partir do momento em
                        que seu cliente preenche as informações no
                        link e gera o boleto.`"
              size="sm"
              name="info-outline"
              color="blue-semi-dark"
            />
          </div>
          <tr-text-field
            v-if="isBoletoChosen"
            v-model="formModel.boletoExpirationDays"
            label=""
            name="boletoExpirationDays"
            :required="hasBoletoCharge"
            error-message="Data de expiração do boleto é obrigatório"
            type="number"
            placeholder="1"
            classes="col-lg-12 no-padding payment-link-card__boleto-expire-day-input"
            :maxlength="3"
          />
        </bg-card>
        <payment-link-summary
          :summary="formModel"
          :is-credit-card-chosen="isCreditCardChosen"
          :is-boleto-chosen="isBoletoChosen"
          :open-cancel-dialog="openCancelDialog"
        />
      </div>
    </tr-form>
  </main>
  <payment-link-cancel-dialog
    ref="cancelDialog"
  />
  <payment-link-success-dialog
    ref="successDialog"
  />
</template>;

<script setup>
import { computed, ref } from 'vue';
import { BgIcon, BgText, BgCard } from '@transfeeradev/bridge';
import PaymentLinkCancelDialog from '@/apps/payin/paymentLink/components/PaymentLinkCancelDialog.vue';
import PaymentLinkSuccessDialog from '@/apps/payin/paymentLink/components/PaymentLinkSuccessDialog.vue';
import PaymentLinkSummary from '@/apps/payin/paymentLink/components/PaymentLinkSummary.vue';
import InstallmentsSelect from '@/apps/payin/paymentLink/components/InstallmentsSelect.vue';
import { createPaymentLink } from '@/apps/payin/paymentLink/services/paymentLink';
import { useErrorHandler } from '@/commons/composables/useErrorHandler';
import checkpoint from '@/commons/constants/checkpoint';
import checkpointResource from '@/commons/resources/checkpoint';
import settingsResource from '@/apps/payments/resources/settings';

const { handleError } = useErrorHandler();

const cancelDialog = ref();
const successDialog = ref();
const form = ref();
const settings = ref();
const paymentMethodsError = ref(false);

const openCancelDialog = () => {
  cancelDialog.value.open();
};

const openSuccessDialog = paymentData => {
  successDialog.value.open(paymentData);
};

const onCreatePaymentLink = async () => {
  checkpointResource.create(checkpoint.PAYMENT_LINK_CREATE_CLICKED);
  try {
    const paymentData = await createPaymentLink(formModel.value);
    openSuccessDialog(paymentData);
  } catch (e) {
    handleError(e, 'Ocorreu um erro ao criar o Link de Pagamentos.');
  }
};

const onInstallmentChange = installments => {
  formModel.value.installments = installments;
};

const onSubmit = () => {
  if (isFormReady.value) {
    onCreatePaymentLink();
    paymentMethodsError.value = false;
  } else {
    paymentMethodsError.value = true;
  }
};

const formModel = ref({
  name: '',
  amount: 0,
  expirationDate: '',
  description: '',
  paymentMethods: [],
  boletoExpirationDays: 1,
  installments: 1
});

const isBoletoChosen = computed(() => {
  return formModel.value.paymentMethods.includes('boleto');
});

const isCreditCardChosen = computed(() => {
  return formModel.value.paymentMethods.includes('credit_card');
});

const hasBoletoCharge = computed(() => {
  return settings.value?.hasBoletoCharge;
});

const hasCardCharge = computed(() => {
  return settings.value?.hasCardCharge;
});

const isFormReady = computed(() => {
  return formModel.value.paymentMethods.length !== 0;
});

const getSettings = async () => {
  try {
    const response = await settingsResource.get();
    settings.value = response;
  } catch (e) {
    handleError(e, 'Ocorreu um erro ao buscar as configurações.');
  } finally {
    emit('loading', false);
  }
};

getSettings();

const emit = defineEmits(['loading']);

checkpointResource.create(checkpoint.PAYMENT_LINK_CREATION_PAGE_VIEWED);
</script>

<style scoped>
main {
  h5 {
    margin-top: var(--bg-spacing-md);
  }
}

.payment-link-navigation {
  display: flex;
  align-items: center;
  gap: var(--bg-spacing-x-sm);
  margin-top: var(--bg-spacing-md);
  cursor: pointer;
}

.payment-link-cards {
  display: grid;
  grid-template-areas: 'payment-info summary';
  grid-template-columns: 2fr 1fr;
  gap: var(--bg-spacing-sm);
  margin-bottom: var(--bg-spacing-x-lg);
}

.payment-link-cards :deep(.bg-card) {
  box-shadow: none;
  border: 1px solid var(--bg-grey-light);
}

.payment-link-card {
  margin-top: var(--bg-spacing-sm);
  grid-area: payment-info;

  form {
    display: flex;
    flex-direction: column;
    margin-top: var(--bg-spacing-md);

    div {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.payment-link-card__title {
  margin-top: var(--bg-spacing-md);
}

fieldset {
  margin-top: var(--bg-spacing-md);

  legend {
    margin: 0;
    font-size: var(--bg-font-size-2);
    color: var(--bg-grey-dark);
    border-bottom: none;
  }

  input:focus-visible {
    outline: 1px solid var(--bg-blue-semi-dark);
  }

  div:nth-child(2) {
    margin-top: var(--bg-spacing-x-sm);
  }
}

.payment-link-card__boleto-expire-day {
  display: flex;
  align-items: center;
  margin-top: var(--bg-spacing-sm);

  svg {
    margin-left: var(--bg-spacing-x-sm);
  }
}

.payment-link-form__expire-date-tooltip {
  position: relative;

  svg {
    position: absolute;
    left: 120px;
  }
}

.payment-link-card__boleto-expire-day-input {
  margin-top: var(--bg-spacing-2x-sm);
}

.payment-link-card__payment-method-title {
  margin-top: calc(var(--bg-spacing-sm) - var(--bg-spacing-2x-sm));
}

.payment-link-card__amount-container {
  display: flex;
  gap: var(--bg-spacing-sm);
}

.no-padding {
  padding: 0;
}
</style>
