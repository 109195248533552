import formatCpfCnpj from '@/commons/formatters/cpfCnpj';
import formatPhone from '@/commons/formatters/phone';
import { pix } from '@transfeeradev/api-enums';
import PersonTypeService from '@transfeeradev/api-services/PersonTypeService';

export default ({ key, key_type }) => {
  if (!key_type) {
    if (key && key.startsWith('+')) {
      key = key.slice(3);
      return formatPhone(key);
    } else if (PersonTypeService.isValidCNPJ(key) || PersonTypeService.isValidCPF(key)) {
      return formatCpfCnpj(key);
    }

    return key || '';
  }

  if ([pix.keyType.CPF, pix.keyType.CNPJ].includes(key_type)) {
    return formatCpfCnpj(key);
  }
  if (key_type === pix.keyType.TELEFONE) {
    if (key.startsWith('+')) {
      key = key.slice(3);
    }
    return formatPhone(key);
  }
  if (key_type === pix.keyType.CHAVE_ALEATORIA && !key) {
    return 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx';
  }
  return key;
};
