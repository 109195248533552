import { useToaster } from '@/commons/composables/useToaster';
import { getMessage } from '@/commons/services/error';
import { captureException } from '@sentry/vue';

export function useErrorHandler() {
  const toaster = useToaster();

  const handleError = (err, fallbackMessage) => {
    toaster.error(getMessage(err, fallbackMessage));
    captureException(err);

    if (process.env.VUE_APP_MODE !== 'production') {
      console.error(err);
    }
  };

  return {
    handleError
  };
}
