<template>
  <tr-dialog
    ref="modal"
    :hide-cancel-button="true"
    name="blocked-user-error-dialog"
    title="Você possui pendências de fatura!"
  >
    <div class="text-center width-100">
      <tr-svg-icon
        size="x-large"
        name="blocked-user-icon"
      />
    </div>
    <div>
      <tr-typography
        classes="vertical-offset2 inline-block"
        variant="h5"
        strong="true"
      >
        Sua fatura venceu e não recebemos a confirmação do pagamento!
      </tr-typography>
    </div>
    <div>
      <tr-typography
        classes="vertical-offset2 inline-block"
        variant="h5"
      >
        Para voltar a criar e editar pagamentos ou recebedores, você precisa regularizar a fatura em atraso.
        <router-link
          class="cursor-pointer"
          :to="{ name: 'BillingPage' }"
        >
          <span @click="close">Baixe seu boleto aqui</span>
        </router-link>, ou entre em contato pelo e-mail financeiro@transfeera.com para regularizar sua situação!
      </tr-typography>
    </div>
    <template #buttons>
      <tr-button
        classes="pull-right"
        :on-button-click="confirm"
        label="Utilizar apenas para visualização"
        variant="raised"
        color="primary"
      />
    </template>
  </tr-dialog>
</template>

<script>
export default {
  name: 'blocked-user-error-dialog',
  props: {
    onCancel: {
      type: Function
    }
  },
  methods: {
    open() {
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
    confirm() {
      this.close();
    }
  }
};
</script>
