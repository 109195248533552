<template>
  <tr-dialog
    ref="modal"
    name="pix-cashin-entry-details-dialog"
    :hide-cancel-button="true"
    :has-its-own-buttons="true"
    :title="payer.name"
    :width="540"
    :min-width="540"
  >
    <div>
      <div class="row">
        <tr-pill
          class="col-xs-6"
          color="blue"
        >
          {{ type === entryType.DEPOSIT ? 'Pagador' : 'Recebedor' }}
        </tr-pill>
      </div>
      <div class="row">
        <tr-read-only-text-field
          label="Chave Pix (CPF/CNPJ)"
          :value="cpfCnpj"
        />
      </div>
      <div class="row">
        <tr-read-only-text-field
          label="Banco"
          :value="payer.bank.name"
        />
      </div>
      <div class="row">
        <tr-read-only-text-field
          classes="col-xs-4"
          label="Agência"
          :value="payer.agency"
          :dont-use-col-classes="true"
        />
        <tr-read-only-text-field
          classes="col-xs-8"
          :label="accountLabel"
          :value="accountWithDigit"
          :dont-use-col-classes="true"
        />
      </div>
      <tr-button
        class="bottom-offset3"
        variant="outlined"
        color="secondary"
        label="Voltar"
        :on-button-click="close"
      />
    </div>
  </tr-dialog>
</template>

<script>
import { accountType } from '@transfeeradev/api-enums';
import entryType from '@transfeeradev/api-enums/statement/entryType';
import PersonTypeService from '@transfeeradev/api-services/PersonTypeService';
import accountTypeNames from '@/commons/constants/accountType';

export default {
  name: 'pix-cashin-entry-details-dialog',
  data: () => {
    return {
      entryType,
      payer: {
        bank: {}
      },
      type: entryType.DEPOSIT
    };
  },
  computed: {
    accountLabel() {
      const name = accountTypeNames[this.payer.account_type];
      if (!name) {
        return accountTypeNames[accountType.CONTA_CORRENTE];
      }

      return name;
    },
    accountWithDigit() {
      return this.payer.account + this.payer.account_digit;
    },
    cpfCnpj() {
      if (PersonTypeService.isObfuscatedCPF(this.payer.document)) {
        return this.payer.document;
      }

      let document = PersonTypeService.format(this.payer.document);
      if (PersonTypeService.isCPF(this.payer.document)) {
        document = PersonTypeService.obfuscateCPF(document);
      }
      return document;
    }
  },
  methods: {
    open(payer, type) {
      this.payer = payer;
      this.type = type;
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    }
  }
};
</script>
