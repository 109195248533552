import introJs from 'intro.js';
import 'intro.js/introjs.css';
import './tour.css';

import checkpointResource from '@/commons/resources/checkpoint';
import { captureException } from '@sentry/vue';
import { warn } from 'vue';

/**
 * @callback defineStepsCallback
 * @param {HTMLElement[]} elements
 * @return {introJs.Step[]}
 */

/**
 *
 * @param {Object} options
 * @param {string[]} [options.elementsIds]
 * @param {string[]} [options.elementSelectors]
 * @param {string} [options.checkpointId]
 */
export const useTour = ({ elementsIds, elementSelectors, checkpointId }) => {
  const getTourElements = () => {
    const TRIES = 5;
    let tries = 0;

    const tryGetElements = (resolve, reject) => {
      tries++;

      setTimeout(() => {
        if (tries >= TRIES) {
          reject(new Error('Failed to get tour elements'));
        } else {
          let tourElements = [];
          let isComplete = false;

          if (elementSelectors?.length) {
            tourElements = elementSelectors.map(selector => {
              return document.querySelector(selector);
            });

            isComplete = tourElements.every(el => !!el) && tourElements.length === elementSelectors.length;
          } else if (elementsIds?.length) {
            tourElements = elementsIds.map(id => {
              return document.querySelector(`[data-tour="${id}"]`);
            });

            isComplete = tourElements.every(el => !!el) && tourElements.length === elementsIds.length;
          }

          if (isComplete) {
            resolve(tourElements);
          } else {
            tryGetElements(resolve, reject);
          }
        }
      }, 1000);
    };

    if (elementsIds?.length || elementSelectors?.length) {
      return new Promise(tryGetElements);
    } else {
      warn('No tour elements defined');
    }
  };

  /**
   * @param {defineStepsCallback} getSteps
   */
  const defineSteps = (getSteps, options = {}) => {
    const introJsInstance = introJs();

    const start = async () => {
      try {
        if (checkpointId) {
          const response = await checkpointResource.exists(checkpointId);

          if (response.exists) {
            introJsInstance.exit();
            return;
          }
        }

        const elements = await getTourElements();

        const steps = getSteps(elements);

        return introJsInstance
          .setOptions({
            buttonClass: 'btn btn-sm',
            nextLabel: 'Próximo',
            prevLabel: 'Anterior',
            doneLabel: 'Finalizar',
            disableInteraction: true,
            showBullets: steps?.length > 1,
            tooltipClass: 'bg-tour' + (steps?.length > 1 ? '' : ' without-bullet'),
            ...options,
            steps
          })
          .onexit(() => {
            if (checkpointId) {
              checkpointResource.create(checkpointId);
            }
          })
          .start();
      } catch (e) {
        warn(e);
        captureException(e);
      }
    };

    const onChange = cb => {
      introJsInstance.onchange(cb);
      return ctx;
    };

    const ctx = {
      start,
      onChange
    };

    return ctx;
  };

  return {
    defineSteps
  };
};
