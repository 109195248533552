import config from '@/config';
import fetch from '@/fetch';
import validationSource from '@transfeeradev/api-enums/contacerta/validationSource';

const BASE_URL = config.url.contacerta + '/app/validation';

export default {
  createValidation: (type, payload) =>
    fetch(`${BASE_URL}?type=${type}`, {
      method: 'POST',
      body: JSON.stringify(payload)
    }),
  getValidation: id =>
    fetch(`${BASE_URL}/${id}`, {
      method: 'GET'
    }),
  getValidations: (filter = {}) => {
    const params = new URLSearchParams({
      ...filter,
      source: validationSource.APP
    });
    return fetch(`${BASE_URL}?${params.toString()}`, {
      method: 'GET'
    });
  }
};
