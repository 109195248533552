<template>
  <div class="text-center">
    <failed-transfers-per-status-description-chart
      v-show="!showBlankslate"
      :date-range="dateRange"
      :on-load="onLoad"
      :height="'170vh'"
    />
    <img
      v-show="showBlankslate"
      :src="'/assets/img/blankslate/blankslate-round-chart.svg'"
    >
  </div>
</template>

<script>
import FailedTransfersPerStatusDescriptionChart from '@/apps/payments/payments/indicator/components/presentational/FailedTransfersPerStatusDescriptionChart';

export default {
  name: 'failed-transfers-per-status-description-chart-container',
  components: {
    FailedTransfersPerStatusDescriptionChart
  },
  props: {
    dateRange: {
      required: true
    }
  },
  data: () => ({
    showBlankslate: false
  }),
  watch: {
    dateRange: {
      deep: true,
      handler() {
        this.showBlankslate = false;
      }
    }
  },
  methods: {
    onLoad(data) {
      if (!data.length) {
        this.showBlankslate = true;
      }
    }
  }
};
</script>
