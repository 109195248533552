<template>
  <div class="security-container__title">
    <bg-text type="h5">
      Autenticação em 2 fatores
    </bg-text>
    <bg-badge
      v-if="tfaState.hasTfaEnabled !== null"
      :color="tfaState.hasTfaEnabled ? 'green-light' : 'red-light'"
      size="sm"
      class="security-container__badge"
    >
      <bg-text
        :color="tfaState.hasTfaEnabled ? 'green-semi-dark' : 'red-semi-dark'"
      >
        {{ tfaState.hasTfaEnabled ? 'Ativada' : 'Desativada' }}
      </bg-text>
    </bg-badge>
  </div>
  <bg-text class="security-container__description">
    Para garantir a proteção máxima dos seus dados, será necessário ativar a Autenticação em 2 Fatores (2FA) até dia <bg-text
      is="strong"
      weight="bold"
    >
      29/07.
    </bg-text>
  </bg-text>
  <tfa-container
    v-if="tfaState.hasTfaEnabled !== null"
    @activate="onActivate"
  />
  <tr-loading v-else />
</template>

<script setup>
import { BgText, BgBadge } from '@transfeeradev/bridge';
import TfaContainer from './TfaContainer.vue';
import { useRouter } from 'vue-router';
import { fetchTfaState, tfaState } from '@/commons/services/tfa';
import { useErrorHandler } from '@/commons/composables/useErrorHandler';

const { handleError } = useErrorHandler();
const router = useRouter();

const onActivate = () => {
  router.push('/');
};

fetchTfaState().catch(e => {
  handleError(e, 'Erro ao buscar estado da 2FA');
});
</script>

<style scoped>
.security-container__title {
  display: flex;
  align-items: center;
  gap: var(--bg-spacing-x-sm);
}

.security-container__description {
  margin-top: var(--bg-spacing-sm);
}

.security-container__badge {
  height: 24px;
}
</style>
