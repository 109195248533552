<template>
  <div>
    <pix-keys-container :open-pix-key-wizard-dialog-on-init="openPixKeyWizardDialogOnInit" />
    <pix-account-container v-if="hasAnyRegisteredKey" />
    <bank-accounts-container />
  </div>
</template>

<script>
import BankAccountsContainer from '@/apps/payments/payments/settings/bankAccounts/components/container/BankAccountsContainer';
import PixKeysContainer from '@/apps/payments/payments/settings/pixKeys/components/container/PixKeysContainer';
import PixAccountContainer from '@/apps/payments/payments/settings/bankAccounts/components/container/PixAccountContainer';
import pixKeyResource from '@/apps/payments/payments/settings/pixKeys/resources/pixKey';
import { getMessage } from '@/commons/services/error';

export default {
  name: 'bank-accounts-page',
  components: {
    BankAccountsContainer,
    PixKeysContainer,
    PixAccountContainer
  },
  props: {
    openPixKeyWizardDialogOnInit: Boolean
  },
  data: () => ({
    hasAnyRegisteredKey: false
  }),
  mounted() {
    this.refresh();
  },
  methods: {
    async refresh() {
      this.isLoading = true;

      try {
        const pixKeys = await pixKeyResource.getRegistered();
        this.hasAnyRegisteredKey = pixKeys.length > 0;
      } catch (error) {
        this.$toaster.error(getMessage(error));
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>
