<template>
  <bg-card class="receivable-summary-card">
    <div class="receivable-summary-card__header">
      <div class="receivable-summary-card__icon-container">
        <bg-icon
          size="md"
          :color="receivableSummaryColor[status].icon"
          :name="receivableSummaryIcon[status]"
          class="receivable-summary-card__icon"
        />
      </div>
      <bg-text
        is="h5"
        color="grey-semi-dark"
        type="body-sm"
        class="receivable-summary-card__label"
      >
        {{ receivableSummaryLabel[status] }}
        <bg-icon
          v-if="description"
          v-tooltip.top-center="description"
          class="receivable-summary-card__info-icon"
          size="md"
          color="blue-semi-dark"
          name="info-outline"
        />
      </bg-text>
    </div>
    <div class="receivable-summary-card__content-container">
      <bg-text
        color="grey-dark"
        type="h6"
        weight="bold"
      >
        {{ centsToReal(amount) }}
      </bg-text>
    </div>
    <div
      v-if="disabled"
      class="receivable-summary-card__overlay"
    />
  </bg-card>
</template>

<script setup>
import { BgIcon, BgText, BgCard } from '@transfeeradev/bridge';
import { receivableStatus } from '@/apps/payin/receivables/constants/receivableStatus';
import { centsToReal } from '@/commons/formatters/currency';

defineProps({
  status: {
    type: String,
    required: true
  },
  amount: {
    type: Number,
    required: true,
    default: 0
  },
  disabled: {
    type: Boolean,
    default: false
  },
  description: {
    type: String,
    default: ''
  }
});

const receivableSummaryColor = {
  [receivableStatus.RECEIVED]: { background: 'green-light', icon: 'green-semi-dark', width: '28px' },
  [receivableStatus.AUTHORIZED]: { background: 'green-light', icon: 'green-semi-dark', width: '28px' },
  [receivableStatus.PENDING]: { background: 'yellow-light', icon: 'yellow-semi-dark', width: '32px' },
  [receivableStatus.CANCELED]: { background: 'grey-semi-light', icon: 'grey-dark', width: '32px' },
  [receivableStatus.UNAUTHORIZED]: { background: 'red-light', icon: 'red-semi-dark', width: '32px' }
};

const receivableSummaryIcon = {
  [receivableStatus.RECEIVED]: 'check-price',
  [receivableStatus.AUTHORIZED]: 'calendar_today',
  [receivableStatus.PENDING]: 'clock',
  [receivableStatus.CANCELED]: 'cancel',
  [receivableStatus.UNAUTHORIZED]: 'error'
};

const receivableSummaryLabel = {
  [receivableStatus.RECEIVED]: 'Recebidos',
  [receivableStatus.AUTHORIZED]: 'A receber',
  [receivableStatus.PENDING]: 'Pendentes',
  [receivableStatus.CANCELED]: 'Cancelados',
  [receivableStatus.UNAUTHORIZED]: 'Negados'
};
</script>

<style scoped>
.receivable-summary-card {
  position: relative;
  display: flex;
  border: 1px solid var(--bg-grey-light);
  flex-direction: column;
  gap: var(--bg-spacing-sm);
  flex-grow: 1;
}

.receivable-summary-card__content-container {
  text-align: center;
}

.receivable-summary-card__icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 48px;
  border-radius: var(--bg-border-radius-md);
  background-color: v-bind('`var(--bg-${receivableSummaryColor[status].background})`');
}

.receivable-summary-card__icon {
  width: v-bind(receivableSummaryColor[status].width);
  height: v-bind(receivableSummaryColor[status].width);
}

.receivable-summary-card__label {
  display: flex;
  align-items: center;
  gap: var(--bg-spacing-2x-sm);
}

.receivable-summary-card__info-icon {
  position: relative;
  z-index: 2;
}

.receivable-summary-card__overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--bg-grey-light);
  opacity: 0.4;
}

.receivable-summary-card__header {
  display: flex;
  gap: var(--bg-spacing-sm);
}
</style>
