<template>
  <tr-dialog
    ref="modal"
    name="transfer-details-dialog"
    title="Detalhes da transferência"
    :on-close="onHidden"
    cancel-button-label="Voltar"
  >
    <transfer-details-container
      :show-batch-fullscreen-modal="showBatchFullscreenModal"
      :transfer-id="transferId"
    />
  </tr-dialog>
</template>

<script>
import TransferDetailsContainer from '@/apps/payments/payments/transfer/components/container/TransferDetailsContainer';

export default {
  name: 'transfer-details-dialog',
  components: {
    TransferDetailsContainer
  },
  props: {
    onHidden: {
      required: false,
      type: Function
    },
    transferId: {
      required: false,
      type: String
    },
    showBatchFullscreenModal: {
      required: false,
      type: Function
    }
  },
  methods: {
    open() {
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    }
  }
};
</script>
