<template>
  <tr-page-section>
    <tr-loading
      v-if="isLoading"
      classes="center-h-and-v width-100 vertical-offset5"
    />
    <receivers-first-use-blankslate v-if="shouldShowBlankslate && !hasItems && !isLoading" />
    <div class="is-flex">
      <tr-hide-for-roles :roles="appRoles.ANALYST">
        <tr-button
          v-if="receivers.length && !isLoading"
          label="Excluir selecionados"
          variant="raised"
          size="medium"
          color="danger"
          :disabled="!checkedReceivers.length"
          :on-button-click="confirmRemoval"
        />
      </tr-hide-for-roles>
    </div>
    <receivers-bulk-delete-confirmation-dialog
      ref="receiversBulkDeleteConfirmationDialog"
      :on-confirm="deleteReceivers"
    />
    <receivers-table
      v-if="hasItems && !isLoading"
      :receivers="receivers"
      :on-item-click="openRecieverManagementFullscreenModal"
      @receivers-checked="onReceiversChecked"
    />
    <tr-empty-search-result
      v-if="!shouldShowBlankslate && !hasItems && !isLoading"
      text="Nenhum recebedor foi encontrado na sua busca"
    />
    <tr-numeric-pagination
      v-if="!isLoading"
      :current-page="currentPage"
      :page-size="pagination.itemsPerPage"
      :total="pagination.totalItems"
      :on-current-page-change="onCurrentPageChange"
    />
    <receiver-management-fullscreen-modal
      ref="receiverManagementFullscreenModal"
      :on-change="refresh"
      :receiver-id="selectedReceiverId"
    />
  </tr-page-section>
</template>

<script>
import ReceiversTable from '@/apps/payments/payments/receivers/components/presentational/ReceiversTable.vue';
import ReceiversFirstUseBlankslate from '@/apps/payments/payments/receivers/components/presentational/ReceiversFirstUseBlankslate.vue';
import ReceiversBulkDeleteConfirmationDialog from '@/apps/payments/payments/receivers/components/presentational/ReceiversBulkDeleteConfirmationDialog.vue';
import receiversResource from '@/apps/payments/payments/receivers/resources/receivers';
import ReceiverManagementFullscreenModal from '@/apps/payments/payments/receivers/pages/ReceiverManagementFullscreenModal.vue';
import { getMessage } from '@/commons/services/error';
import { roles } from '@transfeeradev/api-enums';

export default {
  name: 'receivers-container',
  components: {
    ReceiverManagementFullscreenModal,
    ReceiversTable,
    ReceiversFirstUseBlankslate,
    ReceiversBulkDeleteConfirmationDialog
  },
  props: {
    selectedBankId: {
      required: false,
      type: String,
      default: null
    },
    selectedStatus: {
      required: false,
      type: String,
      default: null
    },
    searchedText: {
      resources: false,
      type: String,
      default: null
    }
  },
  data: () => ({
    receivers: [],
    selectedReceiverId: null,
    isLoading: false,
    currentPage: 0,
    shouldShowBlankslate: false,
    pagination: {},
    checkedReceivers: [],
    appRoles: roles.appRoles
  }),
  computed: {
    hasItems() {
      return Boolean(this.receivers && this.receivers.length);
    }
  },
  watch: {
    selectedBankId() {
      this.refresh();
    },
    selectedStatus() {
      this.refresh();
    },
    searchedText() {
      this.refresh();
    }
  },
  created() {
    return this.refresh().then(() => {
      this.shouldShowBlankslate = !this.receivers.length;
    });
  },
  methods: {
    refresh(resetPagination = true) {
      this.isLoading = true;

      if (resetPagination) {
        this.currentPage = 0;
      }

      return receiversResource
        .getFiltered({
          page: this.currentPage,
          bankId: this.selectedBankId,
          status: this.selectedStatus,
          search: this.searchedText
        })
        .then(
          result => {
            this.isLoading = false;
            this.checkedReceivers = [];
            this.pagination = result.metadata.pagination;
            this.receivers = result.data || [];
          },
          error => this.$toaster.error(getMessage(error))
        );
    },
    onCurrentPageChange(currentPage) {
      this.currentPage = currentPage;
      this.refresh(false);
    },
    openRecieverManagementFullscreenModal(receiver) {
      this.selectedReceiverId = receiver && receiver.id;
      this.$refs.receiverManagementFullscreenModal.open();
    },
    onReceiversChecked(receiversIds) {
      this.checkedReceivers = receiversIds;
    },
    confirmRemoval() {
      this.$refs.receiversBulkDeleteConfirmationDialog.open();
    },
    closeConfirmRemovalModal() {
      this.$refs.receiversBulkDeleteConfirmationDialog.close();
    },
    async deleteReceivers() {
      try {
        this.closeConfirmRemovalModal();
        this.isLoading = true;
        await receiversResource.bulkDelete(this.checkedReceivers);
        this.$toaster.success('Recebedores excluídos com sucesso');
        this.refresh();
      } catch (error) {
        this.$toaster.error(getMessage(error));
      }
      this.isLoading = false;
    }
  }
};
</script>
