<template>
  <tr-form>
    <entry-form
      ref="entryForm"
    />
    <tr-dialog-buttons-container>
      <tr-button
        classes="pull-left"
        :label="'Fechar'"
        variant="raised"
        color="secondary"
        :on-button-click="close"
      />
    </tr-dialog-buttons-container>
  </tr-form>
</template>

<script>
import EntryForm from '@/apps/payments/payments/statement/components/presentational/EntryForm';

export default {
  name: 'entry-form-container',
  components: {
    EntryForm
  },
  props: {
    close: {
      required: false,
      type: Function
    }
  }
};
</script>
