<template>
  <div>
    <transfeera-bank-accounts
      :selected-bank-account="selectedBankAccount || selectedDataBankAccount"
      :on-select-bank-account="onSelectBankAccount || selectDataBankAccount"
      :default-bank-account="defaultBankAccount"
      :full-size="fullSize"
      :no-margin-on-first-line="noMarginOnFirstLine"
    />
  </div>
</template>

<script>
import TransfeeraBankAccounts from '@/apps/payments/payments/bank/components/presentational/TransfeeraBankAccounts';

export default {
  name: 'transfeera-bank-accounts-container',
  components: {
    TransfeeraBankAccounts
  },
  props: {
    defaultBankAccount: {
      required: false
    },
    fullSize: {
      required: false,
      type: Boolean
    },
    onSelectBankAccount: {
      required: false,
      type: Function
    },
    selectedBankAccount: {
      required: false,
      type: Object
    },
    noMarginOnFirstLine: {
      required: false,
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    selectedDataBankAccount: {
      Bank: {}
    }
  }),
  methods: {
    selectDataBankAccount(bankAccount) {
      this.selectedDataBankAccount = bankAccount;
    }
  }
};
</script>
