import batchModalNameConstant from '@/apps/payments/payments/batch/constants/batchModalName';
import verifyRoleAccess from '@/commons/services/verifyRoleAccess';
import { batchStatus, roles } from '@transfeeradev/api-enums';

const get = ({ status }) => {
  if (_shouldSeeBatchForm(status)) {
    return batchModalNameConstant.BATCH_WIZARD;
  }

  if (_shouldSeeBatchToApprove(status)) {
    return batchModalNameConstant.VIEW_BATCH_TO_APPROVE;
  }

  if (_shouldSeeBatchWaitingToReceive(status)) {
    return batchModalNameConstant.VIEW_BATCH_WAITING_TO_RECEIVE;
  }

  return batchModalNameConstant.DEFAULT_VIEW_BATCH;
};

const _shouldSeeBatchForm = status => {
  const rolesForbiddenToAccess = roles.appRoles.ANALYST;
  return Boolean(
    status === batchStatus.DRAFT && !verifyRoleAccess.isLoggedUserForbiddenToAccess(rolesForbiddenToAccess)
  );
};

const _shouldSeeBatchToApprove = status => {
  const rolesAllowedToApprove = roles.appRoles.ADMIN;
  return Boolean(
    batchStatus.WAITING_APPROVAL === status && verifyRoleAccess.isLoggedUserAllowedToAccess(rolesAllowedToApprove)
  );
};

const _shouldSeeBatchWaitingToReceive = status => {
  return Boolean(batchStatus.WAITING === status);
};

export default {
  get
};
