<template>
  <div class="row">
    <tr-text-field
      label="E-mail que receberá as respostas:"
      type="email"
      :required="true"
      :pattern="EMAIL_REGEX"
      error-message="E-mail inválido"
      name="email"
      :model-value="modelValue"
      :on-value-change="updateValue"
    />
  </div>
</template>

<script>
import { regexes } from '@transfeeradev/api-enums';

export default {
  name: 'reply-to-email-config-container',
  props: {
    modelValue: {
      required: true,
      type: String
    }
  },
  emits: ['update:modelValue'],
  data: () => ({
    EMAIL_REGEX: regexes.EMAIL
  }),
  methods: {
    updateValue: function (value) {
      this.$emit('update:modelValue', value);
    }
  }
};
</script>
