/**
 * TODO: Remove this entire file and its uses.
 * https://github.com/transfeera/board/issues/2746
 */
import loggedUserResource from '@/commons/resources/loggedUser';
import { APPS } from '../constants/apps';

export const resourceGateway = ({ appResource, contacertaResource }) => {
  const currentApp = loggedUserResource.getCurrentApplication().name;

  return {
    [APPS.CONTACERTA.key]: contacertaResource,
    [APPS.PAYMENTS.key]: appResource
  }[currentApp];
};
