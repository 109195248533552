<template>
  <template v-if="currentState === transferWizardState.CORRECTION">
    <tr-page-title title="Editar pagamento">
      <template #description>
        <div class="row">
          <transfer-error-banner
            :fail-reason="failReason"
            :transfer-status="transfer.status"
          />
        </div>
      </template>
    </tr-page-title>
    <tr-page-section :no-margin="true">
      <transfer-correction-container
        :on-save="onSave"
        :batch="batch"
        :cancel="cancel"
        :transfer-edit="transfer"
      />
    </tr-page-section>
  </template>
  <template v-if="currentState === transferWizardState.FORM">
    <tr-page-title :title="`${transfer.id ? 'Editar ' : 'Criar novo'} pagamento`" />
    <tr-page-section :no-margin="true">
      <transfer-form-container
        :cancel="cancel"
        :on-save="onSave"
        :on-remove="onRemove"
        :batch-id="batch.id"
        :transfer-edit="transfer"
      />
    </tr-page-section>
  </template>
</template>

<script>
import TransferFormContainer from '@/apps/payments/payments/transfer/components/container/TransferFormContainer.vue';
import TransferCorrectionContainer from '@/apps/payments/payments/transfer/components/container/TransferCorrectionContainer.vue';
import TransferErrorBanner from '@/apps/payments/payments/transfer/components/presentational/TransferErrorBanner.vue';
import transferWizardState from '@/apps/payments/payments/transfer/constants/wizardState';
import { transferStatus, batchStatus } from '@transfeeradev/api-enums';
import getTransferFailReason from '@/apps/payments/payments/transfer/services/getTransferFailReason';

export default {
  name: 'transfer-wizard-container',
  components: {
    TransferFormContainer,
    TransferCorrectionContainer,
    TransferErrorBanner
  },
  props: {
    onRemove: {
      required: false,
      type: Function
    },
    onSave: {
      required: false,
      type: Function
    },
    cancel: {
      required: false,
      type: Function
    },
    batch: {
      required: false,
      type: Object,
      default: () => ({})
    },
    transfer: {
      required: false,
      type: Object,
      default: () => ({
        destination_bank_account: {}
      })
    }
  },
  data: () => ({
    transferWizardState,
    currentState: null
  }),
  computed: {
    failReason() {
      const transferFailReason = getTransferFailReason.get(this.transfer);

      return (
        transferFailReason ||
        (this.transfer.destination_bank_account && this.transfer.destination_bank_account.status_description)
      );
    }
  },
  created() {
    this.refresh();
  },
  methods: {
    refresh() {
      if (this._shouldOpenTransferCorrectionDialog()) {
        this.currentState = transferWizardState.CORRECTION;
      } else {
        this.currentState = transferWizardState.FORM;
      }
    },
    _shouldOpenTransferCorrectionDialog() {
      return (
        !!this.failReason ||
        (this.batch.status === batchStatus.RECEIVED && this.transfer.status === transferStatus.FAILED)
      );
    }
  }
};
</script>
