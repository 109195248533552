<template>
  <batches-waiting-balance-table
    :batches="batches"
    :batches-total="batchesTotal"
  />
</template>

<script>
import BatchesWaitingBalanceTable from '@/apps/payments/payments/batch/components/presentational/BatchesWaitingBalanceTable';
import batchResource from '@/apps/payments/payments/batch/resources/batch';
import { getMessage } from '@/commons/services/error';

export default {
  name: 'batches-waiting-balance-table-container',
  components: {
    BatchesWaitingBalanceTable
  },
  data: () => ({
    batches: [],
    batchesTotal: 0
  }),
  created() {
    return this.refresh();
  },
  methods: {
    refresh() {
      return batchResource.waiting().then(
        response => {
          this.batches = response.batches;
          this.batchesTotal = response.total;
        },
        error => this.$toaster.error(getMessage(error))
      );
    }
  }
};
</script>
