<template>
  <div :class="classes">
    <tr-alert-notification
      v-if="hasTimeError"
      type="danger"
      classes="bottom-offset2"
    >
      <tr-typography
        strong="true"
        classes="block"
      >
        Pagamentos agendados expirados
      </tr-typography>
      <tr-typography>
        Alguns pagamentos estão com a data vencida ou ultrapassaram o horário limite de pagamento.
      </tr-typography>
    </tr-alert-notification>
    <tr-alert-notification
      v-if="!hasTimeError && hasTimeWarning"
      type="warning"
      classes="bottom-offset2"
    >
      <tr-typography
        strong="true"
        classes="block"
      >
        Você possui pagamentos que serão realizados no próximo dia útil
      </tr-typography>
      <tr-typography>
        TEDs recebidos após 16h30
      </tr-typography>
    </tr-alert-notification>
    <tr-alert-notification
      v-if="!hasTimeError && hasDuplicatedIntegrationId"
      type="warning"
      classes="bottom-offset2"
    >
      <tr-typography
        strong="true"
        classes="block"
      >
        Você possui pagamentos com o mesmo ID de integração
      </tr-typography>
      <tr-typography>
        Um ou mais pagamentos possuem o mesmo identificador de integração. Verifique se não existem duplicidades.
      </tr-typography>
    </tr-alert-notification>
  </div>
</template>

<script>
export default {
  name: 'batch-form-alerts',
  props: {
    classes: {
      required: false,
      type: String,
      default: ''
    },
    hasTimeError: {
      required: false,
      type: Boolean,
      default: false
    },
    hasTimeWarning: {
      required: false,
      type: Boolean,
      default: false
    },
    hasDuplicatedIntegrationId: {
      required: false,
      type: Boolean,
      default: false
    }
  }
};
</script>
