<template>
  <tr-dialog
    ref="modal"
    :unique-name="true"
    name="batch-removal-confirmation-dialog"
    title="Excluir falhas"
    :has-its-own-buttons="true"
  >
    <tr-typography variant="h5">
      <div v-if="failedPaymentsSummary.count === 1">
        <tr-typography> Você deseja excluir o pagamento em falha? </tr-typography>
        <tr-typography
          strong="true"
          classes="vertical-offset2 inline-block"
        >
          O valor do pagamento que não será realizado é de {{ $format.currency(failedPaymentsSummary.value) }}
        </tr-typography>
      </div>
      <div v-else>
        <tr-typography> Você deseja excluir os {{ failedPaymentsSummary.count }} pagamentos em falha? </tr-typography>
        <tr-typography
          strong="true"
          classes="vertical-offset2 inline-block"
        >
          O valor dos pagamentos que não serão realizados é de {{ $format.currency(failedPaymentsSummary.value) }}
        </tr-typography>
      </div>
    </tr-typography>
    <tr-dialog-buttons-container>
      <tr-button
        classes="pull-left"
        :on-button-click="close"
        :disabled="isLoading"
        label="Cancelar"
        variant="outlined"
        color="secondary"
      />
      <tr-button
        classes="pull-right"
        :on-button-click="onConfirm"
        :disabled="isLoading"
        label="Confirmar exclusão"
        variant="raised"
        color="danger"
      />
    </tr-dialog-buttons-container>
  </tr-dialog>
</template>

<script>
export default {
  name: 'batch-failed-payments-removal-confirmation-dialog',
  props: {
    onConfirm: {
      required: true,
      type: Function
    }
  },
  data: () => ({
    failedPaymentsSummary: {
      count: 0,
      value: 0,
      ids: []
    },
    isLoading: false
  }),
  methods: {
    open(failedPaymentsSummary) {
      this.failedPaymentsSummary = failedPaymentsSummary;
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
    setIsLoading(isLoading) {
      this.isLoading = isLoading;
    }
  }
};
</script>
