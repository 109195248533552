<template>
  <span>
    <img
      :src="bankImage"
      :width="SIZES[size]"
      :class="classes"
    >
  </span>
</template>

<script>
import config from '@/config';

const SIZES = {
  small: 24,
  medium: 48
};

export default {
  name: 'bank-name',
  props: {
    isPix: {
      required: false,
      type: Boolean,
      default: false
    },
    bank: {
      required: false,
      type: Object,
      default: () => ({})
    },
    size: {
      required: false,
      type: String,
      default: 'small'
    },
    classes: {
      required: false,
      type: String,
      default: ''
    }
  },
  data: () => ({
    SIZES
  }),
  computed: {
    bankImage: function () {
      if (this.isPix) {
        return `${config.url.cdn}/banks/pix-color.svg`;
      }

      if (!this.bank || !this.bank.image) {
        return `${config.url.cdn}/banks/default_bank.svg`;
      }

      return this.bank.image;
    }
  }
};
</script>
