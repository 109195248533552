<template>
  <div
    class="payment-link-card__installments"
  >
    <bg-text
      type="body-md"
      color="grey-dark"
    >
      Parcelamento via cartão de crédito
    </bg-text>
    <select
      aria-label="Selecione o número de parcelas"
      name="installments-dropdown"
      class="payment-link-card__installments-dropdown"
      @change="$emit('installment-selected', $event.target.value)"
    >
      <option
        v-for="installment in maxInstallments"
        :key="installment"
        :value="installment"
      >
        Até {{ installment }}x de {{ formatCurrency(safeDivide(amount, installment)) }}
      </option>
    </select>
  </div>
</template>

<script setup>
import formatCurrency from '@/commons/formatters/currency';
import { safeDivide } from '@/apps/payin/receivables/helper/math';
import { BgText } from '@transfeeradev/bridge';
import { computed } from 'vue';

const props = defineProps({
  amount: { type: Number, required: true }
});

defineEmits(['installment-selected']);

const maxInstallments = computed(() => {
  return Math.min(12, props.amount * 100);
});
</script>

<style scoped>
.payment-link-card__installments {
  display: flex;
  flex-direction: column;
  margin-top: var(--bg-spacing-x-sm);
}

.payment-link-card__installments-dropdown {
  background-color: var(--bg-grey-white);
  border: 1px solid #cdd6de;
  border-radius: var(--bg-border-radius-sm);
  padding: var(--bg-padding-sm);
  margin-top: var(--bg-spacing-2x-sm);

  &:focus-visible {
    outline: none;
    border: 1px solid var(--bg-blue-semi-dark);
  }
}
</style>
