<template>
  <tr-dialog
    ref="modal"
    name="user-removal-confirmation-dialog"
    title="Excluir chave Pix"
  >
    <div>
      <tr-typography variant="h5">
        Você tem certeza que deseja excluir a chave Pix:
      </tr-typography>
      <br>
      <tr-typography
        variant="h5"
        strong="true"
      >
        {{ formattedPixKey }}
      </tr-typography>
      <tr-typography variant="h5">
        ?
      </tr-typography>
    </div>
    <template #buttons>
      <tr-button

        classes="pull-right"
        :on-button-click="remove"
        :disabled="loading"
        label="Confirmar exclusão"
        variant="raised"
        color="danger"
      />
    </template>
  </tr-dialog>
</template>

<script>
import pixKeyRepr from '@/commons/formatters/pixKeyRepr';
import breakBiggerWord from '@/commons/formatters/breakBiggerWord';

export default {
  name: 'pix-key-removal-confirmation-dialog',
  props: ['pixKey', 'loading'],
  emits: ['remove'],
  computed: {
    formattedPixKey() {
      return breakBiggerWord(pixKeyRepr(this.pixKey), 60);
    }
  },
  methods: {
    remove() {
      this.$emit('remove');
    },
    open() {
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    }
  }
};
</script>
