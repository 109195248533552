<template>
  <waiting-claim-approval
    v-if="shouldShow"
    :pix-keys="waitingApprovalKeys"
    @close="onClose"
    @see-details="goToPage"
  />
</template>

<script>
import { pix } from '@transfeeradev/api-enums';
import WaitingClaimApproval from '@/apps/payments/payments/settings/pixKeys/components/presentational/notifications/WaitingClaimApproval.vue';
import pixKeyResource from '@/apps/payments/payments/settings/pixKeys/resources/pixKey';
const { pixKeyStatus } = pix;

export default {
  name: 'waiting-claim-approval-container',
  components: {
    WaitingClaimApproval
  },
  emits: ['toggleAlert'],
  data: () => ({
    waitingApprovalKeys: [],
    shouldShow: false
  }),
  mounted() {
    this.getWatingApprovalKey();
  },
  methods: {
    async getWatingApprovalKey() {
      try {
        const pixKeys = await pixKeyResource.getList();
        this.waitingApprovalKeys = [];
        if (pixKeys) {
          this.waitingApprovalKeys = pixKeys.filter(
            ({ status }) =>
              status === pixKeyStatus.WAITING_PORTABILITY_APPROVAL || status === pixKeyStatus.WAITING_CLAIM_APPROVAL
          );
        }
      } catch (error) {
        this.waitingApprovalKeys = [];
      }

      this.shouldShow = Boolean(this.waitingApprovalKeys.length);
      this.$emit('toggleAlert', this.shouldShow);
    },
    onClose() {
      this.shouldShow = false;
      this.$emit('toggleAlert', this.shouldShow);
    },
    goToPage() {
      this.$router.push({ name: 'PaymentsBankAccountsPage' });
      this.onClose();
    }
  }
};
</script>
