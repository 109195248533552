<template>
  <tr-page-section
    v-if="currentStep === batchWizardStepsConstant.FIRST_STEPS"
    title="Dados da conta"
  >
    <first-steps-container :on-save="onFirstStepsSave" />
  </tr-page-section>
  <batch-bank-account-selection-container
    v-if="currentStep === batchWizardStepsConstant.BANK_ACCOUNT_SELECTION"
    :on-batch-sent-to-approval="onBatchSentToApproval"
    :on-cancel="close"
    :batch="savedBatch"
    :on-batch-close="onBatchCloseSuccess"
  />
  <div v-if="currentStep === batchWizardStepsConstant.BATCHES_WAITING_FOR_BALANCE">
    <tr-page-title title="Aguardando recebimento" />
    <batches-waiting-to-receive-container :on-redirect="close" />
  </div>
  <batch-form-container
    v-if="currentStep === batchWizardStepsConstant.BATCH_FORM"
    :initial-batch="batch"
    :batch-id="batch.id"
    :type="type"
    :was-just-imported="wasJustImported"
    :on-batch-save="onBatchSave"
    :close-modal="close"
    :on-cancel-wizard="onCancelWizard"
    :on-batch-remove="onBatchRemove"
    :on-batch-close="onBatchCloseSuccess"
    :on-go-to-bank-account-selection="goToBankAccountSelection"
  />
</template>

<script>
import BatchFormContainer from '@/apps/payments/payments/batch/components/container/BatchFormContainer.vue';
import batchWizardStepsConstant from '@/apps/payments/payments/batch/constants/batchWizardSteps';
import FirstStepsContainer from '@/apps/payments/payments/config/components/container/FirstStepsContainer.vue';
import BatchBankAccountSelectionContainer from '@/apps/payments/payments/batch/components/container/BatchBankAccountSelectionContainer.vue';
import BatchesWaitingToReceiveContainer from '@/apps/payments/payments/batch/components/container/BatchesWaitingToReceiveContainer.vue';
import checkpointResource from '@/apps/payments/resources/checkpoint';
import checkpoint from '@/commons/constants/checkpoint';
import { getMessage } from '@/commons/services/error';

export default {
  name: 'batch-wizard-container',
  components: {
    BatchFormContainer,
    FirstStepsContainer,
    BatchBankAccountSelectionContainer,
    BatchesWaitingToReceiveContainer
  },
  props: {
    batch: {
      required: false,
      type: Object,
      default: () => ({})
    },
    close: {
      required: true,
      type: Function
    },
    type: {
      required: false,
      type: String
    },
    wasJustImported: {
      required: false,
      type: Boolean,
      default: false
    },
    onBatchClose: {
      required: false,
      type: Function
    }
  },
  data: () => ({
    currentStep: undefined,
    batchWizardStepsConstant,
    savedBatch: null
  }),
  created() {
    checkpointResource.exists(checkpoint.HAS_FILLED_FIRST_STEPS).then(
      response => {
        if (response.exists) {
          this.currentStep = batchWizardStepsConstant.BATCH_FORM;
        } else {
          this.currentStep = batchWizardStepsConstant.FIRST_STEPS;
        }
      },
      error => this.$toaster.error(getMessage(error))
    );
  },
  methods: {
    onBatchSave() {
      this.close();
    },
    onBatchRemove() {
      this.close();
    },
    onBatchSentToApproval() {
      this.close();
    },
    onBatchCloseSuccess() {
      this.$toaster.success('Lote de pagamentos fechado com sucesso!');
      this.close();
      window.dispatchEvent(new Event('topbar.refresh'));

      if (this.onBatchClose) {
        this.onBatchClose();
      }
    },
    goToBankAccountSelection(savedBatch) {
      this.savedBatch = savedBatch;
      this.currentStep = batchWizardStepsConstant.BANK_ACCOUNT_SELECTION;
    },
    onFirstStepsSave() {
      this.currentStep = batchWizardStepsConstant.BATCH_FORM;
    },
    onCancelWizard() {
      if (!this?.batch?.id) {
        this.close();
      }
    }
  }
};
</script>
