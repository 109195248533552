import merge from 'deepmerge';

const model = {
  id: null,
  value: 0,
  payment_date: null,
  due_date: null,
  barcode: null,
  description: null,
  integration_id: null
};

const get = () => merge({}, model);

export default {
  get
};
