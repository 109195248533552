<template>
  <tr-dialog
    ref="modal"
    :unique-name="true"
    name="payment-link-cancel-dialog"
    title="Tem certeza que deseja excluir o Link de Pagamento?"
    :has-its-own-buttons="true"
    :on-close="close"
  >
    <bg-text
      type="body-md"
      color="grey-dark"
    >
      Essa ação não pode ser desfeita posteriormente.
    </bg-text>
    <tr-dialog-buttons-container>
      <bg-button
        class="pull-left"
        variant="outline"
        @click="close()"
      >
        Voltar
      </bg-button>
      <bg-button
        size="md"
        class="pull-right"
        @click="onDeletePaymentLink(paymentLinkDeleteId)"
      >
        Excluir
      </bg-button>
    </tr-dialog-buttons-container>
  </tr-dialog>
</template>

<script setup>
import { ref } from 'vue';
import { BgText, BgButton } from '@transfeeradev/bridge';

const paymentLinkDeleteId = ref();

defineProps({
  onDeletePaymentLink: {
    type: Function
  }
});

const modal = ref();

const open = paymentLinkId => {
  modal.value.open(paymentLinkId);
  paymentLinkDeleteId.value = paymentLinkId;
};

const close = () => {
  modal.value.close();
};

defineExpose({
  open,
  close
});
</script>