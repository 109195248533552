<template>
  <tr-dialog
    ref="modal"
    class="pix-key-registration-dialog"
    name="pix-key-registration-dialog"
    has-its-own-buttons
    :width="850"
    :on-cancel="cancel"
  >
    <div style="display: flex; align-items: center">
      <div style="padding-right: 40px">
        <img src="/assets/img/pix-dialog.svg">
      </div>
      <div>
        <tr-typography
          classes="block bottom-offset3"
          variant="h3"
          strong="true"
        >
          Cadastre sua chave Pix
        </tr-typography>
        <div class="bottom-offset1">
          Agora, além da automatização dos pagamentos como você já conhece, sua empresa pode ter a titularidade de uma
          conta aqui na Transfeera, para fazer pagamentos e também fazer recebimentos por Pix.
        </div>
        <tr-typography
          classes="block bottom-offset3"
          strong="true"
        >
          Cadastre sua chave para habilitar a sua Conta Pix dentro da Transfeera.
        </tr-typography>
        <tr-button
          classes="vertical-offset3"
          label="Cadastrar chave Pix"
          variant="raised"
          color="primary"
          :on-button-click="confirm"
        />
      </div>
    </div>
  </tr-dialog>
</template>

<script>
import checkpointResource from '@/apps/payments/resources/checkpoint';
import checkpoint from '@/commons/constants/checkpoint';
import formatCpfCnpj from '@/commons/formatters/cpfCnpj';
import { transfeeraData } from '@transfeeradev/api-enums';

export default {
  name: 'pix-key-registration-dialog',
  computed: {
    transfeeraCNPJ() {
      return formatCpfCnpj(transfeeraData.CNPJ);
    }
  },
  methods: {
    open() {
      this.$refs.modal.open();
    },
    close() {
      checkpointResource.create(checkpoint.PIX_KEY_REGISTRATION_DISMISSED);

      this.$refs.modal.close();
    },
    cancel() {
      this.close();
    },
    async confirm() {
      await checkpointResource.create(checkpoint.PIX_KEY_REGISTRATION_CTA_CLICKED);

      this.$router.push({
        name: 'PaymentsBankAccountsPage',
        state: {
          openPixKeyWizardDialogOnInit: true
        }
      });
    }
  }
};
</script>

<style>
.pix-key-registration-dialog .modal-content-container {
  padding: 0 !important;
}

.pix-key-registration-dialog .modal-content-header {
  display: none;
}
</style>
