<template>
  <bank-account-form-container
    ref="bankAccountForm"
    bank-label="Banco do recebedor"
    :should-make-complex-validations="shouldMakeComplexValidations"
    should-show-operation-type="true"
    :cpf-cnpj="cpfCnpj"
    :error-field="errorField"
    :full-size="fullSize"
    :no-margin="noMargin"
    :on-bank-change="onBankChange"
    :bank-account="bankAccount"
  />
</template>

<script>
import BankAccountFormContainer from '@/apps/payments/payments/bank/components/container/BankAccountFormContainer.vue';

export default {
  name: 'transfer-bank-account-form-container',
  components: {
    BankAccountFormContainer
  },
  props: [
    'bankAccount',
    'fullSize',
    'errorField',
    'onBankChange',
    'shouldMakeComplexValidations',
    'cpfCnpj',
    'noMargin'
  ],
  methods: {
    revalidate() {
      this.$refs.bankAccountForm.validate();
    }
  }
};
</script>
