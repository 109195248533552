<template>
  <tr-page-section :no-margin="true">
    <batch-status-legend classes="bottom-offset3" />
    <batch-first-use-blankslate v-if="shouldShowBlankslate && !hasItems && !isLoading" />
    <tr-empty-search-result
      v-if="!shouldShowBlankslate && !hasItems && !isLoading"
      text="Nenhum lote foi encontrado na sua busca"
    />
    <div
      v-if="!isLoading"
      class="row"
    >
      <div
        v-for="batchData in batches"
        :key="batchData.id"
        class="col-xs-12 col-md-6 col-lg-4"
      >
        <batch-card
          :batch="batchData"
          :on-card-click="onBatchClick.bind(this, batchData)"
          :on-edit-click="showbatchEditDialog"
          :on-cancel-click="showBatchCancelDialog"
          :is-all-transfers-scheduled="isAllTransfersScheduled"
        />
      </div>
    </div>
    <div
      v-if="isLoading"
      class="width-100 center-h-and-v"
    >
      <tr-loading />
    </div>
  </tr-page-section>
  <batch-fullscreen-modal
    ref="batchFullscreenModal"
    :on-change="refresh"
    :on-hidden="refresh"
    :unique-name="true"
    :on-edit-click="showbatchEditDialog"
    :on-cancel-click="showBatchCancelDialog"
    @is-all-transfers-scheduled-event="isAllTransfersScheduledEvent"
  />
  <batch-name-edit-dialog
    :id="batch.id"
    ref="batchEditDialog"
    :name="batch.name"
    @save="onSaveEdit"
  />
  <batch-cancel-dialog
    :id="batch.id"
    ref="batchCancelDialog"
    :name="batch.name"
    :close-modal="closeFullScreenModal"
    @save="onCancelBatch"
  />
  <tr-numeric-pagination
    v-if="!isLoading"
    :current-page="currentPage"
    :page-size="pagination.itemsPerPage"
    :total="pagination.totalItems"
    :on-current-page-change="onCurrentPageChange"
  />
</template>

<script>
import BatchCard from '@/apps/payments/payments/batch/components/presentational/BatchCard';
import BatchFullscreenModal from '@/apps/payments/payments/batch/pages/BatchFullscreenModal';
import BatchStatusLegend from '@/apps/payments/payments/batch/components/presentational/BatchStatusLegend';
import BatchFirstUseBlankslate from '@/apps/payments/payments/batch/components/presentational/BatchFirstUseBlankslate';
import dashboardResource from '@/apps/payments/resources/dashboard';
import moment from '@transfeeradev/moment';
import verifyRoleAccess from '@/commons/services/verifyRoleAccess';
import { batchStatus, roles } from '@transfeeradev/api-enums';
import batchResource from '@/apps/payments/payments/batch/resources/batch';
import { usePolling } from '@/commons/composables/usePolling';
import { getCurrentInstance, onMounted, ref } from 'vue';
import { getMessage } from '@/commons/services/error';
import BatchNameEditDialog from '../presentational/BatchNameEditDialog.vue';
import BatchCancelDialog from '../presentational/BatchCancelDialog.vue';
import { useErrorHandler } from '@/commons/composables/useErrorHandler';

export default {
  name: 'batches-container',
  components: {
    BatchCard,
    BatchStatusLegend,
    BatchFirstUseBlankslate,
    BatchFullscreenModal,
    BatchNameEditDialog,
    BatchCancelDialog
  },
  props: {
    statusFilter: {
      required: false,
      type: String,
      default: ''
    },
    periodFilter: {
      required: false,
      type: Object,
      default: () => ({
        initialDate: moment().startOf('month'),
        endDate: moment().endOf('month')
      })
    },
    searchFilter: {
      required: false,
      type: String,
      default: ''
    }
  },
  setup() {
    const isLoading = ref(true);
    const batches = ref([]);
    const { ctx } = getCurrentInstance();

    const { startPolling, stopPolling } = usePolling({
      intervalInSeconds: 2
    });

    const refresh = async resetPagination => {
      await ctx.fetchBatches(resetPagination);

      stopPolling();

      startPolling(checkPendingBatches, {
        keepPollingWhile: () => batches.value.some(batch => batchStatus.PENDING === batch.status)
      });
    };

    const checkPendingBatches = async () => {
      batches.value
        .filter(batch => batchStatus.PENDING === batch.status)
        .forEach(async batch => {
          const { status } = await batchResource.get(batch.id);
          batch.status = status;
        });
    };

    onMounted(() => {
      ctx.refreshBlankslate();
      refresh();
    });

    return {
      batches,
      refresh,
      stopPolling,
      isLoading
    };
  },
  data: () => ({
    isAllTransfersScheduled: false,
    currentPage: 0,
    pagination: {},
    shouldShowBlankslate: false,
    currentBatchId: null,
    batch: {
      id: null,
      name: null
    }
  }),
  computed: {
    hasItems() {
      return Boolean(this.batches && this.batches.length);
    }
  },
  watch: {
    statusFilter() {
      this.refresh();
    },
    periodFilter() {
      this.refresh();
    },
    searchFilter() {
      this.refresh();
    }
  },
  created() {
    this.handleError = useErrorHandler().handleError;
  },
  methods: {
    isAllTransfersScheduledEvent(isAllTransfersScheduledEvent) {
      this.isAllTransfersScheduled = isAllTransfersScheduledEvent;
    },
    fetchBatches(resetPagination = true) {
      this.isLoading = true;

      if (resetPagination) {
        this.currentPage = 0;
      }

      return batchResource
        .getFiltered({
          status: this.statusFilter,
          initialDate: this.periodFilter.initialDate,
          endDate: this.periodFilter.endDate,
          searchFilter: this.searchFilter,
          pageFilter: this.currentPage
        })
        .then(
          result => {
            this.batches = result.data;
            this.pagination = result.metadata.pagination;

            if (!this.batches || !this.batches.length) {
              if (this.shouldShowBlankslate) {
                this.refreshBlankslate();
              }

              return;
            }
          },
          error => this.$toaster.error(getMessage(error))
        )
        .then(() => {
          this.isLoading = false;
        });
    },
    refreshBlankslate() {
      dashboardResource.hasBlankslate().then(
        response => {
          this.shouldShowBlankslate = response.data;
        },
        error => this.$toaster.error(getMessage(error))
      );
    },
    _shouldGetTransfersCorrectionsFlag(batch) {
      return !batch.reconciled && !verifyRoleAccess.isLoggedUserForbiddenToAccess(roles.appRoles.ANALYST);
    },
    onBatchClick(batch) {
      this.currentBatchId = batch.id;
      setTimeout(() => {
        this.$refs.batchFullscreenModal.open(batch && batch.id);
        this.stopPolling();
      });
    },
    onCurrentPageChange(newCurrentPage) {
      this.currentPage = newCurrentPage;
      this.refresh(false);
    },
    async onSaveEdit() {
      try {
        this.refresh();
      } catch (e) {
        this.$toaster.error('Houve um problema na edição!');
      }
    },
    async onCancelBatch() {
      try {
        this.onHidden();
      } catch (e) {
        this.handleError(e, 'Houve um problema no cancelamento!');
      }
    },
    onHidden() {
      this.refresh();
      this.currentBatchId = null;
    },
    showbatchEditDialog(batch) {
      this.batch = batch;
      this.$refs.batchEditDialog.open(batch);
    },
    closeBatchEditDialog() {
      this.$refs.batchEditDialog.close();
    },
    showBatchCancelDialog(batch) {
      this.batch = batch;
      this.$refs.batchCancelDialog.open(batch);
    },
    closeFullScreenModal() {
      this.$refs.batchFullscreenModal.close();
    }
  }
};
</script>
