<template>
  <tr-pill
    size="medium"
    :color="statusColor"
    :no-margin="true"
  >
    {{ statusName }}
  </tr-pill>
</template>

<script>
import VALIDATION_STATUS from '@/apps/contacerta/validations/constants/validationStatus';

export default {
  name: 'validation-status-pill',
  props: {
    status: {
      type: String,
      required: true
    }
  },
  computed: {
    statusColor() {
      switch (this.status) {
        case VALIDATION_STATUS.INVALID:
          return 'red';
        case VALIDATION_STATUS.VALIDATING:
          return 'yellow';
        default:
          return 'blue';
      }
    },
    statusName() {
      switch (this.status) {
        case VALIDATION_STATUS.INVALID:
          return 'Inválido';
        case VALIDATION_STATUS.VALIDATING:
          return 'Validando';
        default:
          return 'Validado';
      }
    }
  }
};
</script>
