import transferModel from '@/apps/payments/payments/transfer/models/transfer';
import dbaModel from '@/apps/payments/payments/dba/models/dba';
import transferPrepareToSave from '@/apps/payments/payments/transfer/services/prepareToSave';
import transferValidations from '@/apps/payments/payments/transfer/services/validations/transfer';
import builderFromSheet from '@/apps/payments/payments/spreadsheetImport/services/builderFromSheet';
import { getMessage } from '@/commons/services/error';

function get() {
  return transferModel.get();
}

function getFromSpreadsheet(rows) {
  return new Promise((resolve, reject) => {
    builderFromSheet.build(rows).then(
      transfers => {
        resolve(transfers);
      },
      error => {
        reject({
          message: getMessage(error)
        });
      }
    );
  });
}

function copyDestinationBankAccount(destinationBankAccount) {
  const model = dbaModel.set(destinationBankAccount);
  delete model.id;
  return model;
}

function validate(data) {
  if (data.length) {
    return data.map(transfer => {
      return _getTransferValidated(transfer);
    });
  } else {
    return _getTransferValidated(data);
  }
}

const prepareToSave = (transfer, isFromSpreadsheet) => transferPrepareToSave.prepare(transfer, isFromSpreadsheet);

const _getTransferValidated = transfer =>
  transferValidations.validate(transfer).then(result => {
    transfer.$isValid = result;
    return transfer;
  });

export default {
  get,
  getFromSpreadsheet,
  copyDestinationBankAccount,
  validate,
  prepareToSave
};
