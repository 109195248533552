<template>
  <tr-dialog
    ref="modal"
    name="api-key-generation-confirmation-dialog"
    title="Atualize a sua integração"
    :on-save="onConfirm"
    save-button-color="danger"
    save-button-label="Gerar novas credenciais"
  >
    <div>
      <tr-typography classes="inline-block">
        Você está prestes a gerar credenciais em um novo formato. Esta é uma forma mais segura de autenticação, utilizando padrões de mercado atuais com duas chaves e expiração de token a cada 30 minutos.
      </tr-typography>
      <tr-typography classes="vertical-offset2 inline-block">
        Após gerar uma nova chave de autenticação você precisa alterar a sua integração para o novo formato até o dia
        <b>26/02/2024</b>.
        Siga a nossa <a
          href="https://docs.transfeera.dev/#/authentication"
          target="_blank"
          rel="noopener"
        >documentação</a> para atualizar o
        seu sistema. Até lá sua autenticação atual continuará funcionando normalmente.
      </tr-typography>
    </div>
  </tr-dialog>
</template>

<script>
export default {
  name: 'api-key-generation-confirmation-dialog',
  props: {
    onConfirm: {
      required: true,
      type: Function
    }
  },
  methods: {
    open() {
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    }
  }
};
</script>
