<template>
  <div
    v-if="canEditDocumentValidationValue"
    class="row"
  >
    <div class="bottom-offset1" />
    <dba-cpf-cnpj-field-container
      v-model:cpf-cnpj="cpfCnpjProxy"
      label="CPF/CNPJ a validar (opcional)"
      :classes="fullSize ? 'col-lg-6' : 'col-lg-5'"
      :on-change="onChangeCpfCnpj"
      default-error-message="O CPF/CNPJ para validação deve ser válido"
      :required="false"
    />
  </div>
</template>

<script>
import DbaCpfCnpjFieldContainer from '@/apps/payments/payments/dba/components/container/DbaCpfCnpjFieldContainer';
import dbaModel from '@/apps/payments/payments/dba/models/dba';
import transferModel from '@/apps/payments/payments/transfer/models/transfer';
import { transferStatus } from '@transfeeradev/api-enums';
import TransferPixService from '@transfeeradev/api-services/TransferPixService';

export default {
  name: 'transfer-document-validation-container',
  components: {
    DbaCpfCnpjFieldContainer
  },
  props: {
    dba: {
      required: false,
      type: Object,
      default: dbaModel.get
    },
    transfer: {
      required: false,
      type: Object,
      default: transferModel.get
    },
    fullSize: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      cpfCnpjProxy: ''
    };
  },
  computed: {
    canEditDocumentValidationValue() {
      const allowedTransferStatus = [transferStatus.CREATED, transferStatus.FAILED];
      const isEditableTransfer = !this.transfer.status || allowedTransferStatus.includes(this.transfer.status);
      return Boolean(isEditableTransfer && TransferPixService.isPix(this.dba));
    }
  },
  watch: {
    'transfer.pix_key_validation.cpf_cnpj': {
      immediate: true,
      handler(cpf_cnpj) {
        this.cpfCnpjProxy = cpf_cnpj || '';
      }
    }
  },
  methods: {
    onChangeCpfCnpj(cpfCnpj) {
      if (typeof this.transfer.pix_key_validation !== 'object') {
        this.transfer.pix_key_validation = { cpf_cnpj: '' };
      }

      this.transfer.pix_key_validation.cpf_cnpj = cpfCnpj;
    }
  }
};
</script>
