<template>
  <transfer-card
    :transfer="transfer"
    :hide-status-pill="hideStatusPill"
    :formatted-payment-date="formattedPaymentDate"
    :is-failed="isFailed"
    :is-returned="isReturned"
    :bank-image="bankImage"
    :has-warning="hasWarning"
    :on-card-click="onCardClick"
  />
</template>

<script>
import config from '@/config';
import TransferCard from '@/apps/payments/payments/transfer/components/presentational/TransferCard';
import moment from '@transfeeradev/moment';
import { transferStatus } from '@transfeeradev/api-enums';

export default {
  name: 'transfer-card-container',
  components: {
    TransferCard
  },
  props: ['transfer', 'onCardClick', 'hideStatusPill', 'batch'],
  computed: {
    isFailed() {
      const dba = this.transfer.destination_bank_account;
      return (
        (this.transfer._errors && this.transfer._errors.length) ||
        this.transfer.status === transferStatus.FAILED ||
        (this.transfer.status === transferStatus.CREATED && dba.status === transferStatus.FAILED)
      );
    },
    isReturned() {
      return this.transfer.status === transferStatus.RETURNED;
    },
    hasWarning() {
      return Boolean(this.transfer._warnings && this.transfer._warnings.length);
    },
    formattedPaymentDate() {
      const TODAY_DESCRIPTION = 'Hoje';
      const DATE_FORMAT = 'DD/MM/YYYY';
      if (this.transfer.transfer_date || this.transfer.returned_date) {
        return moment(this.transfer.transfer_date || this.transfer.returned_date).format(DATE_FORMAT);
      }

      if (!this.transfer.payment_date || moment(this.transfer.payment_date).isSame(moment(), 'd')) {
        return TODAY_DESCRIPTION;
      }
      return moment(this.transfer.payment_date).format(DATE_FORMAT);
    },
    bankImage() {
      if (this.transfer.emv) {
        return `${config.url.cdn}/banks/pix-qrcode-circle.svg`;
      }

      if (this.transfer.destination_bank_account.pix_key) {
        return `${config.url.cdn}/banks/pix-circle.svg`;
      }

      if (this.transfer.destination_bank_account.Bank && this.transfer.destination_bank_account.Bank.image) {
        return this.transfer.destination_bank_account.Bank.image;
      }

      return `${config.url.cdn}/banks/default_bank.svg`;
    }
  }
};
</script>
