<template>
  <div>
    <bg-text
      type="h4"
      class="batch-name"
    >
      {{ batch.name }}
      <button
        v-tooltip.top-center="'Editar nome'"
        aria-label="Editar nome"
        class="reset-button"
        @click.stop="onEditClick({id: batch.id, name: batch.name})"
      >
        <bg-icon
          size="md"
          name="edit"
          color="blue-normal"
        />
      </button>
    </bg-text>
    <default-batch-top-info-container
      :batch="batch"
      :items="items"
      :type="batch.type"
    />

    <tr-page-section title="Pagamentos deste lote">
      <template #description>
        <div class="row">
          <div
            :class="isTransfer ? 'vertical-offset2' : ''"
          >
            <transfer-filter-by-status
              v-if="isTransfer"
              :batch-status="batch.status"
              :on-filter-by-change="onStatusFilterChange"
              :current-status="statusFilter"
              class="col-lg-4"
            />
            <billet-filter-by-status-container
              v-if="isBillet && batch.status !== batchStatus.DRAFT"
              :on-filter-by-change="onStatusFilterChange"
              class="col-lg-4"
            />
            <payment-search
              v-if="isTransfer"
              :search-term="searchTerm"
              :on-change="onSearchTermChange"
              :on-search="onSearchByTerm"
            />
          </div>
          <tr-button
            dropdown="true"
            label="Fazer download"
            text-variant="body"
            variant="text"
            classes="btn btn-sm btn-default pull-right download-button"
            size="large"
          >
            <tr-dropdown-item>
              <batch-download-link-container :batch="batch" />
            </tr-dropdown-item>
            <tr-dropdown-item :on-item-click="downloadReceipts">
              Baixar comprovantes
            </tr-dropdown-item>
          </tr-button>
        </div>
      </template>
      <transfer-cards-container
        v-if="isTransfer"
        ref="transferCards"
        :on-cancel-wizard="hide"
        :on-get-transfers="onGetItems"
        :search-term-filter="searchTerm"
        :status-filter="statusFilter"
        :batch="batch"
        :on-edit="refreshItems"
        @is-all-transfers-scheduled-event="isAllTransfersScheduledEvent"
      />
      <billet-cards-container
        v-if="isBillet"
        :on-get-billets="onGetItems"
        :filter-by="statusFilter"
        :batch-id="batch.id"
        :batch="batch"
      />
    </tr-page-section>
    <tr-footer-buttons-container class="batch__footer-buttons">
      <bg-button
        v-if="hide"
        classes="pull-left"
        variant="outline"
        @click.prevent="hide"
      >
        Voltar
      </bg-button>
      <tr-hide-for-roles :roles="appRoles.ANALYST">
        <bg-button
          v-if="batch.status === batchStatus.RECEIVED && isAllTransfersScheduled"
          left-icon="cancel"
          variant="outline"
          @click.prevent="onCancelClick({id: batch.id, name: batch.name})"
        >
          Cancelar lote
        </bg-button>
      </tr-hide-for-roles>
    </tr-footer-buttons-container>
    <download-receipt-dialog
      ref="downloadReceiptDialog"
      :batch="batch"
      :batch-type="batch.type"
      :item-ids="itemsWithReceipt"
    />
    <download-without-receipt-dialog
      ref="downloadWithoutReceiptDialog"
      :batch="batch"
    />
  </div>
</template>

<script>
import DownloadReceiptDialog from '@/apps/payments/payments/batch/components/presentational/DownloadReceiptDialog';
import DownloadWithoutReceiptDialog from '@/apps/payments/payments/batch/components/presentational/DownloadWithoutReceiptDialog';
import TransferCardsContainer from '@/apps/payments/payments/transfer/components/container/TransferCardsContainer';
import BilletCardsContainer from '@/apps/payments/payments/billet/components/container/BilletCardsContainer';
import TransferFilterByStatus from '@/apps/payments/payments/transfer/components/presentational/TransferFilterByStatus';
import BilletFilterByStatusContainer from '@/apps/payments/payments/billet/components/container/BilletFilterByStatusContainer';
import BatchDownloadLinkContainer from '@/apps/payments/payments/batch/components/container/BatchDownloadLinkContainer';
import DefaultBatchTopInfoContainer from '@/apps/payments/payments/batch/components/container/DefaultBatchTopInfoContainer';
import PaymentSearch from '@/apps/payments/payments/batch/components/presentational/PaymentSearch';
import { batchType, batchStatus, roles } from '@transfeeradev/api-enums';
import batchModel from '@/apps/payments/payments/batch/models/batch';
import batchResource from '@/apps/payments/payments/batch/resources/batch';
import { BgIcon, BgText, BgButton } from '@transfeeradev/bridge';

export default {
  name: 'default-view-batch-container',
  components: {
    TransferCardsContainer,
    DownloadReceiptDialog,
    DownloadWithoutReceiptDialog,
    TransferFilterByStatus,
    BilletFilterByStatusContainer,
    BatchDownloadLinkContainer,
    DefaultBatchTopInfoContainer,
    BilletCardsContainer,
    PaymentSearch,
    BgIcon,
    BgText,
    BgButton
  },
  props: {
    batch: {
      required: false,
      type: Object,
      default: batchModel.get
    },
    hide: {
      required: false,
      type: Function
    },
    onChange: {
      required: false,
      type: Function
    },
    onEditClick: {
      type: Function,
      required: false
    },
    onCancelClick: {
      type: Function,
      required: false
    }
  },
  emits: ['is-all-transfers-scheduled-event'],
  data: () => ({
    isAllTransfersScheduled: false,
    statusFilter: undefined,
    searchTerm: '',
    items: [],
    itemsWithReceipt: [],
    batchStatus,
    appRoles: roles.appRoles
  }),
  computed: {
    isTransfer() {
      return Boolean(!this.batch.type || this.batch.type === batchType.TRANSFER);
    },
    isBillet() {
      return Boolean(this.batch.type && this.batch.type === batchType.BILLET);
    }
  },
  methods: {
    isAllTransfersScheduledEvent(isAllTransfersScheduledEvent) {
      this.isAllTransfersScheduled = isAllTransfersScheduledEvent;
      this.$emit('is-all-transfers-scheduled-event', isAllTransfersScheduledEvent);
    },
    onStatusFilterChange(selectedStatus) {
      this.statusFilter = selectedStatus;
    },
    onGetItems(items) {
      this.items = items;
    },
    refreshItems() {
      this.$refs.transferCards.refresh();

      if (this.onChange) {
        this.onChange();
      }
    },
    onSearchByTerm() {
      this.$refs.transferCards.refresh();
    },
    onSearchTermChange(searchTerm) {
      this.searchTerm = searchTerm;
    },
    async downloadReceipts() {
      this.itemsWithReceipt = this.isTransfer
        ? await batchResource.getTransfersWithReceipt(this.batch.id)
        : await batchResource.getBilletsWithReceipt(this.batch.id);

      this.itemsWithReceipt.length > 0
        ? this.$refs.downloadReceiptDialog.open()
        : this.$refs.downloadWithoutReceiptDialog.open();
    }
  }
};
</script>

<style lang="css" scoped>
.batch-name {
  margin-top: 80px;
  margin-bottom: 24px;
}

.batch-name-edit-icon {
  background: none;
  border: none;
}

.batch-name-edit-icon:focus {
  background: none;
  box-shadow: none;
}

.batch__footer-buttons {
  display: flex;
  justify-content: space-between;
}
</style>
