<template>
  <div class="text-center">
    <proporsion-of-failed-vs-finished-per-bank-chart
      v-show="!showBlankslate"
      :date-range="dateRange"
      :on-load="onLoad"
      :height="'170vh'"
    />
    <img
      v-show="showBlankslate"
      :src="'/assets/img/blankslate/blankslate-column-chart.svg'"
    >
  </div>
</template>

<script>
import ProporsionOfFailedVsFinishedPerBankChart from '@/apps/payments/payments/indicator/components/presentational/ProporsionOfFailedVsFinishedPerBankChart';

export default {
  name: 'proporsion-of-failed-vs-finished-per-bank-chart-container',
  components: {
    ProporsionOfFailedVsFinishedPerBankChart
  },
  props: {
    dateRange: {
      required: true
    }
  },
  data: () => ({
    showBlankslate: false
  }),
  watch: {
    dateRange: {
      deep: true,
      handler() {
        this.showBlankslate = false;
      }
    }
  },
  methods: {
    onLoad(data) {
      if (!data.length) {
        this.showBlankslate = true;
      }
    }
  }
};
</script>
