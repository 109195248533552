<template>
  <chart ref="chart" />
</template>

<script>
import Chart from './Chart.vue';
import transferResource from '@/apps/payments/payments/transfer/resources/transfer';
import { transferStatus } from '@transfeeradev/api-enums';

export default {
  name: 'proporsion-of-failed-vs-finished-per-bank-chart',
  components: {
    Chart
  },
  props: {
    onLoad: {
      required: false,
      type: Function
    },
    height: {
      required: false,
      type: String
    },
    width: {
      required: false,
      type: String
    },
    dateRange: {
      required: true
    }
  },
  watch: {
    dateRange: {
      deep: true,
      handler() {
        this.load();
      }
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    renderChart(data, options) {
      this.$refs.chart.renderChart({
        type: 'bar',
        data,
        options
      });
    },
    async load() {
      const distributionOfFinishedTransfers = await transferResource.getDistributionPerBank(
        this.dateRange,
        transferStatus.FINISHED
      );
      const distributionOfReturnedTransfers = await transferResource.getDistributionPerBank(
        this.dateRange,
        transferStatus.RETURNED
      );

      const labels = distributionOfFinishedTransfers.map(d => d.name).slice(0, 5);
      const quantitiesOfFinishedTransfers = distributionOfFinishedTransfers.map(d => +d.quantity).slice(0, 5);
      const quantitiesOfReturnedTransfers = distributionOfReturnedTransfers.map(d => +d.quantity).slice(0, 5);

      const percentageOfFinishedTransfers = quantitiesOfFinishedTransfers.map((quantity, index) => {
        const returnedQuantity = Number(quantitiesOfReturnedTransfers[index]) || 0;

        return ((quantity * 100) / (quantity + returnedQuantity)).toFixed(2);
      });

      const percentageOfReturnedTransfers = quantitiesOfReturnedTransfers.map((quantity, index) => {
        const finishedQuantity = Number(quantitiesOfFinishedTransfers[index]) || 0;

        return ((quantity * 100) / (quantity + finishedQuantity)).toFixed(2);
      });

      if (this.$refs.chart) {
        this.renderChart(
          {
            labels,
            datasets: [
              {
                label: 'Finalizado',
                data: percentageOfFinishedTransfers,
                backgroundColor: '#0096ED'
              },
              {
                label: 'Devolvido',
                data: percentageOfReturnedTransfers,
                backgroundColor: '#FF003A'
              }
            ]
          },
          {
            legend: {
              display: false
            },
            title: {
              display: true,
              text: '',
              position: 'top',
              fontStyle: 'normal'
            },
            scales: {
              xAxes: [
                {
                  stacked: true,
                  gridLines: {
                    display: true,
                    drawBorder: true,
                    drawOnChartArea: true,
                    drawTicks: true
                  }
                }
              ],
              yAxes: [
                {
                  stacked: true,
                  gridLines: {
                    display: true,
                    drawBorder: true,
                    drawOnChartArea: true,
                    drawTicks: true
                  }
                }
              ]
            },
            tooltips: {
              callbacks: {
                label: tooltipItems => {
                  if (tooltipItems.datasetIndex === 1) {
                    return `Devolvido: ${percentageOfReturnedTransfers[tooltipItems.index]}%`;
                  }

                  return `Finalizado: ${percentageOfFinishedTransfers[tooltipItems.index]}%`;
                }
              }
            },
            animation: {
              onComplete: () => {
                return (
                  this.onLoad && this.onLoad(distributionOfFinishedTransfers.concat(distributionOfReturnedTransfers))
                );
              }
            }
          }
        );
      }
    }
  }
};
</script>
