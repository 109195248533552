<template>
  <div class="info-action-box">
    <div
      class="info-action-box-description"
      :class="type"
    >
      <BgColumns>
        <bg-column width="content">
          <BgIcon
            :name="theme.icon"
            size="sm"
            :color="theme.color"
          />
        </bg-column>
        <bg-column width="fluid">
          <slot />
        </bg-column>
      </BgColumns>
    </div>
  </div>
</template>

<script setup>
import { BgColumn, BgColumns, BgIcon } from '@transfeeradev/bridge';
import { computed } from 'vue';

const props = defineProps({
  type: {
    type: String,
    validator: value => ['info', 'error', 'warning'].includes(value),
    required: true
  }
});

const theme = computed(() => {
  return {
    error: {
      icon: 'alert',
      color: 'grey-white'
    }
  }[props.type];
});
</script>
