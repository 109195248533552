<template>
  <tr-page-section
    title="Suas chaves Pix cadastradas na Transfeera"
    :no-margin="true"
  >
    <template #description>
      Adicione suas chaves para <strong>receber e enviar pagamentos Pix</strong> utilizando sua conta Transfeera
    </template>
    <div
      v-if="isLoading"
      class="center-h-and-v"
    >
      <tr-loading />
    </div>
    <div
      v-else
      class="card-grid"
    >
      <tr-add-card
        v-if="pixKeys.length < 20"
        classes="pix-add-card"
        :on-add-click="openWizardDialog"
      />
      <pix-key-card
        v-for="pixKey of pixKeys"
        :key="pixKey.id"
        classes="pix-key-card"
        :pix-key="pixKey"
        :on-card-click="() => openWizardDialog(pixKey.id)"
      />
    </div>
  </tr-page-section>
  <pix-key-wizard-dialog
    ref="pixKeyWizardDialogRef"
    @close="refresh"
  />
</template>

<script>
import PixKeyCard from '@/apps/payments/payments/settings/pixKeys/components/presentational/PixKeyCard';
import PixKeyWizardDialog from '@/apps/payments/payments/settings/pixKeys/components/presentational/PixKeyWizardDialog';
import pixKeyResource from '@/apps/payments/payments/settings/pixKeys/resources/pixKey';
import { usePolling } from '@/commons/composables/usePolling';
import { pixKeyStatus } from '@transfeeradev/api-enums/pix';
import { defineComponent, onMounted, ref } from 'vue';

export default defineComponent({
  name: 'pix-keys-container',
  components: {
    PixKeyCard,
    PixKeyWizardDialog
  },
  props: {
    openPixKeyWizardDialogOnInit: Boolean
  },
  setup(props) {
    const isLoading = ref(true);
    const pixKeys = ref([]);
    const pixKeyWizardDialogRef = ref();

    const { startPolling, stopPolling } = usePolling({
      intervalInSeconds: 2
    });

    const openWizardDialog = id => {
      stopPolling();
      pixKeyWizardDialogRef.value.open(id);
    };

    const refresh = () =>
      startPolling(
        async () => {
          pixKeys.value = await pixKeyResource.getList();
        },
        {
          immediate: true,
          keepPollingWhile: () =>
            pixKeys.value.some(pixKey => [pixKeyStatus.PENDING, pixKeyStatus.PENDING_REMOVAL].includes(pixKey.status))
        }
      );

    onMounted(async () => {
      isLoading.value = true;
      await refresh();
      isLoading.value = false;

      if (props.openPixKeyWizardDialogOnInit) {
        openWizardDialog();
      }
    });

    return {
      isLoading,
      pixKeys,
      refresh,
      startPolling,
      openWizardDialog,
      pixKeyWizardDialogRef
    };
  }
});
</script>

<style scoped>
.pix-key-card:deep(.pill) {
  padding-left: 8px;
  padding-right: 8px;
}
</style>
