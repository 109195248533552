import config from '@/config';
import fetch from '@/fetch';
import moment from '@transfeeradev/moment';

const BASE_URL = config.url.api.transfeera + '/batch';
const BASE_APP_URL = config.url.api.transfeera + '/app/batch';

export default {
  get: id => fetch(`${BASE_APP_URL}/${id}`),
  getFiltered: (filters = {}) => {
    const DATE_FORMAT = 'YYYY-MM-DD';
    const formattedInitialDate = moment(filters.initialDate).format(DATE_FORMAT);
    const formattedEndDate = moment(filters.endDate).format(DATE_FORMAT);
    let url = `${BASE_APP_URL}?endDate=${formattedEndDate}&initialDate=${formattedInitialDate}&page=${filters.pageFilter}`;

    if (filters.status) {
      url += `&status=${filters.status}`;
    }

    if (filters.searchFilter) {
      const encodedSearch = encodeURIComponent(filters.searchFilter);
      url += `&search=${encodedSearch}`;
    }

    return fetch(url);
  },
  getSummary: id => fetch(`${BASE_APP_URL}/${id}/summary`),
  create: batch =>
    fetch(`${BASE_APP_URL}`, {
      method: 'POST',
      body: JSON.stringify(batch)
    }),
  update: (batchId, batch) =>
    fetch(`${BASE_URL}/${batchId}`, {
      method: 'PUT',
      body: JSON.stringify(batch)
    }),
  updateName: (batchId, batchName) =>
    fetch(`${BASE_APP_URL}/${batchId}`, {
      method: 'PATCH',
      body: JSON.stringify({ name: batchName })
    }),
  close: id =>
    fetch(`${BASE_URL}/${id}/close`, {
      method: 'POST'
    }),
  remove: (id, removeDbas) =>
    fetch(`${BASE_URL}/${id}?removeDestinationBankAccounts=${removeDbas}`, {
      method: 'DELETE'
    }),
  hasDestinationBankAccountsToRemove: id => fetch(`${BASE_URL}/${id}/hasDestinationBankAccountsToRemove`),
  waiting: () => fetch(`${BASE_URL}/waiting`),
  getTransferBatchFlags: id => fetch(`${BASE_URL}/${id}/transfer/validate`),
  enableEdit: id =>
    fetch(`${BASE_URL}/${id}/enableEdit`, {
      method: 'POST'
    }),
  reprove: (id, reason) =>
    fetch(`${BASE_URL}/${id}/reprove`, {
      body: JSON.stringify({ reason }),
      method: 'POST'
    }),
  sendToApproval: id =>
    fetch(`${BASE_URL}/${id}/sendToApproval`, {
      method: 'POST'
    }),
  cancelBatch: id => {
    fetch(`${BASE_APP_URL}/${id}/cancel_scheduled`, {
      method: 'POST'
    });
  },
  saveSpreadsheet: (file, fileName) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', fileName);

    return fetch(
      `${BASE_URL}/sheet/upload`,
      {
        method: 'POST',
        'Content-Type': undefined,
        body: formData
      },
      { isFile: true }
    );
  },
  saveNotValidatedBatch: batch =>
    fetch(`${BASE_URL}/spreadsheet`, {
      method: 'POST',
      body: JSON.stringify(batch)
    }),
  billetImportSpreadsheet: (file, fileName) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', fileName);

    return fetch(
      `${BASE_URL}/billet/sheet`,
      {
        method: 'POST',
        'Content-Type': undefined,
        body: formData
      },
      { isFile: true }
    );
  },
  getTransfers: (id, page, status, search) => {
    let url = `${config.url.api.transfeera}/app/batch/${id}/transfer?`;

    if (page || page === 0) {
      url += `&page=${page}`;
    }

    if (status) {
      url += `&status=${status}`;
    }

    if (search) {
      const encodedSearch = encodeURIComponent(search);
      url += `&search=${encodedSearch}`;
    }

    return fetch(url);
  },
  getBilletsWithReceipt: id => fetch(`${BASE_APP_URL}/${id}/billetsWithReceipt`),
  getTransfersWithReceipt: id => fetch(`${BASE_APP_URL}/${id}/transfersWithReceipt`),
  getBillets: id => fetch(`${BASE_URL}/${id}/billet`),
  getReportUrl: id =>
    fetch(`${BASE_URL}/${id}/report`, {
      method: 'POST'
    }),
  setReconciled: (id, reconciled) =>
    fetch(`${BASE_URL}/${id}/setReconciled`, {
      method: 'PUT',
      body: JSON.stringify({ reconciled })
    }),
  countDbaFailedTransfersOnThisBatch: (id, dbaId) =>
    fetch(`${BASE_URL}/${id}/destinationBankAccount/${dbaId}/failedTransfers`),
  hasFailedOrReturnedTransfers: id => fetch(`${BASE_APP_URL}/${id}/hasFailedOrReturnedTransfers`),
  cancelTransfer: (id, transferId) =>
    fetch(`${BASE_URL}/${id}/transfer/${transferId}/cancel`, {
      method: 'POST'
    }),
  updateTransfer: (id, transfer, transferId) =>
    fetch(`${BASE_APP_URL}/${id}/transfer/${transferId}`, {
      body: JSON.stringify(transfer),
      method: 'PUT'
    }),
  removeTransfer: (id, transferId) =>
    fetch(`${BASE_URL}/${id}/transfer/${transferId}`, {
      method: 'DELETE'
    }),
  createTransfer: (id, transfer) =>
    fetch(`${BASE_APP_URL}/${id}/transfer`, {
      method: 'POST',
      body: JSON.stringify(transfer)
    }),
  updateBillet: (id, billet) =>
    fetch(`${BASE_URL}/${id}/billet`, {
      body: JSON.stringify(billet),
      method: 'PUT'
    }),
  removeBillet: (id, billetId) =>
    fetch(`${BASE_URL}/${id}/billet/${billetId}`, {
      method: 'DELETE'
    }),
  createBillet: (id, billet) =>
    fetch(`${BASE_APP_URL}/${id}/billet`, {
      method: 'POST',
      body: JSON.stringify(billet)
    }),
  removeManyTransfers: (batchId, transferIds) =>
    fetch(`${BASE_APP_URL}/${batchId}/transfer/removeMany`, {
      method: 'POST',
      body: JSON.stringify({
        ids: transferIds
      })
    })
};
