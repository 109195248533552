export const StatusCode = {
  UNAUTHORIZED_STATUS_CODE: 401,
  OK_STATUS_RANGE_START: 200,
  OK_STATUS_RANGE_END: 299,
  EMPTY_RESPONSE_STATUS_CODE: 204,
  CLIENT_ERROR_RANGE_START: 400,
  CLIENT_ERROR_RANGE_END: 499,
  SERVER_ERROR_RANGE_START: 500,
  SERVER_ERROR_RANGE_END: 599
};

const isInRange = ({ value, min, max }) => value >= min && value <= max;

export const isServerErrorStatus = status =>
  isInRange({
    value: status,
    min: StatusCode.SERVER_ERROR_RANGE_START,
    max: StatusCode.SERVER_ERROR_RANGE_END
  });

export const isClientErrorStatus = status =>
  isInRange({
    value: status,
    min: StatusCode.CLIENT_ERROR_RANGE_START,
    max: StatusCode.CLIENT_ERROR_RANGE_END
  });

export const isOkStatus = status =>
  isInRange({
    value: status,
    min: StatusCode.OK_STATUS_RANGE_START,
    max: StatusCode.OK_STATUS_RANGE_END
  });
