<template>
  <transfer-details
    :batch="transfer.Batch"
    :transfer="transfer"
    :transfer-fail-reason="transferFailReason"
    :show-batch-fullscreen-modal="showBatchFullscreenModal"
    :dba="transfer.destination_bank_account"
  />
</template>

<script>
import TransferDetails from '@/apps/payments/payments/transfer/components/presentational/TransferDetails';
import transferResource from '@/apps/payments/payments/transfer/resources/transfer';
import getTransferFailReason from '@/apps/payments/payments/transfer/services/getTransferFailReason';

export default {
  name: 'transfer-details-container',
  components: {
    TransferDetails
  },
  props: {
    transferId: {
      required: false,
      type: [Number, String]
    },
    showBatchFullscreenModal: {
      required: false,
      type: Function
    }
  },
  data: () => ({
    transfer: {
      Batch: {},
      destination_bank_account: {
        Bank: {}
      }
    }
  }),
  computed: {
    transferFailReason() {
      return getTransferFailReason.get(this.transfer);
    }
  },
  created() {
    if (this.transferId) {
      transferResource.getDetails(this.transferId).then(transfer => {
        this.transfer = transfer;
      });
    }
  }
};
</script>
