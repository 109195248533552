import { transferStatus } from '@transfeeradev/api-enums';

export default {
  [transferStatus.CREATED]: 'grey',
  [transferStatus.RECEIVED]: 'green',
  [transferStatus.SCHEDULED]: 'blue',
  [transferStatus.TRANSFERRED]: 'blue',
  [transferStatus.FINISHED]: 'blue',
  [transferStatus.FAILED]: 'red',
  [transferStatus.RETURNED]: 'red',
  [transferStatus.EM_REMESSA]: 'green',
  [transferStatus.EXECUTING]: 'green',
  [transferStatus.FAILED_INTERNAL]: 'green',
  [transferStatus.BEING_RETURNED]: 'yellow',
  [transferStatus.REQUESTED_REFUND]: 'yellow'
};
