import formatBanksToObject from '@/commons/services/bank/formatBanksToObject';
import getByProp from '@/commons/services/getByProp';
import UtilsService from '@transfeeradev/api-services/UtilsService';

const search = (searchTerm, banks, acceptPartial) => {
  searchTerm = searchTerm && String(searchTerm).toLowerCase().trim();
  let foundBank;
  const termNumbers = UtilsService.getNumbersFromStr(searchTerm);
  if (termNumbers.length <= 3) {
    foundBank = _searchBankByCode(termNumbers, banks);
  }
  if (!foundBank || termNumbers.length > 3) {
    foundBank = _searchBankByISPB(termNumbers, banks);
  }

  if (foundBank) {
    return foundBank;
  }

  foundBank = _searchBankByNickname(searchTerm, banks, acceptPartial);

  if (foundBank) {
    return foundBank;
  }

  return _searchBankByName(searchTerm, banks);
};

const _searchBankByCode = (searchTerm, banks, acceptPartial) => {
  const BANK_CODE_PROP_NAME = 'code';

  if (_isNumber(searchTerm)) {
    return getByProp.get({
      propValue: searchTerm,
      propName: BANK_CODE_PROP_NAME,
      list: banks,
      includes: acceptPartial
    });
  }
};

const _searchBankByISPB = (searchTerm, banks, acceptPartial) => {
  const BANK_CODE_PROP_NAME = 'ispb';

  return getByProp.get({
    propValue: searchTerm,
    propName: BANK_CODE_PROP_NAME,
    list: banks,
    includes: acceptPartial
  });
};

const _isNumber = searchTerm => !isNaN(searchTerm);

const _searchBankByName = (searchTerm, banks) => {
  const BANK_NAME_PROP_NAME = 'name';

  return getByProp.get({
    propValue: searchTerm,
    propName: BANK_NAME_PROP_NAME,
    list: banks,
    includes: true
  });
};

const _searchBankByNickname = (searchTerm, banks, acceptPartial) => {
  const BANCO_DO_BRASIL_NICKNAME = 'bb';
  const CAIXA_NICKNAME_1 = 'cef';
  const CAIXA_NICKNAME_2 = 'caixa economica';
  const ITAU_NICKNAME = 'itau';
  const ITAU_BANK_NICKNAME = 'banco itaubank';
  const SICOOB_NICKNAME = 'sicob';
  const SICOOB_NICKNAME_2 = 'bancoob';
  const NU_PAGAMENTOS_NICKNAME_1 = 'nu bank';
  const NU_PAGAMENTOS_NICKNAME_2 = 'nubank';
  const NU_PAGAMENTOS_NICKNAME_3 = 'nu conta';
  const NU_PAGAMENTOS_NICKNAME_4 = 'nuconta';
  const INTER_BANK_NICKNAME_1 = 'inter';
  const INTER_BANK_NICKNAME_2 = 'intermedium';
  const banksObj = formatBanksToObject.format(banks);

  if (searchTerm.includes(BANCO_DO_BRASIL_NICKNAME)) {
    return banksObj.BANCO_DO_BRASIL;
  }

  if (
    searchTerm.includes(CAIXA_NICKNAME_1) ||
    searchTerm.includes(CAIXA_NICKNAME_2) ||
    (acceptPartial && (CAIXA_NICKNAME_1.includes(searchTerm) || CAIXA_NICKNAME_2.includes(searchTerm)))
  ) {
    return banksObj.CAIXA_ECONOMICA;
  }

  if (searchTerm.includes(ITAU_BANK_NICKNAME)) {
    return banksObj.BANCO_ITAUBANK;
  }

  if (searchTerm.includes(ITAU_NICKNAME) || (acceptPartial && ITAU_NICKNAME.includes(searchTerm))) {
    return banksObj.ITAU;
  }

  if (
    searchTerm.includes(SICOOB_NICKNAME) ||
    searchTerm.includes(SICOOB_NICKNAME_2) ||
    (acceptPartial && SICOOB_NICKNAME.includes(searchTerm))
  ) {
    return banksObj.SICOOB;
  }

  if (
    searchTerm.includes(NU_PAGAMENTOS_NICKNAME_1) ||
    searchTerm.includes(NU_PAGAMENTOS_NICKNAME_2) ||
    searchTerm.includes(NU_PAGAMENTOS_NICKNAME_3) ||
    searchTerm.includes(NU_PAGAMENTOS_NICKNAME_4) ||
    (acceptPartial &&
      (NU_PAGAMENTOS_NICKNAME_1.includes(searchTerm) ||
        NU_PAGAMENTOS_NICKNAME_2.includes(searchTerm) ||
        NU_PAGAMENTOS_NICKNAME_3.includes(searchTerm) ||
        NU_PAGAMENTOS_NICKNAME_4.includes(searchTerm)))
  ) {
    return banksObj['NU_PAGAMENTOS_S.A.'];
  }

  // eslint-disable-next-line no-useless-escape
  const searchWords = searchTerm.toLowerCase().split(/[\.,]?\s+[\.,]?/);
  if (searchWords.includes(INTER_BANK_NICKNAME_1) || searchWords.includes(INTER_BANK_NICKNAME_2)) {
    return banksObj.BANCO_INTER;
  }

  return null;
};

export default {
  search
};
