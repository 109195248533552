<template>
  <tr-form
    name="newUserForm"
    :on-valid-submit="submit"
  >
    <div class="bottom-offset3">
      Digite o código de verificação enviado para a chave Pix abaixo
    </div>
    <div class="row">
      <tr-read-only-text-field
        v-if="pixKey.key_type === pixKeyType.TELEFONE"
        label="Chave Pix (Celular)"
        :value="pixKeyValue"
      />
      <tr-read-only-text-field
        v-else
        label="Chave Pix (E-mail)"
        :value="pixKeyValue"
      />
    </div>
    <div class="row">
      <tr-text-field
        v-model="verificationData.code"
        label="Código de verificação"
        :required="true"
        :dont-use-col-classes="true"
        class="col-xs-6"
        name="verification-code"
      />
    </div>
    <a
      v-if="verificationData.blockedTime <= 0"
      class="cursor-pointer"
      @click="resendCode"
    >Reenviar código de verificação</a>
    <a
      v-else
      disabled
      class="disabled"
    >Reenviar código de verificação ({{ verificationData.blockedTime }})</a>
    <div class="vertical-offset3 bottom-offset3 inline-block width-100">
      <tr-button
        class="pull-left"
        variant="outlined"
        color="secondary"
        label="Voltar"
        :on-button-click="cancel"
      />
      <tr-button
        class="pull-right"
        variant="raised"
        color="primary"
        label="Finalizar"
        type="submit"
        :disabled="loading"
      />
      <tr-button
        v-tooltip.top-center="'Excluir chave'"
        class="pull-right margin-right"
        variant="raised"
        size="medium"
        svg-icon-name="ic-delete"
        svg-icon-color="white"
        color="danger"
        :on-button-click="remove"
      />
    </div>
  </tr-form>
</template>

<script>
import pixKeyRepr from '@/commons/formatters/pixKeyRepr';
import breakBiggerWord from '@/commons/formatters/breakBiggerWord';
import { pix } from '@transfeeradev/api-enums';

export default {
  name: 'pix-key-verification-form',
  props: ['pixKey', 'verificationData', 'loading'],
  emits: ['submit', 'remove', 'resend', 'cancel'],
  data: () => ({
    pixKeyType: pix.keyType
  }),
  computed: {
    pixKeyValue() {
      return breakBiggerWord(pixKeyRepr(this.pixKey), 40);
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    submit() {
      this.$emit('submit');
    },
    remove() {
      this.$emit('remove');
    },
    resendCode() {
      this.$emit('resend');
    }
  }
};
</script>
