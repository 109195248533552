<template>
  <div>
    <tr-button
      :on-button-click="generateApiCredential"
      label="Gerar uma nova chave de acesso"
      variant="raised"
      color="primary"
    />
    <div class="row vertical-offset2">
      <tr-text-field
        ref="clientIdInput"
        :model-value="clientId"
        classes="col-md-6 col-lg-6"
        :label="texts.clientIdLabel"
        name="clientId"
        :readonly="true"
        @update:modelValue="$emit('update:clientId', $event)"
      />
      <tr-button
        classes="vertical-offset1"
        :on-button-click="copyClientId"
        :label="texts.clientIdCopyBtn"
        variant="raised"
        color="default"
      />
    </div>
    <div
      v-if="clientSecret"
      class="row vertical-offset2"
    >
      <tr-text-field
        ref="clientSecretInput"
        :model-value="clientSecret"
        classes="col-md-6 col-lg-6"
        :label="texts.clientSecretLabel"
        name="clientSecret"
        :readonly="true"
        @update:modelValue="$emit('update:clientSecret', $event)"
      />
      <tr-button
        classes="vertical-offset1"
        :on-button-click="copyClientSecret"
        :label="texts.clientSecretCopyBtn"
        variant="raised"
        color="default"
      />
    </div>
  </div>
</template>

<script>
const defaultTexts = {
  clientIdLabel: 'Client ID do seu usuário:',
  clientIdCopyBtn: 'Copiar client ID',
  clientSecretLabel: 'Seu client secret:',
  clientSecretCopyBtn: 'Copiar client secret'
};

export default {
  name: 'api-credential',
  props: {
    generateApiCredential: {
      required: true,
      type: Function
    },
    clientId: {
      required: false,
      type: String,
      default: ''
    },
    clientSecret: {
      required: false,
      type: String,
      default: ''
    },
    texts: {
      required: false,
      type: Object,
      default: defaultTexts
    }
  },
  emits: ['update:clientId', 'update:clientSecret'],
  methods: {
    copyClientId() {
      this.$refs.clientIdInput.copy();
    },
    copyClientSecret() {
      this.$refs.clientSecretInput.copy();
    }
  }
};
</script>
