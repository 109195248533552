import checkpoint from '@/commons/constants/checkpoint';
import checkpointResource from '@/commons/resources/checkpoint';

export const paymentLinkCheckpoint = paymentMethod => {
  switch (true) {
    case paymentMethod.includes('boleto') && paymentMethod.includes('pix') && paymentMethod.includes('credit_card'):
      checkpointResource.create(checkpoint.PAYMENT_LINK_CREATED_WITH_PIX_AND_BOLETO_AND_CARD);
      break;
    case paymentMethod.includes('boleto') && paymentMethod.includes('pix'):
      checkpointResource.create(checkpoint.PAYMENT_LINK_CREATED_LINK_USING_PIX_AND_BOLETO);
      break;
    case paymentMethod.includes('boleto'):
      checkpointResource.create(checkpoint.PAYMENT_LINK_CREATED_LINK_USING_BOLETO);
      break;
    case paymentMethod.includes('pix'):
      checkpointResource.create(checkpoint.PAYMENT_LINK_CREATED_LINK_USING_PIX);
      break;
    case paymentMethod.includes('credit_card'):
      checkpointResource.create(checkpoint.PAYMENT_LINK_CREATED_LINK_USING_CREDIT_CARD);
      break;
  }
};
