import batchResource from '@/apps/payments/payments/batch/resources/batch';
import errorMessageTranslator from '@/commons/services/errorMessageTranslator';
import Sheet from '@transfeeradev/sheets-import';
import transfersLotModelFactory from '@/apps/payments/payments/transfer/services/transfersLotModelFactory';
import { spreadsheetType, errors } from '@transfeeradev/api-enums';
import * as Sentry from '@sentry/browser';
import { getMessage } from '@/commons/services/error';

class SheetImport {
  constructor(file, onReady, onError) {
    this._file = file;
    this._onReady = onReady;
    this._onError = onError;
    this._fileName = null;
    this._canceled = false;
  }

  buildFile() {
    try {
      this.validate();
      return batchResource
        .saveSpreadsheet(this._file, this._file.name)
        .then(this._buildWorkbook.bind(this), this._onUploadError.bind(this));
    } catch (e) {
      this._onError(e, true);
    }
  }

  validate() {
    this._validateSize();
    this._validateExtension();
  }

  _validateExtension() {
    const allowedExtensions = ['csv', 'xlsx'];
    const fileExtension = String(this._getFileExt()).toLowerCase();

    if (!allowedExtensions.includes(fileExtension)) {
      throw new Error(errorMessageTranslator.translate(errors.ptBr.she.INVALID_FILE_EXTENSION.code).message);
    }
  }

  _validateSize() {
    if (this._file.size === 0) {
      throw new Error(errorMessageTranslator.translate(errors.ptBr.she.FORMAT_ERROR.code).message);
    }
  }

  _onUploadError(error) {
    let message = 'Ocorreu um problema no upload da planilha.';
    if (error && error.code) {
      message = getMessage(error);
    }

    this._onError(new Error(message));
  }

  _buildWorkbook(data) {
    const reader = new FileReader();
    const fileExtension = this._getFileExt();

    this._fileName = data.spreadsheet_file_name;

    reader.onload = this._onFileReaderLoad.bind(this, reader);

    if (fileExtension === 'csv') {
      reader.readAsText(this._file);
    } else {
      reader.readAsBinaryString(this._file);
    }
  }

  _onFileReaderLoad(reader, event) {
    let data;

    if (this._canceled) {
      return;
    }

    if (!event) {
      data = reader.content;
    } else {
      data = event.target.result;
    }

    let sheet;

    try {
      sheet = new Sheet(data, this._fileName, {
        type: 'binary'
      });
    } catch (e) {
      Sentry.captureException(e, {
        extra: {
          fileName: this._fileName
        }
      });
    }

    try {
      if (!(sheet instanceof Sheet)) {
        throw new Error(errorMessageTranslator.translate(errors.ptBr.she.FORMAT_ERROR.code).message);
      }

      const sheetType = sheet.getType();

      if (sheetType === spreadsheetType.BILLET) {
        return batchResource
          .billetImportSpreadsheet(this._file, this._fileName)
          .then(this._onFinished.bind(this), error => this._onError(errorMessageTranslator.translate(error.code)));
      }

      if (sheetType !== spreadsheetType.TRANSFER && sheetType !== spreadsheetType.TRANSFER_WITH_PIX_KEY) {
        throw new Error(errorMessageTranslator.translate(sheetType.code).message);
      }

      const sheetValidationResult = sheet.validate();

      if (!sheetValidationResult.isValid) {
        throw sheetValidationResult.error;
      }

      const items = sheet.getItems();

      if (!items) {
        throw new Error('A planilha não contém nenhum item');
      } else {
        this._onTransferProcessFinish(items);
      }
    } catch (e) {
      this._onError(e);
    }
  }

  _onTransferProcessFinish(data) {
    transfersLotModelFactory.get(data).then(this._onGetLotModel.bind(this), this._onErrorGetLotModel.bind(this));
  }

  _onGetLotModel(model) {
    model.spreadsheet_file_name = this._fileName;
    const data = transfersLotModelFactory.prepareToSave(model, false, true);
    delete data.billets;
    batchResource.saveNotValidatedBatch(data).then(this._onFinished.bind(this), this._onErrorGetLotModel.bind(this));
  }

  _onErrorGetLotModel(error) {
    let message = error?.code
      ? getMessage(error)
      : 'Ocorreu um erro ao importar sua planilha, por favor, verifique os dados, caso o erro persistir, entre em contato.';
    if (error?.validation && error.validation.keys.length > 0) {
      message = 'Identificamos um ou mais erros durante a importação da planilha, erros encontrados:';
      error.validation.keys.forEach(e => {
        let datas = e.split('.');
        if (datas[2] === 'pix_description') {
          if (!message.includes('A mensagem Pix deve ter no máximo 140 caracteres por transação')) {
            message += '\n A mensagem Pix deve ter no máximo 140 caracteres por transação';
          }
        }
      });
    }
    const e = new Error(message);
    this._onError(e, true);
    Sentry.captureException(e, {
      extra: {
        fileName: this._fileName
      }
    });
  }

  _getFileExt() {
    return this._file.name.split('.').pop();
  }

  cancel() {
    this._canceled = true;
  }

  _onFinished(batch) {
    if (!this._canceled) {
      this._onReady(batch);
    }
  }
}

export default SheetImport;
