<template>
  <tr-dialog
    ref="modal"
    name="receiver-removal-confirmation-dialog"
    title="Excluir Recebedor"
  >
    <div>
      <tr-typography
        classes="vertical-offset2 inline-block"
        variant="h5"
      >
        Você tem certeza que deseja excluir esse recebedor?
      </tr-typography>
    </div>
    <template #buttons>
      <tr-button
        classes="pull-right"
        :on-button-click="onConfirm"
        label="Excluir"
        variant="raised"
        color="danger"
      />
    </template>
  </tr-dialog>
</template>

<script>
export default {
  name: 'receiver-removal-confirmation-dialog',
  props: {
    onConfirm: {
      required: true,
      type: Function
    }
  },
  methods: {
    open() {
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    }
  }
};
</script>
