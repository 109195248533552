import config from '@/config';
import fetch from '@/fetch';

const BASE_URL = config.url.api.payin + '/app/payin_reports';

export const reportsResource = {
  /** @returns {string} id */
  exportCSV: ({ created_at__gte, created_at__lte, search, status__in, payment_type__in } = {}) => {
    return fetch(BASE_URL, {
      method: 'POST',
      body: JSON.stringify({
        created_at__gte,
        created_at__lte,
        search,
        status__in,
        payment_type__in
      })
    });
  },
  /** @returns {Promise<{items: PayinReport[]}>} */
  getReportById: id => {
    return fetch(`${BASE_URL}/${id}`);
  }
};
