<template>
  <batch-download-link
    v-if="shouldBeShown"
    :download-report="downloadReport"
    :classes="classes"
    text-variant="body"
    label="Baixar relatório de pagamentos"
    :link-style="linkStyle"
  />
</template>

<script>
import BatchDownloadLink from '@/apps/payments/payments/batch/components/presentational/BatchDownloadLink';
import batchResource from '@/apps/payments/payments/batch/resources/batch';
import { batchStatus } from '@transfeeradev/api-enums';

export default {
  name: 'batch-download-link-container',
  components: {
    BatchDownloadLink
  },
  props: ['batch', 'classes', 'linkStyle'],
  computed: {
    shouldBeShown: function () {
      const status = this.batch.status;
      return Boolean(
        status === batchStatus.RECEIVED || status === batchStatus.FINISHED || status === batchStatus.RETURNED
      );
    }
  },
  methods: {
    downloadReport() {
      batchResource.getReportUrl(this.batch.id).then(response => {
        if (response && typeof response.url === 'string') {
          window.open(response.url, '_blank');
        }
      });
    }
  }
};
</script>
