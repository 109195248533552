import { billetStatus } from '@transfeeradev/api-enums';
import billetStatusName from '@/apps/payments/payments/billet/constants/billetStatusName';

export default {
  [billetStatus.CREATED]: billetStatusName[billetStatus.CREATED],
  [billetStatus.SCHEDULED]: billetStatusName[billetStatus.SCHEDULED],
  [billetStatus.PAID]: billetStatusName[billetStatus.PAID],
  [billetStatus.FAILED]: billetStatusName[billetStatus.FAILED],
  [billetStatus.RETURNED]: billetStatusName[billetStatus.RETURNED]
};
