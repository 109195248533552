<template>
  <tr-form
    name="configForm"
    :on-valid-submit="save"
  >
    <tr-show-for-roles :roles="rolesToHaveAllActionsShown">
      <tr-page-section
        title="Correção Automática"
        :no-margin="true"
      >
        <template #description>
          <tr-typography>
            Através de validações na Receita Federal e nos bancos, conseguimos
            <tr-typography strong="true">
              corrigir dados bancários
            </tr-typography> incorretos (Dígito da agência ou conta,
            tipo da conta, abreviaturas de nome, etc), mas sempre
            <tr-typography
              strong="true"
            >
              garantindo que pagaremos para o CPF ou CNPJ correto, na Conta Certa!
            </tr-typography>
          </tr-typography>
        </template>
        <conta-certa-config-container />
      </tr-page-section>
    </tr-show-for-roles>
    <tr-show-for-roles :roles="rolesToHaveAllActionsShown">
      <tr-page-section title="Encaminhar respostas (Reply to)">
        <template #description>
          <tr-typography>
            Quando seu recebedor(a) responder um e-mail de comprovante de pagamento, a resposta será enviada para o
            e-mail abaixo.
          </tr-typography>
        </template>
        <reply-to-email-config-container v-model="replyToEmail" />
      </tr-page-section>
    </tr-show-for-roles>
    <page-footer>
      <template #right>
        <tr-button
          variant="raised"
          color="primary"
          label="Salvar"
          type="submit"
        />
      </template>
    </page-footer>
  </tr-form>
</template>

<script>
import ContaCertaConfigContainer from '@/apps/payments/payments/settings/config/components/container/ContaCertaConfigContainer';
import ReplyToEmailConfigContainer from '@/apps/payments/payments/settings/config/components/container/ReplyToEmailConfigContainer';
import companySettingsResource from '@/apps/payments/payments/settings/company/resources/settings';
import settingsResource from '@/apps/payments/resources/settings';
import { roles } from '@transfeeradev/api-enums';
import { getMessage } from '@/commons/services/error';
import PageFooter from '@/commons/components/presentational/PageFooter.vue';

export default {
  name: 'config-container',
  components: {
    ContaCertaConfigContainer,
    ReplyToEmailConfigContainer,
    PageFooter
  },
  data: () => ({
    rolesToHaveAllActionsShown: roles.appRoles.ADMIN,
    replyToEmail: ''
  }),
  created() {
    return this.refresh();
  },
  methods: {
    async save() {
      if (this.$el.checkValidity()) {
        try {
          await companySettingsResource.update({
            reply_to_email: this.replyToEmail
          });

          const text = 'Configurações salvas com sucesso!';
          this.$toaster.success(text);
        } catch (error) {
          this.$toaster.error('Ocorreu um erro ao salvar a configuração ' + getMessage(error));
        }
      }
    },
    refresh() {
      return settingsResource.get().then(data => {
        this.replyToEmail = data.replyToEmail;
      });
    }
  }
};
</script>
