import PersonTypeService from '@transfeeradev/api-services/PersonTypeService';
import TransferPixService from '@transfeeradev/api-services/TransferPixService';
import { TRANSFER_HEADERS, TRANSFER_PIX_WITH_KEY_HEADERS } from '@transfeeradev/sheets-import';
import dbaBankFormatter from '@/apps/payments/payments/spreadsheetImport/services/formatters/dbaBank';
import dbaAccountTypeFormatter from '@/apps/payments/payments/spreadsheetImport/services/formatters/dbaAccountType';
import dbaPixKeyTypeFormatter from '@/apps/payments/payments/spreadsheetImport/services/formatters/dbaPixKeyType';

const CPF_CNPJ_MAX_SIZE = 25;
const NAME_MAX_SIZE = 255;
const EMAIL_MAX_SIZE = 255;
const AGENCY_MAX_SIZE = 30;
const AGENCY_DIGIT_MAX_SIZE = 10;
const ACCOUNT_MAX_SIZE = 30;
const ACCOUNT_DIGIT_MAX_SIZE = 10;

const format = (row, banks) => {
  const isPixDba = TransferPixService.isPix({
    pix_key: row[TRANSFER_PIX_WITH_KEY_HEADERS.pixKey.description],
    pix_key_type: row[TRANSFER_PIX_WITH_KEY_HEADERS.pixKeyType.description]
  });

  if (isPixDba) {
    return {
      pix_key: row[TRANSFER_PIX_WITH_KEY_HEADERS.pixKey.description] || null,
      pix_key_type: dbaPixKeyTypeFormatter.format(row[TRANSFER_PIX_WITH_KEY_HEADERS.pixKeyType.description]),
      email: _limitString(row[TRANSFER_PIX_WITH_KEY_HEADERS.email.description], EMAIL_MAX_SIZE)
    };
  } else {
    return {
      name: _limitString(row[TRANSFER_HEADERS.name.description], NAME_MAX_SIZE),
      agency: _limitString(row[TRANSFER_HEADERS.agency.description], AGENCY_MAX_SIZE),
      agency_digit: _limitString(row[TRANSFER_HEADERS.agencyDigit.description], AGENCY_DIGIT_MAX_SIZE),
      account: _limitString(row[TRANSFER_HEADERS.account.description], ACCOUNT_MAX_SIZE),
      account_digit: _limitString(row[TRANSFER_HEADERS.accountDigit.description], ACCOUNT_DIGIT_MAX_SIZE),
      bank_id: dbaBankFormatter.format(row[TRANSFER_HEADERS.bank.description], banks),
      account_type: dbaAccountTypeFormatter.format(row[TRANSFER_HEADERS.accountType.description]),
      email: _limitString(row[TRANSFER_HEADERS.email.description], EMAIL_MAX_SIZE),
      cpf_cnpj: _limitString(
        PersonTypeService.getCpfCnpjNumber(row[TRANSFER_HEADERS.cpfCnpj.description]),
        CPF_CNPJ_MAX_SIZE
      )
    };
  }
};

const _limitString = (inputString, maxLength) => inputString && inputString.substr(0, maxLength);

export default {
  format
};
