import { pix } from '@transfeeradev/api-enums';
import merge from 'deepmerge';

const model = {
  id: null,
  name: null,
  cpf_cnpj: null,
  email: null,
  bank_id: null,
  agency: null,
  agency_digit: null,
  account_type: null,
  account: null,
  account_digit: null,
  pix_key: '',
  pix_key_type: pix.keyType.CNPJ
};

const get = () => merge({}, model);

const set = inputModel => merge.all([{}, model, inputModel]);

export default {
  get,
  set
};
