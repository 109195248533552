<template>
  <page title="Importação de planilha">
    <sheet-import-container />
  </page>
</template>

<script>
import SheetImportContainer from '@/apps/payments/payments/spreadsheetImport/components/container/SheetImportContainer.vue';
import Page from '@/commons/components/presentational/Page.vue';

export default {
  name: 'sheet-import-page',
  components: {
    SheetImportContainer,
    Page
  }
};
</script>
