<template>
  <div class="main-loading">
    <tr-loading
      classes="center-h-and-v"
      style="margin-top: -50px"
    />
  </div>
</template>

<script>
export default {
  name: 'main-loading'
};
</script>
