<template>
  <tr-pill
    :color="statusColor"
    :no-margin="noMargin"
    :size="size"
    :classes="classes"
  >
    {{ statusName }}
  </tr-pill>
</template>

<script>
import billetStatusColor from '@/apps/payments/payments/billet/constants/billetStatusColor';
import billetStatusName from '@/apps/payments/payments/billet/constants/billetStatusName';

export default {
  name: 'billet-status-pill',
  props: {
    status: {
      required: false,
      type: String
    },
    noMargin: {
      required: false,
      type: Boolean,
      default: false
    },
    classes: {
      required: false,
      type: String
    },
    size: {
      required: false,
      type: String
    }
  },
  computed: {
    statusColor() {
      if (this.status && this.status.color) {
        return this.status.color;
      }
      return billetStatusColor[this.status];
    },
    statusName() {
      if (this.status && this.status.name) {
        return this.status.name;
      }
      return billetStatusName[this.status];
    }
  }
};
</script>
