<template>
  <div>
    <div class="bottom-offset3 description">
      Identificamos um pedido de registro para a chave Pix abaixo. <br>
      Para continuar utilizando sua chave, confirme que quer mantê-la até {{ limitDate }}. Se não confirmado até o fim
      do prazo, a chave será liberada para outra
      {{ pixKey.status === pixKeyStatus.WAITING_PORTABILITY_APPROVAL ? 'conta' : 'pessoa' }}.
    </div>
    <div class="bottom-offset4">
      <pix-key-read-only-text-field
        :pix-key="pixKeyValue"
        :pix-key-type="pixKey.key_type"
        :dont-use-col-classes="true"
        :no-margin="true"
      />
      <div class="flex-row vertical-offset2">
        <pix-key-status-pill
          :status="pixKey.status"
          no-margin="true"
        />
      </div>
    </div>
    <div class="bottom-offset3 inline-block width-100">
      <tr-button
        class="pull-left"
        variant="raised"
        color="danger"
        label="Liberar chave"
        :on-button-click="leaveKey"
      />
      <tr-button
        class="pull-right"
        variant="raised"
        color="primary"
        label="Manter chave"
        type="button"
        :disabled="loading"
        :on-button-click="keepKey"
      />
    </div>
  </div>
</template>

<script>
import moment from '@transfeeradev/moment';
import pixKeyRepr from '@/commons/formatters/pixKeyRepr';
import breakBiggerWord from '@/commons/formatters/breakBiggerWord';
import { pix } from '@transfeeradev/api-enums';
import PixKeyReadOnlyTextField from '@/apps/payments/payments/settings/pixKeys/components/presentational/PixKeyReadOnlyTextField.vue';
import PixKeyStatusPill from '@/apps/payments/payments/settings/pixKeys/components/presentational/PixKeyStatusPill.vue';

export default {
  name: 'pix-key-claim-request-approval',
  components: { PixKeyReadOnlyTextField, PixKeyStatusPill },
  props: ['pixKey', 'loading'],
  emits: ['leave-key', 'keep-key'],
  data: () => ({
    pixKeyType: pix.keyType,
    pixKeyStatus: pix.pixKeyStatus
  }),
  computed: {
    pixKeyValue() {
      return breakBiggerWord(pixKeyRepr(this.pixKey), 40);
    },
    limitDate() {
      return moment(this.pixKey.updated_at).add(7, 'days').format('DD/MM/YYYY');
    }
  },
  methods: {
    leaveKey() {
      this.$emit('leave-key');
    },
    keepKey() {
      this.$emit('keep-key');
    }
  }
};
</script>
