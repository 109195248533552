import { Notyf } from 'notyf';
import './toaster.css';

export const toasterClassName = 'tr-toaster';

export const Toaster = app => {
  const notyf = new Notyf({
    duration: 5000,
    dismissible: true,
    ripple: false,
    position: {
      x: 'right',
      y: 'top'
    },
    types: [
      {
        type: 'success',
        background: '#00aef3',
        icon: false,
        className: `${toasterClassName} ${toasterClassName}--success`
      },
      {
        type: 'error',
        icon: false,
        background: '#ff3569',
        className: `${toasterClassName} ${toasterClassName}--error`
      }
    ]
  });

  app.config.globalProperties.$toaster = {
    success: text => notyf.success(text),
    error: text => notyf.error(text)
  };

  app.provide('$toaster', app.config.globalProperties.$toaster);
};
