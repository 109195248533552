<template>
  <tr-fullscreen-modal
    ref="modal"
    name="transfer-form-fullscreen-modal"
    :on-close="onCancel"
  >
    <transfer-wizard-container
      :on-remove="remove"
      :on-save="save"
      :cancel="close"
      :batch="batch"
      :transfer="transfer"
    />
  </tr-fullscreen-modal>
</template>

<script>
import TransferWizardContainer from '@/apps/payments/payments/transfer/components/container/TransferWizardContainer.vue';

export default {
  name: 'transfer-wizard-fullscreen-modal',
  components: {
    TransferWizardContainer
  },
  props: {
    onRemove: {
      required: false,
      type: Function
    },
    onSave: {
      required: false,
      type: Function
    },
    onCancel: {
      required: false,
      type: Function
    },
    batch: {
      required: false,
      type: Object,
      default: () => ({})
    },
    transfer: {
      required: false,
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    open() {
      if (this.$refs.modal) {
        this.$refs.modal.open();
      }
    },
    close() {
      if (this.$refs.modal) {
        this.$refs.modal.close();
      }
    },
    remove() {
      this.close();

      if (this.onRemove) {
        this.onRemove();
      }
    },
    save(batchId) {
      this.close();

      if (this.onSave) {
        this.onSave(batchId);
      }
    }
  }
};
</script>
