import { batchStatus } from '@transfeeradev/api-enums';

export default {
  [batchStatus.DRAFT]: 'grey',
  [batchStatus.WAITING_APPROVAL]: 'red',
  [batchStatus.WAITING]: 'yellow',
  [batchStatus.FINISHED]: 'blue',
  [batchStatus.RECEIVED]: 'green',
  [batchStatus.PENDING]: 'white'
};
