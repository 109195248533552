import { pix } from '@transfeeradev/api-enums';
import config from '@/config';
import fetch from '@/fetch';

const BASE_URL = config.url.api.transfeera + '/pix/key';
const APP_BASE_URL = config.url.api.transfeera + '/app/pix/key';

export default {
  create: data =>
    fetch(`${BASE_URL}`, {
      method: 'POST',
      body: JSON.stringify(data)
    }),
  getList: () => fetch(`${APP_BASE_URL}`),
  getRegistered: async () => {
    const keys = await fetch(`${APP_BASE_URL}`);

    return keys.filter(({ status }) =>
      [
        pix.pixKeyStatus.REGISTERED,
        pix.pixKeyStatus.WAITING_PORTABILITY_APPROVAL,
        pix.pixKeyStatus.WAITING_CLAIM_APPROVAL
      ].includes(status)
    );
  },
  getById: id => fetch(`${APP_BASE_URL}/${id}`),
  verify: (id, data) =>
    fetch(`${BASE_URL}/${id}/verify`, {
      method: 'PUT',
      body: JSON.stringify(data)
    }),
  resendVerificationCode: id =>
    fetch(`${BASE_URL}/${id}/resendVerificationCode`, {
      method: 'PUT'
    }),
  remove: id =>
    fetch(`${BASE_URL}/${id}`, {
      method: 'DELETE'
    }),
  claim: id =>
    fetch(`${BASE_URL}/${id}/claim`, {
      method: 'POST'
    }),
  cancelClaim: id =>
    fetch(`${BASE_URL}/${id}/claim/cancel`, {
      method: 'POST'
    }),
  confirmClaim: id =>
    fetch(`${BASE_URL}/${id}/claim/confirm`, {
      method: 'POST'
    })
};
