<template>
  <billet-filter-by-status :on-filter-by-change="onFilterByChange" />
</template>

<script>
import BilletFilterByStatus from '@/apps/payments/payments/billet/components/presentational/BilletFilterByStatus';

export default {
  name: 'billet-filter-by-status-container',
  components: {
    BilletFilterByStatus
  },
  props: {
    onFilterByChange: {
      required: true,
      type: Function
    }
  }
};
</script>
