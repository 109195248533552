/**
 * TODO: Properly remove aws-sdk dependency by not using dependencies that use it.
 * https://github.com/transfeera/board/issues/2688
 *
 * This non-sense below is a temporary solution to avoid runtime errors while being able
 * to remove aws-sdk from the bundle.
 *
 * This because a call to aws-sdk in any dependency used in this project
 * (e.g. @transfeera-dev/api-services) could lead to a runtime error.
 *
 * Now, any eventual unexpected call to these aws-sdk methods will be caught by
 * Sentry so that we can track and properly fix it.
 *
 * Mocks ref: https://github.com/transfeera/aws-sdk/blob/master/src/testing/mocks.js
 *
 */
import * as Sentry from '@sentry/browser';

const logSentry = logMessage => {
  Sentry.captureException(new Error(`[WEBAPP AWS-SDK] - ${logMessage}`), {
    level: Sentry.Severity.Info,
    fingerprint: ['WEBAPP AWS-SDK']
  });
};

const mockAWSMethod = logMessage => {
  return (data, callback) => {
    logSentry(logMessage);

    if (typeof callback === 'function') {
      return callback();
    }

    let promise = Promise.resolve();

    return { promise };
  };
};

export class S3 {
  constructor() {
    logSentry('S3.constructor');
  }

  putObject() {
    return mockAWSMethod('S3.puObject');
  }
  upload() {
    return mockAWSMethod('S3.upload');
  }
  getObject() {
    return mockAWSMethod('S3.getObject');
  }
}

export class SQS {
  constructor() {
    logSentry('SQS.constructor');
  }

  sendMessageBatch() {
    return mockAWSMethod('SQS.sendMessageBatch');
  }
  sendMessage() {
    return mockAWSMethod('SQS.sendMessage');
  }
  getQueueAttributes() {
    return mockAWSMethod('SQS.getQueueAttributes');
  }
}

export class SNS {
  constructor() {
    logSentry('SNS.constructor');
  }

  publish() {
    return mockAWSMethod('SNS.publish');
  }
}

export class DynamoDB {
  constructor() {
    logSentry('DynamoDB.constructor');
  }

  get() {
    return mockAWSMethod('DynamoDB.get');
  }
  put() {
    return mockAWSMethod('DynamoDB.put');
  }
  delete() {
    return mockAWSMethod('DynamoDB.delete');
  }
}

export class SecretsManager {
  constructor() {
    logSentry('SecretsManager.constructor');
  }

  getSecretValue() {
    return mockAWSMethod('SecretsManager.getSecretValue');
  }
}

export class Lambda {
  constructor() {
    logSentry('Lambda.constructor');
  }

  invoke() {
    return mockAWSMethod('Lambda.invoke');
  }
  invokeAsync() {
    return mockAWSMethod('Lambda.invokeAsync');
  }
}
