<template>
  <bank-autocomplete-container
    :label="label"
    :classes="classes"
    :name="name"
    :warning="warning"
    :hide-message-on-error="hideMessageOnError"
    :error="error"
    :required="true"
    :get-data="getBanksBySource"
    :bank-id="bankId"
    :show-ispb="showIspb"
    @change-bank="onBankChange"
  />
</template>

<script>
import bankResource from '@/apps/payments/payments/bank/resources/bank';
import BankAutocompleteContainer from '@/commons/components/container/bank/BankAutocompleteContainer.vue';

export default {
  name: 'bank-autocomplete',
  components: { BankAutocompleteContainer },
  props: [
    'onBankChange',
    'label',
    'classes',
    'name',
    'required',
    'bankId',
    'warning',
    'hideMessageOnError',
    'error',
    'showIspb'
  ],
  methods: {
    getBanksBySource() {
      return bankResource.getAll();
    }
  }
};
</script>
