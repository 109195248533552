<template>
  <tr-text-field
    v-model="selectedStatus"
    placeholder="Filtrar por status"
    name="billet-filter-by-status"
    no-margin="true"
    classes="col-lg-3"
    select="true"
    :on-value-change="onChange"
  >
    <option value="">
      Todos
    </option>
    <option
      v-for="(billetStatusOption, key) in billetStatusToShowForCustomerContant"
      :key="key"
      :value="billetStatusOption"
    >
      {{ billetStatusNameConstant[key] }}
    </option>
  </tr-text-field>
</template>

<script>
import billetStatusToShowForCustomerContant from '@/apps/payments/payments/billet/constants/billetStatusToShowForCustomer';
import billetStatusNameConstant from '@/apps/payments/payments/billet/constants/billetStatusName';

export default {
  name: 'billet-filter-by-status',
  props: {
    onFilterByChange: {
      required: true,
      type: Function
    }
  },
  data: () => ({
    selectedStatus: '',
    billetStatusToShowForCustomerContant,
    billetStatusNameConstant
  }),
  methods: {
    onChange(newValue) {
      if (newValue.includes(',')) {
        newValue = newValue.split(',');
      }
      this.onFilterByChange(newValue);
    }
  }
};
</script>
