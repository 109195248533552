<template>
  <tr-form
    name="newUserForm"
    :on-valid-submit="save"
  >
    <tr-page-section title="Quais os dados do novo usuário?">
      <div class="row">
        <tr-text-field
          v-model="user.name"
          label="Qual o nome do novo usuário?"
          name="name"
          :required="true"
          :pattern="FULL_NAME"
          error-message="O nome deve ter no mínimo 2 palavras"
        />
        <tr-text-field
          v-model="user.email"
          label="Qual o e-mail?"
          type="email"
          :required="true"
          :pattern="EMAIL_REGEX"
          error-message="E-mail inválido"
          name="email"
        />
      </div>
      <span> * O novo usuário irá receber um e-mail para definir uma senha e ter acesso à plataforma </span>
    </tr-page-section>
    <tr-page-section
      v-if="applications.length"
      title="Quais devem ser os produtos deste usuário?"
    >
      <user-apps-selection
        v-model="user.applications"
        :applications="applications"
      />
    </tr-page-section>
    <tr-page-section
      v-if="user.applications?.length"
      title="Qual deve ser o perfil deste usuário?"
    >
      <user-profile-selection :user="user" />
    </tr-page-section>
    <tr-footer-buttons-container>
      <tr-button
        class="pull-left"
        variant="outlined"
        color="secondary"
        label="Voltar"
        :on-button-click="onCancel"
      />
      <tr-button
        class="pull-right"
        variant="raised"
        color="primary"
        label="Salvar"
        type="submit"
      />
    </tr-footer-buttons-container>
  </tr-form>
</template>

<script>
import UserProfileSelection from './UserProfileSelection';
import UserAppsSelection from './UserAppsSelection';
import { regexes } from '@transfeeradev/api-enums';

export default {
  name: 'new-user-form',
  components: {
    UserProfileSelection,
    UserAppsSelection
  },
  props: {
    onSave: {
      type: Function
    },
    onCancel: {
      type: Function
    },
    applications: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    user: {
      applications: []
    },
    EMAIL_REGEX: regexes.EMAIL,
    FULL_NAME: regexes.FULL_NAME
  }),
  methods: {
    save() {
      if (this.$el.checkValidity()) {
        const user = {
          name: this.user.name,
          email: this.user.email,
          profile: this.user.profile,
          applications: this.user.applications
        };
        this.onSave(user);
      }
    }
  }
};
</script>
