<template>
  <personal-data-form
    :user="user"
    :full-width="fullWidth"
  >
    <template #password-change-dialog="{ close }">
      <password-change-form-container
        :on-cancel="close"
        :on-save="close"
      />
    </template>
  </personal-data-form>
</template>

<script>
import PasswordChangeFormContainer from './PasswordChangeFormContainer.vue';
import PersonalDataForm from '@/commons/components/presentational/company/PersonalDataForm.vue';
import userResource from '@/commons/resources/companyUser';
import loggedUserResource from '@/commons/resources/loggedUser';
import { getMessage } from '@/commons/services/error';

export default {
  name: 'user-form-container',
  components: {
    PersonalDataForm,
    PasswordChangeFormContainer
  },
  props: ['formName', 'onFormSubmit', 'fullWidth'],
  data: () => ({
    user: {}
  }),
  created() {
    this.refresh();
  },
  methods: {
    refresh() {
      userResource.getFreshLoggedUser().then(
        user => {
          this.user = user;
        },
        error => this.$toaster.error(getMessage(error))
      );
    },
    onSubmit() {
      const user = {
        name: this.user.name
      };
      return userResource.update(user).then(
        response => {
          loggedUserResource.set(response.new_token);
          window.dispatchEvent(new Event('refreshLoggedUserData'));
          this.refresh();
        },
        error => this.$toaster.error(getMessage(error))
      );
    }
  }
};
</script>
