<template>
  <page title="Primeiros passos">
    <first-steps-container />
  </page>
</template>

<script>
import FirstStepsContainer from '@/apps/payments/payments/config/components/container/FirstStepsContainer.vue';
import Page from '@/commons/components/presentational/Page.vue';

export default {
  name: 'first-steps-page',
  components: {
    FirstStepsContainer,
    Page
  }
};
</script>
