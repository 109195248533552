<template>
  <top-bar
    :user-name-abbreviated="userNameAbbreviated"
    :apps="apps"
  />
</template>

<script>
import TopBar from '@/commons/components/presentational/TopBar.vue';
import loggedUserResource from '@/commons/resources/loggedUser';
import * as appsService from '@/commons/services/apps';

export default {
  name: 'top-bar-container',
  components: {
    TopBar
  },
  data: () => ({
    userNameAbbreviated: '',
    validationAccount: false,
    apps: appsService.getApps()
  }),
  created() {
    this.refresh();
  },
  methods: {
    refresh() {
      this.getUserData();
    },
    getUserData() {
      const loggedUser = loggedUserResource.get();
      this.userNameAbbreviated = loggedUser.name.split(' ')[0];
    }
  }
};
</script>
