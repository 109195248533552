import loggedUserResource from '@/commons/resources/loggedUser';
import getByProp from '@/commons/services/getByProp';
import { logger } from '@transfeeradev/logger';

const NO_ALLOWED_ROLES_PROVIDED_WARN_MESSAGE =
  'No allowedRoles were provided to role has access verification, allowedRoles var content provided: ';

const isLoggedUserAllowedToAccess = allowedRoles => {
  if (_isNoAllowedRoleProvided(allowedRoles)) {
    logger.warn(NO_ALLOWED_ROLES_PROVIDED_WARN_MESSAGE + allowedRoles);
    return false;
  }

  const loggedUserRole = loggedUserResource.get().profile;

  if (typeof allowedRoles === 'string') {
    allowedRoles = [
      {
        value: allowedRoles
      }
    ];
  }

  if (!Array.isArray(allowedRoles) && typeof allowedRoles === 'object') {
    allowedRoles = [allowedRoles];
  }

  return Boolean(
    getByProp.get({
      propValue: loggedUserRole,
      propName: 'value',
      list: allowedRoles
    })
  );
};

const _isNoAllowedRoleProvided = allowedRoles =>
  Boolean(!allowedRoles || (Array.isArray(allowedRoles) && !allowedRoles.length));

const isLoggedUserForbiddenToAccess = forbiddenRoles => isLoggedUserAllowedToAccess(forbiddenRoles);

export default {
  isLoggedUserAllowedToAccess,
  isLoggedUserForbiddenToAccess
};
