<template>
  <tr-loading
    v-if="isLoading"
    classes="width-100 center-h-and-v"
  />
  <tr-page-section
    v-else-if="hasApiAccess"
    :title="title"
  >
    <api-credential
      v-model:client-id="clientId"
      v-model:client-secret="clientSecret"
      :generate-api-credential="openApiCredentialGenerationConfirmationDialog"
    />
    <api-credential-generation-confirmation-dialog
      ref="apiCredentialGenerationConfirmationDialog"
      :on-confirm="generateApiCredential"
      :is-conta-certa-credential="true"
    />
    <api-key-generation-confirmation-dialog
      ref="apiKeyGenerationConfirmationDialog"
      :on-confirm="generateApiCredential"
    />
  </tr-page-section>
</template>

<script>
import * as appService from '@/commons/services/apps';
import ApiCredential from '@/commons/components/presentational/config/ApiCredential';
import ApiCredentialGenerationConfirmationDialog from '@/commons/components/presentational/config/ApiCredentialGenerationConfirmationDialog';
import ApiKeyGenerationConfirmationDialog from '@/commons/components/presentational/config/ApiKeyGenerationConfirmationDialog';
import settingsResource from '@/apps/contacerta/resources/settings';
import { APPS } from '@/commons/constants/apps';
import { getMessage } from '@/commons/services/error';

export default {
  name: 'api-credential-container',
  components: {
    ApiCredential,
    ApiCredentialGenerationConfirmationDialog,
    ApiKeyGenerationConfirmationDialog
  },
  emits: ['fetched'],
  data: () => ({
    isLoading: true,
    hasApiAccess: false,
    clientId: '',
    clientSecret: '',
    title: `Credenciais para API ${APPS.CONTACERTA.title}`
  }),
  created() {
    this.refresh();
  },
  methods: {
    async refresh() {
      this.isLoading = true;

      await settingsResource.get(true).then(
        async data => {
          this.hasApiAccess = data.hasApiAccess;
          if (!data.hasApiAccess) {
            this.isLoading = false;
            return;
          }

          await appService.authAs(APPS.CONTACERTA.key);
          return settingsResource.getApiCredential().then(
            result => {
              this.clientId = result.client_id;
              this.isLoading = false;
            },
            error => this.$toaster.error(getMessage(error))
          );
        },
        error => this.$toaster.error(getMessage(error))
      );

      this.$emit('fetched');
    },
    openApiCredentialGenerationConfirmationDialog() {
      this.$refs.apiCredentialGenerationConfirmationDialog.open();
    },
    openApiKeyGenerationConfirmationDialog() {
      this.$refs.apiKeyGenerationConfirmationDialog.open();
    },
    async generateApiCredential() {
      this.isLoading = true;

      try {
        /**
         * TODO: Remove this app token switching
         */
        await appService.authAs(APPS.CONTACERTA.key);
        const credentials = await settingsResource.createApiCredential();
        this.clientId = credentials.client_id;
        this.clientSecret = credentials.client_secret;
        this.$toaster.success('Nova credencial de acesso criada com sucesso!');
      } catch (error) {
        this.$toaster.error('Ocorreu um erro ao gerar uma nova credencial: ' + getMessage(error));
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>
