<template>
  <div>
    <tr-page-section title="Qual é o boleto que você deseja pagar?">
      <div class="row">
        <barcode-field-container
          :billet="billet"
          :on-change="onBarcodeChange"
        />
      </div>
    </tr-page-section>
    <tr-page-section title="Dados do boleto a ser pago">
      <billet-data-container
        :barcode="billet.barcode"
        :billet="billet"
        :on-billet-value-change="onBilletValueChange"
        :on-barcode-process="onBarcodeProcess"
        :can-edit="true"
        :on-payment-date-change="onPaymentDateChange"
      />
    </tr-page-section>
    <tr-page-section title="Descrição do boleto">
      <div class="row">
        <billet-description-field-container
          :billet="billet"
          :on-change="onDescriptionChange"
        />
      </div>
    </tr-page-section>
    <tr-page-section
      v-if="settings && settings.hasIntegrationId"
      title="Qual o ID de integração?"
    >
      <div class="row">
        <integration-id-field-container
          :integration-id="billet.integration_id"
          :item-id="billet.id"
          type="BILLET"
          :on-change="integrationId => (billet.integration_id = integrationId)"
        />
      </div>
    </tr-page-section>
  </div>
</template>

<script>
import BarcodeFieldContainer from '@/apps/payments/payments/billet/components/container/BarcodeFieldContainer';
import BilletDescriptionFieldContainer from '@/apps/payments/payments/billet/components/container/BilletDescriptionFieldContainer';
import IntegrationIdFieldContainer from '@/apps/payments/components/container/IntegrationIdFieldContainer';
import BilletDataContainer from '@/apps/payments/payments/billet/components/container/BilletDataContainer';
import settingsResource from '@/apps/payments/resources/settings';

export default {
  name: 'billet-form',
  components: {
    BarcodeFieldContainer,
    BilletDataContainer,
    BilletDescriptionFieldContainer,
    IntegrationIdFieldContainer
  },
  props: {
    billet: {
      required: false,
      type: Object,
      default: () => ({})
    },
    onBarcodeChange: {
      required: true,
      type: Function
    },
    onBarcodeProcess: {
      required: true,
      type: Function
    },
    onPaymentDateChange: {
      required: true,
      type: Function
    },
    onDescriptionChange: {
      required: true,
      type: Function
    },
    onBilletValueChange: {
      required: true,
      type: Function
    }
  },
  data: () => ({
    settings: {}
  }),
  created() {
    settingsResource.get().then(settings => {
      this.settings = settings;
    });
  }
};
</script>
