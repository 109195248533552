import loggedUserResource from '@/commons/resources/loggedUser';

const create = () => {
  /* eslint-disable */
  const host = window.location.host;
  if (host.indexOf('localhost') === -1 && host.indexOf('host.docker.internal') === -1 && host.indexOf('homolog') === -1) {
    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', 'GTM-T82N39G');
  }
  /* eslint-enable */
};

const setDataLayer = async () => {
  if (window.dataLayer && Array.isArray(window.dataLayer)) {
    const loggedUser = loggedUserResource.get();

    window.dataLayer.push({
      event: 'userData',
      userEmail: loggedUser.email,
      userName: loggedUser.name,
      company: loggedUser.company.name,
      cnpj: loggedUser.company.cpfCnpj
    });
  }
};

const setup = () => {
  create();
  setDataLayer();
};

export default {
  setup
};
