import PersonTypeService from '@transfeeradev/api-services/PersonTypeService';

export default value => {
  if (value?.includes('*')) {
    return value;
  }

  return PersonTypeService.format(value);
};

export const obfuscateAndFormatCPF = cpf => {
  return PersonTypeService.obfuscateCPF(PersonTypeService.format(cpf));
};
