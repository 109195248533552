import FirstStepsPage from './config/pages/FirstStepsPage.vue';
import StatementPage from './statement/pages/StatementPage.vue';
import BatchesWaitingToReceive from './batch/pages/BatchesWaitingToReceive.vue';
import TransfersPage from './transfer/pages/TransfersPage.vue';
import BilletsPage from './billet/pages/BilletsPage.vue';
import BatchesPage from './batch/pages/BatchesPage.vue';
import ReceiversPage from './receivers/pages/ReceiversPage.vue';
import IndicatorPage from './indicator/pages/IndicatorPage.vue';
import SheetImportPage from './spreadsheetImport/pages/SheetImportPage.vue';
import PaymentsLayout from './Layout.vue';

import { APPS } from '@/commons/constants/apps';
import settingsRoutes from './settings/routes';
import { roles } from '@transfeeradev/api-enums';

export default {
  path: '/pagamentos',
  component: PaymentsLayout,
  children: [
    settingsRoutes,
    {
      path: '',
      redirect: {
        name: 'BatchesPage'
      }
    },
    {
      path: 'primeiros-passos',
      name: 'FirstStepsPage',
      component: FirstStepsPage
    },
    {
      path: 'saldo',
      name: 'StatementPage',
      component: StatementPage
    },
    {
      path: 'importacao-de-planilha',
      name: 'SheetImportPage',
      component: SheetImportPage,
      meta: {
        forbidForRoles: roles.appRoles.ANALYST
      }
    },
    {
      path: 'lotes',
      name: 'BatchesPage',
      component: BatchesPage
    },
    {
      path: 'indicadores',
      name: 'IndicatorPage',
      component: IndicatorPage
    },
    {
      path: 'lotes-aguardando-recebimento',
      name: 'BatchesWaitingToReceive',
      component: BatchesWaitingToReceive
    },
    {
      path: 'transferencias',
      name: 'TransfersPage',
      component: TransfersPage
    },
    {
      path: 'boletos',
      name: 'BilletsPage',
      component: BilletsPage
    },
    {
      path: 'recebedores',
      name: 'ReceiversPage',
      component: ReceiversPage
    }
  ].map(route => ({
    ...route,
    meta: {
      ...route.meta,
      authAs: APPS.PAYMENTS.key
    }
  }))
};
