<template>
  <tr-svg-icon
    v-tooltip.top-center="icon.text"
    :name="icon.name"
  />
</template>

<script>
import { useTfaIcon } from '@/commons/composables/useTfaIcon';

export default {
  props: {
    hasTwoFactorEnabled: {
      type: Boolean
    }
  },
  setup(props) {
    const { icon } = useTfaIcon({
      hasTwoFactorEnabled: props.hasTwoFactorEnabled
    });

    return {
      icon
    };
  }
};
</script>
