import config from '@/config';
import fetch from '@/fetch';

const BASE_URL = config.url.api.transfeera + '/financial/billingHistory';
export default {
  get: () => fetch(`${BASE_URL}`),
  hasPayRequest: () => fetch(`${BASE_URL}/hasPayRequest`),
  hasOverdue: () => fetch(`${BASE_URL}/hasOverdue`),
  payRequest: () => fetch(`${BASE_URL}/payRequest`),
  getMonthlyReport: date => fetch(`${BASE_URL}/report/monthly?date=${date}`)
};
