<template>
  <tr-fullscreen-modal
    ref="modal"
    name="validation-result-fullscreen-modal"
  >
    <div class="vertical-offset2">
      <validation-result-container
        :validation-id="validationId"
        :show-back-button="true"
        @back="onBack"
      />
    </div>
  </tr-fullscreen-modal>
</template>

<script>
import ValidationResultContainer from '../components/container/ValidationResultContainer';

export default {
  name: 'validation-result-fullscreen-modal',
  components: {
    ValidationResultContainer
  },
  props: {
    validationId: {
      type: String,
      required: false
    }
  },
  methods: {
    onBack() {
      this.close();
    },
    onHide() {
      this.close();
    },
    open() {
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    }
  }
};
</script>
