<template>
  <div>
    <template v-if="showFormHeader">
      <div class="bottom-offset3">
        Essa é a conta para qual enviaremos seu recurso quando um saque for solicitado
      </div>
      <div class="row">
        <tr-read-only-text-field
          label="Nome"
          :value="bankAccount.name"
        />
      </div>
      <div class="row">
        <tr-read-only-text-field
          label="CNPJ"
          :value="cpfCnpj"
        />
      </div>
    </template>
    <bank-account-form-container
      :bank-account="bankAccount"
      should-show-operation-type="true"
      full-size="true"
    />
  </div>
</template>

<script>
import BankAccountFormContainer from '@/apps/payments/payments/bank/components/container/BankAccountFormContainer';
import formatCpfCnpj from '@/commons/formatters/cpfCnpj';

export default {
  name: 'company-bank-account-form',
  components: {
    BankAccountFormContainer
  },
  props: ['bankAccount', 'showFormHeader'],
  computed: {
    cpfCnpj() {
      return formatCpfCnpj(this.bankAccount.cpfCnpj);
    }
  }
};
</script>
