<template>
  <section
    v-if="!isHidden"
    class="banner"
  >
    <div class="banner-text">
      <bg-text
        weight="bold"
        type="h6"
        color="green-dark"
      >
        Faça cobranças com Cartão de Crédito na Transfeera!
      </bg-text>
      <bg-text
        type="body-md"
        color="grey-light"
      >
        Agora você pode usar o Link de Pagamento e ter todos os meios de pagamento em um só lugar.
      </bg-text>
      <bg-link
        to="https://transfeera.com/solucoes/link-de-pagamento/?utm_source=plataforma&utm_medium=banner"
      >
        <bg-text
          weight="bold"
          type="body-md"
          color="blue-semi-dark"
        >
          Comece a usar
        </bg-text>
        <bg-icon
          size="sm"
          name="arrow-right"
          color="blue-semi-dark"
        />
      </bg-link>
    </div>
    <bg-illustration name="girl-with-checkout" />
    <bg-icon
      size="sm"
      name="close"
      color="grey-dark"
      class="banner-close-icon"
      @click="toggleBanner"
    />
  </section>
</template>

<script setup>
import { BgIllustration, BgText, BgLink, BgIcon } from '@transfeeradev/bridge';
import { ref } from 'vue';

const isHidden = ref(false);
const toggleBanner = () => {
  isHidden.value = !isHidden.value;
};
</script>

<style scoped>
section {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 250px;
  width: auto;
  background-color: var(--bg-green-normal);
  border-radius: var(--bg-border-radius-md);
  margin-top: var(--bg-spacing-md);

  div > a {
    display: flex;
    align-items: center;
    gap: var(--bg-spacing-x-sm);
    color: var(--bg-blue-semi-dark);
  }

  div > p {
    margin: var(--bg-spacing-sm) 0;
  }
}

.banner-text {
  max-width: 500px;
}

.banner-close-icon {
  position: absolute;
  top: var(--bg-spacing-sm);
  right: var(--bg-spacing-sm);
  cursor: pointer;
}
</style>
