<template>
  <bank-autocomplete-container
    :label="label"
    :classes="classes"
    :name="name"
    :required="true"
    :get-data="getBanksBySource"
    :bank-id="bankId"
    @change-bank="onBankChange"
  />
</template>

<script>
import bankResource from '@/apps/contacerta/resources/bank';
import BankAutocompleteContainer from '@/commons/components/container/bank/BankAutocompleteContainer.vue';

export default {
  name: 'bank-autocomplete',
  components: { BankAutocompleteContainer },
  props: {
    label: {
      type: String
    },
    classes: {
      type: String
    },
    name: {
      type: String
    },
    required: {
      type: Boolean
    },
    bankId: {
      type: [String, Number]
    }
  },
  emits: ['update:bank'],
  methods: {
    getBanksBySource() {
      return bankResource.get();
    },
    onBankChange(value) {
      this.$emit('update:bank', value);
    }
  }
};
</script>
