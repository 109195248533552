import batchModel from '@/apps/payments/payments/batch/models/batch';
import prepareToSaveBillet from '@/apps/payments/payments/billet/services/prepareToSave';
import { batchType } from '@transfeeradev/api-enums';
import transferModelFactory from '@/apps/payments/payments/transfer/services/transferModelFactory';

var service = {};

service.get = function get(data) {
  return getSpreadsheetData(data);
};

service.prepareToSave = function prepareToSave(batch, isUpdate, isFromSpreadsheet) {
  const currentBatchType = batch.type;
  batch = Object.assign({}, batch);
  delete batch.id;
  delete batch.value;
  delete batch.received_date;
  delete batch.status;
  delete batch.returned_date;
  delete batch.finish_date;
  delete batch.created_at;
  delete batch.reconciled;
  delete batch.created_by;
  delete batch.type;
  delete batch.was_imported;
  delete batch.cancel_requested_at;

  if (isUpdate) {
    delete batch.spreadsheet_file_name;
  }

  if (batchType.BILLET === currentBatchType) {
    batch.billets = batch.billets && batch.billets.map(billet => prepareToSaveBillet(billet));
  } else {
    batch.transfers =
      batch.transfers &&
      batch.transfers.map(transfer => transferModelFactory.prepareToSave(transfer, isFromSpreadsheet));
  }

  return batch;
};

function getSpreadsheetData(spreadsheetData) {
  return new Promise((resolve, reject) => {
    try {
      buildTransfers(spreadsheetData).then(onBuildTransfers, onErrorBuildTransfers);

      // eslint-disable-next-line no-inner-declarations
      function onBuildTransfers(data) {
        const response = batchModel.get();
        response.transfers = data;
        resolve(response);
      }

      // eslint-disable-next-line no-inner-declarations
      function onErrorBuildTransfers(e) {
        reject(e);
      }
    } catch (e) {
      reject(e);
    }
  });
}

function buildTransfers(spreadsheetData) {
  // eslint-disable-next-line no-useless-catch
  try {
    var transfers = transferModelFactory.getFromSpreadsheet(spreadsheetData);
    return transfers;
  } catch (e) {
    throw e;
  }
}

export default service;
