const getDefaultOptions = () => ({
  propValue: '',
  propName: '',
  list: [],
  includes: false
});

const get = (options = getDefaultOptions()) => {
  const list = options.list;
  for (let item in list) {
    // eslint-disable-next-line no-prototype-builtins
    if (list.hasOwnProperty(item)) {
      const itemPropValue = String(list[item][options.propName]).toLowerCase().trim();
      const formattedPropValue = String(options.propValue).toLowerCase().trim();

      if (String(itemPropValue) === String(formattedPropValue)) {
        return list[item];
      }

      if (
        options.includes &&
        (itemPropValue.includes(formattedPropValue) || formattedPropValue.includes(itemPropValue))
      ) {
        return list[item];
      }

      if (_isSameNumber(itemPropValue, formattedPropValue)) {
        return list[item];
      }
    }
  }
  return null;
};

const _isSameNumber = (itemPropValue, propValue) => {
  const areNumbers = Boolean(_isNumber(itemPropValue) && _isNumber(propValue));
  const isSameNumber = Boolean(Number.parseInt(itemPropValue) === Number.parseInt(propValue));
  return Boolean(areNumbers && isSameNumber);
};

const _isNumber = number => !isNaN(number);

export default {
  get
};
