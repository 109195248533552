<template>
  <billet-data
    :can-edit="canEdit"
    :billet="billet"
    :current-billet-type="currentBilletType"
    :should-let-value-be-edited="shouldLetValueBeEdited"
    :on-billet-value-change="onBilletValueChange"
    :on-payment-date-change="onPaymentDateChange"
  />
</template>

<script>
import { GetTypeService, CollectionBarcode, SimpleBarcode } from '@transfeeradev/billet-validation';
import { billetType } from '@transfeeradev/api-enums';
import BilletData from '@/apps/payments/payments/billet/components/presentational/BilletData';
import bankResource from '@/apps/payments/payments/bank/resources/bank';

export default {
  name: 'billet-data-container',
  components: {
    BilletData
  },
  props: {
    billet: {
      required: false,
      type: Object,
      default: () => ({
        bank: {}
      })
    },
    canEdit: {
      required: true,
      type: Boolean,
      default: false
    },
    barcode: {
      required: false,
      type: [Number, String]
    },
    onBarcodeProcess: {
      required: true,
      type: Function
    },
    onPaymentDateChange: {
      required: true,
      type: Function
    },
    onBilletValueChange: {
      required: true,
      type: Function
    }
  },
  data: () => ({
    shouldLetValueBeEdited: false,
    currentBilletType: billetType.SIMPLE
  }),
  watch: {
    barcode() {
      this.processBarcode();
    }
  },
  methods: {
    processBarcode() {
      this.currentBilletType = GetTypeService.get(this.barcode);
      if (this.currentBilletType === billetType.SIMPLE) {
        this.processSimpleBillet();
      } else if (this.currentBilletType === billetType.COLLECTION) {
        this.processCollectionBillet();
      }
    },
    processSimpleBillet() {
      const billetData = new SimpleBarcode({
        barcode: this.barcode
      }).getData();
      if (billetData.bankCode) {
        this.shouldLetValueBeEdited = !billetData.value;
        bankResource.getByCode(billetData.bankCode).then(bank => {
          billetData.bank = bank || {};
          this.onBarcodeProcess(billetData);
        });
      } else {
        this.onBarcodeProcess(Object.assign(billetData, { bank: null }));
        this.shouldLetValueBeEdited = false;
      }
    },
    processCollectionBillet() {
      const billetData = new CollectionBarcode({
        barcode: this.barcode
      }).getData();
      this.onBarcodeProcess(billetData);
      this.shouldLetValueBeEdited = false;
    }
  }
};
</script>
