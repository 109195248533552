import { batchStatus } from '@transfeeradev/api-enums';
import batchStatusName from '@/apps/payments/payments/batch/constants/batchStatusName';

export default {
  [batchStatus.DRAFT]: batchStatusName[batchStatus.DRAFT],
  [batchStatus.WAITING]: batchStatusName[batchStatus.WAITING],
  [batchStatus.WAITING_APPROVAL]: batchStatusName[batchStatus.WAITING_APPROVAL],
  [batchStatus.RECEIVED]: batchStatusName[batchStatus.RECEIVED],
  [batchStatus.FINISHED]: batchStatusName[batchStatus.FINISHED]
};
