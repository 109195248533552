<template>
  <receipt-download-links
    v-if="validationData?.type === type.MICRO_DEPOSIT"
    classes="text-center"
    :item="validationData"
    :get-receipt="getReceipt"
  />
</template>

<script>
import ReceiptDownloadLinks from '@/commons/components/presentational/ReceiptDownloadLinks.vue';
import { contacerta } from '@transfeeradev/api-enums';
export default {
  name: 'validation-receipt-container',
  components: {
    ReceiptDownloadLinks
  },
  props: {
    validationData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      type: contacerta.type
    };
  },
  methods: {
    getReceipt(validation, urlProperty, event) {
      event.stopPropagation();
      if (urlProperty && validation[urlProperty]) {
        window.open(validation[urlProperty], '_blank');
      }
    }
  }
};
</script>
