<template>
  <div class="row margin-left-10 margin-right-10 receivers-autocomplete-suggestions-header">
    <div class="col-lg-5">
      <label>Nome</label>
    </div>
    <div class="col-lg-3">
      <label>Pix ou Conta</label>
    </div>
    <div class="col-lg-4 text-right">
      <label>Documento</label>
    </div>
  </div>
</template>
