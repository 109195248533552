import config from '@/config';
import fetch from '@/fetch';

const BASE_URL = config.url.contacerta + '/app/company';

export default {
  get: () => fetch(`${BASE_URL}`),
  update: company =>
    fetch(`${BASE_URL}`, {
      method: 'PUT',
      body: JSON.stringify(company)
    })
};
