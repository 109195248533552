<template>
  <span
    v-if="value !== null && !validationAccount"
    class="app-topbar-balance"
  >
    Saldo Atual:
    <router-link
      tabindex="3"
      class="cursor-pointer"
      :to="{ name: 'StatementPage' }"
    >
      {{ $format.currency(value) }}
    </router-link>
  </span>
  <span
    v-if="waitingValue && !validationAccount"
    class="app-topbar-pending-balance"
  >
    Aguardando recebimento:
    <router-link
      tabindex="2"
      class="cursor-pointer"
      :to="{ name: 'BatchesWaitingToReceive' }"
    >
      {{ $format.currency(waitingValue) }}
    </router-link>
  </span>
</template>

<script>
import { getAppRoute } from '@/commons/services/apps';

export default {
  name: 'top-bar',
  props: {
    waitingValue: {
      required: false,
      type: [String, Number]
    },
    value: {
      required: false,
      default: null,
      type: [String, Number]
    },
    userNameAbbreviated: {
      required: false,
      type: String
    },
    validationAccount: {
      required: false,
      type: Boolean,
      default: false
    },
    apps: {
      required: true,
      type: Array
    },
    currentAppName: {
      required: false,
      type: String
    }
  },
  setup() {
    return {
      getAppRoute
    };
  }
};
</script>
