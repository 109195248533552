<template>
  <form @submit.prevent="onSave">
    <tr-dialog
      ref="modal"
      :unique-name="true"
      name="batch-name-cancel-dialog"
      title="Tem certeza que deseja cancelar o lote?"
      :has-its-own-buttons="true"
    >
      <bg-text
        type="body-md"
        color="grey-dark"
      >
        Ao confirmar o cancelamento do lote {{ name }}, seus pagamentos serão devolvidos e a operação não poderá ser desfeita.
      </bg-text>
      <tr-dialog-buttons-container>
        <bg-button
          size="md"
          class="pull-right batch-cancel__confirm-button"
          type="submit"
        >
          Confirmar
        </bg-button>
        <bg-button
          class="pull-right"
          variant="outline"
          @click.prevent="close"
        >
          Voltar
        </bg-button>
      </tr-dialog-buttons-container>
    </tr-dialog>
  </form>
</template>

<script setup>
import { ref } from 'vue';
import batchResource from '@/apps/payments/payments/batch/resources/batch';
import { BgText, BgButton } from '@transfeeradev/bridge';

import { useToaster } from '@/commons/composables/useToaster';
import { getMessage } from '@/commons/services/error';
import checkpointResource from '@/commons/resources/checkpoint';
import checkpoint from '@/commons/constants/checkpoint';

const props = defineProps({
  name: {
    type: String
  },
  id: {
    type: [String, Number]
  },
  closeModal: {
    type: Function
  }
});

const emit = defineEmits(['save']);

const toaster = useToaster();
const modal = ref(null);

const open = () => {
  modal.value.open();
};

const close = () => {
  modal.value.close();
};

const onSave = async () => {
  try {
    await batchResource.cancelBatch(props.id);
    checkpointResource.create(checkpoint.CONFIRM_BATCH_CANCEL);
    close();
    emit('save');
    toaster.success('Lote cancelado com sucesso!');
    props.closeModal();
  } catch (error) {
    toaster.error(getMessage(error));
  }
};

defineExpose({
  open,
  close
});
</script>

<style scoped>
.batch-cancel__confirm-button {
  margin-left: var(--bg-spacing-sm);
}
</style>
