import hexCharsFormatter from '@/apps/payments/payments/spreadsheetImport/services/formatters/hexChars';
import { TRANSFER_HEADERS, TRANSFER_PIX_WITH_KEY_HEADERS } from '@transfeeradev/sheets-import';

const format = row => {
  for (let col in row) {
    let colContent = row[col];

    if (colContent instanceof Date) {
      const yearMonthDayFormattedString = colContent.toISOString().split('T')[0];
      row[col] = yearMonthDayFormattedString;
      continue;
    }

    colContent = _convertToString(colContent);

    if (colContent === '') {
      row[col] = colContent;
      continue;
    }

    colContent = hexCharsFormatter.formatString(colContent);
    colContent = colContent.trim();
    colContent = colContent.replace(/^\s+/, '');
    colContent = colContent.replace(/\s+$/, '');
    const isPixDescCol = [
      TRANSFER_HEADERS.pixDescription.description,
      TRANSFER_PIX_WITH_KEY_HEADERS.pixDescription.description
    ].includes(col);
    if (!isPixDescCol) {
      colContent = colContent.replace(/<[^>]*>/g, '');
    }
    row[col] = colContent;
  }
  return row;
};

const _convertToString = colContent => {
  if (colContent === null || typeof colContent === 'undefined') {
    return '';
  } else {
    return String(colContent);
  }
};

export default {
  format
};
