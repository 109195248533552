import config from '@/config';
import fetch from '@/fetch';

let cache;

const BASE_URL = config.url.api.transfeera + '/cpfcnpj';
export default {
  getBlacklist: () => {
    if (cache) {
      return new Promise(resolve => resolve(cache));
    }
    return fetch(`${BASE_URL}/blacklist`).then(blacklist => {
      cache = blacklist;
      return cache;
    });
  }
};
