<template>
  <tr-form :on-valid-submit="onValidSubmit">
    <batch-bank-account-selection
      :batch="batch"
      :on-select-bank-account="selectBankAccount"
      :selected-bank-account="selectedBankAccount"
    />
    <tr-footer-buttons-container>
      <tr-show-for-roles :roles="appRoles.ADMIN">
        <tr-button
          class="pull-right margin-right"
          variant="raised"
          label="Pronto para pagar"
          :disabled="isLoading"
          color="primary"
          type="submit"
        />
      </tr-show-for-roles>
      <tr-show-for-roles :roles="appRoles.OPERATOR">
        <tr-button
          class="pull-right margin-right"
          variant="raised"
          label="Enviar para aprovação"
          :disabled="isLoading"
          color="primary"
          type="submit"
        />
      </tr-show-for-roles>
      <tr-button
        v-if="onCancel"
        classes="pull-left"
        label="Cancelar"
        variant="raised"
        :disabled="isLoading"
        color="secondary"
        :on-button-click="onCancel"
      />
    </tr-footer-buttons-container>
    <batch-close-confirmation-dialog
      ref="batchCloseConfirmationDialog"
      :on-cancel="onCancelCloseBatch"
      :on-confirm="closeBatch"
      :batch-value="batch.value"
    />
  </tr-form>
</template>

<script>
import BatchBankAccountSelection from '@/apps/payments/payments/batch/components/presentational/BatchBankAccountSelection';
import verifyRoleAccess from '@/commons/services/verifyRoleAccess';
import batchResource from '@/apps/payments/payments/batch/resources/batch';
import BatchCloseConfirmationDialog from '@/apps/payments/payments/batch/components/presentational/BatchCloseConfirmationDialog';
import transfersLotModelFactory from '@/apps/payments/payments/transfer/services/transfersLotModelFactory';
import { roles } from '@transfeeradev/api-enums';
import checkpointResource from '@/commons/resources/checkpoint';
import checkpoint from '@/commons/constants/checkpoint';
import { getMessage } from '@/commons/services/error';

export default {
  name: 'batch-bank-account-selection-container',
  components: {
    BatchBankAccountSelection,
    BatchCloseConfirmationDialog
  },
  props: {
    batch: {
      required: false,
      type: Object,
      default: () => ({})
    },
    onBatchSentToApproval: {
      required: true,
      type: Function
    },
    onCancel: {
      required: false,
      type: Function
    },
    onBatchClose: {
      required: true,
      type: Function
    }
  },
  data: () => ({
    selectedBankAccount: {
      Bank: {}
    },
    appRoles: roles.appRoles,
    isLoading: false
  }),
  methods: {
    selectBankAccount(bankAccount) {
      if (!bankAccount.isPix) {
        this.batch.received_bank_account = bankAccount;
      } else {
        this.batch.received_bank_account = null;
      }
      this.selectedBankAccount = bankAccount;
    },
    onValidSubmit() {
      const batch = transfersLotModelFactory.prepareToSave(this.batch, true);
      this.isLoading = true;
      return batchResource.update(this.batch.id, batch).then(
        () => {
          const shouldGoToSendToApproval = verifyRoleAccess.isLoggedUserAllowedToAccess(roles.appRoles.OPERATOR);
          if (shouldGoToSendToApproval) {
            return this.sendToApproval();
          } else {
            return this.openBatchCloseConfirmationDialog();
          }
        },
        error => {
          this.isLoading = false;
          if (error && error.code) {
            return this.$toaster.error(getMessage(error));
          }

          this.$toaster.error('Ocorreu um erro ao salvar o lote.');
          throw error;
        }
      );
    },
    sendToApproval() {
      checkpointResource.create(checkpoint.SEND_BATCH_TO_APPROVAL);
      return batchResource.sendToApproval(this.batch.id).then(
        () => {
          this.isLoading = false;
          this.$toaster.success('Lote enviado para aprovação.');
          this.onBatchSentToApproval();
        },
        error => {
          this.isLoading = false;

          if (error && error.code) {
            return this.$toaster.error(getMessage(error));
          }

          this.$toaster.error(`Ocorreu um erro ao enviar lote para aprovação. ${getMessage(error)}`);
          throw error;
        }
      );
    },
    closeBatch() {
      this.closeBatchCloseConfirmationDialog();
      checkpointResource.create(checkpoint.CONFIRM_BATCH_CLOSE_AFTER_SEND_BALANCE);
      return batchResource.close(this.batch.id).then(
        response => {
          window.dispatchEvent(new Event('topbar.refresh'));
          this.isLoading = false;
          this.onBatchClose(response.is_batch_paid);
        },
        error => {
          this.isLoading = false;

          if (error && error.code) {
            return this.$toaster.error(getMessage(error));
          }

          this.$toaster.error('Ocorreu uma falha ao fechar o lote.');
          throw error;
        }
      );
    },
    openBatchCloseConfirmationDialog() {
      this.$refs.batchCloseConfirmationDialog.open();
    },
    closeBatchCloseConfirmationDialog() {
      this.$refs.batchCloseConfirmationDialog.close();
    },
    onCancelCloseBatch() {
      this.isLoading = false;
    }
  }
};
</script>
