<template>
  <company-form :company="company" />
</template>

<script>
import CompanyForm from '@/commons/components/presentational/company/CompanyForm';
import companyResource from '@/commons/resources/company';
import { getMessage } from '@/commons/services/error';

export default {
  name: 'company-form-container',
  components: {
    CompanyForm
  },
  data: () => ({
    company: {}
  }),
  created() {
    this.refresh();
  },
  methods: {
    refresh() {
      companyResource.get().then(
        company => {
          this.company = company;
        },
        error => this.$toaster.error(getMessage(error))
      );
    },
    onSubmit() {
      return companyResource
        .update({
          phone: this.company.phone.replace(/\D/g, '')
        })
        .then(() => {
          this.refresh();
        });
    }
  }
};
</script>
