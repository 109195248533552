import PaymentsLayout from '@/apps/payments/Layout';
import paymentsRoutes from '@/apps/payments/payments/routes';

export default {
  path: '/',
  component: PaymentsLayout,
  name: 'Payments',
  redirect: {
    name: 'BatchesPage'
  },
  children: [paymentsRoutes]
};
