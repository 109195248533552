<template>
  <tr-form
    v-if="!isLoadingBatch"
    :on-valid-submit="onValidSubmit"
  >
    <tr-page-section title="Dê um nome a este lote">
      <div class="row">
        <tr-text-field
          v-model="batch.name"
          name="name"
          placeholder="Nome do lote"
          :required="true"
          :maxlength="200"
        />
      </div>
    </tr-page-section>
    <tr-page-section title="Deseja adicionar mais pagamentos?">
      <div class="row">
        <transfer-filter-by-status
          v-if="isTransferBatch"
          :batch-status="batchStatus.DRAFT"
          :on-filter-by-change="onFilterByChange"
          :current-status="filterByStatus"
        />
        <payment-search
          v-if="isTransferBatch"
          :search-term="searchTerm"
          :on-change="onSearchTermChange"
          :on-search="onSearchByTerm"
        />
        <div
          v-if="batch.status === batchStatus.DRAFT && _isTransferBatch()"
          class="col-lg-3 pull-right"
        >
          <remove-failed-payments-button
            :failed-payments-count="failedPaymentsSummary.count"
            :disabled="_isPageLoading() || failedPaymentsSummary.count <= 0"
            :on-button-click="showRemoveFailedPaymentsConfirmationDialog"
            :classes="'pull-right'"
            style="margin-top: -20px"
          />
        </div>
      </div>
      <batch-form-alerts-container
        ref="batchFormAlertsContainer"
        classes="vertical-offset3"
        :batch-id="batch.id"
        :batch-type="type || batch.type"
      />
      <billet-cards-container
        v-if="_isBilletBatch()"
        ref="billetCards"
        :add-new="addNew"
        :on-edit="showFormFullscreenModal"
        :on-get-billets="onGet"
        :hide-status-pill="true"
        :batch="batch"
        :batch-id="batch.id"
      />
      <transfer-cards-container
        v-if="_isTransferBatch()"
        ref="transferCards"
        :status-filter="filterByStatus"
        :search-term-filter="searchTerm"
        :on-start-loading="onCardsStartLoading"
        :on-cancel-wizard="onCancelWizard"
        :add-new="true"
        :on-edit="onPaymentSave"
        :on-get-transfers="onGet"
        :on-get-failed-transfers-summary="onGetFailedTransfersSummary"
        :hide-status-pill="true"
        :batch="batch"
        :was-just-imported="wasJustImported"
      />
    </tr-page-section>
    <tr-page-section title="Resumo dos pagamentos:">
      <div class="row">
        <tr-read-only-text-field
          label="total:"
          :no-margin="true"
          :value="$format.currency(batch.value)"
        />
      </div>
    </tr-page-section>
    <tr-footer-buttons-container>
      <div>
        <tr-button
          v-if="shouldGoToNextStep"
          class="pull-right margin-right"
          variant="raised"
          :disabled="_isPageLoading() || !canCloseBatch"
          label="Próximo"
          color="primary"
          type="submit"
        />
      </div>
      <tr-show-for-roles :roles="appRoles.ADMIN">
        <div>
          <tr-button
            v-if="hasEnoughBalance"
            class="pull-right margin-right"
            variant="raised"
            :disabled="_isPageLoading() || !canCloseBatch"
            label="Fechar lote"
            color="primary"
            type="submit"
          />
        </div>
      </tr-show-for-roles>
      <tr-hide-for-roles :roles="appRoles.ANALYST">
        <div>
          <tr-button
            class="pull-right margin-right"
            variant="raised"
            :disabled="_isPageLoading()"
            label="Salvar rascunho"
            :on-button-click="save"
          />
        </div>
      </tr-hide-for-roles>
      <tr-hide-for-roles :roles="appRoles.ANALYST">
        <tr-button
          v-if="batch.id"
          class="pull-right margin-right"
          variant="raised"
          :disabled="_isPageLoading()"
          size="medium"
          svg-icon-name="ic-delete"
          svg-icon-color="white"
          color="danger"
          :on-button-click="openRemovalConfirmationDialog"
        />
      </tr-hide-for-roles>
      <tr-button
        classes="pull-left"
        :label="wasJustImported ? 'Cancelar importação' : 'Voltar'"
        :disabled="isLoading"
        variant="raised"
        color="secondary"
        :on-button-click="cancel"
      />
    </tr-footer-buttons-container>
    <billet-form-fullscreen-modal
      ref="billetFormFullscreenModal"
      :on-edit="onPaymentSave"
      :on-save="onPaymentSave"
      :on-cancel="onCancelWizard"
      :on-remove="onPaymentSave"
      :billet-id="clickedId"
      :batch-id="batch.id"
    />
    <batch-close-confirmation-dialog
      ref="batchCloseConfirmationDialog"
      :on-cancel="onCancelCloseBatch"
      :on-confirm="close"
      :batch-value="batch.value"
    />
    <batch-removal-confirmation-dialog
      ref="batchRemovalConfirmationDialog"
      :on-confirm="remove"
    />
    <batch-failed-payments-removal-confirmation-dialog
      ref="batchFailedPaymentsRemovalConfirmationDialog"
      :on-confirm="removeFailedPayments"
    />
  </tr-form>
  <div
    v-show="isLoadingBatch"
    class="center-h-and-v vertical-offset5"
  >
    <tr-loading />
  </div>
</template>

<script>
import BilletCardsContainer from '@/apps/payments/payments/billet/components/container/BilletCardsContainer';
import BilletFormFullscreenModal from '@/apps/payments/payments/billet/pages/BilletFormFullscreenModal';
import TransferCardsContainer from '@/apps/payments/payments/transfer/components/container/TransferCardsContainer';
import TransferFilterByStatus from '@/apps/payments/payments/transfer/components/presentational/TransferFilterByStatus';
import BatchCloseConfirmationDialog from '@/apps/payments/payments/batch/components/presentational/BatchCloseConfirmationDialog';
import BatchFormAlertsContainer from '@/apps/payments/payments/batch/components/container/BatchFormAlertsContainer';
import BatchRemovalConfirmationDialog from '@/apps/payments/payments/batch/components/presentational/BatchRemovalConfirmationDialog';
import BatchFailedPaymentsRemovalConfirmationDialog from '@/apps/payments/payments/batch/components/presentational/BatchFailedPaymentsRemovalConfirmationDialog';
import RemoveFailedPaymentsButton from '@/apps/payments/payments/batch/components/presentational/RemoveFailedPaymentsButton';
import batchResource from '@/apps/payments/payments/batch/resources/batch';
import batchModel from '@/apps/payments/payments/batch/models/batch';
import transfersLotModelFactory from '@/apps/payments/payments/transfer/services/transfersLotModelFactory';
import verifyRoleAccess from '@/commons/services/verifyRoleAccess';
import hasEnoughBalance from '@/apps/payments/payments/statement/services/hasEnoughBalance';
import PaymentSearch from '@/apps/payments/payments/batch/components/presentational/PaymentSearch';
import { batchType, roles, batchStatus } from '@transfeeradev/api-enums';
import checkpointResource from '@/apps/payments/resources/checkpoint';
import checkpoint from '@/commons/constants/checkpoint';
import merge from 'deepmerge';
import { getMessage } from '@/commons/services/error';

export default {
  name: 'batch-form-container',
  components: {
    BilletCardsContainer,
    TransferCardsContainer,
    TransferFilterByStatus,
    BilletFormFullscreenModal,
    BatchCloseConfirmationDialog,
    BatchFormAlertsContainer,
    BatchRemovalConfirmationDialog,
    BatchFailedPaymentsRemovalConfirmationDialog,
    PaymentSearch,
    RemoveFailedPaymentsButton
  },
  props: {
    initialBatch: {
      required: false,
      type: Object,
      default: () => batchModel.get()
    },
    batchId: {
      required: false,
      type: [Number, String]
    },
    type: {
      required: false,
      type: String
    },
    wasJustImported: {
      required: false,
      type: Boolean,
      default: false
    },
    onBatchSave: {
      required: false,
      type: Function
    },
    onGoToBankAccountSelection: {
      required: false,
      type: Function
    },
    onBatchRemove: {
      required: false,
      type: Function
    },
    onBatchClose: {
      required: false,
      type: Function
    },
    onBatchSentToApproval: {
      required: false,
      type: Function
    },
    closeModal: {
      required: true,
      type: Function
    },
    onCancelWizard: {
      required: true,
      type: Function
    }
  },
  data: () => ({
    isLoading: false,
    isLoadingBatch: false,
    isLoadingCards: false,
    clickedId: null,
    hasEnoughBalance: false,
    batch: batchModel.get(),
    isOperator: false,
    appRoles: roles.appRoles,
    canCloseBatch: true,
    filterByStatus: undefined,
    searchTerm: '',
    batchType,
    payments: [],
    batchStatus,
    failedPaymentsSummary: {
      count: 0,
      value: 0,
      ids: []
    }
  }),
  computed: {
    shouldGoToNextStep() {
      return Boolean(this.isOperator || !this.hasEnoughBalance);
    },
    isTransferBatch() {
      return !this._isBilletBatch();
    }
  },
  watch: {
    batchId() {
      this.refresh();
    }
  },
  created() {
    this.isOperator = verifyRoleAccess.isLoggedUserAllowedToAccess(roles.appRoles.OPERATOR);
  },
  mounted() {
    this.refresh();
  },
  methods: {
    refresh() {
      if (this._hasNoBatchProp()) {
        if (this.batchId) {
          this.refreshBatch();
        } else {
          this.showFormFullscreenModal();
        }
      } else {
        this.batch = merge({}, this.initialBatch);
        this.getHasEnoughBalance();
      }
    },
    getHasEnoughBalance() {
      hasEnoughBalance.hasIt(this.batch.value).then(result => {
        this.hasEnoughBalance = result.isValid;
      });
    },
    onSearchByTerm() {
      this.$refs.transferCards.refresh();
    },
    onSearchTermChange(searchTerm) {
      this.searchTerm = searchTerm;
    },
    onFilterByChange(selectedStatus) {
      this.filterByStatus = selectedStatus;
    },
    refreshBatch(batchId) {
      const batchName = this.batch.name;

      this.isLoadingBatch = true;
      return batchResource.get(batchId || this.batchId || this.batch.id).then(
        batch => {
          this.batch = batch;
          this.isLoadingBatch = false;
          this.batch.name = batchName || batch.name;
          this.getHasEnoughBalance();
        },
        () => {
          this.isLoadingBatch = false;
        }
      );
    },
    onCardsStartLoading() {
      this.isLoadingCards = true;
    },
    onCancelCloseBatch() {
      this.isLoading = false;
    },
    addNew() {
      this.showFormFullscreenModal();
    },
    showFormFullscreenModal(clickedId) {
      this.clickedId = clickedId;
      if (this._isBilletBatch()) {
        this.$refs.billetFormFullscreenModal.open();
      }
    },
    showBatchCloseConfirmationDialog() {
      this.$refs.batchCloseConfirmationDialog.open();
    },
    hideBatchCloseConfirmationDialog() {
      this.$refs.batchCloseConfirmationDialog.close();
    },
    onGet(payments) {
      this.isLoadingCards = false;
      this.payments = payments;

      if (this._isBilletBatch()) {
        this.canCloseBatch = this.$refs.billetCards && this.$refs.billetCards.areCardsValid();
      } else {
        this.canCloseBatch = this.$refs.transferCards && this.$refs.transferCards.areCardsValid();
      }
    },
    onPaymentSave(batchId) {
      this.refreshBatch(batchId).then(() => {
        this.refreshAlerts();
      });
    },
    onPaymentRemove() {
      this.refreshBatch().then(() => {
        this.refreshAlerts();
      });
    },
    refreshAlerts() {
      if (this.$refs.batchFormAlertsContainer) {
        this.$refs.batchFormAlertsContainer.refresh();
      }
    },
    cancel() {
      if (this.wasJustImported) {
        this.openRemovalConfirmationDialog();
      } else {
        this.closeModal();
      }
    },
    onValidSubmit() {
      if (this.shouldGoToNextStep) {
        this.onGoToBankAccountSelection(this.batch);
      } else {
        this.showBatchCloseConfirmationDialog();
      }
    },
    save(hideFeedback) {
      const batch = transfersLotModelFactory.prepareToSave(this.batch, true);
      this.isLoading = true;
      const promise = batchResource.update(this.batch.id, batch);
      if (hideFeedback) {
        return promise;
      }
      return promise.then(this.onSave, this.onSaveError);
    },
    close() {
      this.hideBatchCloseConfirmationDialog();
      checkpointResource.create(checkpoint.CONFIRM_BATCH_CLOSE);
      this.save(true).then(() => {
        hasEnoughBalance.hasIt(this.batch.value).then(result => {
          if (result.isValid) {
            batchResource.close(this.batch.id).then(this.onClose, this.onCloseError);
          } else {
            this.onGoToBankAccountSelection();
          }
        });
      }, this.onCloseError);
    },
    openRemovalConfirmationDialog() {
      this.loading = true;
      this.$refs.batchRemovalConfirmationDialog.open();
    },
    async remove() {
      try {
        this.isLoading = true;
        const hasDba = await batchResource.hasDestinationBankAccountsToRemove(this.batch.id);
        const removeWithDbas = !!hasDba.hasDestinationBankAccountsToRemove;
        const response = await batchResource.remove(this.batch.id, removeWithDbas);

        this.onRemove(response);
      } catch (e) {
        this.onRemoveError(e);
      }
    },
    onSave() {
      this.isLoading = false;
      this.$toaster.success('Seu rascunho foi salvo!');
      if (this.onBatchSave) {
        this.onBatchSave();
      }
    },
    onSaveError(error) {
      this.isLoading = false;
      if (error && error.code) {
        return this.$toaster.error(getMessage(error));
      }

      this.$toaster.error('Ocorreu uma falha ao salvar o lote.');
    },
    onClose() {
      this.isLoading = false;
      window.dispatchEvent(new Event('topbar.refresh'));
      if (this.onBatchClose) {
        this.onBatchClose();
      }
    },
    onCloseError(error) {
      this.isLoading = false;

      if (error && error.code) {
        return this.$toaster.error(getMessage(error));
      }

      this.$toaster.error('Ocorreu uma falha ao salvar o lote.');
      throw error;
    },
    onRemove() {
      this.isLoading = false;
      this.$toaster.success('Lote excluído com sucesso!');
      if (this.onBatchRemove) {
        this.onBatchRemove();
      }
    },
    onRemoveError(error) {
      this.isLoading = false;
      if (error && error.code) {
        return this.$toaster.error(getMessage(error));
      }

      this.$toaster.error('Ocorreu uma falha ao excluir o lote.');
    },
    onSentToApprove() {
      this.isLoading = false;
      this.$toaster.success('Lote enviado para aprovação');
      if (this.onBatchSentToApproval) {
        this.onBatchSentToApproval();
      }
    },
    onSendToApproveError(error) {
      this.isLoading = false;
      if (error && error.code) {
        return this.$toaster.error(getMessage(error));
      }

      this.$toaster.error('Ocorreu um erro ao enviar lote para aprovação.');
    },
    onGetFailedTransfersSummary(summary) {
      this.failedPaymentsSummary = summary;
    },
    showRemoveFailedPaymentsConfirmationDialog() {
      this.$refs.batchFailedPaymentsRemovalConfirmationDialog.open(this.failedPaymentsSummary);
    },
    removeFailedPayments() {
      if (this._isTransferBatch()) {
        this.isLoading = true;
        this.$refs.batchFailedPaymentsRemovalConfirmationDialog.setIsLoading(this.isLoading);

        checkpointResource.create(checkpoint.CLICKED_ON_REMOVE_FAILED_TRANSFERS_BUTTON);

        return batchResource.removeManyTransfers(this.batchId || this.batch.id, this.failedPaymentsSummary.ids).then(
          () => {
            this.isLoading = false;
            this.$refs.batchFailedPaymentsRemovalConfirmationDialog.setIsLoading(this.isLoading);
            this.$refs.batchFailedPaymentsRemovalConfirmationDialog.close();
            this.$toaster.success('Pagamentos com falha excluídos com sucesso!');

            return this.refreshBatch();
          },
          () => {
            this.isLoading = false;
            this.$refs.batchFailedPaymentsRemovalConfirmationDialog.setIsLoading(this.isLoading);
            this.$toaster.error('Ocorreu um erro ao remover as transferências.');
          }
        );
      }
    },
    _isBilletBatch() {
      return this.type === batchType.BILLET || this.batch.type === batchType.BILLET;
    },
    _isTransferBatch() {
      return this.type === batchType.TRANSFER || this.batch.type === batchType.TRANSFER;
    },
    _hasNoBatchProp() {
      return Boolean(!this.initialBatch || !this.initialBatch.id);
    },
    _isPageLoading() {
      return this.isLoading || this.isLoadingBatch || this.isLoadingCards;
    }
  }
};
</script>
