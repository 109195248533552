<template>
  <page title="Credenciais de APIs">
    <credentials-container />
  </page>
</template>

<script>
import Page from '@/commons/components/presentational/Page.vue';
import CredentialsContainer from '../components/container/CredentialsContainer.vue';

export default {
  components: {
    CredentialsContainer,
    Page
  },
  emits: ['loading'],
  created() {
    this.$emit('loading', false);
  }
};
</script>
