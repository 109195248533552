<template>
  <div>
    <tr-loading v-if="isLoading" />
    <company-bank-account-form
      v-else
      :bank-account="bankAccount"
      :show-form-header="showFormHeader"
    />
  </div>
</template>

<script>
import { accountSource } from '@transfeeradev/api-enums';
import CompanyBankAccountForm from '@/apps/payments/payments/settings/bankAccounts/components/presentational/CompanyBankAccountForm';
import companyBankAccountResource from '@/apps/payments/payments/settings/bankAccounts/resources/bankAccount';
import { getMessage } from '@/commons/services/error';

export default {
  name: 'company-bank-account-form-container',
  components: {
    CompanyBankAccountForm
  },
  props: ['showFormHeader'],
  data: () => ({
    isLoading: true,
    bankAccount: {}
  }),
  mounted() {
    this.refresh();
  },
  methods: {
    async refresh() {
      this.isLoading = true;
      try {
        this.bankAccount = (await companyBankAccountResource.get(accountSource.CUSTOMER)) || {};
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.$toaster.error(getMessage(error));
      }
    },
    async onSubmit() {
      try {
        await companyBankAccountResource.update(this.bankAccount);
        if (this.onSave) {
          this.onSave();
        }
      } catch (error) {
        this.$toaster.error(getMessage(error));
        throw error;
      }
    }
  }
};
</script>
