<template>
  <div>
    <div class="row">
      <tr-read-only-text-field
        v-if="currentBilletType === billetType.SIMPLE"
        class="col-lg-5"
        label="Banco"
      >
        <bank-info :bank="billet.bank" />
      </tr-read-only-text-field>
      <billet-value-field-container
        v-if="shouldLetValueBeEdited"
        :value="billet.value"
        :on-change="onBilletValueChange"
        :barcode="billet.barcode"
      />
      <tr-read-only-text-field
        v-if="!shouldLetValueBeEdited"
        class="col-lg-3"
        label="Valor a pagar"
        :value="$format.currency(billet.value)"
      />
    </div>
    <div class="row">
      <tr-read-only-text-field
        v-if="currentBilletType === billetType.SIMPLE"
        class="col-lg-5"
        :no-margin="true"
        label="Vencimento"
        :value="dueDate"
      />
      <billet-payment-date-field-container
        :billet="billet"
        :no-margin="true"
        :on-change="onPaymentDateChange"
      />
    </div>
  </div>
</template>

<script>
import BilletPaymentDateFieldContainer from '@/apps/payments/payments/billet/components/container/BilletPaymentDateFieldContainer';
import BilletValueFieldContainer from '@/apps/payments/payments/billet/components/container/BilletValueFieldContainer';
import formatDateFilter from '@/commons/formatters/formatDate';
import masksContants from '@/commons/constants/masks';
import BankInfo from '@/apps/payments/payments/bank/components/presentational/BankInfo';
import { billetType } from '@transfeeradev/api-enums';

export default {
  name: 'billet-data',
  components: {
    BilletPaymentDateFieldContainer,
    BilletValueFieldContainer,
    BankInfo
  },
  props: {
    billet: {
      required: false,
      type: Object,
      default: () => ({
        bank: {}
      })
    },
    canEdit: {
      required: true,
      type: Boolean,
      default: false
    },
    shouldLetValueBeEdited: {
      required: true,
      type: Boolean,
      default: false
    },
    onPaymentDateChange: {
      required: true,
      type: Function
    },
    onBilletValueChange: {
      required: true,
      type: Function
    },
    currentBilletType: {
      required: false,
      type: String
    }
  },
  data: () => ({
    CURRENCY: masksContants.CURRENCY,
    billetType
  }),
  computed: {
    dueDate() {
      return (this.billet && this.billet.due_date && formatDateFilter(this.billet.due_date)) || '00/00/0000';
    }
  }
};
</script>
