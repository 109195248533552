<template>
  <div>
    <billet-details
      :show-batch-fullscreen-modal="showBatchFullscreenModal"
      :billet="billet"
      :fail-reason="failReason"
    />
    <tr-footer-buttons-container>
      <tr-button
        classes="pull-left"
        label="Voltar"
        variant="raised"
        color="secondary"
        :on-button-click="hide"
      />
      <tr-hide-for-roles :roles="appRoles.ANALYST">
        <tr-button
          v-if="shouldShowCancelBtn"
          classes="pull-right"
          label="Cancelar agendamento"
          variant="raised"
          color="primary"
          :on-button-click="openConfirmationDialog"
        />
      </tr-hide-for-roles>
    </tr-footer-buttons-container>
    <billet-cancel-confirmation-dialog
      ref="billetCancelConfirmationDialog"
      :billet="billet"
      :on-confirm="cancelScheduledBillet"
    />
  </div>
</template>

<script>
import batchResource from '@/apps/payments/payments/batch/resources/batch';
import billetResource from '@/apps/payments/payments/billet/resources/billet';
import billetModel from '@/apps/payments/payments/billet/models/billet';
import BilletDetails from '@/apps/payments/payments/billet/components/presentational/BilletDetails';
import getBilletFailReason from '@/apps/payments/payments/billet/services/getBilletFailReason';
import BilletCancelConfirmationDialog from '@/apps/payments/payments/billet/components/presentational/BilletCancelConfirmationDialog';
import { roles, billetStatus } from '@transfeeradev/api-enums';
import moment from 'moment';
import { getMessage } from '@/commons/services/error';

export default {
  name: 'billet-details-container',
  components: {
    BilletDetails,
    BilletCancelConfirmationDialog
  },
  props: {
    billetId: {
      required: false,
      type: [Number, String]
    },
    showBatchFullscreenModal: {
      required: false,
      type: Function
    },
    hide: {
      required: false,
      type: Function
    },
    onCancel: {
      required: false,
      type: Function
    }
  },
  data: () => ({
    billet: billetModel.get(),
    appRoles: roles.appRoles
  }),
  computed: {
    failReason() {
      return getBilletFailReason.get(this.billet);
    },
    shouldShowCancelBtn() {
      if (this.billet && this.billet.status === billetStatus.SCHEDULED) {
        const cancelLimitDay = moment(this.billet.payment_date).subtract(1, 'day').endOf('day');
        return Boolean(moment().isBefore(cancelLimitDay));
      }
      return false;
    }
  },
  created() {
    if (this.billetId) {
      billetResource.getDetails(this.billetId).then(billet => {
        this.billet = billet;
      });
    }
  },
  methods: {
    openConfirmationDialog() {
      this.$refs.billetCancelConfirmationDialog.open();
    },
    cancelScheduledBillet() {
      this.$refs.billetCancelConfirmationDialog.close();
      return batchResource.removeBillet(this.billet.batch_id, this.billet.id).then(
        () => {
          if (this.onCancel) {
            this.onCancel();
          }
          this.$toaster.success('Agendamento cancelado com sucesso!');
          if (this.hide) {
            this.hide();
          }
        },
        error => {
          if (error && error.code) {
            return this.$toaster.error(getMessage(error));
          }

          this.$toaster.error('Ocorreu um erro ao cancelar o boleto. Tente novamente!');
        }
      );
    }
  }
};
</script>
