import verifyRoleAccess from '@/commons/services/verifyRoleAccess';

export const hasAccess = async routeConfig => {
  let hasAccessToRoute = true;

  if (routeConfig) {
    const isAllowed = await _isLoggedUserAllowedToAccess(routeConfig);
    const isForbidden = _isLoggedUserForbiddenToAccess(routeConfig);
    hasAccessToRoute = isAllowed && !isForbidden;
  }

  return hasAccessToRoute;
};

const _isLoggedUserAllowedToAccess = async routeConfig => {
  if (routeConfig.allowForRoles) {
    const allowedRoles = routeConfig.allowForRoles;
    return verifyRoleAccess.isLoggedUserAllowedToAccess(allowedRoles);
  }

  if (routeConfig.isAllowed && typeof routeConfig.isAllowed === 'function') {
    return await routeConfig.isAllowed();
  }

  return true;
};

const _isLoggedUserForbiddenToAccess = routeConfig => {
  if (routeConfig.forbidForRoles) {
    const forbiddenRoles = routeConfig.forbidForRoles;
    return verifyRoleAccess.isLoggedUserForbiddenToAccess(forbiddenRoles);
  }
  return false;
};

export default {
  hasAccess
};
