<template>
  <tr-page>
    <tr-page-header
      title="Saldo"
      :hide-button-for-roles="hideActionForRoles"
      :on-add-click="openCreateEntryDialog"
    >
      <template #center-filter>
        <tr-month-picker-button :on-filter="onSearchByDatePeriod" />
      </template>
      <template #right-filter>
        <div />
      </template>
    </tr-page-header>
    <tr-page-section :no-margin="true">
      <balance-info-container @refresh="refresh" />
    </tr-page-section>
    <tr-page-section v-if="!isLoading">
      <template #title>
        <div class="space-between">
          <tr-typography
            class="no-margin"
            strong="true"
            variant="h4"
          >
            Extrato
          </tr-typography>
          <tr-button
            v-if="hasItems"
            ref="reports-dropdown"
            variant="raised"
            label="Baixar extratos"
            color="primary"
            size="medium"
            dropdown="true"
          >
            <tr-dropdown-item
              key="xlsx-v2"
              :on-item-click="getXlsxEntriesReportV2"
            >
              Extrato XLS
            </tr-dropdown-item>

            <tr-dropdown-item
              key="csv"
              :on-item-click="getCSVEntriesReport"
            >
              Extrato CSV
            </tr-dropdown-item>

            <tr-dropdown-item
              key="ofx"
              :on-item-click="getOFXEntriesReport"
            >
              Extrato OFX
            </tr-dropdown-item>

            <tr-dropdown-item
              key="pdf"
              :on-item-click="getPdfBatchReport"
            >
              Extrato PDF
            </tr-dropdown-item>

            <tr-dropdown-item
              v-if="hasUsedOldXLSReport"
              key="xlsx-v1"
              :on-item-click="getXlsxEntriesReport"
            >
              Relatório XLS
            </tr-dropdown-item>
          </tr-button>
        </div>
      </template>
      <tr-empty-search-result
        v-if="!hasItems"
        text="Nenhuma movimentação realizada neste período"
      />
      <statement-table
        v-if="hasItems"
        :on-change="search"
        :statement="statementData"
      />
      <tr-numeric-pagination
        :current-page="currentPage"
        :page-size="pagination.itemsPerPage"
        :total="pagination.totalItems"
        :on-current-page-change="onCurrentPageChange"
      />
    </tr-page-section>
    <tr-loading
      v-if="isLoading"
      classes="center-h-and-v width-100 vertical-offset5"
    />
    <entry-wizard-dialog
      ref="entryWizardDialog"
      :on-change="refresh"
    />
  </tr-page>
</template>

<script>
import StatementTable from '@/apps/payments/payments/statement/components/presentational/StatementTable.vue';
import EntryWizardDialog from '@/apps/payments/payments/statement/components/presentational/EntryWizardDialog.vue';
import statementResource from '@/apps/payments/payments/statement/resources/statement';
import checkpointResource from '@/apps/payments/resources/checkpoint';
import checkpoint from '@/commons/constants/checkpoint';
import moment from '@transfeeradev/moment';
import { roles } from '@transfeeradev/api-enums';
import { getMessage } from '@/commons/services/error';
import BalanceInfoContainer from '@/apps/payments/payments/statement/components/container/BalanceInfoContainer.vue';

export default {
  name: 'statement-page',
  components: {
    EntryWizardDialog,
    StatementTable,
    BalanceInfoContainer
  },
  data: () => ({
    isLoading: true,
    initialDate: moment().startOf('month'),
    endDate: moment().endOf('month'),
    statement: {},
    hideActionForRoles: roles.appRoles.ANALYST,
    currentPage: 0,
    pagination: {},
    hasUsedOldXLSReport: false
  }),
  computed: {
    hasItems() {
      return Boolean(this.statement && this.statement.entries && this.statement.entries.length);
    },
    statementData() {
      const statementData = {
        entries: []
      };

      if (this.currentPage === 0) {
        statementData.afterBalance = this.statement.afterBalance;
      }

      if ((this.currentPage + 1) * this.pagination.itemsPerPage >= this.pagination.totalItems) {
        statementData.prevBalance = this.statement.prevBalance;
      }

      statementData.entries = this.statement.entries;

      return statementData;
    }
  },
  beforeCreate() {
    checkpointResource.exists(checkpoint.HAS_FILLED_FIRST_STEPS).then(
      response => {
        if (!response.exists) {
          this.$router.push({ name: 'FirstStepsPage', state: { redirectedFrom: 'StatementPage' } });
        }
      },
      error => this.$toaster.error(getMessage(error))
    );
  },
  created() {
    checkpointResource.existsInCompany(checkpoint.CREATED_XLSX_STATEMENT_ENTRIES_REPORT).then(
      response => {
        this.hasUsedOldXLSReport = Boolean(response?.exists);
      },
      error => this.$toaster.error(getMessage(error))
    );

    this.refresh();
  },
  methods: {
    refresh() {
      window.dispatchEvent(new Event('topbar.refresh'));
      this.search();
    },
    openCreateEntryDialog() {
      this.$refs.entryWizardDialog.open();
    },
    async getXlsxEntriesReport() {
      try {
        checkpointResource.create(checkpoint.CREATED_XLSX_STATEMENT_ENTRIES_REPORT);
        await statementResource.getXlsxEntriesReport(this.initialDate, this.endDate);
        this.$toaster.success({
          message: 'Extrato está sendo gerado, quando concluído será enviado para o seu email.',
          duration: 10000
        });
      } catch (error) {
        this.$toaster.error(getMessage(error));
      }
    },
    async getXlsxEntriesReportV2() {
      try {
        checkpointResource.create(checkpoint.CREATED_XLSX_V2_STATEMENT_ENTRIES_REPORT);
        await statementResource.getXlsxEntriesReportV2(this.initialDate, this.endDate);
        this.$toaster.success({
          message: 'Extrato está sendo gerado, quando concluído será enviado para o seu email.',
          duration: 10000
        });
      } catch (error) {
        this.$toaster.error(getMessage(error));
      }
    },
    async getCSVEntriesReport() {
      try {
        checkpointResource.create(checkpoint.CREATED_CSV_STATEMENT_ENTRIES_REPORT);
        await statementResource.getCSVEntriesReport(this.initialDate, this.endDate);
        this.$toaster.success({
          message: 'Extrato está sendo gerado, quando concluído será enviado para o seu email.',
          duration: 10000
        });
      } catch (error) {
        this.$toaster.error(getMessage(error));
      }
    },
    async getPdfBatchReport() {
      try {
        checkpointResource.create(checkpoint.CREATED_PDF_STATEMENT_BATCH_REPORT);
        await statementResource.getPdfBatchReport(this.initialDate, this.endDate);
        this.$toaster.success({
          message: 'Extrato está sendo gerado, quando concluído será enviado para o seu email.',
          duration: 10000
        });
      } catch (error) {
        this.$toaster.error(getMessage(error));
      }
    },
    async getOFXEntriesReport() {
      try {
        checkpointResource.create(checkpoint.CREATED_OFX_STATEMENT_ENTRIES_REPORT);
        await statementResource.getOFXEntriesReport(this.initialDate, this.endDate);
        this.$toaster.success({
          message: 'Extrato está sendo gerado, quando concluído será enviado para o seu email.',
          duration: 10000
        });
      } catch (error) {
        this.$toaster.error(getMessage(error));
      }
    },
    search(page = null) {
      this.isLoading = true;
      this.currentPage = page || 0;

      statementResource.getFiltered(this.initialDate, this.endDate, this.currentPage + 1).then(
        statement => {
          this.statement = statement;
          this.pagination = statement.metadata.pagination;
          this.isLoading = false;
        },
        error => this.$toaster.error(getMessage(error))
      );
    },
    onCurrentPageChange(newCurrentPage) {
      this.search(newCurrentPage);
    },
    onSearchByDatePeriod(initialDate, endDate) {
      this.initialDate = initialDate;
      this.endDate = endDate;
      this.search();
    }
  }
};
</script>
