<template>
  <tr-switch
    :is-checked="isChecked"
    :on-change="onSwitchChange"
    :label="`A correção automática está ${isChecked ? 'ativa' : 'inativa'}`"
  />
</template>

<script>
import companySettingsResource from '@/apps/payments/payments/settings/company/resources/settings';
import settingsResource from '@/apps/payments/resources/settings';
import { getMessage } from '@/commons/services/error';

export default {
  name: 'conta-certa-config-container',
  data: () => ({
    isChecked: false
  }),
  created() {
    return this.refresh();
  },
  methods: {
    refresh() {
      settingsResource.get(true).then(
        settings => {
          this.isChecked = settings.acceptContaCertaSuggestions;
        },
        error => this.$toaster.error(getMessage(error))
      );
    },
    onSwitchChange(isChecked) {
      this.isChecked = isChecked;

      return companySettingsResource
        .update({
          accept_contacerta_suggestions: this.isChecked
        })
        .then(
          () => {
            const text = this.isChecked
              ? 'A correção automática está ativa'
              : 'A correção automática está desabilitada';
            this.$toaster.success(text);
          },
          error => {
            this.isChecked = !this.isChecked;
            this.$toaster.error('Ocorreu um erro ao alterar a configuração do Conta Certa ' + getMessage(error));
          }
        );
    }
  }
};
</script>
