import contacertaCompanyUserResource from '@/apps/contacerta/resources/companyUser';
import appCompanyUserResource from '@/apps/payments/payments/settings/company/resources/user';
import { resourceGateway } from './resourceGateway';

const getResource = async () => {
  return resourceGateway({
    appResource: appCompanyUserResource,
    contacertaResource: contacertaCompanyUserResource
  });
};

export default {
  getAll: async (...args) => (await getResource()).getAll(...args),
  get: async (...args) => (await getResource()).get(...args),
  getFreshLoggedUser: async (...args) => (await getResource()).getFreshLoggedUser(...args),
  create: async (...args) => (await getResource()).create(...args),
  update: async (...args) => (await getResource()).update(...args),
  updateUser: async (...args) => (await getResource()).updateUser(...args),
  remove: async (...args) => (await getResource()).remove(...args),
  updatePassword: async (...args) => (await getResource()).updatePassword(...args)
};
