<template>
  <tr-page>
    <billing-closed-container />
    <tr-page-header
      :on-search="onSearchByText"
      title="Seus lotes"
      search-placeholder="Nome do lote ou valor"
      :hide-button-for-roles="appRoles.ANALYST"
      :on-add-click="openTransferForm"
    >
      <template #mainButtonDropdownOptions>
        <tr-dropdown-item :on-item-click="openTransferForm">
          Lotes de Transferências
        </tr-dropdown-item>
        <tr-dropdown-item :on-item-click="openBilletForm">
          Lotes de Boletos
        </tr-dropdown-item>
      </template>
      <template #left-filter>
        <batch-status-dropdown-filter-container :on-search="onSearchByStatus" />
      </template>
      <template #center-filter>
        <tr-month-picker-button :on-filter="onSearchByDatePeriod" />
      </template>
      <template #right-filter>
        <div />
      </template>>
    </tr-page-header>
    <batches-container
      ref="batches"
      :status-filter="statusFilter"
      :period-filter="periodFilter"
      :search-filter="searchFilter"
    />
    <batch-fullscreen-modal
      ref="batchFullscreenModal"
      :on-change="refresh"
      :unique-name="true"
      :type="selectedBatchType"
    />
  </tr-page>
</template>

<script>
import BillingClosedContainer from '@/apps/admin/billing/components/container/BillingClosedContainer.vue';
import BatchesContainer from '@/apps/payments/payments/batch/components/container/BatchesContainer.vue';
import BatchStatusDropdownFilterContainer from '@/apps/payments/payments/batch/components/container/BatchStatusDropdownFilterContainer.vue';
import BatchFullscreenModal from '@/apps/payments/payments/batch/pages/BatchFullscreenModal.vue';
import moment from '@transfeeradev/moment';
import { roles, batchType } from '@transfeeradev/api-enums';

export default {
  name: 'batches-page',
  components: {
    BillingClosedContainer,
    BatchesContainer,
    BatchFullscreenModal,
    BatchStatusDropdownFilterContainer
  },
  data: () => ({
    selectedBatchType: null,
    statusFilter: '',
    periodFilter: {
      initialDate: moment().startOf('month'),
      endDate: moment().endOf('month')
    },
    searchFilter: '',
    appRoles: roles.appRoles
  }),
  methods: {
    refresh() {
      this.$refs.batches.refresh();
    },
    openBatchFullscreenModal() {
      this.$refs.batchFullscreenModal.open();
    },
    openTransferForm() {
      this.selectedBatchType = batchType.TRANSFER;
      this.openBatchFullscreenModal();
    },
    openBilletForm() {
      this.selectedBatchType = batchType.BILLET;
      this.openBatchFullscreenModal();
    },
    onSearchByText(searchTerm) {
      this.searchFilter = searchTerm;
    },
    onSearchByStatus(searchStatus) {
      this.statusFilter = searchStatus;
    },
    onSearchByDatePeriod(initialDate, endDate) {
      this.periodFilter = {
        initialDate,
        endDate
      };
    }
  }
};
</script>
