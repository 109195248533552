<template>
  <div>
    <tr-page-section title="Detalhes do pagamento" />
    <div class="row text-center">
      <tr-typography variant="h2">
        {{ $format.currency(billet.value) }}
      </tr-typography>
      <br>
      <tr-typography
        variant="h4"
        classes="vertical-offset1 block"
      >
        {{ breakBiggerWord(billet.description, 125) }}
      </tr-typography>
    </div>
    <div class="row text-center">
      <billet-status-pill
        classes="col-lg-4 col-lg-offset-4 vertical-offset2"
        size="big"
        :status="billet.status"
      />
    </div>
    <div class="row">
      <billet-receipt-download-links-container
        classes="vertical-offset3 text-center"
        :should-always-show="true"
        :billet="billet"
      />
    </div>
    <hr>
    <tr-alert-notification
      v-if="failReason"
      type="info"
      classes="bottom-offset3"
    >
      <tr-typography>
        {{ failReason }}
      </tr-typography>
    </tr-alert-notification>
    <div class="row">
      <tr-read-only-text-field
        class="col-lg-12"
        content-text-variant="h4"
        label="Linha digitável do boleto"
        :value="barcode(billet.barcode)"
      />
    </div>
    <div class="row">
      <tr-read-only-text-field
        class="col-lg-3 col-md-4 col-sm-6 col-xs-12"
        label="Banco"
      >
        <bank-info
          :bank="billet.bank"
          :size="'medium'"
        />
      </tr-read-only-text-field>
      <tr-read-only-text-field
        class="col-lg-3 col-md-4 col-sm-6 col-xs-12"
        label="Vencimento"
        :value="formatDate(billet.due_date)"
      />
      <tr-read-only-text-field
        class="col-lg-3 col-md-4 col-sm-6 col-xs-12"
        label="Agendado para"
        :no-margin="!showBatchFullscreenModal"
        :value="formatDate(billet.payment_date)"
      />
      <tr-read-only-text-field
        class="col-lg-3 col-md-4 col-sm-6 col-xs-12"
        label="Pago em"
        :no-margin="!showBatchFullscreenModal"
        :value="formatDateTime(billet.paid_date) || '-- --'"
      />
    </div>
    <div
      v-if="showBatchFullscreenModal"
      class="row"
    >
      <div class="col-lg-6">
        <tr-read-only-text-field
          :on-value-click="showBatchFullscreenModal"
          class="col-lg-12"
          label="Lote de pagamentos"
          :no-margin="!billet.integration_id"
          :value="billet.batch && billet.batch.name"
        />
      </div>
      <div
        v-if="billet.integration_id"
        class="col-lg-6"
      >
        <tr-read-only-text-field
          class="col-lg-12"
          label="ID de integração"
          no-margin="true"
          :value="billet.integration_id"
        />
      </div>
    </div>
  </div>
</template>

<script>
import breakBiggerWord from '@/commons/formatters/breakBiggerWord';
import formatDate, { formatDateTime } from '@/commons/formatters/formatDate';
import barcode from '@/commons/formatters/barcode';
import BilletStatusPill from '@/apps/payments/payments/billet/components/presentational/BilletStatusPill';
import BilletReceiptDownloadLinksContainer from '@/apps/payments/payments/billet/components/container/BilletReceiptDownloadLinksContainer';
import billetModel from '@/apps/payments/payments/billet/models/billet';
import BankInfo from '@/apps/payments/payments/bank/components/presentational/BankInfo';

export default {
  name: 'billet-details',
  components: {
    BilletStatusPill,
    BilletReceiptDownloadLinksContainer,
    BankInfo
  },
  props: {
    billet: {
      required: false,
      type: Object,
      default: billetModel.get
    },
    showBatchFullscreenModal: {
      required: false,
      type: Function
    },
    failReason: {
      required: false,
      type: String,
      default: ''
    }
  },
  methods: {
    breakBiggerWord,
    formatDate,
    formatDateTime,
    barcode
  }
};
</script>
