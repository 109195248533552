<template>
  <bank-account-card
    :bank-account="bankAccount"
    :bank-image="bankImage"
    :on-card-click="onCardClick"
    :hide-name="hideName"
  />
</template>

<script>
import config from '@/config';
import BankAccountCard from '@/apps/payments/payments/settings/bankAccounts/components/presentational/BankAccountCard';

export default {
  name: 'bank-account-card-container',
  components: {
    BankAccountCard
  },
  props: ['bankAccount', 'onCardClick', 'hideName'],
  computed: {
    bankImage() {
      if (this.bankAccount.Bank && this.bankAccount.Bank.image) {
        return this.bankAccount.Bank.image;
      }

      return `${config.url.cdn}/banks/default_bank.svg`;
    }
  }
};
</script>
