<template>
  <default-batch-top-info
    :batch="batch"
    :can-reconcile="canReconcile"
    :company-name="companyName"
    :returned-value="returnedValue"
  />
</template>

<script>
import DefaultBatchTopInfo from '@/apps/payments/payments/batch/components/presentational/DefaultBatchTopInfo';
import { batchType, batchStatus } from '@transfeeradev/api-enums';
import batchModel from '@/apps/payments/payments/batch/models/batch';
import loggedUserResource from '@/commons/resources/loggedUser';
import batchResource from '@/apps/payments/payments/batch/resources/batch';

export default {
  name: 'default-batch-top-info-container',
  components: {
    DefaultBatchTopInfo
  },
  props: {
    batch: {
      required: false,
      type: Object,
      default: batchModel.get
    },
    items: {
      required: false,
      type: Array,
      default: () => []
    },
    type: {
      required: false,
      type: String,
      default: batchType.TRANSFER
    }
  },
  data: () => ({
    companyName: '',
    returnedValue: 0
  }),
  computed: {
    canReconcile() {
      return Boolean(this.batch && this.batch.status === batchStatus.FINISHED);
    }
  },
  created() {
    this.refresh();
  },
  methods: {
    refresh() {
      this.companyName = loggedUserResource.get().company.name;
      this.getSummary();
    },
    getSummary() {
      batchResource.getSummary(this.batch.id).then(data => {
        this.returnedValue = data.returned;
      });
    }
  }
};
</script>
