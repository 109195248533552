<template>
  <div class="row">
    <div
      v-for="app in applications"
      :key="app.id"
      class="col-sm-2"
    >
      <input
        :id="`app-${app.id}`"
        v-model="selectedApps"
        :disabled="app.disabled || disabled"
        :value="app.id"
        type="checkbox"
      >
      <label
        class="margin-left-10"
        :for="`app-${app.id}`"
      >
        {{ app.name }}
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    applications: {
      type: Array,
      default: () => []
    },
    modelValue: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      selectedApps: this.modelValue
    };
  },
  watch: {
    modelValue(modelValue) {
      this.selectedApps = modelValue;
    },
    selectedApps(selectedApps) {
      this.$emit('update:modelValue', selectedApps);
    }
  }
};
</script>
