import ReceivablesLayout from '@/apps/payin/Layout';
import PayinTermsOfUsePage from '@/apps/payin/PayinTermsOfUsePage.vue';
import ReceivablesPage from '@/apps/payin/receivables/pages/ReceivablesPage.vue';
import PaymentLinkPage from '@/apps/payin/paymentLink/pages/PaymentLinkPage.vue';
import PaymentLinkListPage from '@/apps/payin/paymentLink/pages/PaymentLinkListPage.vue';
import checkpoint from '@/commons/constants/checkpoint';
import checkpointResource from '@/commons/resources/checkpoint';
import settingsResource from '@/commons/resources/settings';
import loggedUser from '@/commons/resources/loggedUser';
import { APPS } from '@/commons/constants/apps';

export default {
  path: '/recebimentos',
  component: ReceivablesLayout,
  name: 'Receivables',
  redirect: {
    name: 'ReceivablesPage'
  },
  beforeEnter: () => {
    checkpointResource.create(checkpoint.PAYIN_PAGE_VIEWED);
  },
  children: [
    {
      path: 'termos-de-uso',
      component: PayinTermsOfUsePage,
      name: 'PayinTermsOfUsePage',
      meta: {
        async beforeEnter() {
          const settings = await settingsResource.get();
          const { exists: alreadyAccepted } = await checkpointResource.existsInCompany(
            checkpoint.CREDIT_CARD_ACCEPTED_TERMS_OF_USE
          );

          if (!settings.hasCardCharge || (settings.hasCardCharge && alreadyAccepted)) {
            return { name: 'Receivables' };
          }
        }
      }
    },
    {
      path: 'recebiveis',
      component: ReceivablesPage,
      name: 'ReceivablesPage',
      meta: {
        beforeEnter: [termsOfUseGuard]
      }
    },
    {
      path: 'links-de-pagamento',
      component: PaymentLinkListPage,
      name: 'PaymentLinkListPage',
      meta: {
        beforeEnter: [termsOfUseGuard]
      }
    },
    {
      path: 'links-de-pagamento/novo',
      component: PaymentLinkPage,
      name: 'PaymentLinkPage',
      meta: {
        beforeEnter: [termsOfUseGuard]
      }
    }
  ].map(route => ({
    ...route,
    meta: {
      authAs: APPS.PAYMENTS.key,
      ...route.meta
    }
  }))
};

async function termsOfUseGuard() {
  const settings = await settingsResource.get();
  const { exists: alreadyAccepted } = await checkpointResource.existsInCompany(
    checkpoint.CREDIT_CARD_ACCEPTED_TERMS_OF_USE
  );

  if (settings.hasCardCharge && !alreadyAccepted && loggedUser.isAdmin()) {
    return { name: 'PayinTermsOfUsePage' };
  }
}
