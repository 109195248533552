<template>
  <dba-form
    ref="dbaForm"
    :dba="dba"
    :transfer="transfer"
    :should-make-complex-validations="shouldMakeComplexValidations"
    :full-size="fullSize"
    :on-bank-change="onBankChange"
    :on-dba-type-change="onDbaTypeChange"
    :hide-email="hideEmail"
  />
</template>

<script>
import DbaForm from '@/apps/payments/payments/dba/components/presentational/DbaForm.vue';
import dbaModel from '@/apps/payments/payments/dba/models/dba';
import transferModel from '@/apps/payments/payments/transfer/models/transfer';

export default {
  name: 'dba-form-container',
  components: {
    DbaForm
  },
  props: {
    dba: {
      required: false,
      type: Object,
      default: dbaModel.get
    },
    transfer: {
      required: false,
      type: Object,
      default: transferModel.get
    },
    shouldMakeComplexValidations: {
      required: false,
      type: Boolean,
      default: false
    },
    fullSize: {
      required: false,
      type: Boolean,
      default: false
    },
    onBankChange: {
      required: false,
      type: Function
    },
    hideEmail: {
      required: false,
      type: Boolean,
      default: false
    },
    onDbaTypeChange: {
      required: true,
      type: Function
    }
  }
};
</script>
