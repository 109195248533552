import './tooltip.css';
import VTooltip from 'v-tooltip';

export const Tooltip = app => {
  /** We can install in multiple apps */
  VTooltip.install.installed = false;
  app.use(VTooltip, {
    themes: {
      tooltip: {
        html: true
      }
    }
  });
};
