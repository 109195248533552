import loggedUser from '@/commons/resources/loggedUser';
import * as appService from '@/commons/services/apps';
import { ref } from 'vue';

/**
 * TODO: Use payments token if user has access, due to first steps.
 * Remove this when ContaCerta have all required APIs
 */
export const useToken = app => {
  const loading = ref(true);

  /** @type {Ref<boolean>} */
  const hasApplicationAccess = ref(loggedUser.hasApplicationAccess(app));

  const stopLoading = () => {
    loading.value = false;
  };

  if (hasApplicationAccess.value) {
    appService.authAs(app).finally(stopLoading);
  } else {
    stopLoading();
  }

  return {
    hasApplicationAccess,
    loading
  };
};
