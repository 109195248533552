<template>
  <new-user-form
    :applications="applications"
    :on-save="save"
    :on-cancel="onCancel"
  />
</template>

<script>
import NewUserForm from '@/commons/components/presentational/company/NewUserForm';
import userResource from '@/commons/resources/companyUser';
import { getMessage } from '@/commons/services/error';
import { getAvailableApps } from '../../userApplications';

export default {
  name: 'new-user-form-container',
  components: {
    NewUserForm
  },
  props: {
    onCancel: {
      required: false,
      type: Function
    },
    onSave: {
      required: true,
      type: Function
    }
  },
  data() {
    return {
      applications: getAvailableApps()
    };
  },
  methods: {
    save(user) {
      if (user) {
        userResource.create(user).then(
          () => {
            this.onSave();
            this.$toaster.success('Usuário criado com sucesso!');
          },
          error => this.$toaster.error(getMessage(error))
        );
      }
    }
  }
};
</script>
