import { billetStatus } from '@transfeeradev/api-enums';

export default {
  [billetStatus.CREATED]: 'Criado',
  [billetStatus.SCHEDULED]: 'Agendado',
  [billetStatus.FAILED]: 'Falha',
  [billetStatus.RETURNED]: 'Devolvido',
  [billetStatus.EM_REMESSA]: 'Em processamento',
  [billetStatus.EXECUTING]: 'Em processamento',
  [billetStatus.FAILED_INTERNAL]: 'Em processamento',
  [billetStatus.PAID]: 'Pago',
  [billetStatus.FINISHED]: 'Pago'
};
