<template>
  <div :key="dba.pix_key_type">
    <tr-page-section
      :no-margin="true"
      class="vertical-offset3"
      title="Dados do recebedor"
    >
      <div class="row">
        <div class="col-xs-12">
          <dba-type-selector
            :model-value="dba.pix_key_type"
            @update:model-value="onDbaTypeChange"
          />
        </div>
      </div>
      <div
        v-if="dba.pix_key_type"
        class="row vertical-offset1"
      >
        <!--
            Bug in inputmask, if v-if is used directly on tr-text-field it causes an DOMException when switching
            components. Thats why it's a div and a span, to force vue to recreate the html elements tree.
          -->
        <div class="inline">
          <span>
            <tr-text-field
              v-if="dba.pix_key_type === pixKeyType.TELEFONE"
              ref="pixKeyField"
              v-model="dba.pix_key"
              label="Chave Pix (Telefone)"
              name="pixKey"
              :classes="fullSize ? 'col-lg-6' : 'col-lg-5'"
              required="true"
              :pattern="regexes.MOBILE_PHONE"
              :mask-format="masks.MOBILE_PHONE"
              error-message="A chave Pix deve ser válida"
              :on-value-change="pixKeyChanged"
            />
          </span>
        </div>
        <div
          v-if="[pixKeyType.CPF, pixKeyType.CNPJ].includes(dba.pix_key_type)"
          class="inline"
        >
          <dba-cpf-cnpj-field-container
            ref="pixKeyField"
            v-model:cpf-cnpj="dba.pix_key"
            label="Chave Pix (CPF/CNPJ)"
            name="pixKey"
            :classes="fullSize ? 'col-lg-6' : 'col-lg-5'"
            default-error-message="A chave Pix deve ser válida"
            :check-blacklist="false"
            :on-change="pixKeyChanged"
          />
        </div>
        <span v-if="dba.pix_key_type === pixKeyType.EMAIL">
          <tr-text-field
            ref="pixKeyField"
            v-model="dba.pix_key"
            label="Chave Pix (E-mail)"
            name="pixKey"
            :classes="fullSize ? 'col-lg-6' : 'col-lg-5'"
            required="true"
            :pattern="regexes.PIX_EMAIL"
            error-message="A chave Pix deve ser válida"
            :on-value-change="pixKeyChanged"
          />
        </span>
        <span v-if="dba.pix_key_type === pixKeyType.CHAVE_ALEATORIA">
          <div class="inline">
            <tr-text-field
              ref="pixKeyField"
              v-model="dba.pix_key"
              label="Chave Pix (Aleatória)"
              name="pixKey"
              :classes="fullSize ? 'col-lg-6' : 'col-lg-5'"
              required="true"
              :pattern="regexes.PIX_EVP"
              error-message="A chave Pix deve ser válida"
              :on-value-change="pixKeyChanged"
            />
          </div>
        </span>
      </div>
      <div
        v-if="dba.pix_key_type"
        class="row"
      >
        <tr-text-field
          ref="name"
          v-model="dba.name"
          label="Nome (opcional)"
          name="name"
          :classes="fullSize ? 'col-lg-6' : 'col-lg-5'"
          :on-value-change="nameChanged"
        />
      </div>
    </tr-page-section>
    <tr-page-section
      v-if="!dba.pix_key_type"
      :no-margin="true"
      class="vertical-offset3"
      title="Dados pessoais"
    >
      <div class="row">
        <tr-text-field
          ref="nameField"
          v-model="dba.name"
          label="Nome do recebedor"
          name="name"
          :classes="fullSize ? 'col-lg-6' : 'col-lg-5'"
          required="true"
          :error-to-force-until-is-dirty="errorField === 'name'"
          :pattern="regexes.FIRST_NAME"
          :ignore-error-on-dirty="true"
          error-message="O nome deve ter no mínimo 3 letras"
        />
        <dba-cpf-cnpj-field-container
          label="CPF/CNPJ"
          :classes="fullSize ? 'col-lg-6' : ''"
          :cpf-cnpj="dba.cpf_cnpj"
          :on-change="cpfCnpj => (dba.cpf_cnpj = cpfCnpj)"
        />
      </div>
      <div class="row">
        <tr-text-field
          v-if="!hideEmail"
          v-model="dba.email"
          label="E-mail para envio do comprovante (opcional)"
          name="email"
          :classes="fullSize ? 'col-lg-6' : 'col-lg-5'"
          :required="false"
          :pattern="regexes.EMAIL"
          :no-margin="true"
        />
      </div>
    </tr-page-section>
    <div
      v-else
      class="row"
    >
      <tr-text-field
        v-if="!hideEmail"
        v-model="dba.email"
        label="E-mail para envio do comprovante (opcional)"
        name="email"
        :classes="fullSize ? 'col-lg-6' : 'col-lg-5'"
        :required="false"
        :pattern="regexes.EMAIL"
        :no-margin="true"
      />
    </div>
    <tr-page-section
      v-if="!dba.pix_key_type"
      :no-margin="true"
      class="vertical-offset3"
      title="Dados bancários"
    >
      <transfer-bank-account-form-container
        ref="bankAccountForm"
        :should-make-complex-validations="shouldMakeComplexValidations"
        :full-size="fullSize"
        :bank-account="dba"
        :cpf-cnpj="dba.cpf_cnpj"
        :no-margin="true"
        :error-field="errorField"
        :on-bank-change="onBankChange"
      />
    </tr-page-section>
  </div>
</template>

<script>
import TransferBankAccountFormContainer from '@/apps/payments/payments/transfer/components/container/TransferBankAccountFormContainer.vue';
import DbaCpfCnpjFieldContainer from '@/apps/payments/payments/dba/components/container/DbaCpfCnpjFieldContainer.vue';
import DbaTypeSelector from '@/apps/payments/payments/dba/components/presentational/DbaTypeSelector.vue';
import dbaModel from '@/apps/payments/payments/dba/models/dba';
import transferModel from '@/apps/payments/payments/transfer/models/transfer';
import { pix, regexes } from '@transfeeradev/api-enums';
import masks from '@/commons/constants/masks';
import PersonTypeService from '@transfeeradev/api-services/PersonTypeService';

export default {
  name: 'dba-form',
  components: {
    TransferBankAccountFormContainer,
    DbaCpfCnpjFieldContainer,
    DbaTypeSelector
  },
  props: {
    dba: {
      required: false,
      type: Object,
      default: dbaModel.get
    },
    transfer: {
      required: false,
      type: Object,
      default: transferModel.get
    },
    shouldMakeComplexValidations: {
      required: false,
      type: Boolean,
      default: false
    },
    fullSize: {
      required: false,
      type: Boolean,
      default: false
    },
    errorField: {
      required: false,
      type: String
    },
    onBankChange: {
      required: false,
      type: Function
    },
    hideEmail: {
      required: false,
      type: Boolean,
      default: false
    },
    onDbaTypeChange: {
      required: true,
      type: Function
    }
  },
  data() {
    return {
      previous: null,
      regexes,
      masks,
      pixKeyType: { ...pix.keyType }
    };
  },
  methods: {
    pixKeyChanged(key) {
      if ([pix.keyType.CPF, pix.keyType.CNPJ].includes(this.dba.pix_key_type)) {
        if (PersonTypeService.isCNPJ(key)) {
          this.dba.pix_key_type = pix.keyType.CNPJ;
        } else {
          this.dba.pix_key_type = pix.keyType.CPF;
        }
      }
    },
    nameChanged(name) {
      this.dba.name = name;
    }
  }
};
</script>
