import PersonTypeService from '@transfeeradev/api-services/PersonTypeService';
import TransferPixService from '@transfeeradev/api-services/TransferPixService';
import { TRANSFER_PIX_WITH_KEY_HEADERS } from '@transfeeradev/sheets-import';

const CPF_CNPJ_MAX_SIZE = 25;

const format = row => {
  const isPixDba = TransferPixService.isPix({
    pix_key: row[TRANSFER_PIX_WITH_KEY_HEADERS.pixKey.description],
    pix_key_type: row[TRANSFER_PIX_WITH_KEY_HEADERS.pixKeyType.description]
  });

  if (isPixDba) {
    return {
      cpf_cnpj: _limitString(
        PersonTypeService.getCpfCnpjNumber(row[TRANSFER_PIX_WITH_KEY_HEADERS.cpfCnpj.description]),
        CPF_CNPJ_MAX_SIZE
      )
    };
  } else {
    return undefined;
  }
};

const _limitString = (inputString, maxLength) => inputString && inputString.substr(0, maxLength);

export default {
  format
};
