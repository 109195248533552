<template>
  <tr-page-section :no-margin="true">
    <tr-page-section
      title="Documentação da API"
      :no-margin="true"
    >
      <tr-typography>
        Seu usuário está liberado para utilizar a API de integração. Siga a documentação para integrar com seu sistema.
      </tr-typography>
      <doc-link />
    </tr-page-section>
    <app-api-credential-container
      v-if="shouldShow[PAYMENTS_KEY]"
      @fetched="onRendered(PAYMENTS_KEY)"
    />
    <contacerta-api-credential-container
      v-if="shouldShow[CONTACERTA_KEY]"
      @fetched="onRendered(CONTACERTA_KEY)"
    />
  </tr-page-section>
</template>

<script lang="jsx">
import { computed, reactive } from 'vue';
import AppApiCredentialContainer from '@/apps/payments/payments/settings/config/components/container/ApiCredentialContainer.vue';
import ContacertaApiCredentialContainer from '@/apps/contacerta/settings/components/container/ApiContainer.vue';
import { APPS } from '@/commons/constants/apps';
import { useAppSwitcher } from '@/commons/composables/useAppSwitcher';

const PAYMENTS_KEY = APPS.PAYMENTS.key;
const CONTACERTA_KEY = APPS.CONTACERTA.key;

const DocLink = () => (
  <tr-button
    class="margin-top"
    label="Ir para documentação da API"
    href="https://docs.transfeera.dev/"
    variant="raised"
    color="primary"
    open-in-new-tab="true"
  />
);

export default {
  name: 'developer-area-credentials-container',
  components: {
    DocLink,
    AppApiCredentialContainer,
    ContacertaApiCredentialContainer
  },
  setup() {
    /**
     * TODO: Remove all this app token switching
     * https://github.com/transfeera/board/issues/2746
     */
    const { currentApp, switchApp } = useAppSwitcher();

    const rendered = reactive({
      [PAYMENTS_KEY]: false,
      [CONTACERTA_KEY]: false
    });

    const onRendered = async app => {
      rendered[app] = true;
      switchApp();
    };

    return {
      CONTACERTA_KEY,
      PAYMENTS_KEY,
      onRendered,
      shouldShow: computed(() => ({
        [PAYMENTS_KEY]: currentApp.value === PAYMENTS_KEY || rendered[PAYMENTS_KEY],
        [CONTACERTA_KEY]: currentApp.value === CONTACERTA_KEY || rendered[CONTACERTA_KEY]
      }))
    };
  }
};
</script>
