<template>
  <tr-loading
    v-if="isLoading"
    classes="width-100 center-h-and-v"
  />
  <tr-page-section
    v-else-if="hasApiAccess"
    :title="title"
  >
    <api-key
      v-if="apiKey"
      v-model:api-key="apiKey"
      :generate-new-api-key="isDeadlineForAuthMigration ? openApiCredentialGenerationConfirmationDialog : openApiKeyGenerationConfirmationDialog"
    />
    <api-credential
      v-if="!apiKey"
      v-model:client-id="clientId"
      v-model:client-secret="clientSecret"
      :generate-api-credential="openApiCredentialGenerationConfirmationDialog"
    />
    <api-credential-generation-confirmation-dialog
      ref="apiCredentialGenerationConfirmationDialog"
      :on-confirm="generateApiCredential"
    />
    <api-key-generation-confirmation-dialog
      ref="apiKeyGenerationConfirmationDialog"
      :on-confirm="generateApiCredential"
    />
  </tr-page-section>
</template>

<script>
import * as appService from '@/commons/services/apps';
import ApiKey from '@/commons/components/presentational/config/ApiKey';
import ApiCredential from '@/commons/components/presentational/config/ApiCredential';
import ApiCredentialGenerationConfirmationDialog from '@/commons/components/presentational/config/ApiCredentialGenerationConfirmationDialog';
import ApiKeyGenerationConfirmationDialog from '@/commons/components/presentational/config/ApiKeyGenerationConfirmationDialog';
import settingsResource from '@/apps/payments/resources/settings';
import { APPS } from '@/commons/constants/apps';
import { getMessage } from '@/commons/services/error';

export default {
  name: 'api-credential-container',
  components: {
    ApiKey,
    ApiCredential,
    ApiCredentialGenerationConfirmationDialog,
    ApiKeyGenerationConfirmationDialog
  },
  emits: ['fetched'],
  data: () => ({
    isLoading: true,
    apiKey: '',
    clientId: '',
    clientSecret: '',
    hasApiAccess: false,
    isDeadlineForAuthMigration: false,
    title: `Credenciais para API ${APPS.PAYMENTS.title}`
  }),
  created() {
    this.refresh();
  },
  methods: {
    async refresh() {
      this.isLoading = true;

      await settingsResource.get(true).then(
        async data => {
          this.hasApiAccess = data.hasApiAccess;
          await appService.authAs(APPS.PAYMENTS.key);
          return settingsResource.getApiCredential().then(
            result => {
              this.clientId = result.client_id;
              this.apiKey = this.clientId ? '' : data.apiAccessToken;
              this.isDeadlineForAuthMigration = this.reachedDeadlineForAuthMigration();
              this.isLoading = false;
            },
            error => this.$toaster.error(getMessage(error))
          );
        },
        error => this.$toaster.error(getMessage(error))
      );

      this.$emit('fetched');
    },
    reachedDeadlineForAuthMigration() {
      const currentDate = new Date();
      const deadlineForAuthMigration = new Date('2024-02-26');
      return currentDate > deadlineForAuthMigration;
    },
    openApiCredentialGenerationConfirmationDialog() {
      this.$refs.apiCredentialGenerationConfirmationDialog.open();
    },
    openApiKeyGenerationConfirmationDialog() {
      this.$refs.apiKeyGenerationConfirmationDialog.open();
    },
    async generateApiCredential() {
      this.isLoading = true;

      try {
        await appService.authAs(APPS.PAYMENTS.key);
        const credentials = await settingsResource.createApiCredential();
        this.apiKey = '';
        this.clientId = credentials.client_id;
        this.clientSecret = credentials.client_secret;
        this.$toaster.success('Nova credencial de acesso criada com sucesso!');
      } catch (error) {
        this.$toaster.error('Ocorreu um erro ao gerar uma nova credencial ' + getMessage(error));
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>
