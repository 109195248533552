import config from '@/config';
import fetch from '@/fetch';

let cache;
let cachePixBanks;
let cacheAllBanks;

const BASE_URL = config.url.api.transfeera + '/bank';

export default {
  get: () => {
    if (cache) {
      return new Promise(resolve => resolve(cache));
    }
    return fetch(BASE_URL).then(banks => {
      cache = banks;
      return cache;
    });
  },
  getPixBanks: () => {
    if (cachePixBanks) {
      return new Promise(resolve => resolve(cachePixBanks));
    }
    return fetch(BASE_URL + '?pix=true').then(banks => {
      cachePixBanks = banks;
      return cachePixBanks;
    });
  },
  getAll: () => {
    if (cacheAllBanks) {
      return new Promise(resolve => resolve(cacheAllBanks));
    }
    return fetch(BASE_URL + '?all=true').then(banks => {
      cacheAllBanks = banks;
      return cacheAllBanks;
    });
  },
  getById: id => fetch(`${BASE_URL}/${id}`),
  getByCode: code => fetch(`${BASE_URL}/code/${code}`),
  accountTypes: bankId => fetch(`${BASE_URL}/${bankId}/accountTypes`)
};
