<template>
  <tr-page>
    <tr-page-header title="Indicadores">
      <template #center-filter>
        <div class="row indicator-page__date-filter">
          <div class="col-lg-10">
            <tr-month-picker-button
              :on-filter="onChangeFilter"
              :range="filterDateRule"
            />
          </div>
        </div>
      </template>
    </tr-page-header>
    <div class="row">
      <div class="col-lg-6 vertical-offset3">
        <card title="Pagamentos por mês">
          <quantity-of-transfers-per-month-chart-container :date-range="{ startDate, endDate }" />
        </card>
      </div>
      <div class="col-lg-6 vertical-offset3">
        <card title="Pagamentos por dia">
          <quantity-of-transfers-per-day-chart-container :date-range="{ startDate, endDate }" />
        </card>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 vertical-offset3">
        <card title="Pagamentos por banco">
          <distribution-of-transfers-per-bank-chart-container :date-range="{ startDate, endDate }" />
        </card>
      </div>
      <div class="col-lg-6 vertical-offset3">
        <card title="Falhas por banco">
          <proporsion-of-failed-vs-finished-per-bank-chart-container :date-range="{ startDate, endDate }" />
        </card>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 vertical-offset3">
        <card title="Finalizado x Devolvido">
          <proporsion-of-failed-vs-finished-chart-container :date-range="{ startDate, endDate }" />
        </card>
      </div>
      <div class="col-lg-6 vertical-offset3">
        <card title="Principais motivos de falhas">
          <failed-transfers-per-status-description-chart-container :date-range="{ startDate, endDate }" />
        </card>
      </div>
    </div>
  </tr-page>
</template>

<script>
import QuantityOfTransfersPerMonthChartContainer from '@/apps/payments/payments/indicator/components/container/QuantityOfTransfersPerMonthChartContainer.vue';
import QuantityOfTransfersPerDayChartContainer from '@/apps/payments/payments/indicator/components/container/QuantityOfTransfersPerDayChartContainer.vue';
import DistributionOfTransfersPerBankChartContainer from '@/apps/payments/payments/indicator/components/container/DistributionOfTransfersPerBankChartContainer.vue';
import ProporsionOfFailedVsFinishedChartContainer from '@/apps/payments/payments/indicator/components/container/ProporsionOfFailedVsFinishedChartContainer.vue';
import ProporsionOfFailedVsFinishedPerBankChartContainer from '@/apps/payments/payments/indicator/components/container/ProporsionOfFailedVsFinishedPerBankChartContainer.vue';
import FailedTransfersPerStatusDescriptionChartContainer from '@/apps/payments/payments/indicator/components/container/FailedTransfersPerStatusDescriptionChartContainer.vue';
import moment from '@transfeeradev/moment';
import Card from '@/commons/components/presentational/Card.vue';

export default {
  name: 'indicator-page',
  components: {
    QuantityOfTransfersPerMonthChartContainer,
    DistributionOfTransfersPerBankChartContainer,
    ProporsionOfFailedVsFinishedChartContainer,
    QuantityOfTransfersPerDayChartContainer,
    ProporsionOfFailedVsFinishedPerBankChartContainer,
    FailedTransfersPerStatusDescriptionChartContainer,
    Card
  },
  data() {
    return {
      startDate: moment().startOf('month'),
      endDate: moment().endOf('month'),
      filterDateRule: {
        max: { value: 365, unit: 'days', error: 'Período selecionado deve ser menor que 1 ano.' },
        min: { value: 1, unit: 'day', error: 'Período selecionado deve ser maior que 1 dia.' }
      }
    };
  },
  methods: {
    onChangeFilter(startDate, endDate) {
      this.startDate = startDate;
      this.endDate = endDate;
    }
  }
};
</script>
