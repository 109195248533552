<template>
  <tr-slimbox
    :classes="`${noMargin ? '' : 'vertical-offset3'}`"
    :no-margin="true"
  >
    <div class="row">
      <div class="col-lg-3">
        <tr-svg-icon
          name="billing-icon"
          size="medium"
          classes="vertical-offset2 pull-left margin-right"
        />
        <p class="vertical-offset2">
          Sua fatura de <tr-typography strong="true">
            {{ billingMonth }}
          </tr-typography> já está fechada!
        </p>
      </div>
      <div class="col-lg-3">
        <div class="width-100">
          <tr-typography variant="h3">
            {{ amountOfTransfers }}
          </tr-typography>
        </div>
        <div class="width-100">
          <tr-typography variant="h5">
            Pagamentos realizados
          </tr-typography>
        </div>
        <tr-button
          label="Baixar relatório"
          variant="text"
          :link-color="true"
          text-variant="body"
          :on-button-click="getMonthlyReport"
        />
      </div>
      <div class="col-lg-3">
        <div class="width-100">
          <tr-typography variant="h3">
            {{ $format.currency(billingTotal) }}
          </tr-typography>
        </div>
        <div class="width-100">
          <tr-typography variant="h5">
            Valor faturado
          </tr-typography>
        </div>
        <tr-button
          label="Baixar nota fiscal"
          variant="text"
          :link-color="true"
          text-variant="body"
          :open-in-new-tab="true"
          :href="eletronicInvoiceUrl"
          :disabled="!eletronicInvoiceUrl"
        />
      </div>
      <div class="col-lg-3">
        <tr-button
          label="Baixar boleto"
          classes="pull-right"
          :href="ticketUrl"
          svg-icon-color="white"
          :disabled="!ticketUrl"
          :open-in-new-tab="true"
          color="primary"
          variant="raised"
          size="xLarge"
          svg-icon-name="ic-file-download"
        />
      </div>
    </div>
  </tr-slimbox>
</template>

<script>
export default {
  name: 'billing-closed',
  props: {
    billingMonth: {
      required: false,
      type: String,
      default: ''
    },
    amountOfTransfers: {
      required: false,
      type: Number,
      default: 0
    },
    billingTotal: {
      required: false,
      type: Number,
      default: 0
    },
    noMargin: {
      required: false,
      type: Boolean,
      default: false
    },
    ticketUrl: {
      required: false,
      type: String
    },
    eletronicInvoiceUrl: {
      required: false,
      type: String
    },
    getMonthlyReport: {
      required: true,
      type: Function
    }
  }
};
</script>
