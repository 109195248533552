<template>
  <tr-form
    ref="form"
    :on-valid-submit="save"
  >
    <billet-form
      :billet="billet"
      :on-barcode-process="onBarcodeProcess"
      :on-barcode-change="onBarcodeChange"
      :on-payment-date-change="onPaymentDateChange"
      :on-description-change="onDescriptionChange"
      :on-billet-value-change="onBilletValueChange"
    />
    <tr-footer-buttons-container>
      <tr-button
        class="pull-right margin-right"
        variant="raised"
        :disabled="isLoading"
        label="Salvar"
        color="primary"
        type="submit"
      />
      <tr-button
        v-if="billet.id"
        class="pull-right margin-right"
        variant="raised"
        :disabled="isLoading"
        size="medium"
        svg-icon-name="ic-delete"
        svg-icon-color="white"
        color="danger"
        :on-button-click="showBilletRemovalConfirmationDialog"
      />
    </tr-footer-buttons-container>
    <billet-removal-confirmation-dialog
      ref="billetRemovalConfirmationDialog"
      :on-confirm="remove"
    />
  </tr-form>
</template>

<script>
import BilletRemovalConfirmationDialog from '@/apps/payments/payments/billet/components/presentational/BilletRemovalConfirmationDialog';
import BilletForm from '@/apps/payments/payments/billet/components/presentational/BilletForm';
import { batchType } from '@transfeeradev/api-enums';
import DateService from '@transfeeradev/api-services/DateService';
import prepareToSave from '@/apps/payments/payments/billet/services/prepareToSave';
import billetResource from '@/apps/payments/payments/billet/resources/billet';
import batchResource from '@/apps/payments/payments/batch/resources/batch';
import billetModel from '@/apps/payments/payments/billet/models/billet';
import batchModel from '@/apps/payments/payments/batch/models/batch';
import BilletValidation from '@transfeeradev/billet-validation';
import transfersLotModelFactory from '@/apps/payments/payments/transfer/services/transfersLotModelFactory';
import { getMessage } from '@/commons/services/error';

export default {
  name: 'billet-form-container',
  components: {
    BilletRemovalConfirmationDialog,
    BilletForm
  },
  props: {
    onSave: {
      required: false,
      type: Function
    },
    onRemove: {
      required: false,
      type: Function
    },
    batchId: {
      required: false,
      type: [String, Number]
    },
    billetId: {
      required: false,
      type: [String, Number]
    }
  },
  data: () => ({
    isLoading: false,
    billet: billetModel.get(),
    initialBarCode: null,
    hasChangedBilletValue: false
  }),
  watch: {
    billetId() {
      this.refresh();
    }
  },
  mounted() {
    if (this.billetId) {
      this.refresh();
    }
  },
  methods: {
    refresh() {
      return billetResource.getDetails(this.billetId).then(billet => {
        this.billet = billet;
        if (!this.initialBarCode) {
          this.initialBarCode = billet.barcode;
        }
        this.setFormAsSubmitted();
      });
    },
    onDescriptionChange(newValue) {
      this.billet.description = newValue;
    },
    onPaymentDateChange(newValue) {
      this.billet.payment_date = newValue;
    },
    onBilletValueChange(newValue) {
      this.billet.value = newValue;
    },
    onBarcodeProcess(billetData) {
      this.billet.due_date = billetData.dueDate;
      if (this._shouldSetProcessedBilletValue()) {
        this.billet.value = billetData.value;
        this.hasChangedBilletValue = true;
      }
      this.billet.bank = billetData.bank;
    },
    setFormAsSubmitted() {
      this.$refs.form.setAsSubmitted();
    },
    showBilletRemovalConfirmationDialog() {
      this.$refs.billetRemovalConfirmationDialog.open();
    },
    remove() {
      this.$refs.billetRemovalConfirmationDialog.close();
      this.isLoading = true;
      batchResource.removeBillet(this.batchId, this.billet.id).then(() => {
        this.isLoading = false;
        if (this.onRemove) {
          this.onRemove();
        }
        this.$toaster.success('Boleto excluído com sucesso');
      }, this._onFailedRemove);
    },
    onBarcodeChange(barcode) {
      this.billet.barcode = barcode;
    },
    save() {
      const billet = Object.assign({}, this.billet);
      billet.payment_date = DateService.getFormatted(billet.payment_date);

      const isValid = !new BilletValidation({
        billet,
        disallowOverdue: true
      }).validate().length;
      if (isValid) {
        this.saveBillet();
      }
    },
    saveBillet() {
      this.isLoading = true;
      const billet = prepareToSave.prepare(this.billet);

      if (this.billet.id) {
        this.saveEdit(billet);
      } else {
        this.saveNew(billet);
      }
    },
    saveEdit(billet) {
      batchResource.updateBillet(this.batchId, billet).then(() => this._onBilletSave(), this._onFailedSave);
    },
    saveNew(billet) {
      if (this.batchId) {
        batchResource.createBillet(this.batchId, billet).then(() => this._onBilletSave(), this._onFailedSave);
      } else {
        this.saveBatch(billet);
      }
    },
    saveBatch(billet) {
      let batch = batchModel.get();
      batch.type = batchType.BILLET;
      batch = transfersLotModelFactory.prepareToSave(batch, false);
      batch.type = batchType.BILLET;
      batch.billets.push(billet);
      batchResource.create(batch).then(savedBatch => this.onSaveBatch(savedBatch), this._onFailedSave);
    },
    onSaveBatch(savedBatch) {
      this._onBilletSave(savedBatch.id);
    },
    _onFailedSave(error) {
      this.isLoading = false;
      if (error && error.code) {
        return this.$toaster.error(getMessage(error));
      }

      this.$toaster.error('Ocorreu um erro ao salvar o boleto. Tente salvar novamente!');
    },
    _onBilletSave(savedBatchId) {
      this.$toaster.success('Boleto incluído com sucesso');
      this.isLoading = false;
      this.onSave(savedBatchId);
    },
    _onFailedRemove(error) {
      this.isLoading = false;
      if (error && error.code) {
        return this.$toaster.error(getMessage(error));
      }

      this.$toaster.error('Ocorreu um erro ao excluir o boleto. Tente excluir novamente!');
    },
    _shouldSetProcessedBilletValue() {
      return Boolean(!this.initialBarCode || this.initialBarCode !== this.billet.barcode || this.hasChangedBilletValue);
    }
  }
};
</script>
