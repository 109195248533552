<template>
  <div class="row is-flex vertical-offset4">
    <tr-add-card
      v-if="addNew && (!isLoading || currentPage)"
      :on-add-click="onTransferClick.bind(this, {})"
    />
    <template v-for="(transfer, index) in transfers">
      <transfer-card-container
        v-if="transfers && transfers.length > 0 && index <= transfersLimit - 1 && (!isLoading || currentPage)"
        :key="transfer.id"
        :batch="batch"
        :hide-status-pill="hideStatusPill"
        :on-card-click="onTransferClick.bind(this, transfer)"
        :transfer="transfer"
      />
    </template>
    <div
      v-if="isLoading"
      class="col-lg-3 col-md-4 col-sm-12 center-h-and-v bottom-offset4"
    >
      <tr-loading />
    </div>
    <transfer-details-dialog
      ref="transferDetailsDialog"
      :on-hidden="onHideTransferDetailsDialog"
      :transfer-id="clickedTransfer.id"
    />
    <transfer-wizard-fullscreen-modal
      ref="transferWizardFullscreenModal"
      :on-cancel="onCancel"
      :on-save="onEdit"
      :on-remove="onEdit"
      :batch="batch"
      :transfer="clickedTransfer"
    />
  </div>
  <tr-button
    v-if="(transfers.length > transfersLimit || mightHaveMoreTransfersOnBackend) && (!isLoading || currentPage)"
    classes="btn-block col-lg-12"
    block-size="true"
    :disabled="isLoading"
    :on-button-click="updateLimitation"
    size="large"
    label="Mostrar mais transferências"
    variant="outlined"
    color="secondary"
  />
</template>

<script>
import TransferDetailsDialog from '@/apps/payments/payments/transfer/components/presentational/TransferDetailsDialog';
import TransferCardContainer from '@/apps/payments/payments/transfer/components/container/TransferCardContainer';
import TransferWizardFullscreenModal from '@/apps/payments/payments/transfer/pages/TransferWizardFullscreenModal';
import batchResource from '@/apps/payments/payments/batch/resources/batch';
import verifyRoleAccess from '@/commons/services/verifyRoleAccess';
import { transferStatus, batchStatus, roles } from '@transfeeradev/api-enums';
import checkpointResource from '@/apps/payments/resources/checkpoint';
import checkpoint from '@/commons/constants/checkpoint';

const DEFAULT_TRANSFERS_LIMIT = 50;

export default {
  name: 'transfer-cards-container',
  components: {
    TransferWizardFullscreenModal,
    TransferDetailsDialog,
    TransferCardContainer
  },
  props: [
    'batch',
    'statusFilter',
    'searchTermFilter',
    'hideStatusPill',
    'onGetTransfers',
    'onGetFailedTransfersSummary',
    'addNew',
    'onEdit',
    'onStartLoading',
    'onCancelWizard',
    'wasJustImported'
  ],
  emits: ['is-all-transfers-scheduled-event'],
  data: () => ({
    transfers: [],
    clickedTransfer: {},
    transfersLimit: DEFAULT_TRANSFERS_LIMIT,
    currentPage: 0,
    lastCurrentPage: 0,
    totalItems: 0,
    isLoading: false,
    lastStatusFilter: null,
    lastSearchTermFilter: null
  }),
  computed: {
    mightHaveMoreTransfersOnBackend() {
      return this.transfers && this.transfers.length < this.totalItems;
    }
  },
  watch: {
    statusFilter() {
      this.refresh();
    }
  },
  mounted() {
    this.refresh();
  },
  methods: {
    refresh() {
      if (this.isLoading) {
        return;
      }

      let page = null;
      const isDraft = Boolean(!this.batch.status || this.batch.status === batchStatus.DRAFT);
      const isAnalyst = verifyRoleAccess.isLoggedUserForbiddenToAccess(roles.appRoles.ANALYST);

      if (isDraft && !isAnalyst && (!this.batch || !this.batch.id)) {
        return this.onTransferClick();
      }

      if (!this.batch || !this.batch.id) {
        return;
      }

      this.isLoading = true;

      if (this.onStartLoading) {
        this.onStartLoading();
      }

      const hasChangedAFilter =
        this.lastStatusFilter !== this.statusFilter || this.lastSearchTermFilter !== this.searchTermFilter;

      if (hasChangedAFilter || this.lastCurrentPage === this.currentPage) {
        this.currentPage = 0;
        this.transfers = [];
      }

      this.lastStatusFilter = this.statusFilter;
      this.lastSearchTermFilter = this.searchTermFilter;
      this.lastCurrentPage = this.currentPage;

      page = this.currentPage;

      batchResource.getTransfers(this.batch.id, page, this.statusFilter, this.searchTermFilter).then(result => {
        this.totalItems = result.metadata.pagination.totalItems;

        this.transfers = this.transfers.concat(result.data);
        const isAllTransfersScheduled = result.is_all_transfers_scheduled;
        this.$emit('is-all-transfers-scheduled-event', isAllTransfersScheduled);

        this.isLoading = false;

        if (typeof this.onGetTransfers === 'function') {
          this.onGetTransfers(this.transfers);
        }

        if (typeof this.onGetFailedTransfersSummary === 'function' && result.failedTransfersSummary) {
          this.onGetFailedTransfersSummary(result.failedTransfersSummary);
        }

        if (isDraft && !this.currentPage && !isAnalyst && !this.areCardsValid()) {
          this.$toaster.error('Seu lote possui alguns erros! Verifique as transferências em destaque para corrigir.');
        }

        if (this.wasJustImported) {
          this._sendFailedTransferCountMetric();
        }
      });
    },
    onTransferClick(clickedTransfer) {
      if (this._shouldOpenTransferWizardFullscreenModal()) {
        this.openTransferWizardFullscreenModal(clickedTransfer);
      } else {
        this.openTransferDetailsDialog(clickedTransfer);
      }
    },
    areCardsValid() {
      return this.transfers.every(transfer => !this.isTransferFailed(transfer));
    },
    isTransferFailed(transfer) {
      const dba = transfer.destination_bank_account;
      return Boolean(
        (transfer._errors && transfer._errors.length) ||
          transfer.status === transferStatus.FAILED ||
          dba.status === transferStatus.FAILED
      );
    },
    hasWarningOnTransfer(transfer) {
      return Boolean(transfer._warnings && transfer._warnings.length);
    },
    updateLimitation() {
      this.transfersLimit += DEFAULT_TRANSFERS_LIMIT;
      this.currentPage++;
      this.refresh();
    },
    openTransferWizardFullscreenModal(clickedTransfer = {}) {
      this.$refs.transferWizardFullscreenModal.open();
      this.clickedTransfer = clickedTransfer;
    },
    onCancel() {
      this.clickedTransfer = {};

      if (this.onCancelWizard) {
        this.onCancelWizard();
      }
    },
    openTransferDetailsDialog(clickedTransfer = {}) {
      this.$refs.transferDetailsDialog.open();
      this.clickedTransfer = clickedTransfer;
    },
    onHideTransferDetailsDialog() {
      this.clickedTransfer = {};
    },
    _shouldOpenTransferWizardFullscreenModal() {
      return Boolean(
        (!this.batch.status || this.batch.status === batchStatus.DRAFT) &&
          !verifyRoleAccess.isLoggedUserForbiddenToAccess(roles.appRoles.ANALYST)
      );
    },
    _sendFailedTransferCountMetric() {
      let failedTransfersCount = 0;

      for (const transfer of this.transfers) {
        if (this.isTransferFailed(transfer)) {
          failedTransfersCount++;
        }
      }

      if (failedTransfersCount > 0) {
        checkpointResource.create(checkpoint.IMPORTED_SHEET_WITH_FAILED_TRANSFERS, {
          failedTransfersCount: failedTransfersCount
        });
      }
    }
  }
};
</script>
