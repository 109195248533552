import { pix } from '@transfeeradev/api-enums';

const format = pixKeyType => {
  if (!pixKeyType || !Object.keys(pix.keyType).includes(pixKeyType.toUpperCase())) {
    return null;
  }

  return pixKeyType.toUpperCase();
};

export default {
  format
};
