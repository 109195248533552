import Layout from './Layout';
import AdminPage from './pages/AdminPage';
import { APPS } from '@/commons/constants/apps';
import { roles } from '@transfeeradev/api-enums';

export default {
  path: APPS.MY_COMPANY.path,
  component: Layout,
  name: 'Admin',
  meta: {
    allowForRoles: roles.appRoles.ADMIN
  },
  redirect: {
    name: 'AdminCompanyDataPage'
  },
  children: [
    {
      path: 'dados-da-empresa',
      name: 'AdminCompanyDataPage',
      component: AdminPage
    },
    {
      path: 'usuarios',
      name: 'AdminUsersPage',
      component: AdminPage
    },
    {
      path: 'faturas',
      name: 'BillingPage',
      component: AdminPage,
      meta: {
        allowForRoles: roles.appRoles.ADMIN
      }
    }
  ].map(route => ({
    ...route,
    meta: {
      ...route.meta,
      authAs: [APPS.PAYMENTS.key, APPS.CONTACERTA.key]
    }
  }))
};
