<template>
  <div>
    <tr-table :disable-click="true">
      <tr-thead>
        <tr-tr>
          <tr-th width="25%">
            Descrição
          </tr-th>
          <tr-th width="15%">
            Fechado em
          </tr-th>
          <tr-th width="20%">
            Criado por
          </tr-th>
          <tr-th width="25%">
            Status
          </tr-th>
          <tr-th
            class="text-right"
            width="15%"
          >
            Valor
          </tr-th>
        </tr-tr>
      </tr-thead>
      <tr-tbody>
        <tr-tr
          v-for="batch in batches"
          :key="batch.id"
          :disable-click="true"
        >
          <tr-td>
            <tr-typography>
              {{ breakBiggerWord(batch.name, 30) }}
            </tr-typography>
          </tr-td>
          <tr-td>
            <tr-typography>
              {{ formatDate(batch.created_at) }}
            </tr-typography>
          </tr-td>
          <tr-td>
            <tr-typography>
              {{ batch.created_by.name }}
            </tr-typography>
          </tr-td>
          <tr-td>
            <tr-typography>
              <batch-status-pill
                no-margin="true"
                size="medium"
                no-col-limit="true"
                :batch-status="batch.status"
              />
            </tr-typography>
          </tr-td>
          <tr-td class="text-right">
            <tr-typography>
              <span class="inline-block">{{ $format.currency(batch.value) }}</span>
            </tr-typography>
          </tr-td>
        </tr-tr>
      </tr-tbody>
    </tr-table>
    <batches-waiting-balance-totals-container
      class="pull-right"
      :batches-total="batchesTotal"
    />
  </div>
</template>

<script>
import formatDate from '@/commons/formatters/formatDate';
import breakBiggerWord from '@/commons/formatters/breakBiggerWord';
import BatchStatusPill from '@/apps/payments/payments/batch/components/presentational/BatchStatusPill';
import BatchesWaitingBalanceTotalsContainer from '@/apps/payments/payments/batch/components/container/BatchesWaitingBalanceTotalsContainer';

export default {
  name: 'batches-waiting-balance-table',
  components: {
    BatchStatusPill,
    BatchesWaitingBalanceTotalsContainer
  },
  props: {
    batches: {
      required: true,
      type: Array
    },
    batchesTotal: {
      required: true,
      type: Number
    }
  },
  methods: {
    formatDate,
    breakBiggerWord
  }
};
</script>
