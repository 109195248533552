<template>
  <tr-dialog
    ref="modal"
    name="tfa-deactivation-dialog"
    :on-save="() => { }"
    :on-close="close"
    save-button-label="Ativar"
    title="Desativar autenticação em 2 fatores"
    :has-its-own-buttons="true"
  >
    <tr-form
      ref="form"
      :on-valid-submit="onSubmit"
    >
      <div>
        <tr-typography variant="h5">
          Para desativar, use o código gerado em seu aplicativo de autenticação.
        </tr-typography>
        <div class="row center-h-and-v vertical-offset2">
          <tr-text-field
            v-model="code"
            autofocus="true"
            classes="col-lg-5"
            label="Código de autenticação"
            required="true"
            name="code"
            no-margin="true"
            :exact-length="6"
            error-message="Código obrigatório e deve conter exatamente 6 números"
          />
        </div>
      </div>
      <tr-dialog-buttons-container>
        <bg-button
          type="button"
          class="pull-left"
          variant="outline"
          @click.prevent="cancel()"
        >
          Cancelar
        </bg-button>
        <bg-button
          size="md"
          class="pull-right"
          type="submit"
        >
          Desativar
        </bg-button>
      </tr-dialog-buttons-container>
    </tr-form>
  </tr-dialog>
</template>

<script setup>
import { ref } from 'vue';
import { BgButton } from '@transfeeradev/bridge';
import { getMessage } from '@/commons/services/error';
import { useToaster } from '@/commons/composables/useToaster';
import { disableTfa } from '@/commons/services/tfa';

const emit = defineEmits(['deactivate', 'cancel', 'error']);

const toaster = useToaster();

const modal = ref();
const code = ref();

const open = () => {
  modal.value.open();
};

const close = () => {
  modal.value.close();
};

const cancel = () => {
  close();
  emit('cancel');
};

const onSubmit = async () => {
  try {
    await disableTfa(code.value);
    toaster.success('Autenticação em 2 fatores desativada!');
    emit('deactivate');
  } catch (e) {
    emit('error', e);
    toaster.error(getMessage(e));
  }
};

defineExpose({
  open,
  close
});
</script>
