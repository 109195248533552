<template>
  <tr-button
    :on-button-click="showNewUserFullscreenModal"
    label="Criar usuário"
    variant="raised"
    color="primary"
  />
  <users-table
    :key="tableKey"
    @click-user="user => showUserDetailsFullscreenModal(user.id)"
  />
  <new-user-fullscreen-modal
    ref="newUserFullscreenModal"
    :on-change="onCreateNewUser"
  />
  <user-details-fullscreen-modal
    ref="userDetailsFullscreenModal"
    :user-id="selectedUserId"
    :on-hidden="() => (selectedUserId = undefined)"
    :on-change="onUserChange"
  />
</template>

<script>
import NewUserFullscreenModal from '../../pages/NewUserFullscreenModal';
import UserDetailsFullscreenModal from '../../pages/UserDetailsFullscreenModal';
import checkpointResource from '@/commons/resources/checkpoint';
import checkpoint from '@/commons/constants/checkpoint';
import UsersTable from './UsersTable.vue';
import { getMessage } from '@/commons/services/error';
import { isAppAllowed } from '@/commons/services/apps';
import { APPS } from '@/commons/constants/apps';

export default {
  name: 'users-container',
  components: {
    NewUserFullscreenModal,
    UserDetailsFullscreenModal,
    UsersTable
  },
  data: () => ({
    tableKey: 0,
    selectedUserId: null
  }),
  created() {
    if (isAppAllowed(APPS.PAYMENTS.key)) {
      checkpointResource.exists(checkpoint.HAS_FILLED_FIRST_STEPS).then(
        response => {
          if (!response.exists) {
            this.$router.push({
              name: 'FirstStepsPage',
              state: { redirectedFrom: 'AdminUsersPage' }
            });
          }
        },
        error => this.$toaster.error(getMessage(error))
      );
    }
  },
  methods: {
    refresh() {
      this.tableKey++;
    },
    showNewUserFullscreenModal() {
      this.$refs.newUserFullscreenModal.open();
    },
    onCreateNewUser() {
      this.refresh();
      this.$refs.newUserFullscreenModal.close();
    },
    showUserDetailsFullscreenModal(selectedUserId) {
      this.selectedUserId = selectedUserId;
      this.$refs.userDetailsFullscreenModal.open();
    },
    onUserChange() {
      this.refresh();
      this.$refs.userDetailsFullscreenModal.close();
    }
  }
};
</script>
