import contacertaSettingsResource from '@/apps/contacerta/resources/settings';
import appSettingsResource from '@/apps/payments/resources/settings';
import { resourceGateway } from './resourceGateway';

const getResource = () => {
  return resourceGateway({
    appResource: appSettingsResource,
    contacertaResource: contacertaSettingsResource
  });
};

export default {
  /**
   * @returns {Promise<Settings>}
   */
  get: (...args) => getResource().get(...args),
  getApiCredential: (...args) => getResource().getApiCredential(...args),
  createApiCredential: (...args) => getResource().createApiCredential(...args),
  getTwoFactor: (...args) => getResource().getTwoFactor(...args),
  setTwoFactor: (...args) => getResource().setTwoFactor(...args)
};
