<template>
  <div>
    <tr-page-section title="Dados do usuário">
      <div class="row">
        <tr-read-only-text-field
          label="Nome"
          :value="user.name"
        />
      </div>
      <div class="row">
        <tr-read-only-text-field
          label="E-mail"
          :value="user.email"
        />
      </div>
      <div
        class="flex-row flex-start v-center"
      >
        <tr-svg-icon :name="tfaIcon.name" />
        &nbsp;&nbsp;
        <strong>{{ tfaIcon.text }}</strong>
      </div>
    </tr-page-section>
    <tr-page-section title="Produtos">
      <user-apps-selection
        v-model="user.applications"
        :applications="applications"
        :disabled="shouldDisableProfileSelection"
      />
    </tr-page-section>
    <tr-page-section
      v-if="user.applications?.length"
      title="Perfil"
    >
      <user-profile-selection
        :user="user"
        :disabled="shouldDisableProfileSelection"
      />
    </tr-page-section>
    <tr-footer-buttons-container>
      <tr-button
        class="pull-left"
        variant="outlined"
        color="secondary"
        label="Voltar"
        :on-button-click="onCancel"
      />
      <tr-button
        v-if="canChangeUser"
        class="pull-right"
        label="Salvar"
        variant="raised"
        color="primary"
        :on-button-click="updateUser"
      />
      <tr-button
        v-if="canChangeUser"
        class="pull-right margin-right"
        variant="raised"
        size="medium"
        svg-icon-name="ic-delete"
        svg-icon-color="white"
        color="danger"
        :on-button-click="onRemove"
      />
    </tr-footer-buttons-container>
  </div>
</template>

<script>
import { useTfaIcon } from '@/commons/composables/useTfaIcon';
import UserProfileSelection from './UserProfileSelection';
import UserAppsSelection from './UserAppsSelection';

export default {
  name: 'user-details',
  components: {
    UserProfileSelection,
    UserAppsSelection
  },
  props: {
    onCancel: {
      type: Function
    },
    user: {
      type: Object
    },
    canChangeUser: {
      type: Boolean
    },
    shouldDisableProfileSelection: {
      type: Boolean
    },
    onRemove: {
      type: Function
    },
    updateUser: {
      type: Function
    },
    applications: {
      type: Array,
      default: () => []
    }
  },
  setup(props) {
    const { icon: tfaIcon } = useTfaIcon({
      hasTwoFactorEnabled: props.user.has_two_factor_enabled
    });

    return {
      tfaIcon
    };
  }
};
</script>
