<template>
  <router-view
    v-slot="{ Component }"
  >
    <waiting-claim-approval-container />
    <component
      :is="Component"
      @loading="$emit('loading', $event)"
    />
  </router-view>
</template>

<script>
import TopBarContainer from './components/container/TopBarContainer.vue';
import WaitingClaimApprovalContainer from './payments/settings/pixKeys/components/container/notifications/WaitingClaimApprovalContainer.vue';

export default {
  name: 'payments-layout',
  components: {
    TopBarContainer,
    WaitingClaimApprovalContainer
  },
  inheritAttrs: false,
  emits: ['loading'],
  data: () => ({
    isloading: true
  }),
  methods: {
    handleLoading(value) {
      this.isloading = value;
    }
  }
};
</script>
