import config from '@/config';
import fetch from '@/fetch';
import moment from '@transfeeradev/moment';

const BASE_URL = config.url.api.transfeera + '/billet';
const BASE_APP_URL = config.url.api.transfeera + '/app/billet';

export default {
  get: id => fetch(`${BASE_URL}/${id}`),
  getDetails: id => fetch(`${BASE_URL}/${id}/details`),
  hasBlankslate: () => fetch(`${BASE_URL}/hasBlankslate`),
  downloadReceipts: billetIds =>
    fetch(`${BASE_APP_URL}/downloadReceipts`, {
      method: 'POST',
      body: JSON.stringify(billetIds)
    }),
  getFiltered: (initialDate, endDate, page, search, status, bank) => {
    const DATE_FORMAT = 'YYYY-MM-DD';
    const formattedInitialDate = moment(initialDate).format(DATE_FORMAT);
    const formattedEndDate = moment(endDate).format(DATE_FORMAT);
    let url = `${BASE_APP_URL}?endDate=${formattedEndDate}&initialDate=${formattedInitialDate}&page=${page}`;

    if (search) {
      const encodedSearch = encodeURIComponent(search);
      url += `&search=${encodedSearch}`;
    }

    if (status) {
      url += `&status=${status}`;
    }

    if (bank) {
      url += `&bankId=${bank}`;
    }

    return fetch(url);
  },
  getAllWithReceipt: (initialDate, endDate, search, status, bank) => {
    const DATE_FORMAT = 'YYYY-MM-DD';
    const formattedInitialDate = moment(initialDate).format(DATE_FORMAT);
    const formattedEndDate = moment(endDate).format(DATE_FORMAT);
    let url = `${BASE_APP_URL}/withReceipts?initialDate=${formattedInitialDate}&endDate=${formattedEndDate}`;

    if (search) {
      const encodedSearch = encodeURIComponent(search);
      url += `&search=${encodedSearch}`;
    }

    if (status) {
      url += `&status=${status}`;
    }

    if (bank) {
      url += `&bankId=${bank}`;
    }

    return fetch(url);
  },
  isDuplicated: billet => {
    let params;

    if (billet.id) {
      params = `?id=${billet.id}`;
    }

    if (billet.barcode) {
      params = `${params ? `${params}&` : '?'}barcode=${billet.barcode}`;
    }

    return fetch(`${BASE_URL}/duplicated${params}`);
  },
  duplicatedIntegrationId: id => fetch(`${BASE_URL}/${id}/duplicatedIntegrationId`)
};
