import loggedUserResource from '@/commons/resources/loggedUser';
import { roles } from '@transfeeradev/api-enums';
import { APPS } from '../constants/apps';
import { authApp } from '../resources/auth';
import { logout } from './auth';

const APP_ITEMS = [
  {
    authAs: APPS.PAYMENTS.key,
    link: APPS.PAYMENTS.path,
    title: APPS.PAYMENTS.title,
    icon: 'payments',
    disabled: false
  },
  {
    authAs: APPS.CONTACERTA.key,
    link: APPS.CONTACERTA.path,
    title: APPS.CONTACERTA.title,
    icon: 'contacerta',
    disabled: false
  },
  {
    link: APPS.MY_COMPANY.path,
    title: APPS.MY_COMPANY.title,
    icon: 'my_company',
    allowForRoles: [roles.appRoles.ADMIN],
    disabled: false
  },
  {
    link: APPS.DEVELOPER_AREA.path,
    title: APPS.DEVELOPER_AREA.title,
    icon: 'api',
    allowForRoles: [roles.appRoles.ADMIN],
    disabled: false
  },
  {
    link: 'https://status.transfeera.com/',
    icon: 'status_page',
    title: 'Status page',
    disabled: false
  },
  {
    link: 'https://transfeera.com/blog/',
    icon: 'blog',
    title: 'Blog',
    disabled: false
  },
  {
    link: '/logout',
    icon: 'logout',
    title: 'Logout',
    disabled: false
  }
];

const APP_ROUTES = {
  [APPS.CONTACERTA.key]: 'ContaCerta',
  [APPS.PAYMENTS.key]: 'Payments'
};

/**
 * TODO: Temporary solution to get the app title while
 * db is not updated
 */
export const getAppTitleByTitle = title => {
  return (
    {
      'App Transfeera': APPS.PAYMENTS.title,
      'Conta Certa': APPS.CONTACERTA.title,
      AppTransfeera: APPS.PAYMENTS.title,
      ContaCerta: APPS.CONTACERTA.title
    }[title] || title
  );
};

/**
 * TODO: Temporary solution to get the app title while
 * db is not updated
 */
export const getAppNameByTitle = title => {
  return {
    'App Transfeera': APPS.PAYMENTS.key,
    'Conta Certa': APPS.CONTACERTA.key,
    AppTransfeera: APPS.PAYMENTS.key,
    ContaCerta: APPS.CONTACERTA.key
  }[title];
};

export const getApps = () => {
  const userApplications = loggedUserResource.getApplications();

  return APP_ITEMS.filter(app => !app.authAs || userApplications.some(({ name }) => name === app.authAs));
};

export const firstAllowedApp = (names = []) => {
  const userApplications = loggedUserResource.getApplications();

  for (const name of names) {
    const found = userApplications.find(app => app.name === name);
    if (found) {
      return found;
    }
  }
};

export const isAppAllowed = name => {
  const userApplications = loggedUserResource.getApplications();
  return userApplications.some(app => app.name === name);
};

export const isAppTrialEnded = name => {
  const userApplications = loggedUserResource.getApplications();
  return userApplications.find(app => app.name === name)?.is_trial_expired ?? false;
};

export const getAppRoute = () => {
  const currentApp = loggedUserResource.getCurrentApplication();

  if (isAppAllowed(currentApp?.name) && APP_ROUTES[currentApp?.name]) {
    return APP_ROUTES[currentApp?.name];
  }

  return 'LogoutPage';
};

export const getCurrentApp = () => {
  return loggedUserResource.getCurrentApplication();
};

export const authAs = async scope => {
  const currentApp = loggedUserResource.getCurrentApplication();

  if (!currentApp) {
    return logout();
  }

  if (currentApp.name !== scope) {
    const { token } = await authApp({ scope, refresh_token: loggedUserResource.getToken() });
    loggedUserResource.set(token);
  }
};
