<template>
  <tr-page classes="text-center">
    <tr-svg-icon
      classes="vertical-offset4"
      size="large"
      name="ic-lock-close"
    />
    <div class="row vertical-offset2">
      <tr-typography variant="h3">
        Você não tem permissão para acessar esta página
      </tr-typography>
    </div>
    <div class="row vertical-offset1">
      <tr-typography variant="body">
        Peça para seu administrador alterar seu perfil para ter acesso à este conteúdo
      </tr-typography>
    </div>
    <tr-button
      classes="vertical-offset3"
      label="Voltar à página inicial"
      variant="raised"
      color="primary"
      :on-button-click="goToHomePage"
    />
  </tr-page>
</template>

<script>
import * as appService from '@/commons/services/apps';
import { useRouter } from 'vue-router';
export default {
  name: 'forbidden-page',
  emits: ['loading'],
  setup(props, { emit }) {
    emit('loading', false);
    const router = useRouter();

    const goToHomePage = () => {
      router.push({ name: appService.getAppRoute() });
    };

    return {
      goToHomePage
    };
  }
};
</script>
