<template>
  <div class="text-center">
    <quantity-of-transfers-per-month-chart
      v-show="!showBlankslate && showChart"
      :date-range="dateRange"
      :on-load="onLoad"
      :height="'170vh'"
    />

    <tr-read-only-text-field
      v-show="!showBlankslate && !showChart && !!sumQuantity"
      classes="vertical-offset5"
      value-color="blue"
      :dont-use-col-classes="true"
      :value="sumQuantity"
      content-text-variant="h2"
    />
    <img
      v-show="showBlankslate"
      :src="'/assets/img/blankslate/blankslate-column-chart.svg'"
    >
  </div>
</template>

<script>
import QuantityOfTransfersPerMonthChart from '@/apps/payments/payments/indicator/components/presentational/QuantityOfTransfersPerMonthChart';

export default {
  name: 'quantity-of-transfers-per-month-chart-container',
  components: {
    QuantityOfTransfersPerMonthChart
  },
  props: {
    dateRange: {
      required: true
    }
  },
  data: () => ({
    showBlankslate: false,
    showChart: false,
    sumQuantity: 0
  }),
  watch: {
    dateRange: {
      deep: true,
      handler() {
        this.showBlankslate = false;
        this.showChart = false;
        this.sumQuantity = 0;
      }
    }
  },
  methods: {
    onLoad(data) {
      this.showChart = data.length > 1;
      this.sumQuantity = data.reduce((acc, i) => (acc += i.quantity), 0);

      if (!this.sumQuantity) {
        this.showBlankslate = true;
      }
    }
  }
};
</script>
