export default value => `R$ ${String(Number.parseFloat(value).toFixed(2)).replace('.', ',')}`;

export const centsToReal = (value = 0) =>
  new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  })
    .format(value / 100)
    .replace(/\u00A0/, ' ');

export const realToCents = (value = 0) => Math.round((Math.abs(value) / 100) * 10000);
