<template>
  <tr-text-field
    ref="field"
    :label="label"
    :classes="`${classes} input-width--sm`"
    type="date"
    :dont-use-col-classes="dontUseColClasses"
    name="paymentDate"
    :datepicker-orientation="datepickerOrientation"
    :error-message="errorMessage"
    :no-margin="noMargin"
    :custom-validation="validate"
    :model-value="transfer.payment_date"
    :on-value-change="onChange"
  />
</template>

<script>
import { getMessage } from '@/commons/services/error';
import { PaymentDate } from '@transfeeradev/transfer-validation';

export default {
  name: 'transfer-payment-date-field-container',
  props: {
    transfer: {
      required: false,
      type: Object
    },
    datepickerOrientation: {
      required: false,
      type: String,
      default: 'bottom'
    },
    dontUseColClasses: {
      required: false,
      type: Boolean,
      default: false
    },
    paymentDate: {
      required: false
    },
    bankId: {
      required: false,
      type: [Number, String]
    },
    label: {
      required: false,
      type: String,
      default: 'Agendar para'
    },
    classes: {
      required: false,
      type: String,
      default: ''
    },
    onChange: {
      required: true,
      type: Function
    },
    noMargin: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    validationResult: {}
  }),
  computed: {
    errorMessage() {
      return this.validationResult.errorMessage;
    }
  },
  watch: {
    paymentDate() {
      this.validate();
    },
    bankId() {
      this.$refs.field.forceValidation();
    },
    'transfer.payment_method'() {
      this.$refs.field.forceValidation();
    }
  },
  mounted() {
    const result = this.validate();
    if (!result) {
      return this.$refs.field.forceValidation();
    }
  },
  methods: {
    validate() {
      let validation = new PaymentDate({
        transfer: this.transfer
      }).validate();

      if (!validation.isValid) {
        this.validationResult = {
          isValid: false,
          errorMessage: getMessage(validation)
        };
        return false;
      }

      this.validationResult = {
        isValid: true,
        errorMessage: ''
      };
      return true;
    }
  }
};
</script>
