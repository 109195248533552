<template>
  <tr-fullscreen-modal
    ref="modal"
    name="user-details-fullscreen-modal"
    :on-close="onHidden"
  >
    <user-details-container
      :user-id="userId"
      :on-cancel="close"
      :on-change="onChange"
    />
  </tr-fullscreen-modal>
</template>

<script>
import UserDetailsContainer from '../components/container/UserDetailsContainer';

export default {
  name: 'user-details-fullscreen-modal',
  components: {
    UserDetailsContainer
  },
  props: {
    onHidden: {
      required: true,
      type: Function
    },
    userId: {
      required: false,
      type: [String, Number]
    },
    onChange: {
      required: true,
      type: Function
    }
  },
  methods: {
    open() {
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    }
  }
};
</script>
