export default [
  {
    path: '/usuarios-da-empresa',
    redirect: {
      name: 'AdminUsersPage'
    }
  },
  {
    path: '/dados-da-conta',
    redirect: {
      name: 'AccountDataPage'
    }
  },
  {
    path: '/primeiros-passos',
    redirect: {
      name: 'FirstStepsPage'
    }
  },
  {
    path: '/saldo',
    redirect: {
      name: 'StatementPage'
    }
  },
  {
    path: '/faturas',
    redirect: {
      name: 'BillingPage'
    }
  },
  {
    path: '/cliente/faturas',
    redirect: {
      name: 'BillingPage'
    }
  },
  {
    path: '/configuracoes',
    redirect: {
      name: 'ConfigPage'
    }
  },
  {
    path: '/dashboard',
    redirect: {
      name: 'BatchesPage'
    }
  },
  {
    path: '/importacao-de-planilha',
    redirect: {
      name: 'SheetImportPage'
    }
  },
  {
    path: '/lotes',
    redirect: {
      name: 'BatchesPage'
    }
  },
  {
    path: '/indicadores',
    redirect: {
      name: 'IndicatorPage'
    }
  },
  {
    path: '/lotes-aguardando-recebimento',
    redirect: {
      name: 'BatchesWaitingToReceive'
    }
  },
  {
    path: '/transferencias',
    redirect: {
      name: 'TransfersPage'
    }
  },
  {
    path: '/boletos',
    redirect: {
      name: 'BilletsPage'
    }
  },
  {
    path: '/cliente/usuarios-da-empresa',
    redirect: {
      name: 'AdminUsersPage'
    }
  },
  {
    path: '/cliente/dados-da-conta',
    redirect: {
      name: 'MyAccountAccountDataPage'
    }
  },
  {
    path: '/cliente/dados-bancarios',
    redirect: {
      name: 'PaymentsBankAccountsPage'
    }
  },
  {
    path: '/cliente/configuracoes',
    redirect: {
      name: 'PaymentsGeneralSettingsPage'
    }
  },
  {
    path: '/contacerta/configuracoes/dados-da-conta',
    redirect: {
      name: 'MyAccountAccountDataPage'
    }
  },
  {
    path: '/contacerta/configuracoes/geral',
    redirect: {
      name: 'DeveloperAreaCredentialsPage'
    }
  },
  {
    path: '/minha-conta/credenciais',
    redirect: {
      name: 'DeveloperAreaCredentialsPage'
    }
  },
  {
    path: '/financeiro/faturas',
    redirect: {
      name: 'BillingPage'
    }
  }
];
