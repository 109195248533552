import config from '@/config';
import fetch from '@/fetch';

let cache;

const BASE_URL = config.url.api.transfeera + '/bankAccount';
export default {
  getTransfeeraBankAccounts: () => {
    if (cache) {
      return new Promise(resolve => resolve(cache));
    }
    return fetch(`${BASE_URL}/payment`).then(transfeeraBankAccounts => {
      cache = transfeeraBankAccounts;
      return cache;
    });
  }
};
