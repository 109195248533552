<template>
  <tr-page>
    <tr-page-header
      title="Seus recebedores"
      :on-search="onSearchByText"
      :hide-button-for-roles="hideNewDbaActionForRoles"
      search-placeholder="Nome, CPF, ag. ou conta"
      :on-add-click="openReceiverManagementFullscreenModal"
    >
      <template #center-filter>
        <bank-dropdown-filter-container :on-search="onSearchByBank" />
      </template>
    </tr-page-header>
    <receivers-container
      ref="receiversContainer"
      :selected-bank-id="selectedBankId"
      :selected-status="selectedStatus"
      :searched-text="searchedText"
    />
    <receiver-management-fullscreen-modal
      ref="receiverManagementFullscreenModal"
      :on-change="refresh"
    />
  </tr-page>
</template>

<script>
import ReceiversContainer from '@/apps/payments/payments/receivers/components/container/ReceiversContainer.vue';
import BankDropdownFilterContainer from '@/apps/payments/payments/bank/components/container/BankDropdownFilterContainer.vue';
import ReceiverManagementFullscreenModal from '@/apps/payments/payments/receivers/pages/ReceiverManagementFullscreenModal.vue';
import { roles } from '@transfeeradev/api-enums';

export default {
  name: 'receivers-page',
  components: {
    ReceiversContainer,
    BankDropdownFilterContainer,
    ReceiverManagementFullscreenModal
  },
  data: () => ({
    selectedBankId: null,
    selectedStatus: null,
    searchedText: null,
    hideNewDbaActionForRoles: roles.appRoles.ANALYST
  }),
  methods: {
    refresh() {
      this.$refs.receiversContainer.refresh();
    },
    openReceiverManagementFullscreenModal() {
      this.$refs.receiverManagementFullscreenModal.open();
    },
    onSearchByText(searchedText) {
      this.searchedText = searchedText;
    },
    onSearchByBank(bankId) {
      this.selectedBankId = bankId;
    },
    onSearchByStatus(status) {
      this.selectedStatus = status;
    }
  }
};
</script>
