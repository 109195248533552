<template>
  <tr-dialog
    ref="modal"
    name="transfer-refund-confirmation-dialog"
    title="Editar dados de pagamento"
  >
    <div>
      <tr-typography
        variant="h5"
        strong="true"
      >
        Você confirma a devolução deste pagamento?
      </tr-typography>
      <tr-typography
        v-if="dbaHasMoreThanOneTransfer"
        classes="vertical-offset2 inline-block"
        variant="h5"
      >
        Existem mais pagamentos para <tr-typography strong="true">
          {{ destinationBankAccount.name }}
        </tr-typography> e
        todos os pagamentos serão estornados.
      </tr-typography>
      <tr-typography
        v-else
        classes="vertical-offset2 inline-block"
        variant="h5"
      >
        O valor de <tr-typography strong="true">
          {{ $format.currency(transfer.value) }}
        </tr-typography> relativo à este
        pagamento, retornará para você assim que o lote for finalizado.
      </tr-typography>
    </div>
    <template #buttons>
      <tr-button
        classes="pull-right"
        :on-button-click="onConfirm"
        label="Cancelar pagamento"
        variant="raised"
        color="primary"
        :disabled="confirmDisabled"
      />
    </template>
  </tr-dialog>
</template>

<script>
export default {
  name: 'transfer-edition-confirmation-dialog',
  props: ['onConfirm', 'transfer', 'dbaHasMoreThanOneTransfer', 'destinationBankAccount', 'confirmDisabled'],
  methods: {
    open() {
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    }
  }
};
</script>
