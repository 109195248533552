<template>
  <main class="receivables-wrapper">
    <tr-page-header title="Seus recebimentos">
      <template #center-filter>
        <div class="row indicator-page__date-filter">
          <div class="col-lg-10">
            <tr-month-picker-button :on-filter="onSearchByDatePeriod" />
          </div>
        </div>
      </template>
      <template #right-filter>
        <bg-button @click="onNavigateToPaymentLink">
          Criar Link de Pagamento
        </bg-button>
      </template>
    </tr-page-header>
    <tr-loading
      v-if="isSummaryLoading"
      classes="center-h-and-v width-100"
    />
    <div
      v-else
      class="receivable-summary__container"
      role="list"
    >
      <receivable-summary
        role="listitem"
        status="received"
        :amount="receivablesSummary?.received"
      />
      <receivable-summary
        role="listitem"
        status="authorized"
        :amount="receivablesSummary?.authorized"
        :disabled="!hasCardCharge"
        :description="!hasCardCharge ? 'Fale com a suporte e habilite cartão de crédito para impulsionar suas vendas.' : ''"
      />
      <receivable-summary
        role="listitem"
        status="pending"
        :amount="receivablesSummary?.pending"
      />
      <receivable-summary
        role="listitem"
        status="canceled"
        :amount="receivablesSummary?.canceled"
      />
      <receivable-summary
        role="listitem"
        status="unauthorized"
        :amount="receivablesSummary?.unauthorized"
      />
    </div>
    <div class="receivables-export-and-filter">
      <div class="receivables-filters">
        <bg-filters
          v-model="selectedFilters"
          :label="`Filtros ${filtersCount ? filtersCount : ''}`"
          @update:modelValue="onSubmitFilters"
        >
          <bg-filter
            key="status"
            label="Status"
          >
            <bg-filter-option
              v-for="(statusName, statusKey) in receivableStatusFilter"
              :key="statusKey"
              :label="statusName"
            />
          </bg-filter>
          <bg-filter
            key="paymentMethod"
            label="Método"
          >
            <bg-filter-option
              v-for="(name, key) in paymentMethodNames"
              :key="key"
              :label="name"
            />
          </bg-filter>
        </bg-filters>
        <tr-form
          name="searchForm"
          :on-valid-submit="onSubmitFilters"
        >
          <tr-text-field
            v-model="searchTerm"
            name="search"
            classes="col-lg-12"
            no-margin="true"
            placeholder="Pagador ou valor"
            input-search="true"
          />
        </tr-form>
      </div>
      <receivable-export-button
        :statuses="selectedFilters?.status"
        :payment-types="selectedFilters?.paymentMethod"
        :search="searchTerm"
        :created-at-gte="period.startDate.toISOString()"
        :created-at-lte="period.endDate.toISOString()"
      />
    </div>
    <button
      v-if="filtersCount"
      class="reset-button receivables-reset-filters-button"
      @click="onCleanFilter"
    >
      <bg-text
        is="u"
        weight="bold"
        type="body-sm"
      >
        Limpar filtros
      </bg-text>
    </button>
    <receivables-table
      :is-end-date-out-of-bounds="isEndDateOutOfBounds"
      :receivables="receivables"
      :on-open-refund-dialog="openRefundDialog"
      :on-open-details-dialog="openDetailsDialog"
    />
    <receivable-details-dialog
      ref="detailsDialog"
      @refund-click="openRefundDialog"
    />
    <receivable-refund-dialog
      :id="refundShape.id"
      ref="refundDialog"
      :value="refundShape.value"
      @save="onRefund"
    />
    <tr-numeric-pagination
      class="receivables-pagination"
      :current-page="pagination.currentPage"
      :page-size="pagination.itemsPerPage"
      :total="pagination.totalItems"
      :on-current-page-change="onPageChange"
    />
  </main>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import ReceivablesTable from '@/apps/payin/receivables/components/ReceivablesTable.vue';
import ReceivableRefundDialog from '@/apps/payin/receivables/components/ReceivableRefundDialog.vue';
import ReceivableSummary from '@/apps/payin/receivables/components/ReceivableSummary.vue';
import ReceivableDetailsDialog from '@/apps/payin/receivables/components/ReceivableDetailsDialog.vue';
import moment from '@transfeeradev/moment/src/plugins/moment-business-day';
import ReceivableExportButton from '@/apps/payin/receivables/components/ReceivableExportButton.vue';
import checkpoint from '@/commons/constants/checkpoint';
import checkpointResource from '@/commons/resources/checkpoint';
import { useTour } from '@/commons/composables/useTour';
import { receivableStatusFilter } from '@/apps/payin/receivables/constants/receivableStatusFilter';
import { paymentMethodNames } from '@/apps/payin/receivables/constants/paymentMethod';
import { BgFilters, BgFilter, BgFilterOption, BgText, BgButton } from '@transfeeradev/bridge';
import { useErrorHandler } from '@/commons/composables/useErrorHandler';
import { listReceivables } from '@/apps/payin/receivables/services/receivables';
import { getSummary } from '@/apps/payin/receivables/services/summary';
import { captureException } from '@sentry/vue';
import { usePolling } from '@/commons/composables/usePolling';
import { receivableStatus } from '../constants/receivableStatus';
import { useRouter } from 'vue-router';
import { useDebounceFn, watchOnce } from '@vueuse/core';
import settingsResource from '@/apps/payments/resources/settings';

const router = useRouter();
const { handleError } = useErrorHandler();

const emit = defineEmits(['loading']);

const refundDialog = ref();
const detailsDialog = ref();
/** @type {receivables: Receivable[], metadata: {pagination: {itemsPerPage: number, totalItems: number}}}} */
const receivables = ref([]);
/** @type {PayinSummary} */
const receivablesSummary = ref({});

const pagination = ref({ totalItems: null, itemsPerPage: 10, currentPage: 0 });
const searchTerm = ref();
const statusFilter = ref();
const paymentTypeFilter = ref();
const isEndDateOutOfBounds = ref(false);
const isSummaryLoading = ref(true);
const selectedFilters = ref({
  status: [],
  paymentMethod: []
});
const settings = ref();

const hasCardCharge = computed(() => {
  return settings.value?.hasCardCharge;
});

const openRefundDialog = receivableData => {
  refundDialog.value.open(receivableData);
};

const openDetailsDialog = id => {
  detailsDialog.value.open(id);
};

const closeDetailsDialog = () => {
  detailsDialog.value.close();
};

const onNavigateToPaymentLink = () => {
  checkpointResource.create(checkpoint.PAYMENT_LINK_ACCESSED_THROUGH_RECEIVABLES);
  router.push({ name: 'PaymentLinkPage' });
};

const period = ref({
  startDate: moment().startOf('month'),
  endDate: moment().endOf('month')
});

const onSearchByDatePeriod = (startDate, endDate) => {
  pagination.value.currentPage = 0;
  period.value = {
    startDate,
    endDate
  };
};

const refundShape = {
  id: '',
  value: ''
};

const onPageChange = page => {
  pagination.value.currentPage = page;
};

const onSubmitFilters = () => {
  pagination.value.currentPage = 0;
  checkRefundingStatus();
  fetchSumary();
};

const onCleanFilter = () => {
  selectedFilters.value = {
    status: [],
    paymentMethod: []
  };
};

const onRefund = () => {
  checkRefundingStatus();
  closeDetailsDialog();
};

const filtersCount = computed(() => {
  return Object.values(selectedFilters.value)
    .map(values => Object.values(values).filter(selected => selected).length)
    .reduce((acc, curr) => acc + curr, 0);
});

const setupTour = async () => {
  const { defineSteps } = useTour({
    checkpointId: checkpoint.PAYIN_DASHBOARD_TOUR_VIEWED,
    elementSelectors: [
      '.receivable-summary__container',
      'table > tbody > tr:nth-child(1) > td div > button[aria-label="Devolver"]',
      'table > tbody > tr:nth-child(1) > td div > button[aria-label="Ver mais"]'
    ]
  });

  defineSteps(
    ([step1El, step2El, step3El]) => [
      {
        element: step1El,
        title: 'Visão geral dos status',
        intro: 'Tenha uma visão geral de cada status dos seus recebimentos: recebidos, pendentes e cancelados.'
      },
      {
        element: step2El,
        title: 'Devolução Simples',
        intro: 'Faça um reembolso integral ou parcial para quem te pagou.'
      },
      {
        element: step3El,
        title: 'Veja mais detalhes',
        intro: 'Obtenha informações detalhadas sobre cada recebível para um gerenciamento mais completo.'
      }
    ],
    {
      doneLabel: 'Finalizar'
    }
  ).start();
};

const fetchReceivables = async () => {
  try {
    const response = await listReceivables({
      page: pagination.value.currentPage,
      pageSize: pagination.value.itemsPerPage,
      statuses: selectedFilters.value?.status,
      paymentTypes: selectedFilters.value?.paymentMethod,
      search: searchTerm.value,
      startDate: period.value.startDate,
      endDate: period.value.endDate
    });

    receivables.value = response.receivables;
    pagination.value.itemsPerPage = response.metadata.pagination.itemsPerPage;
    pagination.value.totalItems = response.metadata.pagination.totalItems;
    isEndDateOutOfBounds.value = response.isEndDateOutOfBounds;
  } catch (e) {
    handleError(e, 'Ocorreu um erro ao buscar os recebimentos');
  } finally {
    emit('loading', false);
  }
};

watchOnce(
  () => receivables.value,
  () => {
    if (receivables.value.length > 0) {
      setupTour().catch(captureException);
    }
  }
);

const fetchSumary = useDebounceFn(async () => {
  isSummaryLoading.value = true;
  try {
    const response = await getSummary({
      statuses: selectedFilters.value?.status,
      paymentTypes: selectedFilters.value?.paymentMethod,
      search: searchTerm.value,
      startDate: period.value.startDate,
      endDate: period.value.endDate
    });
    receivablesSummary.value = response;
  } catch (e) {
    handleError(e, 'Ocorreu um erro ao buscar os totalizadores');
  } finally {
    isSummaryLoading.value = false;
  }
}, 300);

const { startPolling, stopPolling, pollingError } = usePolling({
  intervalInSeconds: 5
});

const checkRefundingStatus = useDebounceFn(() => {
  stopPolling();

  startPolling(fetchReceivables, {
    immediate: true,
    keepPollingWhile: () => receivables.value.some(receivable => receivable.status === receivableStatus.REFUNDING)
  });
}, 300);

watch([() => statusFilter.value, () => paymentTypeFilter.value, () => period.value], () => {
  checkRefundingStatus();
  fetchSumary();
});

watch([() => pagination.value.currentPage], () => {
  checkRefundingStatus();
});

watch(pollingError, error => {
  if (error) {
    stopPolling();
    handleError(error);
  }
});

const getSettings = async () => {
  try {
    const response = await settingsResource.get();
    settings.value = response;
  } catch (e) {
    handleError(e, 'Ocorreu um erro ao buscar as configurações.');
  } finally {
    emit('loading', false);
  }
};

getSettings();
checkRefundingStatus();
fetchSumary();
</script>

<style scoped>
.receivables-wrapper {
  display: flex;
  flex-direction: column;
  margin: var(--bg-spacing-md);
}

.receivables-export-and-filter {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.receivables-filters {
  display: flex;
  align-items: flex-end;
  margin-top: var(--bg-spacing-lg);
}

.receivables-reset-filters-button {
  width: fit-content;
  color: var(--bg-blue-normal);
  margin-top: var(--bg-spacing-sm);
}

.receivable-summary__container {
  display: flex;
  flex-direction: row;
  gap: var(--bg-spacing-sm);
}

.receivable-summary__container :deep(.bg-card) {
  box-shadow: none;
}

.receivables-pagination {
  margin-top: var(--bg-spacing-sm) !important;
}
</style>
