<template>
  <page
    v-model:active-tab="activeTab"
    title="Minha empresa"
    :is-table="[usersRoute, billingRoute].includes(activeTab)"
  >
    <template #tabs>
      <bg-tab :key="companyRoute">
        Dados da empresa
      </bg-tab>
      <bg-tab :key="usersRoute">
        Usuários
      </bg-tab>
      <bg-tab :key="billingRoute">
        Faturas
      </bg-tab>
    </template>
    <template #panels>
      <bg-tab-panel :key="companyRoute">
        <company-data-container />
      </bg-tab-panel>
      <bg-tab-panel :key="usersRoute">
        <users-container />
      </bg-tab-panel>
      <bg-tab-panel :key="billingRoute">
        <billing-container />
      </bg-tab-panel>
    </template>
  </page>
</template>

<script setup>
import { BgTab, BgTabPanel } from '@transfeeradev/bridge';
import CompanyDataContainer from '../company/components/container/CompanyDataContainer.vue';
import UsersContainer from '@/apps/admin/users/components/container/UsersContainer.vue';
import BillingContainer from '@/apps/admin/billing/components/container/BillingContainer.vue';
import Page from '@/commons/components/presentational/Page.vue';
import { ref, watch } from 'vue';
import { useRouter } from 'vue-router';

const emit = defineEmits(['loading']);
const router = useRouter();
const activeTab = ref(router.currentRoute.value.name);
const companyRoute = 'AdminCompanyDataPage';
const usersRoute = 'AdminUsersPage';
const billingRoute = 'BillingPage';

emit('loading', false);

watch(activeTab, name => {
  router.push({ name });
});
</script>
