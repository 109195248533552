<template>
  <tr-text-field
    v-if="hasBasicValidationAccess"
    :model-value="modelValue"
    name="validation_type"
    classes="inline-block margin-right"
    type="radio"
    :dont-use-col-classes="true"
    :required="true"
    :radio-value="TYPES.BASIC"
    @update:modelValue="$emit('update:modelValue', $event)"
  >
    <template #label>
      <span>Validação básica</span>
    </template>
  </tr-text-field>
  <tr-text-field
    v-if="hasMicroDepositValidationAccess"
    :model-value="modelValue"
    name="validation_type"
    classes="inline-block margin-right"
    type="radio"
    :dont-use-col-classes="true"
    :required="true"
    :radio-value="TYPES.MICRO_DEPOSIT"
    @update:modelValue="$emit('update:modelValue', $event)"
  >
    <template #label>
      <span>Validação micro depósito</span>
    </template>
  </tr-text-field>
</template>

<script>
import settingsResource from '@/apps/contacerta/resources/settings';
import { contacerta } from '@transfeeradev/api-enums';
import { computed, defineComponent, ref } from 'vue';

const TYPES = contacerta.type;

export default defineComponent({
  props: {
    modelValue: {
      type: String
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const companySettings = ref({});

    const hasBasicValidationAccess = computed(() => {
      return companySettings.value?.uses_basic_validation === true;
    });

    const hasMicroDepositValidationAccess = computed(() => {
      return companySettings.value?.uses_micro_deposit === true;
    });

    settingsResource.getCompanySettings().then(result => {
      companySettings.value = result || {};

      if (!props.modelValue) {
        emit('update:modelValue', hasBasicValidationAccess.value ? TYPES.BASIC : TYPES.MICRO_DEPOSIT);
      }
    });

    return {
      TYPES,
      hasBasicValidationAccess,
      hasMicroDepositValidationAccess
    };
  }
});
</script>
