<template>
  <div class="row">
    <tr-text-field
      v-model="validationData.pix_key"
      name="pix_key"
      error-message="A chave Pix deve ser válida"
      :required="true"
      :label="KEY_TYPE_PROPS_MAP[keyType]?.label"
      :pattern="KEY_TYPE_PROPS_MAP[keyType]?.pattern"
      :mask-format="KEY_TYPE_PROPS_MAP[keyType]?.mask"
      classes="col-sm-12 col-md-6 col-lg-4"
    />
    <cpf-cnpj-field-container
      v-if="type === TYPES.MICRO_DEPOSIT"
      v-model:cpf-cnpj="validationData.cpf_cnpj"
      name="cpf_cnpj"
      label="CPF/CNPJ a validar (opcional)"
      classes="col-sm-12 col-md-6 col-lg-4"
      error-message="CPF ou CNPJ inválido"
      :on-change="value => (validationData.cpf_cnpj = value)"
      :required="false"
    />
  </div>
</template>

<script>
import CpfCnpjFieldContainer from '@/commons/components/container/CpfCnpjFieldContainer';
import regexes from '@transfeeradev/api-enums/regexes';
import masks from '@/commons/constants/masks';
import { contacerta } from '@transfeeradev/api-enums';
import { keyType } from '@transfeeradev/api-enums/pix';
const TYPES = contacerta.type;

const KEY_TYPE_PROPS_MAP = {
  [keyType.EMAIL]: {
    label: 'Chave Pix (E-mail)',
    pattern: regexes.PIX_EMAIL
  },
  [keyType.TELEFONE]: {
    label: 'Chave Pix (Telefone)',
    pattern: regexes.MOBILE_PHONE,
    mask: masks.MOBILE_PHONE
  },
  [keyType.CHAVE_ALEATORIA]: {
    label: 'Chave Pix (Aleatória)',
    pattern: regexes.PIX_EVP
  }
};

export default {
  name: 'validation-pix-key-form',
  components: { CpfCnpjFieldContainer },
  props: {
    validationData: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    keyType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      KEY_TYPE_PROPS_MAP,
      TYPES
    };
  }
};
</script>
