<template>
  <tr-form
    name="companyDataForm"
    :on-valid-submit="submit"
  >
    <div class="row">
      <div class="col-md-7">
        <company-form-container ref="companyFormContainer" />
      </div>
      <div
        v-if="hasLogoUpload"
        class="col-md-5"
      >
        <tr-page-section
          title="Imagem da empresa"
          :no-margin="true"
        >
          <logo-upload-container />
        </tr-page-section>
      </div>
    </div>
    <page-footer>
      <template #right>
        <tr-button
          type="submit"
          label="Salvar"
          variant="raised"
          color="primary"
        />
      </template>
    </page-footer>
  </tr-form>
</template>

<script>
import { useToken } from '@/commons/composables/useToken';
import { getMessage } from '@/commons/services/error';
import { applications } from '@transfeeradev/api-enums';
import { computed, defineComponent, inject, ref } from 'vue';
import CompanyFormContainer from './CompanyFormContainer';
import LogoUploadContainer from './LogoUploadContainer';
import PageFooter from '@/commons/components/presentational/PageFooter.vue';

export default defineComponent({
  name: 'company-data-container',
  components: {
    LogoUploadContainer,
    CompanyFormContainer,
    PageFooter
  },
  props: {
    onSave: {
      type: Function
    }
  },
  setup(props) {
    const $toaster = inject('$toaster');
    const { hasApplicationAccess } = useToken(applications.APP);
    const companyFormContainer = ref();
    const hasLogoUpload = computed(() => hasApplicationAccess.value);

    const submit = () => {
      return companyFormContainer.value.onSubmit().then(
        () => {
          $toaster.success('Dados salvos com sucesso');
          props.onSave?.();
        },
        error => $toaster.error(getMessage(error))
      );
    };

    return {
      submit,
      hasLogoUpload,
      companyFormContainer
    };
  }
});
</script>
