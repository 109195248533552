<template>
  <router-view
    v-slot="{ Component }"
  >
    <div>
      <component
        :is="Component"
        @loading="$emit('loading', $event)"
      />
    </div>
  </router-view>
</template>

<script>
export default {
  name: 'admin-layout',
  inheritAttrs: false,
  emits: ['loading']
};
</script>
