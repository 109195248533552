<template>
  <tr-text-field
    v-model="internalIntegrationId"
    label="ID de integração (opcional)"
    name="integrationId"
    :required="false"
    :warning="hasWarning"
    :no-margin="true"
    input-classes="input-width--sm"
  />
</template>

<script>
import transferResource from '@/apps/payments/payments/transfer/resources/transfer';
import billetResource from '@/apps/payments/payments/billet/resources/billet';

export default {
  name: 'integration-id-field-container',
  props: {
    integrationId: {
      required: false,
      type: String,
      default: ''
    },
    itemId: {
      required: false,
      type: [String, Number],
      default: ''
    },
    onChange: {
      required: true,
      type: Function
    },
    type: {
      required: false,
      type: String,
      default: 'TRANSFER'
    }
  },
  data: () => ({
    hasWarning: false,
    hasTyped: false
  }),
  computed: {
    internalIntegrationId: {
      get() {
        return this.integrationId;
      },
      set(newIntegrationId) {
        this.hasTyped = true;
        this.hasWarning = false;
        this.onChange(newIntegrationId);
      }
    }
  },
  watch: {
    integrationId() {
      if (!this.hasTyped) {
        this.isIdDuplicated();
      }
    }
  },
  created() {
    if (this.integrationId) {
      this.isIdDuplicated();
    }
  },
  methods: {
    isIdDuplicated() {
      if (!this.itemId || !this.integrationId) {
        return false;
      } else {
        if (this.type === 'BILLET') {
          billetResource.duplicatedIntegrationId(this.itemId).then(response => {
            this.hasWarning = Boolean(response && response.isDuplicated);
          });
        } else {
          transferResource.duplicatedIntegrationIds(this.itemId).then(response => {
            this.hasWarning = Boolean(response && response.isDuplicated);
          });
        }
      }
    }
  }
};
</script>
