<template>
  <cpf-cnpj-field-container
    ref="field"
    :name="name"
    :label="label"
    :cpf-cnpj="cpfCnpj"
    :classes="classes"
    :required="required"
    :default-error-message="defaultErrorMessage"
    :check-blacklist="checkBlacklist"
    :get-blacklist="getBlacklist"
    @update:cpfCnpj="onUpdateCpfCnpj"
  />
</template>

<script>
import cpfCnpjResource from '@/apps/payments/payments/dba/resources/cpfCnpj';
import CpfCnpjFieldContainer from '@/commons/components/container/CpfCnpjFieldContainer';

export default {
  name: 'dba-cpf-cnpj-field-container',
  components: {
    CpfCnpjFieldContainer
  },
  props: {
    cpfCnpj: {
      required: false,
      type: String
    },
    label: {
      required: false,
      type: String
    },
    classes: {
      required: false,
      type: String
    },
    onChange: {
      required: false,
      type: Function
    },
    name: {
      required: false,
      type: String
    },
    defaultErrorMessage: {
      required: false,
      type: String
    },
    checkBlacklist: {
      required: false,
      type: Boolean,
      default: true
    },
    required: {
      required: false,
      type: Boolean,
      default: true
    }
  },
  emits: ['update:cpfCnpj'],
  methods: {
    onUpdateCpfCnpj(newCpfCnpj) {
      this.$emit('update:cpfCnpj', newCpfCnpj);
      if (this.onChange) {
        this.onChange(newCpfCnpj);
      }
    },
    getBlacklist() {
      return cpfCnpjResource.getBlacklist();
    },
    focus() {
      this.$refs.field.focus();
    }
  }
};
</script>
