<template>
  <div>
    <div class="row">
      <div :class="`col-lg-${fullSize ? '2' : '1'}`">
        <bank-image-container
          :bank-image="bank && bank.image"
          size="medium"
        />
      </div>
      <tr-read-only-text-field
        :classes="`col-lg-${fullSize ? '6' : '3'} col-xs-12 no-padding-left`"
        label="Banco"
        :value="bank && bank.name"
      />
      <tr-read-only-text-field
        v-if="operationType"
        :classes="`col-lg-${fullSize ? '4' : '8'}`"
        label="Tipo de operação"
        :value="operationType"
      />
    </div>
    <div class="row">
      <tr-read-only-text-field
        :classes="`col-lg-${fullSize ? '6' : '3'} col-xs-12`"
        label="Tipo da conta"
        :no-margin="hideNameAndDocument && noMarginOnLastLine"
        :value="
          accountType[bankAccount.account_type] ? accountType[bankAccount.account_type] : accountType.CONTA_CORRENTE
        "
      />
      <tr-read-only-text-field
        :classes="`col-lg-${fullSize ? '3' : '2'} col-xs-12`"
        label="Agência"
        :no-margin="hideNameAndDocument && noMarginOnLastLine"
        :value="agency"
      />
      <tr-read-only-text-field
        :classes="`col-lg-${fullSize ? '3' : '7'} col-xs-12`"
        :label="`Conta ${
          accountTypeLastName[bankAccount.account_type] ? accountTypeLastName[bankAccount.account_type] : 'corrente'
        }`"
        :no-margin="hideNameAndDocument && noMarginOnLastLine"
        :value="account"
      />
    </div>
    <div
      v-if="!hideNameAndDocument"
      class="row"
    >
      <tr-read-only-text-field
        :classes="`col-lg-${fullSize ? '6' : '4'} col-xs-12`"
        :no-margin="noMarginOnLastLine"
        label="Razão social"
        :value="bankAccount.name || transfeeraData.COMPANY_NAME"
      />
      <tr-read-only-text-field
        :classes="`col-lg-${fullSize ? '6' : '6'} col-xs-12`"
        :no-margin="noMarginOnLastLine"
        label="CNPJ"
        :value="cpfCnpj"
      />
    </div>
  </div>
</template>

<script>
import BankImageContainer from '@/apps/payments/payments/bank/components/container/BankImageContainer';
import formatCpfCnpj from '@/commons/formatters/cpfCnpj';
import accountType from '@/commons/constants/accountType';
import accountTypeLastName from '@/commons/constants/accountTypeLastName';
import { transfeeraData } from '@transfeeradev/api-enums';

export default {
  name: 'view-bank-account',
  components: {
    BankImageContainer
  },
  props: {
    bankAccount: {
      required: false,
      type: Object,
      default: () => ({})
    },
    fullSize: {
      required: false,
      type: Boolean,
      default: false
    },
    hideNameAndDocument: {
      required: false,
      type: Boolean,
      default: false
    },
    noMarginOnLastLine: {
      required: false,
      type: Boolean,
      default: false
    },
    bank: {
      required: false,
      type: Object,
      default: () => ({})
    },
    operationType: {
      required: false,
      type: String,
      default: ''
    }
  },
  data: () => ({
    transfeeraData,
    accountType,
    accountTypeLastName
  }),
  computed: {
    cpfCnpj() {
      return formatCpfCnpj(this.bankAccount.cpfCnpj || transfeeraData.CNPJ);
    },
    agency() {
      const agency = this.bankAccount.agency || '';
      return agency;
    },
    account() {
      const account = this.bankAccount.account || '';
      const accountDigit = this.bankAccount.account_digit ? ` - ${this.bankAccount.account_digit}` : '';
      return account + accountDigit;
    }
  }
};
</script>
