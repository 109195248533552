<template>
  <div class="row">
    <receivers-autocomplete-container
      v-if="showSearch"
      auto-focus="true"
      label="Buscar um recebedor cadastrado"
      :no-margin="true"
      :on-receiver-select="onChangeReceiver"
    />
    <div
      v-else
      class="col-sm-12"
    >
      <tr-button
        v-tooltip.right="'Buscar um recebedor cadastrado'"
        data-tour="receivers-search"
        class="pull-left small-icon-button"
        aria-label="Buscar um recebedor cadastrado"
        variant="raised"
        size="small"
        svg-icon-name="ic-search"
        svg-icon-color="white"
        color="primary"
        :on-button-click="onSearch"
      />
    </div>
  </div>
  <dba-form-container
    ref="form"
    :dba="dba"
    :transfer="transfer"
    :on-bank-change="onBankChange"
    :on-dba-type-change="onDbaTypeChange"
    :should-make-complex-validations="shouldMakeComplexValidations"
  />
</template>

<script>
import ReceiversAutocompleteContainer from '@/apps/payments/payments/receivers/components/container/ReceiversAutocompleteContainer.vue';
import DbaFormContainer from '@/apps/payments/payments/dba/components/container/DbaFormContainer.vue';
import transferWizardState from '@/apps/payments/payments/transfer/constants/wizardState';
import dbaModel from '@/apps/payments/payments/dba/models/dba';
import transferModel from '@/apps/payments/payments/transfer/models/transfer';
import { useTour } from '@/commons/composables/useTour';
import checkpoint from '@/commons/constants/checkpoint';

export default {
  name: 'dba-wizard',
  components: {
    ReceiversAutocompleteContainer,
    DbaFormContainer
  },
  props: {
    dba: {
      required: false,
      type: Object,
      default: dbaModel.get
    },
    transfer: {
      required: false,
      type: Object,
      default: transferModel.get
    },
    wizardState: {
      required: false,
      type: String,
      default: transferWizardState.FORM
    },
    onBankChange: {
      required: false,
      type: Function
    },
    shouldMakeComplexValidations: {
      required: false,
      type: Boolean,
      default: false
    },
    onChangeDba: {
      required: true,
      type: Function
    },
    onDbaTypeChange: {
      required: true,
      type: Function
    }
  },
  data: () => ({
    showSearch: false
  }),
  mounted() {
    const { defineSteps } = useTour({
      checkpointId: checkpoint.RECEIVERS_SEARCH_VIEWED,
      elementsIds: ['receivers-search']
    });

    defineSteps(
      ([searchEl]) => [
        {
          element: searchEl,
          title: 'Busque aqui seus recebedores',
          position: 'bottom-right-aligned',
          intro: `
            Sempre que precisar, você pode buscar por pessoas ou 
            empresas cadastradas na lista de <a target="_blank" href="/pagamentos/recebedores">recebedores</a>.
          `
        }
      ],
      {
        doneLabel: 'Entendi'
      }
    ).start();
  },
  methods: {
    onSearch() {
      this.showSearch = true;
    },
    onChangeReceiver(receiver) {
      this.onChangeDba(
        {
          ...receiver,
          id: null
        },
        { clear: true }
      );

      this.showSearch = false;
    }
  }
};
</script>
