<template>
  <teleport to="#app-notification">
    <tr-alert-notification
      v-if="hasOverdue && !isBlocked && isAdmin"
      :full-width="true"
      type="danger"
    >
      <tr-typography>
        Você está com faturas em atraso.
        <router-link
          class="underlined cursor-pointer"
          :to="{ name: 'BillingPage' }"
        >
          Veja suas faturas
        </router-link> e
        regularize, evitando bloqueios.
      </tr-typography>
    </tr-alert-notification>
  </teleport>
</template>

<script>
import billingResource from '@/apps/admin/billing/resources/billing';
import loggedUserResource from '@/commons/resources/loggedUser';
import { getMessage } from '@/commons/services/error';
import { companyStatus, roles } from '@transfeeradev/api-enums';

export default {
  name: 'billing-overdue-notification',
  data: () => ({
    hasOverdue: false,
    isBlocked: false,
    isAdmin: false
  }),
  async created() {
    const loggedUser = loggedUserResource.get();
    this.isBlocked = loggedUser.company.status === companyStatus.BLOCKED;
    this.isAdmin = loggedUser.profile === roles.appRoles.ADMIN.value;

    if (this.isBlocked) {
      return;
    }

    try {
      const result = await billingResource.hasOverdue();
      this.hasOverdue = result.data;
    } catch (error) {
      this.$toaster.error(getMessage(error));
    }
  }
};
</script>
