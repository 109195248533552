<template>
  <tr-page>
    <tr-page-header title="Aguardando recebimento" />
    <batches-waiting-to-receive-container />
  </tr-page>
</template>

<script>
import BatchesWaitingToReceiveContainer from '@/apps/payments/payments/batch/components/container/BatchesWaitingToReceiveContainer.vue';

export default {
  name: 'batches-waiting-to-receive',
  components: {
    BatchesWaitingToReceiveContainer
  }
};
</script>
