import { roles } from '@transfeeradev/api-enums';
import jwtDecode from 'jwt-decode';

const TOKEN_NAME = 'id_token';

/**
 * @returns {LoggedUser}
 */
const get = () => {
  const token = getToken();

  if (!token) {
    return { name: '', company: {} };
  } else {
    return jwtDecode(token);
  }
};

const getToken = () => {
  return localStorage.getItem(TOKEN_NAME);
};

const set = token => {
  localStorage.setItem(TOKEN_NAME, token);
};

/**
 * @returns {LoggedUserApplication[]}
 */
const getApplications = () => {
  return get()?.allowed_applications || [];
};

/**
 * @returns {LoggedUserApplication}
 */
const getCurrentApplication = () => {
  return getApplications().find(app => get().application.id === app.id);
};

const hasApplicationAccess = applicationName => {
  return getApplications().some(app => app.name === applicationName);
};

const logout = () => {
  localStorage.removeItem(TOKEN_NAME);
};

const isAdmin = () => {
  return get().profile === roles.appRoles.ADMIN.value;
};

const isAnalyst = () => {
  return get().profile === roles.appRoles.ANALYST.value;
};

const isOperator = () => {
  return get().profile === roles.appRoles.OPERATOR.value;
};

const getFeatureFlags = () => {
  return get().feature_flags || {};
};

export default {
  get,
  set,
  isAdmin,
  isAnalyst,
  isOperator,
  getToken,
  getApplications,
  getCurrentApplication,
  hasApplicationAccess,
  logout,
  getFeatureFlags
};
