import config from '@/config';
import fetch from '@/fetch';

let cache;
const BASE_URL = config.url.contacerta + '/app/settings';

export default {
  /** @type {Promise<Settings>} */
  get: refreshCache => {
    if (cache && !refreshCache) {
      return new Promise(resolve => resolve(cache));
    }
    return fetch(BASE_URL).then(toCache => {
      cache = toCache;
      return cache;
    });
  },
  getApiCredential: () =>
    fetch(`${BASE_URL}/credentials`, {
      method: 'GET'
    }),
  createApiCredential: () =>
    fetch(`${BASE_URL}/credentials`, {
      method: 'POST'
    }),
  getTwoFactor: () =>
    fetch(`${BASE_URL}/twoFactor/secret`, {
      method: 'POST'
    }),
  setTwoFactor: settings =>
    fetch(`${BASE_URL}/twoFactor`, {
      body: JSON.stringify(settings),
      method: 'POST'
    }),
  getCompanySettings: () =>
    fetch(`${BASE_URL}/company`, {
      method: 'GET'
    })
};
