<template>
  <tr-text-field
    ref="field"
    v-model="internalBarcode"
    :label="label"
    classes="col-lg-6"
    name="barcode"
    required="true"
    :mask-format="BILLET_LINE_CODE"
    :custom-validation="validate"
    :error-message="errorMessage"
  />
</template>

<script>
import accountLimitsValidations from '@/apps/payments/payments/billet/services/validations/value';
import DateService from '@transfeeradev/api-services/DateService';
import masksContants from '@/commons/constants/masks';
import Validation, { SimpleBarcode } from '@transfeeradev/billet-validation';
import billetResource from '@/apps/payments/payments/billet/resources/billet';
import { errors, paymentType } from '@transfeeradev/api-enums';
import { getMessage } from '@/commons/services/error';

const DEFAULT_ERROR_MESSAGE = 'A linha digitável do boleto está incorreta, insira novamente';

export default {
  name: 'barcode-field-container',
  props: {
    billet: {
      required: false,
      type: Object,
      default: () => ({})
    },
    label: {
      required: false,
      type: String,
      default: 'Qual a linha digitável do boleto?'
    },
    onChange: {
      required: true,
      type: Function
    }
  },
  data: () => ({
    currentBillet: {},
    validationResult: {},
    BILLET_LINE_CODE: masksContants.BILLET_LINE_CODE
  }),
  computed: {
    internalBarcode: {
      get() {
        return this.billet.barcode;
      },
      set(newValue) {
        this.onChange(newValue);
      }
    },
    errorMessage() {
      const isInvalid = this._isInvalid();
      if (isInvalid) {
        return this.validationResult.errorMessage;
      } else {
        return DEFAULT_ERROR_MESSAGE;
      }
    },
    getCurrentBilletValue() {
      const billet = new SimpleBarcode({
        barcode: this.internalBarcode
      }).getData();

      return billet.value;
    }
  },
  mounted() {
    this.validate().then(result => {
      if (!result && this.billet.barcode) {
        this.$refs.field.forceValidation();
      }
    });
  },
  methods: {
    validateService() {
      let validationErrors = new Validation({
        billet: this.currentBillet,
        disallowOverdue: true
      }).validateBarcode();

      if (validationErrors && validationErrors.length) {
        // This error (BIL_16) will already be displayed in "Valor a pagar" field.
        validationErrors = validationErrors.filter(e => e.errorMessage.code !== 'BIL_16');

        if (validationErrors.length) {
          throw getMessage(validationErrors[0].errorMessage);
        }
      }
    },
    async validateIsDuplicated() {
      const response = await billetResource.isDuplicated(this.currentBillet);

      if (response.isDuplicated) {
        throw errors.ptBr.bil.DUPLICATED_BARCODE.message;
      }
    },
    async validateAccountLimits() {
      const response = await accountLimitsValidations.validate(this.currentBillet.value, paymentType.BILLET);
      if (!response.isValid) {
        throw response.errorMessage;
      }
    },
    async validate() {
      this.currentBillet = Object.assign({}, this.billet);
      this.currentBillet.payment_date = DateService.getFormatted(this.currentBillet.payment_date);
      this.currentBillet.value = this.getCurrentBilletValue;

      if (!this.currentBillet.barcode) {
        return true;
      }

      try {
        this.validateService();
        await this.validateIsDuplicated();
        await this.validateAccountLimits();
        return true;
      } catch (error) {
        this.validationResult = this._createError(error);
        return false;
      }
    },
    _createError(message) {
      return {
        isValid: false,
        errorMessage: message
      };
    },
    _isInvalid() {
      return Boolean(this.validationResult && !this.validationResult.isValid);
    }
  }
};
</script>
