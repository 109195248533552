export default phone => {
  if (typeof phone === 'string' && phone !== '') {
    let dddCode = '';
    if (phone.length >= 10) {
      dddCode = phone.slice(0, 2);
      phone = phone.slice(2, phone.length);
    }

    let sliceIdx = 4;
    if (phone.length === 9) {
      sliceIdx = 5;
    }

    phone = `${phone.slice(0, sliceIdx)}-${phone.slice(sliceIdx, phone.length)}`;
    if (dddCode) {
      phone = `(${dddCode}) ${phone}`;
    }
    return phone;
  } else {
    return '';
  }
};
