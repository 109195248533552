import { batchStatus } from '@transfeeradev/api-enums';

export default {
  [batchStatus.VALIDATING]: 'Validando',
  [batchStatus.WAITING]: 'Aguardando recebimento',
  [batchStatus.RECEIVED]: 'Recebido',
  [batchStatus.FINISHED]: 'Finalizado',
  [batchStatus.REMOVED]: 'Removido',
  [batchStatus.DRAFT]: 'Rascunho',
  [batchStatus.RETURNED]: 'Devolvido',
  [batchStatus.WAITING_APPROVAL]: 'Aguardando aprovação',
  [batchStatus.PENDING]: 'Fechando lote'
};
