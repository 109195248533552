<template>
  <canvas
    ref="canvasRef"
    width="555"
    height="314"
  />
</template>

<script>
import { ref, onBeforeUnmount } from 'vue';
import { Chart } from 'chart.js';

export default {
  setup() {
    const canvasRef = ref();
    let chart;

    const renderChart = ({ type, data, options }) => {
      if (chart) {
        chart.destroy();
      }

      chart = new Chart(canvasRef.value.getContext('2d'), {
        type,
        data,
        options
      });
    };

    onBeforeUnmount(() => {
      if (chart) {
        chart.destroy();
      }
    });

    return {
      canvasRef,
      renderChart
    };
  }
};
</script>
