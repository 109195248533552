<template>
  <tr-dialog
    ref="modal"
    name="company-bank-account-form-dialog"
    title="Conta para saque"
    :has-its-own-buttons="true"
  >
    <tr-form
      name="newUserForm"
      :on-valid-submit="onSubmit"
    >
      <company-bank-account-form-container
        ref="companyBankAccountFormContainer"
        show-form-header="true"
      />
      <div class="vertical-offset3 bottom-offset3 inline-block width-100">
        <tr-button
          class="pull-left"
          variant="outlined"
          color="secondary"
          label="Cancelar"
          :on-button-click="close"
        />
        <tr-button
          class="pull-right"
          variant="raised"
          color="primary"
          label="Salvar"
          type="submit"
        />
      </div>
    </tr-form>
  </tr-dialog>
</template>

<script>
import CompanyBankAccountFormContainer from '@/apps/payments/payments/settings/bankAccounts/components/container/CompanyBankAccountFormContainer';

export default {
  name: 'company-bank-account-form-dialog',
  components: {
    CompanyBankAccountFormContainer
  },
  props: ['onSave'],
  methods: {
    open() {
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
    async onSubmit() {
      await this.$refs.companyBankAccountFormContainer.onSubmit();
      this.close();
      this.onSave();
    }
  }
};
</script>
