<template>
  <div>
    <tr-button
      :on-button-click="generateNewApiKey"
      label="Gerar uma nova chave de acesso"
      variant="raised"
      color="primary"
    />
    <div class="row vertical-offset2">
      <tr-text-field
        ref="apiKeyInput"
        :model-value="apiKey"
        classes="col-md-6 col-lg-6"
        label="Sua chave de acesso:"
        name="apiKey"
        :readonly="true"
        @update:modelValue="$emit('update:apiKey', $event)"
      />
      <tr-button
        classes="vertical-offset1"
        :on-button-click="copyKey"
        label="Copiar chave"
        variant="raised"
        color="default"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'api-key',
  props: {
    generateNewApiKey: {
      required: true,
      type: Function
    },
    apiKey: {
      required: false,
      type: String,
      default: ''
    }
  },
  emits: ['update:apiKey'],
  methods: {
    copyKey() {
      this.$refs.apiKeyInput.copy();
    }
  }
};
</script>
