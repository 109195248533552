<template>
  <div :class="`${classes || ''} inline`">
    <button
      class="link"
      @click="downloadReport"
    >
      <bg-text
        :is="linkStyle ? 'u' : 'p'"
        class="inline cursor-pointer"
      >
        {{ label }}
      </bg-text>
    </button>
  </div>
</template>

<script>
import { BgText, BgLink } from '@transfeeradev/bridge';
export default {
  name: 'batch-download-link',
  components: {
    BgText,
    BgLink
  },
  props: ['downloadReport', 'classes', 'label', 'linkStyle'],
  computed: {
    hasLinkStyle() {
      return this.linkStyle === 'true';
    }
  }
};
</script>

<style scoped>
.inline {
  display: inline;
}
</style>