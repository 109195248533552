<template>
  <div>
    <tr-page-section title="Dados bancários para o envio do saldo:">
      <view-bank-account-container
        :bank-account="entry.bankAccount"
        :full-size="true"
        :no-margin-on-last-line="true"
      />
    </tr-page-section>
  </div>
</template>

<script>
import ViewBankAccountContainer from '@/apps/payments/payments/bank/components/container/ViewBankAccountContainer';

export default {
  name: 'entry-confirmation',
  components: {
    ViewBankAccountContainer
  },
  props: {
    entry: {
      required: true,
      type: Object,
      default: () => ({
        bankAccount: {
          Bank: {}
        }
      })
    }
  }
};
</script>
