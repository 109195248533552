<template>
  <chart ref="chart" />
</template>

<script>
import Chart from './Chart.vue';
import transferResource from '@/apps/payments/payments/transfer/resources/transfer';
import { transferStatus } from '@transfeeradev/api-enums';

export default {
  name: 'proporsion-of-failed-vs-finished-chart',
  components: {
    Chart
  },
  props: {
    onLoad: {
      required: false,
      type: Function
    },
    height: {
      required: false,
      type: String
    },
    width: {
      required: false,
      type: String
    },
    dateRange: {
      required: true
    }
  },
  watch: {
    dateRange: {
      deep: true,
      handler() {
        this.load();
      }
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    renderChart(data, options) {
      this.$refs.chart.renderChart({
        type: 'doughnut',
        data,
        options
      });
    },
    async load() {
      const status = `${transferStatus.FINISHED},${transferStatus.RETURNED}`;
      const data = await transferResource.getCountByStatus(this.dateRange, status);

      const total = data.finished + data.returned;
      const returned = ((data.returned * 100) / total).toFixed(2);
      const finished = ((data.finished * 100) / total).toFixed(2);

      if (this.$refs.chart) {
        this.renderChart(
          {
            labels: ['Finalizado', 'Devolvido'],
            datasets: [
              {
                label: '',
                data: [finished, returned],
                backgroundColor: ['#0096ED', '#FF003A']
              }
            ]
          },
          {
            legend: {
              position: 'left',
              onClick: () => {
                return false;
              },
              labels: {
                usePointStyle: true
              }
            },
            title: {
              display: true,
              text: '',
              position: 'top',
              fontStyle: 'normal'
            },
            tooltips: {
              callbacks: {
                label: tooltipItems => {
                  if (tooltipItems.index === 0) {
                    return `Finalizado: ${finished}%`;
                  }

                  return `Devolvido: ${returned}%`;
                },
                footer: tooltipItems => {
                  if (tooltipItems[0].index === 0) {
                    return `Quantidade: ${data.finished}`;
                  }

                  return `Quantidade: ${data.returned}`;
                }
              }
            },
            animation: {
              onComplete: () => {
                return this.onLoad && this.onLoad(data);
              }
            }
          }
        );
      }
    }
  }
};
</script>
