<template>
  <div>
    <tr-page-title
      title="Quase tudo pronto para finalizar o pagamento."
      description="Para finalizar, transfira o valor total do pagamento para a conta Transfeera e nosso sistema automaticamente enviará para o destino."
    />
    <tr-page-section>
      <div class="row">
        <tr-read-only-text-field
          label="Total a enviar"
          :value="$format.currency(batch.value)"
          :no-margin="true"
        />
      </div>
    </tr-page-section>
    <tr-page-section>
      <pix-key-selection
        v-if="currentStep === entryWizardStepsConstant.ENTRY_PIX_SELECTION"
        :go-to-bank-selection="goToBankSelection"
        @hasPixKeys="(value) => (hasPixKey = value)"
      />
      <entry-form
        v-if="currentStep === entryWizardStepsConstant.ENTRY_BANK_SELECTION"
        ref="entryForm"
      />
    </tr-page-section>
  </div>
</template>

<script>
import PixKeySelection from '@/apps/payments/payments/bank/components/presentational/PixKeySelection';
import EntryForm from '@/apps/payments/payments/statement/components/presentational/EntryForm';

const entryWizardStepsConstant = {
  ENTRY_PIX_SELECTION: 'ENTRY_PIX_SELECTION',
  ENTRY_BANK_SELECTION: 'ENTRY_BANK_SELECTION'
};

export default {
  name: 'batch-bank-account-selection',
  components: {
    PixKeySelection,
    EntryForm
  },
  props: {
    batch: {
      required: false,
      type: Object,
      default: () => {}
    },
    onSelectBankAccount: {
      required: true,
      type: Function
    },
    selectedBankAccount: {
      required: true,
      type: Object
    }
  },
  data: () => ({
    currentStep: entryWizardStepsConstant.ENTRY_PIX_SELECTION,
    entryWizardStepsConstant
  }),
  methods: {
    goToBankSelection() {
      this.currentStep = entryWizardStepsConstant.ENTRY_BANK_SELECTION;
    }
  }
};
</script>
