<template>
  <receipt-download-links
    v-if="mightHaveReceipt"
    :classes="classes"
    :show-fake-links="showFakeLinks"
    :get-receipt="getReceipt"
    :item="billet"
  />
</template>

<script>
import ReceiptDownloadLinks from '@/commons/components/presentational/ReceiptDownloadLinks';
import billetModel from '@/apps/payments/payments/billet/models/billet';
import { billetStatus } from '@transfeeradev/api-enums';

export default {
  name: 'billet-receipt-download-links-container',
  components: {
    ReceiptDownloadLinks
  },
  props: {
    billet: {
      required: false,
      type: Object,
      default: billetModel.get
    },
    classes: {
      required: false,
      type: String
    },
    shouldAlwaysShow: {
      required: false,
      type: Boolean,
      default: false
    },
    showFakeLinks: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  computed: {
    mightHaveReceipt() {
      return Boolean(
        this.shouldAlwaysShow ||
          (this.billet && (this.billet.status === billetStatus.PAID || this.billet.status === billetStatus.FINISHED))
      );
    }
  },
  methods: {
    getReceipt(billet, urlProperty, event) {
      event.stopPropagation();
      if (this._hasReceipt(billet, urlProperty)) {
        window.open(billet[urlProperty], '_blank');
      }
    },
    _hasReceipt(billet, urlProperty) {
      return Boolean(billet && urlProperty && billet[urlProperty]);
    }
  }
};
</script>
