<template>
  <page
    v-model:active-tab="activeTab"
    title="Configurações"
  >
    <template #tabs>
      <bg-tab :key="bankDataRoute">
        Dados bancários
      </bg-tab>
      <bg-tab :key="generalRoute">
        Configurações gerais
      </bg-tab>
    </template>
    <template #panels>
      <bg-tab-panel :key="bankDataRoute">
        <bank-accounts-page :open-pix-key-wizard-dialog-on-init="openPixKeyWizardDialogOnInit" />
      </bg-tab-panel>
      <bg-tab-panel :key="generalRoute">
        <config-page />
      </bg-tab-panel>
    </template>
  </page>
</template>

<script setup>
import { BgTab, BgTabPanel } from '@transfeeradev/bridge';
import ConfigPage from '../config/pages/ConfigPage.vue';
import BankAccountsPage from '../bankAccounts/pages/BankAccountsPage.vue';
import Page from '@/commons/components/presentational/Page.vue';
import { useRouter } from 'vue-router';
import { ref, watch } from 'vue';

const emit = defineEmits(['loading']);
const router = useRouter();
const activeTab = ref(router.currentRoute.value.name);
const bankDataRoute = 'PaymentsBankAccountsPage';
const generalRoute = 'PaymentsGeneralSettingsPage';
const openPixKeyWizardDialogOnInit = history.state?.openPixKeyWizardDialogOnInit;

emit('loading', false);

watch(activeTab, name => {
  router.push({ name });
});
</script>
