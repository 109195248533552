<template>
  <tr-card
    :has-error="isFailed"
    :has-warning="hasWarning"
    :break-bigger-word="11"
    :on-card-click="onCardClick"
    image-src="/assets/img/billet.svg"
    :title="$format.currency(billet.value)"
  >
    <div>
      <div class="row vertical-offset2 center-block">
        <billet-status-pill
          v-if="!hideStatusPill"
          classes="col-lg-12 bottom-offset2"
          size="medium"
          :no-margin="true"
          :status="billet.status"
        />
      </div>
      <tr-typography
        break-all-words="true"
        fixed-lines-qtd="2"
        strong="true"
      >
        {{ breakBiggerWord(capitalize(billet.description), 45) }}
      </tr-typography>
      <tr-typography break-all-words="true">
        Agendado para: <tr-typography strong="true">
          {{ formatDate(billet.payment_date) }}
        </tr-typography>
      </tr-typography>
    </div>
    <billet-receipt-download-links-container
      classes="vertical-offset1 center-block"
      :billet="billet"
    />
  </tr-card>
</template>

<script>
import breakBiggerWord from '@/commons/formatters/breakBiggerWord';
import capitalize from '@/commons/formatters/capitalize';
import formatDate from '@/commons/formatters/formatDate';
import BilletStatusPill from '@/apps/payments/payments/billet/components/presentational/BilletStatusPill';
import BilletReceiptDownloadLinksContainer from '@/apps/payments/payments/billet/components/container/BilletReceiptDownloadLinksContainer';

export default {
  name: 'billet-card',
  components: {
    BilletStatusPill,
    BilletReceiptDownloadLinksContainer
  },
  props: {
    billet: {
      required: false,
      type: Object,
      default: () => ({})
    },
    onCardClick: {
      required: false,
      type: Function
    },
    getReceipt: {
      required: false,
      type: Function
    },
    isFailed: {
      required: false,
      type: Boolean,
      default: false
    },
    hideStatusPill: {
      required: false,
      type: Boolean
    },
    hasWarning: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  methods: {
    breakBiggerWord,
    capitalize,
    formatDate
  }
};
</script>
