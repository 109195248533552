<template>
  <tr-table :disable-click="true">
    <tr-thead>
      <tr-tr>
        <tr-th width="13%">
          Data
        </tr-th>
        <tr-th width="17%">
          Descrição
        </tr-th>
        <tr-th width="7%">
          Banco
        </tr-th>
        <tr-th width="12%">
          Agência
        </tr-th>
        <tr-th width="12%">
          CC
        </tr-th>
        <tr-th width="13%">
          Status
        </tr-th>
        <tr-th
          class="text-right"
          width="15%"
        >
          Valor (R$)
        </tr-th>
        <tr-th
          class="text-right"
          width="15%"
        >
          Saldo (R$)
        </tr-th>
      </tr-tr>
    </tr-thead>
    <tr-tbody>
      <tr-tr v-if="statement.afterBalance">
        <tr-td>
          <tr-typography :strong="true">
            {{ formatDate(statement.afterBalance.date) }}
          </tr-typography>
        </tr-td>
        <tr-td colspan="5">
          <tr-typography
            classes="text-right"
            :strong="true"
          >
            Saldo
          </tr-typography>
        </tr-td>
        <tr-td><div class="statement-row-stub" /></tr-td>
        <tr-td class="text-right">
          <tr-typography :strong="true">
            {{ $format.currency(statement.afterBalance.value) }}
          </tr-typography>
        </tr-td>
      </tr-tr>
      <tr-tr
        v-for="item in statement.entries"
        :key="item.id"
      >
        <tr-td>
          <tr-typography>
            {{ item?.complement?.timestamp ? formatDateTime(item.complement.timestamp) : formatDate(item.date) }}
          </tr-typography>
        </tr-td>
        <tr-td :colspan="item.complement.bank || isPixWithAccountData(item) ? 1 : 4">
          <statement-table-description
            :item="item"
            @click="showBatchFullscreenModal"
            @click-pix="showPixDetailsModal"
          />
        </tr-td>
        <template v-if="item.complement.bank || isPixWithAccountData(item)">
          <tr-td>
            <tr-typography>
              <bank-image-container :bank-id="getBankId(item)" />
            </tr-typography>
          </tr-td>
          <tr-td>
            <tr-typography>
              {{ getBankAgency(item) }}
            </tr-typography>
          </tr-td>
          <tr-td>
            <tr-typography>
              {{ getBankAccount(item) }}
            </tr-typography>
          </tr-td>
        </template>
        <tr-td>
          <entry-type-pill
            :no-margin="true"
            size="medium"
            :type="item.type"
          />
        </tr-td>
        <tr-td class="text-right">
          <tr-typography
            :classes="`text--${entryTypeColor[item.type]} ${isCancelledEntry(item) ? 'line-through' : ''}`"
          >
            {{ $format.currency(item.value) }}
          </tr-typography>
        </tr-td>
        <tr-td class="text-right">
          <tr-typography>
            {{ $format.currency(item.balance) }}
          </tr-typography>
        </tr-td>
      </tr-tr>
      <tr-tr v-if="statement.prevBalance">
        <tr-td>
          <tr-typography :strong="true">
            {{ formatDate(statement.prevBalance.date) }}
          </tr-typography>
        </tr-td>
        <tr-td colspan="5">
          <tr-typography
            classes="text-right"
            :strong="true"
          >
            Saldo
          </tr-typography>
        </tr-td>
        <tr-td><div class="statement-row-stub" /></tr-td>
        <tr-td class="text-right">
          <tr-typography :strong="true">
            {{ $format.currency(statement.prevBalance.value) }}
          </tr-typography>
        </tr-td>
      </tr-tr>
    </tr-tbody>
    <batch-fullscreen-modal
      ref="batchFullscreenModal"
      :on-change="onChange"
      :unique-name="true"
    />
    <pix-cashin-entry-details-dialog ref="pixCashinEntryDetailsDialog" />
  </tr-table>
</template>

<script>
import formatDate, { formatDateTime } from '@/commons/formatters/formatDate';
import breakBiggerWord from '@/commons/formatters/breakBiggerWord';
import BankImageContainer from '@/apps/payments/payments/bank/components/container/BankImageContainer';
import BatchFullscreenModal from '@/apps/payments/payments/batch/pages/BatchFullscreenModal';
import EntryTypePill from '@/apps/payments/payments/statement/components/presentational/EntryTypePill';
import PixCashinEntryDetailsDialog from '@/apps/payments/payments/statement/components/presentational/PixCashinEntryDetailsDialog';
import StatementTableDescription from '@/apps/payments/payments/statement/components/presentational/StatementTableDescription.vue';
import entryTypeColor from '@/apps/payments/payments/statement/constants/entryTypeColor';
import { statement } from '@transfeeradev/api-enums';

export default {
  name: 'statement-table',
  components: {
    BatchFullscreenModal,
    BankImageContainer,
    EntryTypePill,
    PixCashinEntryDetailsDialog,
    StatementTableDescription
  },
  props: {
    statement: {
      required: false,
      type: [Object, Array]
    },
    onChange: {
      required: false,
      type: Function
    }
  },
  data: () => ({
    entryTypeColor,
    entryType: statement.entryType
  }),
  methods: {
    breakBiggerWord,
    formatDate,
    formatDateTime,
    showBatchFullscreenModal(item) {
      this.$refs.batchFullscreenModal.open(item && item.complement && item.complement.batch_id);
    },
    hideBatchFullscreenModal() {
      this.$refs.batchFullscreenModal.close();
    },
    showPixDetailsModal(item) {
      let thirdParty = this.getPixThirdParty(item);
      this.$refs.pixCashinEntryDetailsDialog.open(thirdParty, item.type);
    },
    getBankId(item) {
      let bank = item.complement.bank;
      if (item.complement.isPix) {
        let thirdParty = this.getPixThirdParty(item);
        bank = thirdParty.bank;
      }

      return bank && bank.id;
    },
    getBankAgency(item) {
      if (item.complement.isPix) {
        let thirdParty = this.getPixThirdParty(item);
        return thirdParty.agency;
      }

      return item.complement.agency;
    },
    getBankAccount(item) {
      if (item.complement.isPix) {
        let thirdParty = this.getPixThirdParty(item);
        return thirdParty.account;
      }

      return item.complement.account;
    },
    getPixThirdParty(item) {
      if (item.type === statement.entryType.DEPOSIT) {
        return item.complement.payer;
      }

      return item.complement.receiver;
    },
    isCancelledEntry(item) {
      if (
        [
          statement.entryType.EXPIRED_DEPOSIT,
          statement.entryType.CANCELLED_DEPOSIT_REFUND,
          statement.entryType.UNLOCKED
        ].includes(item.type)
      ) {
        return true;
      }

      return false;
    },
    isPixWithAccountData(item) {
      return (
        item.isPix &&
        [
          statement.entryType.DEPOSIT,
          statement.entryType.PENDING_DEPOSIT_REFUND,
          statement.entryType.DEPOSIT_REFUND,
          statement.entryType.CANCELLED_DEPOSIT_REFUND
        ].includes(item.type)
      );
    }
  }
};
</script>

<style>
.statement-row-stub {
  height: 24px;
  display: inline-block;
}
</style>
