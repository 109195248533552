<template>
  <tr-dialog
    ref="modal"
    name="withdraw-dialog"
    :hide-cancel-button="true"
    :has-its-own-buttons="true"
    title="Resgatar saldo"
  >
    <withdraw-container
      :close="close"
      :on-change="onChange"
    />
  </tr-dialog>
</template>

<script>
import WithdrawContainer from '@/apps/payments/payments/statement/components/container/WithdrawContainer';

export default {
  name: 'withdraw-dialog',
  components: {
    WithdrawContainer
  },
  props: {
    onChange: {
      required: false,
      type: Function
    }
  },
  methods: {
    open() {
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    }
  }
};
</script>
