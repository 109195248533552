<template>
  <div>
    <tr-slimbox
      title="Aqui você gerencia todos seus recebedores"
      :with-border="true"
      arrow-direction="bottom-left"
    >
      Os recebedores que você cadastra para realizar pagamentos ficam organizados aqui. Você pode
      corrigir, editar ou
      excluir!
    </tr-slimbox>
    <tr-slimbox
      title="Seus recebedores"
      :with-dashed-border="true"
    >
      <receivers-table :receivers="receiversExample" />
    </tr-slimbox>
  </div>
</template>

<script>
import config from '@/config';
import ReceiversTable from '@/apps/payments/payments/receivers/components/presentational/ReceiversTable';
import { destinationBankAccountStatus } from '@transfeeradev/api-enums';

export default {
  name: 'receivers-first-use-blankslate',
  components: {
    ReceiversTable
  },
  data: () => ({
    receiversExample: [
      {
        name: 'Ana da Silva',
        cpf_cnpj: '02193523912',
        Bank: {
          image: `${config.url.cdn}/banks/caixa.svg`,
          name: 'Caixa Econômica'
        },
        agency: '0123',
        account: '123456',
        agency_digit: '9',
        status: destinationBankAccountStatus.VERIFIED
      },
      {
        name: 'Bernardo Ferreira',
        cpf_cnpj: '08815211118',
        Bank: {
          image: `${config.url.cdn}/banks/bradesco.svg`,
          name: 'Bradesco'
        },
        agency: '1000',
        account: '9876543',
        agency_digit: '1',
        status: destinationBankAccountStatus.VERIFIED
      },
      {
        name: 'Transfeera Pagamentos',
        cpf_cnpj: '27084098000169',
        Bank: {
          image: `${config.url.cdn}/banks/sicoob.svg`,
          name: 'Sicoob'
        },
        agency: '123',
        account: '11223344',
        agency_digit: '9',
        status: destinationBankAccountStatus.VERIFIED
      }
    ]
  })
};
</script>
