<template>
  <dba-wizard
    ref="presentational"
    :dba="dba"
    :transfer="transfer"
    :on-change-dba="onChangeDba"
    :on-dba-type-change="onDbaTypeChange"
    :on-bank-change="onBankChange"
    :wizard-state="wizardState"
    :should-make-complex-validations="shouldMakeComplexValidations"
  />
</template>

<script>
import DbaWizard from '@/apps/payments/payments/dba/components/presentational/DbaWizard.vue';
import transferWizardState from '@/apps/payments/payments/transfer/constants/wizardState';
import dbaModel from '@/apps/payments/payments/dba/models/dba';
import transferModel from '@/apps/payments/payments/transfer/models/transfer';

export default {
  name: 'dba-wizard-container',
  components: {
    DbaWizard
  },
  props: {
    dba: {
      required: false,
      type: Object,
      default: dbaModel.get
    },
    transfer: {
      required: false,
      type: Object,
      default: transferModel.get
    },
    onChangeDba: {
      required: true,
      type: Function
    },
    onBankChange: {
      required: false,
      type: Function
    },
    hideNavigation: {
      required: false,
      type: Boolean,
      default: false
    },
    onResetDba: {
      required: false,
      type: Function
    },
    shouldMakeComplexValidations: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    wizardState: transferWizardState.FORM
  }),
  methods: {
    onDbaTypeChange(type) {
      const dbaData = dbaModel.get();
      delete dbaData.id;
      dbaData.pix_key_type = type;
      this.onChangeDba(Object.assign({}, this.dba, dbaData), { clear: true });
    }
  }
};
</script>
