<template>
  <tr-pill
    :color="typeColor"
    :size="size"
    :no-margin="noMargin"
    :classes="`${classes} capitalize`"
  >
    {{ typeName }}
  </tr-pill>
</template>

<script>
import entryTypeColor from '@/apps/payments/payments/statement/constants/entryTypeColor';
import entryTypeName from '@/apps/payments/payments/statement/constants/entryTypeName';

export default {
  name: 'entry-type-pill',
  props: {
    type: {
      required: false,
      type: String
    },
    noMargin: {
      required: false,
      type: Boolean,
      default: false
    },
    classes: {
      required: false,
      type: String
    },
    size: {
      required: false,
      type: String
    }
  },
  computed: {
    typeColor() {
      return entryTypeColor[this.type];
    },
    typeName() {
      return entryTypeName[this.type];
    }
  }
};
</script>
