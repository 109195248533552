export default {
  DOCUMENT: {
    mask: ['999.999.999-99', '99.999.999/9999-99'],
    autoUnmask: true
  },
  DOCUMENT_OR_TEXT: {
    mask: ['999.999.999-99', '99.999.999/9999-99', '[F]{1,255}'],
    showMaskOnHover: false,
    showMaskOnFocus: false,
    autoUnmask: true
  },
  PHONE: {
    mask: ['0S00 NNN NNNN', '(XN) NNNN-NNNN', '(XN) 9NNNN-NNNN'],
    autoUnmask: true,
    definitions: {
      0: {
        validator: '0'
      },
      9: {
        validator: '9'
      },
      N: {
        validator: '[0-9]'
      },
      X: {
        validator: '[1-9]'
      },
      Y: {
        validator: '[2-6]'
      },
      S: {
        validator: '[3589]'
      },
      P: {
        validator: '[34]'
      }
    }
  },
  MOBILE_PHONE: {
    mask: '(99) 99999-9999',
    autoUnmask: true
  },
  BILLET_LINE_CODE: {
    regex:
      '([8]{1}[0-9]{11} [0-9]{12} [0-9]{12} [0-9]{12})|([0-9]{5}\\.[0-9]{5} [0-9]{5}\\.[0-9]{6} [0-9]{5}\\.[0-9]{6} [0-9]{1} [0-9]{14})',
    autoUnmask: true
  },
  DATE: {
    mask: '99/99/9999',
    placeholder: 'dd/mm/aaaa',
    clearMaskOnLostFocus: false
  },
  CURRENCY: {
    alias: 'currency',
    radixPoint: ',',
    groupSeparator: '.',
    numericInput: true,
    allowMinus: false,
    prefix: 'R$ ',
    digits: 2,
    digitsOptional: false,
    rightAlign: false,
    unmaskAsNumber: false,
    autoUnmask: true
  }
};
