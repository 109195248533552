<template>
  <div :class="classes">
    <tr-svg-icon
      v-tooltip.top-center="bankReceiptUrlTooltipTitle"
      size="small"
      :classes="`inline-block margin-right-10 ${bankReceiptLinkCursor}`"
      :color="item.bank_receipt_url || showFakeLinks ? 'blue' : 'grey'"
      :on-button-click="getReceipt.bind(this, item, 'bank_receipt_url')"
      name="ic-account-balance"
    />
    <tr-svg-icon
      v-tooltip.top-center="'Comprovante <br/> Transfeera'"
      :classes="`inline-block ${transfeeraReceiptLinkCursor}`"
      :color="item.receipt_url || showFakeLinks ? 'blue' : 'grey'"
      size="small"
      :on-button-click="getReceipt.bind(this, item, 'receipt_url')"
      name="ic-receipt"
    />
  </div>
</template>

<script>
export default {
  name: 'receipt-download-links',
  props: ['item', 'getReceipt', 'classes', 'showFakeLinks'],
  computed: {
    bankReceiptLinkCursor() {
      if (this.item.bank_receipt_url) {
        return 'cursor-pointer';
      }
      if (this.showFakeLinks) {
        return 'cursor-disabled';
      }
      return 'cursor-default';
    },
    transfeeraReceiptLinkCursor() {
      if (this.item.receipt_url) {
        return 'cursor-pointer';
      }
      if (this.showFakeLinks) {
        return 'cursor-disabled';
      }
      return 'cursor-default';
    },
    bankReceiptUrlTooltipTitle() {
      if (this.item.bank_receipt_url) {
        return 'Comprovante <br /> bancário';
      }
      return 'Comprovante bancário <br /> disponível no próximo dia útil';
    }
  }
};
</script>
