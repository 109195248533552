import searchService from '@/commons/services/bank/search';

const format = (bankDescription, banks) => {
  if (!bankDescription || _isBancoDoBrasilDescribedOnlyAsBrasil(bankDescription)) {
    return null;
  }

  const bank = searchService.search(bankDescription, banks);
  return bank && bank.id.toString();
};

const _isBancoDoBrasilDescribedOnlyAsBrasil = bankDescription =>
  Boolean(bankDescription && bankDescription.toString().toLowerCase().trim() === 'brasil');

export default {
  format
};
