<template>
  <tr-form
    ref="form"
    name="transferCorrection"
    :hide-erros-until-is-dirty="true"
    :on-valid-submit="save"
  >
    <transfer-form
      ref="transferForm"
      :transfer="transfer"
      :dba="transfer.destination_bank_account"
      :should-make-complex-validations="true"
      :on-valid-after-dba-validation="onSaveConfirm"
      :on-invalid-after-dba-validation="onDbaValidationError"
      :on-payment-date-change="onPaymentDateChange"
      :settings="settings"
      :on-reset-dba="onResetDba"
      :on-change-dba="onChangeDba"
      :on-bank-change="onChangeBank"
    />
    <tr-footer-buttons-container>
      <tr-button
        classes="pull-right"
        type="submit"
        label="Salvar"
        variant="raised"
        color="primary"
        :disabled="isLoading || transferHasNoChanges"
      />
      <tr-button
        classes="pull-right margin-right-10"
        label="Cancelar pagamento"
        size="medium"
        variant="raised"
        color="danger"
        :on-button-click="showTransferRefundConfirmationDialog"
        :disabled="isLoading"
      />
      <tr-button
        classes="pull-left"
        label="Cancelar"
        variant="raised"
        color="secondary"
        :on-button-click="onCancel"
        :disabled="isLoading"
      />
    </tr-footer-buttons-container>
  </tr-form>
  <transfer-refund-confirmation-dialog
    ref="transferEditionConfirmationDialog"
    :dba-has-more-than-one-transfer="dbaHasMoreThanOneTransfer"
    :transfer="transfer"
    :destination-bank-account="transfer.destination_bank_account"
    :on-confirm="cancelAndRefund"
    :confirm-disabled="isLoading"
  />
</template>

<script>
import TransferForm from '@/apps/payments/payments/transfer/components/presentational/TransferForm.vue';
import TransferRefundConfirmationDialog from '@/apps/payments/payments/transfer/components/presentational/TransferRefundConfirmationDialog.vue';
import batchResource from '@/apps/payments/payments/batch/resources/batch';
import DestinationBankAccountDataChangeValidationService from '@transfeeradev/api-services/DestinationBankAccountDataChangeValidationService';
import prepareToSave from '@/apps/payments/payments/transfer/services/prepareToSave';
import prepareToEditDba from '@/apps/payments/payments/dba/services/prepareToEdit';
import settingsResource from '@/apps/payments/resources/settings';
import transferModel from '@/apps/payments/payments/transfer/models/transfer';
import checkpointResource from '@/apps/payments/resources/checkpoint';
import checkpoint from '@/commons/constants/checkpoint';
import { destinationBankAccountStatus } from '@transfeeradev/api-enums';
import merge from 'deepmerge';
import { getMessage } from '@/commons/services/error';

export default {
  name: 'transfer-correction-container',
  components: {
    TransferForm,
    TransferRefundConfirmationDialog
  },
  props: {
    transferEdit: {
      required: false,
      type: Object,
      default: () => ({
        destination_bank_account: {}
      })
    },
    onSave: {
      required: false,
      type: Function
    },
    cancel: {
      required: false,
      type: Function
    },
    batch: {
      required: false,
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    dbaHasMoreThanOneTransfer: false,
    isLoading: false,
    settings: {},
    transfer: transferModel.get(),
    initialDba: {},
    initialPixKeyValidation: {}
  }),
  computed: {
    showTransferErrorBanner() {
      return !this.isLoading;
    },
    transferHasNoChanges() {
      return (
        !DestinationBankAccountDataChangeValidationService.hasChangedCriticalData(
          this.initialDba,
          this.transfer.destination_bank_account
        ) &&
        this.initialPaymentDate === this.transfer.payment_date &&
        this.initialPixKeyValidation?.cpf_cnpj === this.transfer.pix_key_validation?.cpf_cnpj
      );
    }
  },
  created() {
    this.transfer = merge({}, this.transferEdit);
    const dbaId = this.transfer.destination_bank_account.id;

    this.transfer.destination_bank_account = prepareToEditDba.prepare(this.transfer.destination_bank_account);
    this.initialDba = Object.assign({}, this.transfer.destination_bank_account);
    this.initialPaymentDate = this.transfer.payment_date;
    this.initialPaymentMethod = this.transfer.payment_method;
    this.initialPixKeyValidation = Object.assign({}, this.transfer.pix_key_validation);

    batchResource.countDbaFailedTransfersOnThisBatch(this.batch.id, dbaId).then(dbaFailedTransfersOnThisBatch => {
      this.dbaHasMoreThanOneTransfer = Boolean(dbaFailedTransfersOnThisBatch.count >= 2);
    });

    settingsResource.get().then(settings => {
      this.settings = settings;
    });
  },
  methods: {
    showTransferRefundConfirmationDialog() {
      this.$refs.transferEditionConfirmationDialog.open();
    },
    onChangeBank(bankId) {
      if (bankId) {
        this.transfer.destination_bank_account.bank_id = bankId;
      }
    },
    cancelAndRefund() {
      this.isLoading = true;

      batchResource.cancelTransfer(this.batch.id, this.transfer.id).then(() => {
        window.dispatchEvent(new Event('topbar.refresh'));
        this.$toaster.success('Sua transferência foi devolvida!');

        this.isLoading = false;
        if (this.onSave && typeof this.onSave === 'function') {
          this.onSave();
        }
      }, this.onEditTransferError);
    },
    onPaymentDateChange(paymentDate) {
      this.transfer.payment_date = paymentDate;
    },
    onResetDba() {
      this.transfer.status_description = '';
      this.$refs.form.resetSubmit();
    },
    onChangeDba(dba) {
      this.transfer.status_description = '';
      const transfer = merge({}, this.transfer);
      transfer.destination_bank_account = dba;
      this.transfer = transfer;
    },
    save() {
      this.isLoading = true;
      this.$refs.transferForm.validateDba();
    },
    onCancel() {
      this.transfer.destination_bank_account = this.initialDba;
      this.transfer.payment_date = this.initialPaymentDate;
      this.transfer.payment_method = this.initialPaymentMethod;
      this.cancel();
    },
    onSaveConfirm() {
      // eslint-disable-next-line no-self-assign
      this.transfer.destination_bank_account = this.transfer.destination_bank_account;
      const transferToSave = prepareToSave.prepare(this.transfer);

      if (this.dbaHasChanged()) {
        delete transferToSave.destination_bank_account.id;
      }

      if (this.transfer.destination_bank_account.status === destinationBankAccountStatus.FAILED) {
        checkpointResource.create(checkpoint.TRANSFER_FAIL_CORRECTION_CHANGING_DATA);
      } else {
        checkpointResource.create(checkpoint.TRANSFER_FAIL_CORRECTION_CHANGING_DATA_VALID_DBA);
      }
      delete transferToSave.id;
      batchResource.updateTransfer(this.batch.id, transferToSave, this.transfer.id).then(() => {
        this.$toaster.success('Sua transferência foi corrigida e será processada novamente!');
        this.isLoading = false;
        if (this.onSave && typeof this.onSave === 'function') {
          this.onSave();
        }
      }, this.onEditTransferError);
    },
    onEditTransferError(error) {
      this.$toaster.error(getMessage(error) || 'Ocorreu um erro ao editar a transferência');
      this.isLoading = false;
    },
    onDbaValidationError() {
      this.isLoading = false;
    },
    dbaHasChanged() {
      return (
        DestinationBankAccountDataChangeValidationService.hasChangedNonCriticalData(
          this.initialDba,
          this.transfer.destination_bank_account
        ) ||
        DestinationBankAccountDataChangeValidationService.hasChangedCriticalData(
          this.initialDba,
          this.transfer.destination_bank_account
        )
      );
    }
  }
};
</script>
