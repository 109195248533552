<template>
  <teleport to="#app-notification">
    <tr-alert-notification
      v-if="showNotification"
      role="alert"
      :full-width="true"
      type="warning"
    >
      {{ message }} - Mais detalhes na
      <a
        target="_blank"
        :href="eventHref"
      >Status Page</a>
    </tr-alert-notification>
  </teleport>
</template>

<script>
import statusPageAlerts from '@/commons/resources/statusPageAlerts';

export default {
  name: 'status-page-incident-notification-container',
  data: () => ({
    isSandbox: process.env.VUE_APP_MODE === 'sandbox',
    message: '',
    eventHref: 'https://status.transfeera.com/',
    showNotification: false
  }),
  async mounted() {
    if (this.isSandbox) {
      return;
    }

    const data = await statusPageAlerts.get();
    this.notify(data.incidents);
  },
  methods: {
    notify(incidents) {
      const activeIncident = incidents.find(incident => !incident.resolved_at);
      if (!activeIncident) {
        return;
      }

      this.message = activeIncident.name.slice(0, 120);
      if (this.message.length < activeIncident.name.length) {
        this.message += '...';
      }
      this.eventHref = activeIncident.shortlink || this.eventHref;
      this.showNotification = true;
    }
  }
};
</script>
