<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <tr-loading
          v-if="isLoading"
          classes="width-100"
        />
        <img
          v-if="imgSrc && !isLoading"
          style="height: 48px"
          :src="imgSrc"
          alt="Logo da empresa"
        >
        <div class="vertical-offset2">
          <div v-show="!file">
            <tr-button
              type="file"
              size="small"
              accept=".png, .jpg"
              :on-files-select="onFileSelect"
              label="Alterar logo"
            />
          </div>
          <div v-if="file">
            <tr-button
              class="pull-left margin-right"
              variant="outlined"
              size="small"
              :on-button-click="cancel"
              label="Cancelar"
              color="secondary"
            />
            <tr-button
              class="pull-left"
              variant="raised"
              color="primary"
              size="small"
              :on-button-click="save"
              label="Salvar"
            />
          </div>
        </div>
        <div
          v-if="file"
          class="vertical-offset2"
        />
      </div>
    </div>
    <div class="row">
      <tr-typography classes="block col-md-12 vertical-offset2">
        Essa logo aparece no seu comprovante e deve ter no mínimo 48px de altura, ser menor que 1MB e estar no formato
        JPG ou PNG
      </tr-typography>
    </div>
  </div>
</template>

<script>
import companyResource from '@/commons/resources/company';
import { getMessage } from '@/commons/services/error';
import { errors } from '@transfeeradev/api-enums';

export default {
  name: 'logo-upload-container',
  data: () => ({
    imgSrc: '',
    file: null,
    isLoading: true
  }),
  created() {
    this.refresh();
  },
  methods: {
    refresh() {
      this.file = null;
      this.isLoading = true;
      companyResource.get().then(
        result => {
          this.imgSrc = result.logo;
          this.isLoading = false;
        },
        error => {
          this.$toaster.error(getMessage(error));
          this.isLoading = false;
        }
      );
    },
    onFileSelect(files) {
      this.file = files[0];
      const reader = new FileReader();

      const isValid = this._validateFile(this.file);

      if (!isValid) {
        this.file = null;
        return;
      }

      reader.onload = e => {
        this.imgSrc = e.target.result;
      };

      reader.readAsDataURL(this.file);
    },
    cancel() {
      this.refresh();
    },
    save() {
      if (!this.file) {
        return;
      }

      this.isLoading = true;
      companyResource.uploadLogo(this.file).then(
        () => this.refresh(),
        error => {
          this.$toaster.error(getMessage(error));
          this.isLoading = false;
        }
      );
    },
    _validateFile(file) {
      let isValid = true;
      const oneMegabyte = 1048576;

      if (file.size > oneMegabyte) {
        this.$toaster.error(errors.ptBr.com.IMAGE_EXCEEDED_SIZE.message);
        isValid = false;
      }

      if (file.type !== 'image/png' && file.type !== 'image/jpeg' && file.type !== 'image/jpg') {
        this.$toaster.error(errors.ptBr.com.IMAGE_INVALID_FORMAT.message);
        isValid = false;
      }

      return isValid;
    }
  }
};
</script>
