<template>
  <bank-image
    :bank="bank"
    :size="size"
    :classes="classes"
  />
</template>

<script>
import BankImage from '@/apps/payments/payments/bank/components/presentational/BankImage';
import bankResource from '@/apps/payments/payments/bank/resources/bank';
import ListItemGetterService from '@transfeeradev/api-services/ListItemGetterService';

export default {
  name: 'bank-image-container',
  components: {
    BankImage
  },
  props: {
    bankImage: {
      required: false,
      type: String
    },
    bankId: {
      required: false,
      type: [String, Number]
    },
    size: {
      required: false,
      type: String
    },
    classes: {
      required: false,
      type: String
    }
  },
  data: () => ({
    bank: {}
  }),
  watch: {
    bankImage() {
      return this.refresh();
    },
    bankId() {
      return this.refresh();
    }
  },
  created() {
    return this.refresh();
  },
  methods: {
    refresh() {
      if (!this.bankId && !this.bankImage) {
        this.bank = {};
        return;
      }

      if (this.bankImage) {
        this.bank = {
          image: this.bankImage,
          bankId: this.bankId
        };
        return;
      }

      return this._getBankById();
    },
    _getBankById() {
      return bankResource.get().then(banks => {
        this.bank = ListItemGetterService.getByProp({
          propValue: this.bankId,
          propName: 'id',
          list: banks
        });
      });
    }
  }
};
</script>
