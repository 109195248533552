<template>
  <tr-page-section
    :no-margin="noMargin"
    :style="{ marginTop: '-40px' }"
  >
    <div
      v-if="dba.pix_key"
      class="row vertical-offset3"
    >
      <view-dba-pix-key
        :dba="dba"
        :no-margin-on-last-line="true"
      />
    </div>
    <div
      class="row"
      :class="dba.pix_key ? 'vertical-offset1' : 'vertical-offset3'"
    >
      <tr-read-only-text-field
        v-if="dba.name"
        label="Nome"
        :value="capitalize(dba.name)"
        :no-margin="true"
      />
    </div>
    <tr-typography
      v-if="![keyType.CNPJ, keyType.CPF].includes(dba.pix_key_type)"
      variant="h5"
      classes="vertical-offset1 block"
    >
      <dba-document-view :dba="dba" />
    </tr-typography>
    <div
      v-if="!dba.pix_key"
      class="row"
    >
      <div class="col-xs-12 vertical-offset3">
        <view-bank-account-container
          :bank-account="dba"
          :hide-name-and-document="true"
          :no-margin-on-last-line="true"
        />
      </div>
    </div>
  </tr-page-section>
</template>

<script>
import capitalize from '@/commons/formatters/capitalize';
import DbaDocumentView from '@/apps/payments/payments/dba/components/presentational/DbaDocumentView.vue';
import ViewDbaPixKey from '@/apps/payments/payments/dba/components/presentational/ViewDbaPixKey.vue';
import ViewBankAccountContainer from '@/apps/payments/payments/bank/components/container/ViewBankAccountContainer.vue';
import dbaModel from '@/apps/payments/payments/dba/models/dba';
import { regexes } from '@transfeeradev/api-enums';
import { keyType } from '@transfeeradev/api-enums/pix';

export default {
  name: 'view-dba',
  components: {
    DbaDocumentView,
    ViewDbaPixKey,
    ViewBankAccountContainer
  },
  props: {
    dba: {
      required: false,
      type: Object,
      default: dbaModel.get
    },
    noMargin: {
      type: Boolean
    }
  },
  data: () => ({
    EMAIL: regexes.EMAIL,
    keyType
  }),
  methods: {
    capitalize
  }
};
</script>
