<template>
  <billet-card
    :billet="billet"
    :hide-status-pill="hideStatusPill"
    :is-failed="isFailed"
    :can-edit="canEdit"
    :on-card-click="onCardClick"
    :has-warning="hasWarning"
  />
</template>

<script>
import BilletCard from '@/apps/payments/payments/billet/components/presentational/BilletCard';
import verifyRoleAccess from '@/commons/services/verifyRoleAccess';
import { batchStatus, roles } from '@transfeeradev/api-enums';

const component = {
  name: 'billet-card-container',
  props: {
    batch: {
      required: false,
      type: Object,
      default: () => ({})
    },
    billet: {
      required: false,
      type: Object,
      default: () => ({})
    },
    onCardClick: {
      required: false,
      type: Function
    },
    hideStatusPill: {
      required: false,
      type: Boolean
    }
  },
  components: {
    BilletCard
  },
  computed: {
    isFailed() {
      return Boolean(this.billet._errors && this.billet._errors.length);
    },
    hasWarning() {
      return Boolean(this.billet._warnings && this.billet._warnings.length);
    },
    canEdit() {
      const isUserAllowed = !verifyRoleAccess.isLoggedUserForbiddenToAccess(roles.appRoles.ANALYST);
      return Boolean(isUserAllowed && this.batch.status === batchStatus.DRAFT);
    }
  }
};

export default component;
</script>
