<template>
  <tr-button
    :class="`${classes || ''}`"
    variant="raised"
    :disabled="disabled"
    :label="`Excluir Falhas (${failedPaymentsCount})`"
    color="danger"
    type="button"
    :on-button-click="onButtonClick"
  />
</template>

<script>
export default {
  name: 'remove-failed-payments-button',
  props: {
    failedPaymentsCount: {
      required: true,
      type: Number
    },
    onButtonClick: {
      required: true,
      type: Function
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false
    },
    classes: {
      required: false,
      type: String
    }
  }
};
</script>
