<template>
  <tr-drag-and-drop-area-state title="Importando seus pagamentos">
    <template #main-icon>
      <tr-loading classes="vertical-offset5" />
    </template>
    <div class="row vertical-offset1 block bottom-offset4">
      <tr-button
        label="Cancelar a importação"
        text-variant="body"
        variant="text"
        :link-color="true"
        :on-button-click="cancel"
      />
    </div>
  </tr-drag-and-drop-area-state>
</template>

<script>
export default {
  name: 'sheet-import-loading-state',
  props: {
    cancel: {
      required: true,
      type: Function
    }
  }
};
</script>
