<template>
  <bg-icon
    v-tooltip="tooltip"
    :name="icon"
    size="sm"
    :aria-label="tooltip"
  />
</template>

<script setup>
import { paymentMethodNames } from '@/apps/payin/receivables/constants/paymentMethod';
import { paymentMethod as paymentMethods } from '@transfeeradev/api-enums/payinApi';
import { BgIcon } from '@transfeeradev/bridge';
import { computed } from 'vue';

const props = defineProps({
  paymentMethod: {
    required: true,
    type: String
  }
});

const paymentMethodMacro = computed(
  () =>
    ({
      [paymentMethods.pixKey]: 'pix',
      [paymentMethods.pixQrCodeStatic]: 'pix',
      [paymentMethods.pixQrCodeDynamic]: 'pix',
      [paymentMethods.chargeBoleto]: 'boleto',
      [paymentMethods.chargeBoletoQrCode]: 'pix',
      [paymentMethods.chargePixQrCodeDynamic]: 'pix',
      [paymentMethods.manual]: 'pix',
      [paymentMethods.creditCard]: 'credit_card'
    }[props.paymentMethod])
);

const tooltip = computed(() => paymentMethodNames[props.paymentMethod]);

const icon = computed(
  () =>
    ({
      pix: 'pix',
      boleto: 'receipt-long',
      credit_card: 'credit-card'
    }[paymentMethodMacro.value])
);
</script>
