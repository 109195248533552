import { APPS } from '@/commons/constants/apps';
import SettingsLayout from './Layout';
import AccountSettingsPage from './pages/AccountSettingsPage.vue';

export default {
  path: '',
  component: SettingsLayout,
  name: 'MyAccountSettings',
  redirect: { name: 'MyAccountAccountDataPage' },
  children: [
    {
      path: 'seguranca',
      name: 'MyAccountSecuritySettingsPage',
      component: AccountSettingsPage
    },
    {
      path: 'dados-da-conta',
      name: 'MyAccountAccountDataPage',
      component: AccountSettingsPage
    }
  ].map(route => ({
    ...route,
    meta: {
      ...route.meta,
      authAs: [APPS.PAYMENTS.key, APPS.CONTACERTA.key]
    }
  }))
};
