<template>
  <tr-page-section
    title="Informações da empresa"
    :no-margin="true"
  >
    <div class="row">
      <tr-read-only-text-field
        label="Razão social da sua empresa"
        :value="company.name"
      />
    </div>
    <div class="row">
      <tr-read-only-text-field
        label="CNPJ"
        :value="cpfCnpj"
      />
    </div>
    <div class="row">
      <tr-text-field
        v-model="company.phone"
        label="Telefone"
        no-margin="true"
        :mask-format="masks.PHONE"
        name="company-phone"
      />
    </div>
  </tr-page-section>
</template>

<script>
import formatCpfCnpj from '@/commons/formatters/cpfCnpj';
import masks from '@/commons/constants/masks';

export default {
  name: 'company-form',
  props: ['company'],
  data: () => ({
    masks
  }),
  computed: {
    cpfCnpj() {
      return formatCpfCnpj(this.company.cpfCnpj);
    }
  }
};
</script>
