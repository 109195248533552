<template>
  <div class="row margin-left-10 margin-right-10">
    <div class="col-lg-1 text-left">
      <img
        :src="bankImage"
        width="18"
        class="ic--mini"
        :alt="suggestion.name"
      >
    </div>
    <div
      :class="{
        'col-lg-3': showIspb,
        'col-lg-1': !showIspb
      }"
    >
      ({{ bankCode }})
    </div>
    <div
      :class="{
        'text-limit': true,
        'col-lg-8': showIspb,
        'col-lg-10': !showIspb
      }"
    >
      {{ suggestion.name }}
    </div>
  </div>
</template>

<script>
import config from '@/config';

export default {
  name: 'bank-autocomplete-suggestion',
  props: {
    suggestion: {
      type: Object,
      required: true
    },
    showIspb: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    bankImage: function () {
      if (!this.suggestion || !this.suggestion.image) {
        return `${config.url.cdn}/banks/default_bank.svg`;
      }

      return this.suggestion.image;
    },
    bankCode() {
      if (this.showIspb) {
        if (this.suggestion.code && this.suggestion.ispb) {
          return `${this.suggestion.code}/${this.suggestion.ispb}`;
        }
        return this.suggestion.code || this.suggestion.ispb;
      }

      return this.suggestion.code;
    }
  }
};
</script>
