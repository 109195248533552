<template>
  <tr-drag-and-drop-area
    ref="dragAndDropArea"
    classes="spreadsheet-import"
    :on-drop="buildFile"
  >
    <template #dragState>
      <sheet-import-drag-state :on-file-select="buildFile" />
    </template>
    <template #dragOverState>
      <sheet-import-drag-over-state />
    </template>
    <template #loadingState>
      <sheet-import-loading-state :cancel="cancel" />
    </template>
    <template #errorState>
      <sheet-import-error-state
        :error-message="errorMessage"
        :on-file-select="buildFile"
      />
    </template>
  </tr-drag-and-drop-area>
  <batch-fullscreen-modal
    ref="batchFullscreenModal"
    :unique-name="true"
    :was-just-imported="true"
    :on-change="onChangeBatchOnModal"
    :on-batch-close="onBatchClose"
  />
</template>

<script>
import BatchFullscreenModal from '@/apps/payments/payments/batch/pages/BatchFullscreenModal';
import SheetImport from '@/apps/payments/payments/spreadsheetImport/services/SheetImport';
import SheetImportDragState from '@/apps/payments/payments/spreadsheetImport/components/presentational/SheetImportDragState';
import SheetImportDragOverState from '@/apps/payments/payments/spreadsheetImport/components/presentational/SheetImportDragOverState';
import SheetImportLoadingState from '@/apps/payments/payments/spreadsheetImport/components/presentational/SheetImportLoadingState';
import SheetImportErrorState from '@/apps/payments/payments/spreadsheetImport/components/presentational/SheetImportErrorState';
import checkpointResource from '@/apps/payments/resources/checkpoint';
import checkpoint from '@/commons/constants/checkpoint';
import { getMessage } from '@/commons/services/error';

export default {
  name: 'sheet-import-container',
  components: {
    BatchFullscreenModal,
    SheetImportDragState,
    SheetImportDragOverState,
    SheetImportLoadingState,
    SheetImportErrorState
  },
  data: () => ({
    errorMessage: '',
    file: null,
    fileName: null,
    sheetImport: null
  }),
  created() {
    this.openAccountDataIfIsNotFilled();
  },
  methods: {
    openAccountDataIfIsNotFilled() {
      checkpointResource.exists(checkpoint.HAS_FILLED_FIRST_STEPS).then(
        response => {
          if (!response.exists) {
            this.$router.push({ name: 'FirstStepsPage', state: { redirectedFrom: 'SheetImportPage' } });
          }
        },
        error => this.$toaster.error(getMessage(error))
      );
    },
    buildFile(droppedFiles) {
      this.file = droppedFiles[0];
      this.$refs.dragAndDropArea.setLoadingState();

      this.sheetImport = new SheetImport(
        this.file,
        this.openBatchFullscreenModal.bind(this),
        this.throwError.bind(this)
      );
      this.sheetImport.buildFile();
    },
    throwError(err, dontThrowError) {
      if (this.$refs.dragAndDropArea) {
        this.$refs.dragAndDropArea.setErrorState();
      }

      this.errorMessage =
        getMessage(err) || 'Seu arquivo está fora do modelo. Mantenha sempre os mesmos nomes do cabeçalho.';

      if (!dontThrowError) {
        if (err instanceof Error) {
          throw err;
        }
        throw new Error(JSON.stringify(err));
      }
    },
    openBatchFullscreenModal(batch) {
      if (!this.$refs) {
        return;
      }

      this.$refs.batchFullscreenModal.open(batch.id);
      this.$refs.dragAndDropArea.setDragState();
    },
    cancel() {
      this.sheetImport.cancel();
      this.$refs.dragAndDropArea.setDragState();
    },
    onChangeBatchOnModal() {
      this.$router.push({ name: 'BatchesPage' });
    },
    onBatchClose() {
      this.$router.push({ name: 'BatchesPage' });
    }
  }
};
</script>
