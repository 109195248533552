<template>
  <tr-dialog
    ref="modal"
    name="password-change-form-dialog"
    title="Alterar senha"
    :hide-cancel-button="true"
  >
    <slot :close="close" />
  </tr-dialog>
</template>

<script>
export default {
  name: 'password-change-form-dialog',
  methods: {
    open() {
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    }
  }
};
</script>
