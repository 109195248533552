<template>
  <tr-fullscreen-modal
    ref="modal"
    :on-close="onHidden"
    name="batches-waiting-to-receive-fullscreen-modal"
  >
    <tr-page-title title="Aguardando recebimento" />
    <batches-waiting-to-receive-container :on-redirect="close" />
  </tr-fullscreen-modal>
</template>

<script>
import BatchesWaitingToReceiveContainer from '@/apps/payments/payments/batch/components/container/BatchesWaitingToReceiveContainer';

export default {
  name: 'batches-waiting-to-receive-fullscreen-modal',
  components: {
    BatchesWaitingToReceiveContainer
  },
  props: {
    onHidden: {
      required: false,
      type: Function
    }
  },
  methods: {
    open() {
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    }
  }
};
</script>
