import config from '@/config';
import fetch from '@/fetch';

const BASE_URL = config.url.api.checkpoint + '/checkpoints';

export default {
  exists: location => fetch(`${BASE_URL}/${location}/exists`),
  existsInCompany: location => fetch(`${BASE_URL}/${location}/exists?in_company=true`),
  create: (location, metadata = null) => {
    return fetch(`${BASE_URL}`, {
      method: 'POST',
      body: JSON.stringify({
        location,
        metadata
      })
    });
  }
};
