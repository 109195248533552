import moment from '@transfeeradev/moment';

export default value => {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY');
  }
  return value;
};

export const formatDateTime = (value, formatShape = 'DD/MM/YYYY HH:mm') => {
  if (value) {
    return moment(value).format(formatShape);
  }
  return value;
};
