import ErrorMessageTranslator from '@transfeeradev/api-services/ErrorMessageTranslator';
import { languages, errors } from '@transfeeradev/api-enums';

const translate = (code, data) => {
  try {
    return new ErrorMessageTranslator(languages.PT_BR).translate(code, data);
  } catch (error) {
    return errors.ptBr.sys.GENERIC_ERROR;
  }
};

export default {
  translate
};
