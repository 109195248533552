export const safeSum = (...numbersToSum) => {
  return numbersToSum.reduce((sum, currentValue) => {
    if (isNaN(currentValue)) {
      throw new Error('Invalid number provided');
    } else {
      return Number(sum) + Number(currentValue);
    }
  }, 0);
};

export const safeDivide = (a, b) => {
  const nA = Number(a);
  const nB = Number(b);

  if (isNaN(nA) || isNaN(nB)) {
    return 0;
  }

  if (nB === 0) {
    return 0;
  }

  return nA / nB;
};
