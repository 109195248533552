<template>
  <batches-waiting-balance-totals
    :balance="balance"
    :batches-total="batchesTotal"
    :value-waiting-to-be-received="valueWaitingToBeReceived"
  />
</template>

<script>
import BatchesWaitingBalanceTotals from '@/apps/payments/payments/batch/components/presentational/BatchesWaitingBalanceTotals';
import statementResource from '@/apps/payments/payments/statement/resources/statement';
import { getMessage } from '@/commons/services/error';

export default {
  name: 'batches-waiting-balance-totals-container',
  components: {
    BatchesWaitingBalanceTotals
  },
  props: {
    batchesTotal: {
      required: true,
      type: Number
    }
  },
  data: () => ({
    balance: 0,
    valueWaitingToBeReceived: 0
  }),
  created() {
    return this.refresh();
  },
  methods: {
    async refresh() {
      try {
        const balance = await statementResource.getBalance();
        this.balance = balance && balance.value;
        this.valueWaitingToBeReceived = balance && balance.waiting_value;
      } catch (error) {
        this.$toaster.error(getMessage(error));
      }
    }
  }
};
</script>
