<template>
  <chart ref="chart" />
</template>

<script>
import Chart from './Chart.vue';
import transferResource from '@/apps/payments/payments/transfer/resources/transfer';
import moment from '@transfeeradev/moment';
import { transferStatus } from '@transfeeradev/api-enums';

export default {
  name: 'quantity-of-transfers-per-month-chart',
  components: {
    Chart
  },
  props: {
    onLoad: {
      required: false,
      type: Function
    },
    height: {
      required: false,
      type: String
    },
    width: {
      required: false,
      type: String
    },
    dateRange: {
      required: true
    }
  },
  watch: {
    dateRange: {
      deep: true,
      handler() {
        this.load();
      }
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    renderChart(data, options) {
      this.$refs.chart.renderChart({
        type: 'bar',
        data,
        options
      });
    },
    async load() {
      const status = `${transferStatus.FINISHED},${transferStatus.TRANSFERRED}`;
      const data = (await transferResource.getQuantityPerMonth(this.dateRange, status)).sort((d1, d2) => {
        if (d1.date < d2.date) {
          return -1;
        }

        return 0;
      });

      const labels = data.map(d => moment(d.date).format('MM/YYYY'));
      const quantities = data.map(d => d.quantity);

      if (this.$refs.chart) {
        this.renderChart(
          {
            labels,
            datasets: [
              {
                label: '',
                backgroundColor: '#0096ED',
                data: quantities
              }
            ]
          },
          {
            legend: {
              display: false
            },
            title: {
              display: true,
              text: '',
              position: 'top',
              fontStyle: 'normal'
            },
            scales: {
              xAxes: [
                {
                  gridLines: {
                    display: true,
                    drawBorder: true,
                    drawOnChartArea: true,
                    drawTicks: true
                  }
                }
              ],
              yAxes: [
                {
                  gridLines: {
                    display: true,
                    drawBorder: true,
                    drawOnChartArea: true,
                    drawTicks: true
                  },
                  ticks: {
                    precision: 0
                  }
                }
              ]
            },
            tooltips: {
              callbacks: {
                title: () => {
                  return '';
                },
                label: tooltipItems => {
                  const item = data[tooltipItems.index];
                  return moment(item.date).format('MM/YYYY');
                },
                footer: tooltipItems => {
                  const item = data[tooltipItems[0].index];
                  return `Quantidade: ${item.quantity}`;
                }
              }
            },
            animation: {
              onComplete: () => {
                return this.onLoad && this.onLoad(data);
              }
            }
          }
        );
      }
    }
  }
};
</script>
