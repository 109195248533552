<template>
  <div class="row">
    <div class="col-lg-4">
      <batch-card :batch="exampleBatch" />
    </div>
    <div class="col-lg-8">
      <tr-slimbox
        title="Os lotes de pagamentos que você cria, ficam aqui!"
        :with-border="true"
        arrow-direction="left-top"
      >
        Seu histórico de pagamentos ficam organizados, aqui nos seus lotes
      </tr-slimbox>
    </div>
  </div>
</template>

<script>
import BatchCard from '@/apps/payments/payments/batch/components/presentational/BatchCard';
import { batchStatus } from '@transfeeradev/api-enums';

export default {
  name: 'batch-first-use-blankslate',
  components: {
    BatchCard
  },
  data: () => ({
    exampleBatch: {
      name: 'Exemplo',
      value: 100,
      status: batchStatus.FINISHED
    }
  })
};
</script>
