<template>
  <div>
    <div class="inline-block">
      <tr-switch
        classes="pull-left margin-right"
        :no-margin="true"
        :is-checked="shouldShowPaymentDate"
        :on-change="onSwitchChange"
      />
      <tr-typography
        v-if="!shouldShowPaymentDate"
        class="pull-left"
        style="margin-top: 5px"
      >
        Agendar para outro dia
      </tr-typography>
      <tr-typography
        v-if="shouldShowPaymentDate"
        class="pull-left"
        style="margin-top: 5px"
      >
        Agendar para o dia:
      </tr-typography>
    </div>
    <div class="row">
      <transfer-payment-date-field-container
        v-if="shouldShowPaymentDate"
        label=""
        classes="col-lg-12 vertical-offset2"
        :dont-use-col-classes="true"
        datepicker-orientation="top"
        :payment-date="transfer.payment_date"
        :transfer="transfer"
        :bank-id="transfer.destination_bank_account && transfer.destination_bank_account.bank_id"
        :no-margin="true"
        :on-change="onChange"
      />
    </div>
  </div>
</template>

<script>
import TransferPaymentDateFieldContainer from '@/apps/payments/payments/transfer/components/container/TransferPaymentDateFieldContainer';

export default {
  name: 'transfer-payment-date-switch-container',
  components: {
    TransferPaymentDateFieldContainer
  },
  props: {
    paymentDate: {
      required: false
    },
    transfer: {
      required: false,
      type: Object,
      default: () => ({
        destination_bank_account: {}
      })
    },
    onChange: {
      required: true,
      type: Function
    }
  },
  data: () => ({
    isChecked: false
  }),
  computed: {
    shouldShowPaymentDate() {
      return this.isChecked || Boolean(this.paymentDate);
    }
  },
  methods: {
    onSwitchChange(isChecked) {
      if (!isChecked) {
        this.onChange(null);
      }

      this.isChecked = isChecked;
    }
  }
};
</script>
