<template>
  <pix-selection-container
    v-if="currentStep === entryWizardStepsConstant.ENTRY_PIX_SELECTION"
    :go-to-bank-selection="goToBankSelection"
    :close="close"
  />
  <entry-form-container
    v-if="currentStep === entryWizardStepsConstant.ENTRY_BANK_SELECTION"
    :close="close"
  />
</template>

<script>
import EntryFormContainer from '@/apps/payments/payments/statement/components/container/EntryFormContainer.vue';
import PixSelectionContainer from '@/apps/payments/payments/statement/components/container/PixSelectionContainer.vue';

const entryWizardStepsConstant = {
  ENTRY_PIX_SELECTION: 'ENTRY_PIX_SELECTION',
  ENTRY_BANK_SELECTION: 'ENTRY_BANK_SELECTION'
};

export default {
  name: 'entry-wizard-container',
  components: {
    EntryFormContainer,
    PixSelectionContainer
  },
  props: {
    onChange: {
      required: false,
      type: Function
    },
    close: {
      required: false,
      type: Function
    }
  },
  data: () => ({
    currentStep: entryWizardStepsConstant.ENTRY_PIX_SELECTION,
    entryWizardStepsConstant
  }),
  methods: {
    goToBankSelection() {
      this.currentStep = entryWizardStepsConstant.ENTRY_BANK_SELECTION;
    }
  }
};
</script>
