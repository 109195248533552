<template>
  <div>
    <tr-page-section title="Sua Conta Pix na Transfeera">
      <template #description>
        Use sua conta Pix para <strong>receber e enviar pagamentos Pix</strong> utilizando sua conta Transfeera
      </template>
      <div
        v-if="isLoading"
        class="center-h-and-v"
      >
        <tr-loading />
      </div>
      <div
        v-else
        class="row vertical-offset4"
      >
        <tr-read-only-text-field
          classes="col-md-3"
          label="ISPB"
          :value="pixAccount.BankISPB"
          :dont-use-col-classes="true"
        />
        <tr-read-only-text-field
          class="col-md-2"
          label="Agência"
          :value="pixAccount.agency"
          :dont-use-col-classes="true"
        />
        <tr-read-only-text-field
          classes="col-md-3"
          label="Conta"
          :value="pixAccount.accountNumber"
          :dont-use-col-classes="true"
        />
        <tr-read-only-text-field
          classes="col-md-3"
          label="Tipo da conta"
          :value="pixAccount.accountType"
          :dont-use-col-classes="true"
        />
      </div>
    </tr-page-section>
  </div>
</template>

<script>
import { accountSource } from '@transfeeradev/api-enums';
import companyBankAccountResource from '@/apps/payments/payments/settings/bankAccounts/resources/bankAccount';
import accountType from '@/commons/constants/accountType';
import { getMessage } from '@/commons/services/error';

export default {
  name: 'pix-account-container',
  data: () => ({
    isLoading: true,
    pixAccount: {
      agency: '',
      accountNumber: '',
      accountType: '',
      BankISPB: ''
    }
  }),
  mounted() {
    this.getPixAccount();
  },
  methods: {
    async getPixAccount() {
      this.isLoading = true;

      try {
        const companyAccount = await companyBankAccountResource.get(accountSource.TRANSFEERA);

        if (companyAccount) {
          this.pixAccount = {
            agency: `${companyAccount.agency}${companyAccount.agency_digit ? companyAccount.agency_digit : ''}`,
            accountNumber: `${companyAccount.account}${companyAccount.account_digit}`,
            accountType: accountType[companyAccount.account_type],
            BankISPB: companyAccount.Bank ? companyAccount.Bank.ispb : ''
          };
        }
      } catch (error) {
        this.$toaster.error(getMessage(error));
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>
