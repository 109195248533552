import { captureException } from '@sentry/vue';
import languages from '@transfeeradev/api-enums/languages';
import ErrorMessageTranslator from '@transfeeradev/api-services/ErrorMessageTranslator';

export function getMessage(error, fallbackMessage) {
  if (error?.code) {
    try {
      return new ErrorMessageTranslator(languages.PT_BR).translate(error.code, error.data).message;
    } catch (e) {
      captureException(e, { extra: { code: error.code } });
      return fallbackMessage || error?.message;
    }
  }

  return fallbackMessage || error?.message;
}
