<template>
  <tr-table :disable-click="true">
    <tr-thead>
      <tr-tr>
        <tr-th width="13%">
          Competência
        </tr-th>
        <tr-th width="13%">
          Quantidade
        </tr-th>
        <tr-th width="16%">
          Valor
        </tr-th>
        <tr-th width="20%">
          Status do pagamento
        </tr-th>
        <tr-th width="11%">
          Relatório
        </tr-th>
        <tr-th width="12%">
          Nota Fiscal
        </tr-th>
        <tr-th width="12%">
          Boleto
        </tr-th>
      </tr-tr>
    </tr-thead>
    <tr-tbody>
      <tr-tr
        v-for="bill in billing"
        :key="bill.id"
        :disable-click="true"
      >
        <tr-td>
          <tr-typography>
            {{ formatDateMonth(bill.billing_period_start) }}
          </tr-typography>
        </tr-td>
        <tr-td>
          <tr-typography>
            {{ bill.amount_of_transfers }}
          </tr-typography>
        </tr-td>
        <tr-td>
          <tr-typography>
            {{ $format.currency(bill.total) }}
          </tr-typography>
        </tr-td>
        <tr-td>
          <billing-status-pill
            :no-margin="true"
            size="medium"
            :status="bill.status"
          />
        </tr-td>
        <tr-td>
          <tr-button
            label="Baixar"
            size="small"
            button-classes="width-100"
            :disabled="!bill.billing_period_start || !getMonthlyReport"
            :on-button-click="getMonthlyReport && getMonthlyReport.bind(null, bill.billing_period_start)"
            color="secondary"
            variant="outlined"
          />
        </tr-td>
        <tr-td>
          <tr-button
            label="Baixar"
            size="small"
            button-classes="width-100"
            :disabled="!bill.electronic_invoice_url"
            :href="bill.electronic_invoice_url"
            :open-in-new-tab="true"
            color="secondary"
            variant="outlined"
          />
        </tr-td>
        <tr-td>
          <tr-button
            label="Baixar boleto"
            size="small"
            button-classes="width-100"
            :href="bill.ticket_url"
            :disabled="bill.status === invoiceStatus.PAID || !bill.ticket_url"
            :open-in-new-tab="true"
            color="secondary"
            variant="outlined"
          />
        </tr-td>
      </tr-tr>
    </tr-tbody>
  </tr-table>
</template>

<script>
import formatDateMonth from '@/commons/formatters/formatDateMonth';
import BillingStatusPill from '@/apps/admin/billing/components/presentational/BillingStatusPill';
import { billing } from '@transfeeradev/api-enums';
const invoiceStatus = billing.invoiceStatus;

export default {
  name: 'billing-table',
  components: {
    BillingStatusPill
  },
  props: {
    billing: {
      required: false,
      type: Array,
      default: () => []
    },
    getMonthlyReport: {
      required: false,
      type: Function
    }
  },
  data: () => ({
    invoiceStatus
  }),
  methods: {
    formatDateMonth
  }
};
</script>
