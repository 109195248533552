import dbaModel from '@/apps/payments/payments/dba/models/dba';
import merge from 'deepmerge';

const model = {
  id: null,
  value: 0,
  integration_id: null,
  payment_method: null,
  pix_description: '',
  destination_bank_account: dbaModel.get(),
  $isValid: false
};

const get = () => merge({}, model);

export default {
  get
};
