/**
 * @typedef {Object} Config
 * @property {Object} url
 * @property {string} url.login
 * @property {string} url.cdn
 * @property {string} url.contacerta
 * @property {Object} url.api
 * @property {string} url.api.transfeera
 * @property {string} url.api.login
 * @property {string} url.api.checkpoint
 * @property {string} url.api.payin
 * @property {Object} app
 * @property {Object} app.sentry
 * @property {string} [app.sentry.dsn]
 */

/**
 * @type {Config}
 */
module.exports = {
  url: {
    login: process.env.VUE_APP_LOGIN_URL,
    cdn: process.env.VUE_APP_CDN_URL,
    contacerta: process.env.VUE_APP_CONTACERTA_API_URL,
    api: {
      transfeera: process.env.VUE_APP_TRANSFEERA_API_URL,
      login: process.env.VUE_APP_LOGIN_API_URL,
      checkpoint: process.env.VUE_APP_CHECKPOINT_API_URL,
      payin: process.env.VUE_APP_PAYIN_API_URL
    }
  },
  app: {
    sentry: {
      dsn: process.env.VUE_APP_SENTRY_DSN
    }
  }
};
