<template>
  <tr-page-section
    title="Informações pessoais"
    :no-margin="true"
  >
    <div class="row">
      <tr-text-field
        v-model="user.name"
        label="Seu nome completo"
        :classes="fullWidth ? 'col-md-12 col-lg-12' : ''"
        name="user-name"
        :required="true"
        :pattern="FULL_NAME"
        error-message="O nome deve ter no mínimo 2 palavras"
      />
      <tr-text-field
        v-model="user.email"
        label="Seu e-mail"
        :classes="fullWidth ? 'col-md-12 col-lg-12' : ''"
        type="email"
        disabled="true"
        name="user-email"
      />
    </div>
    <div class="row">
      <tr-text-field
        v-model="FAKE_PASSWORD"
        label="Sua senha"
        :classes="fullWidth ? 'col-md-12 col-lg-12' : ''"
        type="password"
        disabled="true"
        name="user-fakepassword"
      />
    </div>
    <tr-button
      :on-button-click="showPasswordChangeDialog"
      label="Alterar senha"
      variant="raised"
      color="default"
    />
    <password-change-form-dialog ref="passwordChangeFormDialog">
      <template #default="{ close }">
        <slot
          name="password-change-dialog"
          :close="close"
        />
      </template>
    </password-change-form-dialog>
  </tr-page-section>
</template>

<script>
import { regexes } from '@transfeeradev/api-enums';
import PasswordChangeFormDialog from './PasswordChangeFormDialog';

export default {
  name: 'personal-data-form',
  components: {
    PasswordChangeFormDialog
  },
  props: ['user', 'fullWidth'],
  data: () => ({
    FULL_NAME: regexes.FULL_NAME,
    FAKE_PASSWORD: 'fakepassword'
  }),
  methods: {
    showPasswordChangeDialog() {
      this.$refs.passwordChangeFormDialog.open();
    }
  }
};
</script>
