<template>
  <div>
    <div class="row text-center">
      <tr-typography variant="h2">
        {{ $format.currency(transfer.value) }}
      </tr-typography>
      <br>
      <tr-typography
        variant="h4"
        classes="vertical-offset1 block"
      >
        {{ capitalize(dba.name) }}
      </tr-typography>
      <tr-typography
        variant="h5"
        classes="vertical-offset1 block"
      >
        <dba-document-view :dba="dba" />
      </tr-typography>
    </div>
    <div class="row">
      <transfer-status-pill
        classes="col-lg-6 col-lg-offset-3 vertical-offset2"
        size="big"
        :transfer-status="transfer.status"
      />
    </div>
    <div class="row">
      <transfer-receipt-download-links-container
        classes="vertical-offset3 text-center"
        :should-always-show="true"
        :transfer="transfer"
      />
    </div>
    <div class="payment-details-receipt-cut vertical-offset3 bottom-offset2" />
    <tr-alert-notification
      v-if="transferFailReason"
      type="info"
      classes="bottom-offset3"
    >
      <tr-typography>
        {{ transferFailReason }}
      </tr-typography>
    </tr-alert-notification>
    <view-bank-account-container
      v-if="!dba.pix_key && !transfer.emv"
      :bank-account="dba"
      :hide-name-and-document="true"
      :full-size="true"
    />
    <div
      v-if="dba.pix_key && !transfer.emv"
      class="row"
    >
      <view-dba-pix-key :dba="dba" />
    </div>
    <div
      v-if="isFinishedEmv"
      class="row"
    >
      <tr-read-only-text-field
        label="Valor pago"
        classes="col-lg-5"
      >
        {{ $format.currency(transfer.value) }}
      </tr-read-only-text-field>
      <tr-read-only-text-field
        label="Valor Original"
        classes="col-lg-5"
      >
        {{ $format.currency(transfer.emv_data.amount ?? 0) }}
      </tr-read-only-text-field>
    </div>
    <div class="row">
      <tr-read-only-text-field
        v-if="transfer.created_at && (!isFinishedEmv || transfer.emv_data?.type !== qrcodeType.DUE_DATE_COLLECTION)"
        label="Data de criação"
        classes="col-lg-5"
        :value="formatDate(transfer.created_at)"
      />
      <tr-read-only-text-field
        v-if="isFinishedEmv && transfer.emv_data?.type === qrcodeType.DUE_DATE_COLLECTION"
        label="Data de vencimento"
        classes="col-lg-5"
        :value="formatDate(transfer.emv_data.due_date)"
      />
      <tr-read-only-text-field
        v-if="transfer.payment_date || transfer.returned_date || transfer.transfer_date"
        :label="transfer.transfer_date || transfer.returned_date ? 'Pago em' : 'Pagar em'"
        classes="col-lg-7"
        :value="formatDate(transfer.returned_date || transfer.transfer_date || transfer.payment_date)"
      />
    </div>
    <div
      v-if="isFinishedEmv && transfer.emv_data?.type === qrcodeType.DUE_DATE_COLLECTION"
      class="row"
    >
      <tr-read-only-text-field
        label="Multa"
        classes="col-lg-5"
      >
        {{ $format.currency(transfer.emv_data.fine_amount ?? 0) }}
      </tr-read-only-text-field>
      <tr-read-only-text-field
        label="Juros"
        classes="col-lg-5"
      >
        {{ $format.currency(transfer.emv_data.interest_amount ?? 0) }}
      </tr-read-only-text-field>
    </div>
    <div
      v-if="isFinishedEmv && transfer.emv_data?.type === qrcodeType.DUE_DATE_COLLECTION"
      class="row"
    >
      <tr-read-only-text-field
        label="Desconto"
        classes="col-lg-5"
      >
        {{ $format.currency(transfer.emv_data.discount_amount ?? 0) }}
      </tr-read-only-text-field>
      <tr-read-only-text-field
        label="Abatimento"
        classes="col-lg-5"
      >
        {{ $format.currency(transfer.emv_data.abatment_amount ?? 0) }}
      </tr-read-only-text-field>
    </div>
    <div
      v-if="isFinishedEmv && transfer.emv_data?.txid"
      class="row"
    >
      <tr-read-only-text-field
        classes="col-lg-12"
        label="Identificador da transação"
        :value="transfer.emv_data.txid"
      />
    </div>
    <div class="row">
      <tr-read-only-text-field
        label="Lote"
        classes="col-lg-5"
        :no-margin="!transfer.integration_id"
        :on-value-click="showBatchFullscreenModal"
        :value="batch.name"
      />
      <tr-read-only-text-field
        class="col-lg-4"
        label="ID da transferência"
        :no-margin="!transfer.integration_id"
        :value="transfer.id"
      />
    </div>
    <div class="row">
      <tr-read-only-text-field
        v-if="transfer.integration_id"
        classes="col-lg-7"
        :no-margin="true"
        label="Id de integração"
        :value="transfer.integration_id"
      />
    </div>
  </div>
</template>

<script>
import DbaDocumentView from '@/apps/payments/payments/dba/components/presentational/DbaDocumentView';
import formatDate from '@/commons/formatters/formatDate';
import TransferStatusPill from '@/apps/payments/payments/transfer/components/presentational/TransferStatusPill';
import ViewBankAccountContainer from '@/apps/payments/payments/bank/components/container/ViewBankAccountContainer';
import ViewDbaPixKey from '@/apps/payments/payments/dba/components/presentational/ViewDbaPixKey';
import TransferReceiptDownloadLinksContainer from '@/apps/payments/payments/transfer/components/container/TransferReceiptDownloadLinksContainer';
import capitalize from '@/commons/formatters/capitalize';
import { transferStatus, batchStatus, pix } from '@transfeeradev/api-enums';

export default {
  name: 'transfer-details',
  components: {
    DbaDocumentView,
    TransferStatusPill,
    ViewBankAccountContainer,
    ViewDbaPixKey,
    TransferReceiptDownloadLinksContainer
  },
  props: {
    transfer: {
      required: false,
      type: Object,
      default: () => ({})
    },
    dba: {
      required: false,
      type: Object,
      default: () => ({
        Bank: {}
      })
    },
    batch: {
      required: false,
      type: Object,
      default: () => ({})
    },
    showBatchFullscreenModal: {
      required: false,
      type: Function
    },
    transferFailReason: {
      required: false,
      type: String,
      default: ''
    }
  },
  data: () => ({
    transferStatus,
    batchStatus,
    qrcodeType: pix.qrcodeType
  }),
  computed: {
    isFinishedEmv() {
      return this.transfer.emv && this.transfer.status === transferStatus.FINISHED;
    }
  },
  methods: {
    formatDate,
    capitalize
  }
};
</script>
