import { APPS } from '@/commons/constants/apps';
import Layout from './Layout';
import settingsRoutes from './settings/routes';

export default {
  path: APPS.MY_ACCOUNT.path,
  component: Layout,
  name: 'MyAccount',
  redirect: {
    name: 'MyAccountSettings'
  },
  children: [settingsRoutes]
};
