<template>
  <div>
    <view-bank-account-container
      v-if="!isLoading"
      :full-size="true"
      :bank-account="bankAccount"
      :no-margin-on-last-line="true"
    />
    <tr-loading v-if="isLoading" />
  </div>
</template>

<script>
import { accountSource } from '@transfeeradev/api-enums';
import ViewBankAccountContainer from '@/apps/payments/payments/bank/components/container/ViewBankAccountContainer';
import companyBankAccountResource from '@/apps/payments/payments/settings/bankAccounts/resources/bankAccount';
import companyResource from '@/apps/payments/payments/settings/company/resources/company';

export default {
  name: 'view-company-bank-account-container',
  components: {
    ViewBankAccountContainer
  },
  data: () => ({
    bankAccount: {},
    isLoading: true
  }),
  created() {
    return this.refresh();
  },
  methods: {
    refresh() {
      this.isLoading = true;
      return Promise.all([this.getCompanyInfo(), this.getCompanyBankAccount()]).then(() => {
        this.isLoading = false;
      });
    },
    getCompanyBankAccount() {
      return companyBankAccountResource.get(accountSource.CUSTOMER).then(companyBankAccount => {
        this.bankAccount = Object.assign({}, this.bankAccount, companyBankAccount);
      });
    },
    getCompanyInfo() {
      return companyResource.get().then(company => {
        this.bankAccount = Object.assign({}, this.bankAccount, {
          name: company.name,
          cpfCnpj: company.cpfCnpj
        });
      });
    }
  }
};
</script>
