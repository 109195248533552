<template>
  <tr-page>
    <tr-page-header
      title="Seus boletos"
      :on-search="onSearchByText"
      :hide-button-for-roles="hideNewBatchActionForRoles"
      search-placeholder="Código, valor ou lote"
      :on-add-click="openCreateBatchModal"
    >
      <template #left-filter>
        <billet-status-dropdown-filter-container :on-search="onSearchByStatus" />
      </template>
      <template #center-filter>
        <tr-month-picker-button :on-filter="onSearchByDatePeriod" />
      </template>
      <template #right-filter>
        <bank-dropdown-filter-container :on-search="onSearchByBank" />
      </template>
    </tr-page-header>
    <tr-page-section :no-margin="true">
      <tr-loading
        v-if="isLoading"
        classes="center-h-and-v width-100 vertical-offset5"
      />
      <tr-empty-search-result
        v-if="!shouldShowBlankslate && !hasBillets && !isLoading"
        text="Nenhum boleto foi encontrado na sua busca"
      />
      <billet-first-use-blankslate v-if="shouldShowBlankslate && !hasBillets && !isLoading" />
      <billets-table
        v-if="hasBillets && !isLoading"
        :on-change="search"
        :billets="billets"
        @on-billet-checked="onBilletChecked"
      />
      <div class="is-flex flex-end vertical-offset3">
        <tr-button
          v-if="hasBillets && !isLoading"
          label="Baixar selecionados"
          variant="outlined"
          color="secondary"
          :on-button-click="downloadReceipts"
          :disabled="!checkedBillets.length"
        />
        <tr-button
          v-if="hasBillets && !isLoading"
          label="Baixar todos"
          variant="raised"
          class="horizontal-offset2"
          color="primary"
          :on-button-click="downloadAllReceipts"
        />
      </div>
      <tr-numeric-pagination
        v-if="!isLoading"
        class="billet-pagination"
        :current-page="currentPage"
        :page-size="pagination.itemsPerPage"
        :total="pagination.totalItems"
        :on-current-page-change="onCurrentPageChange"
      />
    </tr-page-section>
    <download-receipt-dialog
      ref="downloadReceiptDialog"
      :batch-type="batchType.BILLET"
      :item-ids="billetsToDownload"
    />
    <download-receipt-error-dialog
      ref="downloadReceiptErrorDialog"
      :message="downloadReceiptErrorMessage"
    />
    <download-without-receipt-dialog ref="downloadWithoutReceiptDialog" />
    <batch-fullscreen-modal
      ref="batchFullscreenModal"
      :unique-name="true"
      :type="batchType.BILLET"
      :on-change="search"
    />
  </tr-page>
</template>

<script>
import BilletStatusDropdownFilterContainer from '@/apps/payments/payments/billet/components/container/BilletStatusDropdownFilterContainer';
import BilletFirstUseBlankslate from '@/apps/payments/payments/billet/components/presentational/BilletFirstUseBlankslate';
import BilletsTable from '@/apps/payments/payments/billet/components/presentational/BilletsTable';
import BankDropdownFilterContainer from '@/apps/payments/payments/bank/components/container/BankDropdownFilterContainer';
import BatchFullscreenModal from '@/apps/payments/payments/batch/pages/BatchFullscreenModal';
import DownloadReceiptDialog from '@/apps/payments/payments/batch/components/presentational/DownloadReceiptDialog';
import DownloadReceiptErrorDialog from '@/apps/payments/payments/batch/components/presentational/DownloadReceiptErrorDialog';
import DownloadWithoutReceiptDialog from '@/apps/payments/payments/batch/components/presentational/DownloadWithoutReceiptDialog';
import loggedUserResource from '@/commons/resources/loggedUser';
import billetResource from '@/apps/payments/payments/billet/resources/billet';
import moment from '@transfeeradev/moment';
import checkpointResource from '@/apps/payments/resources/checkpoint';
import checkpoint from '@/commons/constants/checkpoint';
import { roles, batchType } from '@transfeeradev/api-enums';
import { getMessage } from '@/commons/services/error';

export default {
  name: 'billets-page',
  components: {
    BilletStatusDropdownFilterContainer,
    BilletFirstUseBlankslate,
    BilletsTable,
    BankDropdownFilterContainer,
    BatchFullscreenModal,
    DownloadReceiptDialog,
    DownloadReceiptErrorDialog,
    DownloadWithoutReceiptDialog
  },
  data: () => ({
    initialDate: moment().startOf('month'),
    endDate: moment().endOf('month'),
    searchTerm: '',
    currentPage: 0,
    isLoading: false,
    pagination: {},
    billets: [],
    searchStatus: undefined,
    searchBank: undefined,
    shouldShowBlankslate: false,
    hideNewBatchActionForRoles: roles.appRoles.ANALYST,
    batchType,
    downloadReceiptErrorMessage: '',
    checkedBillets: [],
    billetsToDownload: []
  }),
  computed: {
    hasBillets() {
      return Boolean(this.billets && this.billets.length);
    }
  },
  created() {
    this.refreshBlankslate();
    return this.search();
  },
  methods: {
    showBatchFullscreenModal() {
      this.$refs.batchFullscreenModal.open();
    },
    search(resetPagination = true) {
      this.isLoading = true;

      if (resetPagination) {
        this.currentPage = 0;
      }

      return billetResource
        .getFiltered(
          this.initialDate,
          this.endDate,
          this.currentPage,
          this.searchTerm,
          this.searchStatus,
          this.searchBank
        )
        .then(
          response => {
            this.isLoading = false;
            this.checkedBillets = [];

            if (response) {
              this.pagination = response.metadata.pagination;
              this.billets = response.data || [];
            } else {
              this.pagination = {};
              this.billets = [];
            }

            if (this.billets.length) {
              this.shouldShowBlankslate = false;
            } else if (this.shouldShowBlankslate) {
              this.refreshBlankslate();
            }
          },
          error => this.$toaster.error(getMessage(error))
        );
    },
    refreshBlankslate() {
      billetResource.hasBlankslate().then(
        response => {
          this.shouldShowBlankslate = response.data;
        },
        error => this.$toaster.error(getMessage(error))
      );
    },
    onSearchByText(searchTerm) {
      this.searchTerm = searchTerm;
      this.search();
    },
    onSearchByStatus(searchStatus) {
      this.searchStatus = searchStatus;
      this.search();
    },
    onSearchByBank(searchBank) {
      this.searchBank = searchBank;
      this.search();
    },
    onSearchByDatePeriod(initialDate, endDate) {
      this.initialDate = initialDate;
      this.endDate = endDate;
      this.search();
    },
    onCurrentPageChange(newCurrentPage) {
      this.currentPage = newCurrentPage;
      this.search(false);
    },
    onBilletChecked(billetIds) {
      this.checkedBillets = billetIds;
    },
    openCreateBatchModal() {
      const loggedUser = loggedUserResource.get();
      if (loggedUser.company.acceptedContract) {
        checkpointResource
          .create(checkpoint.OPENED_BILLET_CREATION_FROM_BILLETS_PAGE)
          .catch(error => this.$toaster.error(getMessage(error)));
        this.showBatchFullscreenModal();
      }
    },
    downloadReceipts() {
      this.billetsToDownload = this.checkedBillets;

      this.$refs.downloadReceiptDialog.open();
    },
    async downloadAllReceipts() {
      try {
        this.billetsToDownload = await billetResource.getAllWithReceipt(
          this.initialDate,
          this.endDate,
          this.searchTerm,
          this.searchStatus,
          this.searchBank
        );

        if (this.billetsToDownload.length > 0) {
          this.$refs.downloadReceiptDialog.open();
        } else {
          this.$refs.downloadWithoutReceiptDialog.open();
        }
      } catch (error) {
        this.downloadReceiptErrorMessage = getMessage(error);

        this.$refs.downloadReceiptErrorDialog.open();
      }
    }
  }
};
</script>

<style scoped>
.billet-pagination {
  margin-top: 40px !important;
}
</style>
