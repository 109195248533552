<template>
  <tr-empty-search-result
    text="Nenhuma validação foi encontrada"
  />
  <div class="text-center vertical-offset3">
    <tr-typography>Acesse <strong>validações</strong> para criar novas validações na ContaCerta!</tr-typography>
  </div>
  <div class="vertical-offset3">
    <tr-button
      classes="text-center"
      variant="raised"
      label="Ir para validações"
      color="primary"
      @click="goToValidation"
    />
  </div>
</template>

<script>
export default {
  name: 'validation-history-empty-search',
  methods: {
    goToValidation() {
      this.$router.push({ name: 'ContaCertaNewValidationPage' });
    }
  }
};
</script>
