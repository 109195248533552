<template>
  <tr-fullscreen-modal
    ref="modal"
    name="new-user-fullscreen-modal"
  >
    <new-user-form-container
      :on-save="onChange"
      :on-cancel="close"
    />
  </tr-fullscreen-modal>
</template>

<script>
import NewUserFormContainer from '../components/container/NewUserFormContainer';

export default {
  name: 'new-user-fullscreen-modal',
  components: {
    NewUserFormContainer
  },
  props: {
    onChange: {
      required: false,
      type: Function
    }
  },
  methods: {
    open() {
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    }
  }
};
</script>
