<template>
  <div class="row">
    <div :class="!fullSize ? 'col-lg-6 col-md-6' : 'col-lg-12'">
      <tr-text-field
        v-model="internalValue"
        :select="true"
        :required="true"
        :dont-use-col-classes="true"
      >
        <option
          v-for="(bankAccount, index) in bankAccounts"
          :key="index + 1"
          :value="index + 1"
        >
          {{ itemName(bankAccount) }}
        </option>
      </tr-text-field>
    </div>
  </div>
</template>

<script>
import formatCpfCnpj from '@/commons/formatters/cpfCnpj';
import formatPhone from '@/commons/formatters/phone';
import breakBiggerWord from '@/commons/formatters/breakBiggerWord';
import { pix } from '@transfeeradev/api-enums';

export default {
  name: 'bank-account-selection',
  props: {
    bankAccounts: {
      required: false,
      type: Array,
      default: () => []
    },
    onSelect: {
      required: true,
      type: Function
    },
    fullSize: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return { selectedIdx: 1 };
  },
  computed: {
    internalValue: {
      get() {
        return this.selectedIdx;
      },
      set(value) {
        this.onSelect(this.bankAccounts[value - 1]);
        this.selectedIdx = value;
      }
    }
  },
  methods: {
    validate() {
      return !!this.bankAccounts[this.selectedIdx - 1];
    },
    itemName(item) {
      if (!item.isPix) {
        return item.Bank.name;
      }

      if (item.isTransfeera) {
        return 'Conta Pix Transfeera';
      }
      let key = item.key;
      if (item.keyType === pix.keyType.CNPJ) {
        key = formatCpfCnpj(key);
      }
      if (item.keyType === pix.keyType.TELEFONE) {
        key = formatPhone(key);
      }

      return 'Conta Pix - ' + breakBiggerWord(key, 50);
    }
  }
};
</script>
