<template>
  <tr-table :disable-click="disableClick">
    <tr-thead>
      <tr-tr>
        <tr-th
          v-tooltip.top-center="'Selecionar todos dessa página'"
          class="cursor-pointer"
          @click.stop="selectAll = !selectAll"
        >
          <input
            id="select-all"
            v-model="selectAll"
            type="checkbox"
            class="cursor-pointer"
            style="margin-left: 10px"
          >
        </tr-th>
        <tr-th width="10%">
          Criado em
        </tr-th>
        <tr-th width="27%">
          Recebedor
        </tr-th>
        <tr-th width="20%">
          Pix ou Conta
        </tr-th>
        <tr-th width="18%">
          Status
        </tr-th>
        <tr-th width="12%">
          Valor
        </tr-th>
        <tr-th width="10%" />
      </tr-tr>
    </tr-thead>
    <tr-tbody>
      <tr-tr
        v-for="transfer in transfers"
        :key="transfer.id"
        :disable-click="disableClick"
        :on-item-click="showTransferDetailsDialog.bind(undefined, transfer)"
      >
        <tr-td @click.stop>
          <input
            :id="`transfer-${transfer.id}`"
            v-model="checkedTransfers"
            type="checkbox"
            class="cursor-pointer"
            style="margin-left: 10px"
            :disabled="!hasReceiptToDownload(transfer)"
            :value="transfer.id"
          >
        </tr-td>
        <tr-td>
          <tr-typography>
            {{ formatDate(transfer.created_at) }}
          </tr-typography>
        </tr-td>
        <tr-td>
          <tr-typography class="margin-right-10">
            <bank-image
              :bank="transfer.DestinationBankAccount.Bank"
              :is-pix="!!transfer.DestinationBankAccount.pix_key"
            />
          </tr-typography>
          <tr-typography v-tooltip.top-center="getDbaName(transfer.DestinationBankAccount)">
            {{ breakBiggerWord(getDbaName(transfer.DestinationBankAccount), 40) }}
          </tr-typography>
        </tr-td>
        <tr-td>
          <tr-typography v-tooltip.top-center="dbaAccountRepr(transfer.DestinationBankAccount)">
            {{ breakBiggerWord(dbaAccountRepr(transfer.DestinationBankAccount), 20) }}
          </tr-typography>
        </tr-td>
        <tr-td>
          <transfer-status-pill
            no-margin="true"
            size="medium"
            :transfer-status="transfer.status"
          />
        </tr-td>
        <tr-td>
          <tr-typography>
            <span class="inline-block">{{ $format.currency(transfer.value) }}</span>
          </tr-typography>
        </tr-td>
        <tr-td>
          <transfer-receipt-download-links-container
            :show-fake-links="showFakeReceiptLinks"
            :should-always-show="true"
            :transfer="transfer"
          />
        </tr-td>
      </tr-tr>
    </tr-tbody>
    <transfer-details-dialog
      ref="transferDetailsDialog"
      :show-batch-fullscreen-modal="showBatchFullscreenModal"
      :transfer-id="clickedTransfer.id"
    />
    <batch-fullscreen-modal
      ref="batchFullscreenModal"
      :on-change="onChange"
      :unique-name="true"
    />
  </tr-table>
</template>

<script>
import formatDate from '@/commons/formatters/formatDate';
import breakBiggerWord from '@/commons/formatters/breakBiggerWord';
import capitalize from '@/commons/formatters/capitalize';
import TransferStatusPill from '@/apps/payments/payments/transfer/components/presentational/TransferStatusPill';
import TransferReceiptDownloadLinksContainer from '@/apps/payments/payments/transfer/components/container/TransferReceiptDownloadLinksContainer';
import TransferDetailsDialog from '@/apps/payments/payments/transfer/components/presentational/TransferDetailsDialog';
import BankImage from '@/apps/payments/payments/bank/components/presentational/BankImage';
import BatchFullscreenModal from '@/apps/payments/payments/batch/pages/BatchFullscreenModal';
import checkpointResource from '@/apps/payments/resources/checkpoint';
import checkpoint from '@/commons/constants/checkpoint';
import dbaAccountRepr from '@/commons/formatters/dbaAccountRepr';
import { getMessage } from '@/commons/services/error';

export default {
  name: 'transfers-table',
  components: {
    TransferStatusPill,
    TransferReceiptDownloadLinksContainer,
    TransferDetailsDialog,
    BankImage,
    BatchFullscreenModal
  },
  props: {
    transfers: {
      required: false,
      type: [Object, Array]
    },
    onChange: {
      required: false,
      type: Function
    },
    disableClick: {
      required: false,
      type: Boolean,
      default: false
    },
    showFakeReceiptLinks: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  emits: ['on-transfer-checked'],
  data: () => ({
    clickedTransfer: {},
    selectAll: false,
    checkedTransfers: []
  }),
  watch: {
    selectAll(enabled) {
      if (enabled) {
        this.checkedTransfers = this.transfers.filter(t => this.hasReceiptToDownload(t)).map(t => t.id);
      } else {
        this.checkedTransfers = [];
      }
    },
    checkedTransfers: {
      deep: true,
      handler(transferIds) {
        this.$emit('on-transfer-checked', transferIds);
      }
    }
  },
  methods: {
    breakBiggerWord,
    formatDate,
    capitalize,
    dbaAccountRepr,
    showTransferDetailsDialog(clickedTransfer) {
      checkpointResource
        .create(checkpoint.OPENED_TRANSFER_FROM_TRANSFERS_PAGE)
        .catch(error => this.$toaster.error(getMessage(error)));
      this.clickedTransfer = clickedTransfer;
      this.$refs.transferDetailsDialog.open();
    },
    hideTransferDetailsDialog() {
      this.$refs.transferDetailsDialog.close();
    },
    showBatchFullscreenModal() {
      this.hideTransferDetailsDialog(true);
      this.$refs.batchFullscreenModal.open(this.clickedTransfer.Batch && this.clickedTransfer.Batch.id);
    },
    getDbaName(dba) {
      if (!dba.name) {
        return '---';
      }

      return capitalize(dba.name);
    },
    hasReceiptToDownload(transfer) {
      return transfer.bank_receipt_url || transfer.receipt_url;
    }
  }
};
</script>
