const HEX_CHAR_BEGINNING = '&#x0';
const HEX_CHAR_BEGINNING_LENGTH = '&#x0'.length;
const HEX_CHAR_END = ';';
const STRING_TO_REPLACE = ' ';
const CHAR_QTD_BETWEEN_BEGINNING_AND_END_OF_HEX_CHAR = 3;
const HEX_CHAR_NOT_FOUND_INDEX = -1;

const formatString = toBeFormatted => {
  if (typeof toBeFormatted !== 'string') {
    return toBeFormatted;
  }

  let formatted = String(toBeFormatted);
  let hexCharIndexes = _findPatternIndexes(formatted);
  while (hexCharIndexes) {
    const hexChar = _getHexCharByIndexes(formatted, hexCharIndexes);
    formatted = formatted.replace(hexChar, STRING_TO_REPLACE).trim();
    hexCharIndexes = _findPatternIndexes(formatted);
  }

  return formatted;
};

const _findPatternIndexes = toBeFormatted => {
  const beginningIndex = toBeFormatted.search(HEX_CHAR_BEGINNING);
  const endIndex = _getEndPatternIndex(toBeFormatted, beginningIndex);

  if (beginningIndex === HEX_CHAR_NOT_FOUND_INDEX || endIndex === HEX_CHAR_NOT_FOUND_INDEX) {
    return null;
  }
  return {
    beginningIndex,
    endIndex
  };
};

const _getEndPatternIndex = (toBeFormatted, beginningIndex) => {
  const endIndex = beginningIndex + HEX_CHAR_BEGINNING_LENGTH + CHAR_QTD_BETWEEN_BEGINNING_AND_END_OF_HEX_CHAR;
  const supposedToBeEndChar = toBeFormatted[endIndex];

  if (supposedToBeEndChar !== HEX_CHAR_END) {
    return HEX_CHAR_NOT_FOUND_INDEX;
  }
  return endIndex;
};

const _getHexCharByIndexes = (toBeFormatted, hexCharIndexes) =>
  toBeFormatted.slice(hexCharIndexes.beginningIndex, hexCharIndexes.endIndex + 1);

export default {
  formatString
};
