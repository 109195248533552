<template>
  <tr-form
    name="accountDataForm"
    :on-valid-submit="submit"
  >
    <user-form-container ref="userFormContainer" />
    <page-footer>
      <template #right>
        <tr-button
          type="submit"
          label="Salvar"
          variant="raised"
          color="primary"
        />
      </template>
    </page-footer>
  </tr-form>
</template>

<script>
import { getMessage } from '@/commons/services/error';
import UserFormContainer from './UserFormContainer.vue';
import PageFooter from '@/commons/components/presentational/PageFooter.vue';

export default {
  name: 'account-data-container',
  components: {
    UserFormContainer,
    PageFooter
  },
  props: {
    onSave: {
      required: false,
      type: Function
    }
  },
  methods: {
    submit() {
      const submitPromises = [];
      const userFormContainer = this.$refs.userFormContainer;

      if (userFormContainer && userFormContainer.onSubmit) {
        submitPromises.push(userFormContainer.onSubmit());
      }

      return Promise.all(submitPromises).then(
        () => {
          this.$toaster.success('Dados salvos com sucesso');
          if (this.onSave) {
            this.onSave();
          }
        },
        error => this.$toaster.error(getMessage(error))
      );
    }
  }
};
</script>
