<template>
  <div
    v-if="isFailed"
    class="col-lg-12"
  >
    <div class="info-action-box">
      <div
        class="info-action-box-description flex"
        :class="[isFailed ? 'error' : '']"
      >
        <div
          v-if="isFailed"
          class="row"
        >
          <div class="col-xs-1">
            <tr-svg-icon
              class="action-box-icon"
              color="white"
              name="ic-error"
            />
          </div>
          <div class="col-xs-11">
            <tr-typography
              strong="true"
              classes="block"
            >
              {{ label }}
            </tr-typography>
            <tr-typography classes="block vertical-offset1">
              <slot>
                {{ failReason }}
              </slot>
            </tr-typography>
          </div>
        </div>
        <bg-icon
          size="xs"
          name="close"
          color="grey-white"
          class="cursor-pointer"
          @click="onClose"
        />
      </div>
    </div>
  </div>
  <transfer-status-pill
    v-else
    :no-margin="true"
    size="medium"
    classes="col-lg-2"
    :transfer-status="transferStatus"
  />
</template>

<script>
import TransferStatusPill from '@/apps/payments/payments/transfer/components/presentational/TransferStatusPill.vue';
import { BgIcon } from '@transfeeradev/bridge';

export default {
  name: 'transfer-error-banner',
  components: {
    TransferStatusPill,
    BgIcon
  },
  props: {
    label: {
      type: String,
      default: 'Motivo da falha informado pelo banco:'
    },
    transferStatus: {
      required: true,
      type: String
    },
    failReason: {
      type: String
    },
    onClose: {
      type: Function
    }
  },
  computed: {
    isFailed() {
      return this.failReason || this.$slots.default;
    }
  }
};
</script>

<style scoped>
.flex {
  display: flex;
  justify-content: space-between;
}
</style>