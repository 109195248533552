<template>
  <div>
    <view-batch-with-any-waiting-status
      :batch="batch"
      :on-close="onClose"
    >
      <tr-button
        class="pull-right"
        variant="raised"
        color="primary"
        :on-button-click="approveBatch"
        label="Aprovar lote"
        :dropdown="true"
        :disabled="loading"
      >
        <tr-dropdown-item :on-item-click="openBatchReprovalConfirmationDialog">
          Reprovar lote
        </tr-dropdown-item>
        <tr-dropdown-item :on-item-click="openBatchEditionConfirmationDialog">
          Editar lote
        </tr-dropdown-item>
      </tr-button>
    </view-batch-with-any-waiting-status>
    <batches-waiting-to-receive-fullscreen-modal
      ref="batchesWaitingToReceiveFullscreenModal"
      :on-hidden="closeAndRefresh"
    />
    <batch-edition-confirmation-dialog
      ref="batchEditionConfirmationDialog"
      :on-confirm="enableEdit"
    />
    <batch-reproval-confirmation-dialog
      ref="batchReprovalConfirmationDialog"
      :on-confirm="reproveBatch"
    />
  </div>
</template>

<script>
import ViewBatchWithAnyWaitingStatus from '@/apps/payments/payments/batch/components/presentational/ViewBatchWithAnyWaitingStatus';
import BatchEditionConfirmationDialog from '@/apps/payments/payments/batch/components/presentational/BatchEditionConfirmationDialog';
import BatchReprovalConfirmationDialog from '@/apps/payments/payments/batch/components/presentational/BatchReprovalConfirmationDialog';
import BatchesWaitingToReceiveFullscreenModal from '@/apps/payments/payments/batch/pages/BatchesWaitingToReceiveFullscreenModal';
import batchResource from '@/apps/payments/payments/batch/resources/batch';
import { getMessage } from '@/commons/services/error';

export default {
  name: 'view-batch-to-approve-container',
  components: {
    ViewBatchWithAnyWaitingStatus,
    BatchEditionConfirmationDialog,
    BatchReprovalConfirmationDialog,
    BatchesWaitingToReceiveFullscreenModal
  },
  props: ['batch', 'onClose', 'onEdit', 'onChange'],
  data: () => ({
    loading: false
  }),
  methods: {
    openBatchEditionConfirmationDialog() {
      this.$refs.batchEditionConfirmationDialog.open();
    },
    openBatchReprovalConfirmationDialog() {
      this.$refs.batchReprovalConfirmationDialog.open();
    },
    approveBatch() {
      if (this.loading) {
        return;
      }
      this.loading = true;

      batchResource.close(this.batch.id).then(response => {
        this.loading = false;

        if (response.is_batch_paid) {
          this.$toaster.success('Lote aprovado com sucesso!');
          this.closeAndRefresh();
        } else {
          this.$toaster.success('Lote aprovado com sucesso! Aguardamos o recebimento do valor total do lote');
          this.$refs.batchesWaitingToReceiveFullscreenModal.open();
        }

        window.dispatchEvent(new Event('topbar.refresh'));
      }, this._onRequestError);
    },
    enableEdit() {
      if (this.loading) {
        return;
      }
      this.loading = true;

      this.$refs.batchEditionConfirmationDialog.close();

      batchResource.enableEdit(this.batch.id).then(() => {
        this.loading = false;

        if (this.onClose) {
          this.onClose();
        }
        if (this.onEdit) {
          this.onEdit();
        }
      }, this._onRequestError);
    },
    closeAndRefresh() {
      if (this.onClose) {
        this.onClose();
      }

      if (this.onChange) {
        this.onChange();
      }
    },
    reproveBatch(reason) {
      if (this.loading) {
        return;
      }
      this.loading = true;

      this.$refs.batchReprovalConfirmationDialog.close();

      batchResource.reprove(this.batch.id, reason).then(() => {
        this.loading = false;

        this.$toaster.success('Lote reprovado, o criador do lote receberá um aviso por e-mail');
        this.closeAndRefresh();
      }, this._onRequestError);
    },
    _onRequestError(error) {
      this.loading = false;

      if (error && error.message) {
        this.$toaster.error(getMessage(error));
      }
    }
  }
};
</script>
