import Layout from './Layout';
import validationsRoutes from './validations/routes';
import { APPS } from '@/commons/constants/apps';

export default {
  path: '/contacerta',
  component: Layout,
  name: 'ContaCerta',
  redirect: {
    name: 'ContaCertaValidations'
  },
  children: [validationsRoutes].map(route => ({
    ...route,
    meta: {
      ...route.meta,
      authAs: APPS.CONTACERTA.key
    }
  }))
};
