import ForbiddenPage from '@/commons/pages/ForbiddenPage';
import oldRoutes from '@/oldRoutes';
import paymentsRoutes from '@/apps/payments/routes';
import contacertaRoutes from '@/apps/contacerta/routes';
import adminRoutes from '@/apps/admin/routes';
import myAccountRoutes from '@/apps/my-account/routes';
import developerAreaRoutes from '@/apps/developer-area/routes';
import payinRoutes from '@/apps/payin/routes';
import { logout } from '@/commons/services/auth';

export const routes = [
  {
    path: '/logout',
    name: 'LogoutPage',
    beforeEnter: () => {
      logout();
      return false;
    }
  },
  {
    path: '/acesso-negado',
    name: 'ForbiddenPage',
    component: ForbiddenPage
  },
  paymentsRoutes,
  payinRoutes,
  contacertaRoutes,
  adminRoutes,
  myAccountRoutes,
  developerAreaRoutes,
  ...oldRoutes
];
