import config from '@/config';
import fetch from '@/fetch';

const BASE_URL = config.url.api.transfeera + '/company';
const APP_BASE_URL = config.url.api.transfeera + '/app/company';

export default {
  get: () => fetch(`${BASE_URL}`),
  update: company =>
    fetch(`${BASE_URL}`, {
      method: 'PUT',
      body: JSON.stringify(company)
    }),
  uploadLogo: (file, fileName) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', fileName);

    return fetch(
      `${APP_BASE_URL}/logo`,
      {
        method: 'POST',
        'Content-Type': undefined,
        body: formData
      },
      { isFile: true }
    );
  }
};
