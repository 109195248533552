<template>
  <tr-form
    name="newUserForm"
    :on-valid-submit="submit"
  >
    <div class="bottom-offset3">
      Vinculando uma chave, você poderá receber e enviar pagamentos instantâneos pela plataforma!
    </div>
    <div>
      <tr-text-field
        v-model="pixKey.key_type"
        name="key_type_phone"
        type="radio"
        classes="inline-block margin-right"
        :dont-use-col-classes="true"
        :required="true"
        :radio-value="pixKeyType.TELEFONE"
        @update:model-value="keyTypeChange"
      >
        <template #label>
          <span>Celular</span>
        </template>
      </tr-text-field>
      <tr-text-field
        v-model="pixKey.key_type"
        name="key_type_cnpj"
        type="radio"
        classes="inline-block margin-right"
        :dont-use-col-classes="true"
        :required="true"
        :radio-value="pixKeyType.CNPJ"
        @update:model-value="keyTypeChange"
      >
        <template #label>
          <span>CNPJ</span>
        </template>
      </tr-text-field>
      <tr-text-field
        v-model="pixKey.key_type"
        name="key_type_email"
        type="radio"
        classes="inline-block margin-right"
        :dont-use-col-classes="true"
        :required="true"
        :radio-value="pixKeyType.EMAIL"
        @update:model-value="keyTypeChange"
      >
        <template #label>
          <span>E-mail</span>
        </template>
      </tr-text-field>
      <tr-text-field
        v-model="pixKey.key_type"
        name="key_type_evp"
        type="radio"
        classes="inline-block margin-right"
        :dont-use-col-classes="true"
        :required="true"
        :radio-value="pixKeyType.CHAVE_ALEATORIA"
        @update:model-value="keyTypeChange"
      >
        <template #label>
          <span>Chave aleatória</span>
        </template>
      </tr-text-field>
    </div>
    <div class="row">
      <!--
        Bug in inputmask, if v-if is used directly on tr-text-field it causes an DOMException when switching
        components. Thats why it's a span and a div, to force vue to recreate the html elements tree.
      -->
      <span v-if="pixKey.key_type === pixKeyType.TELEFONE">
        <div class="pix-key-input">
          <tr-text-field
            v-model="pixKey.key"
            label="Chave Pix (Celular)"
            :required="true"
            :mask-format="masks.MOBILE_PHONE"
            :pattern="MOBILE_PHONE_REGEX"
            :dont-use-col-classes="true"
            class="col-xs-6"
            error-message="Número de celular inválido"
            name="phone-pix-key"
          />
        </div>
        <div class="col-xs-12">
          <tr-typography fixed-lines-qtd="2">
            <tr-typography strong="true">Enviaremos um código de verificação</tr-typography> para este número!
          </tr-typography>
        </div>
      </span>
      <div v-else-if="pixKey.key_type === pixKeyType.EMAIL">
        <div class="pix-key-input">
          <tr-text-field
            v-model="pixKey.key"
            label="Chave Pix (E-mail)"
            type="email"
            :required="true"
            :pattern="EMAIL_REGEX"
            :dont-use-col-classes="true"
            class="col-xs-6"
            error-message="E-mail inválido"
            name="email-pix-key"
          />
        </div>
        <div class="col-xs-12">
          <tr-typography fixed-lines-qtd="2">
            <tr-typography strong="true">
              Enviaremos um código de verificação
            </tr-typography> para este e-mail!
          </tr-typography>
        </div>
      </div>
      <div v-else-if="pixKey.key_type === pixKeyType.CNPJ">
        <div class="pix-key-input">
          <tr-read-only-text-field
            label="CNPJ"
            :no-margin="true"
            :value="formatCpfCnpj(pixKey.key)"
          />
        </div>
        <div class="col-xs-12">
          <tr-typography fixed-lines-qtd="2">
            Você só pode cadastrar o CNPJ da sua empresa
          </tr-typography>
        </div>
      </div>
      <div v-else-if="pixKey.key_type === pixKeyType.CHAVE_ALEATORIA">
        <div class="pix-key-input">
          <tr-read-only-text-field
            label="Chave aleatória"
            :no-margin="true"
            :value="pixKeyRepr(pixKey)"
          />
        </div>
        <div class="col-xs-12">
          <tr-typography
            fixed-lines-qtd="2"
          >
            Será gerado uma <tr-typography :strong="true">
              chave aleatória
            </tr-typography>, que permite receber
            transferências Pix sem expor informações da empresa
          </tr-typography>
        </div>
      </div>
    </div>
    <div class="vertical-offset3 bottom-offset3 inline-block width-100">
      <tr-button
        class="pull-left"
        variant="outlined"
        color="secondary"
        label="Cancelar"
        :on-button-click="cancel"
      />
      <tr-button
        class="pull-right"
        variant="raised"
        color="primary"
        label="Próximo"
        :disabled="loading"
        type="submit"
      />
    </div>
  </tr-form>
</template>

<script>
import formatCpfCnpj from '@/commons/formatters/cpfCnpj';
import pixKeyRepr from '@/commons/formatters/pixKeyRepr';
import masks from '@/commons/constants/masks';
import { pix, regexes } from '@transfeeradev/api-enums';

export default {
  name: 'new-pix-key-form',
  props: ['pixKey', 'companyCnpj', 'loading'],
  emits: ['submit', 'cancel'],
  data: () => ({
    masks,
    EMAIL_REGEX: regexes.PIX_EMAIL,
    MOBILE_PHONE_REGEX: regexes.MOBILE_PHONE,
    pixKeyType: pix.keyType
  }),
  methods: {
    pixKeyRepr,
    formatCpfCnpj,
    submit() {
      this.$emit('submit');
    },
    cancel() {
      this.$emit('cancel');
    },
    keyTypeChange() {
      this.pixKey.key = '';
      if (this.pixKey.key_type === pix.keyType.CNPJ) {
        this.pixKey.key = this.companyCnpj;
      } else if (this.pixKey.key_type === pix.keyType.CHAVE_ALEATORIA) {
        this.pixKey.key = null;
      }
    }
  }
};
</script>
