<template>
  <teleport to="#app-notification">
    <tr-alert-notification
      v-if="isBlocked && isAdmin"
      :full-width="true"
      type="warning"
    >
      <tr-typography>
        Seu acesso permite apenas visualização,
        <router-link
          class="cursor-pointer"
          :to="{ name: 'BillingPage' }"
        >
          baixe seu boleto aqui
        </router-link>
        e regularize sua situação.
      </tr-typography>
    </tr-alert-notification>
  </teleport>
</template>

<script>
import loggedUserResource from '@/commons/resources/loggedUser';
import { companyStatus, roles } from '@transfeeradev/api-enums';

export default {
  name: 'blocked-user-notification-container',
  data: () => ({
    isBlocked: false,
    isAdmin: false
  }),
  created() {
    const loggedUser = loggedUserResource.get();
    this.isBlocked = loggedUser.company.status === companyStatus.BLOCKED;
    this.isAdmin = loggedUser.profile === roles.appRoles.ADMIN.value;
  }
};
</script>
