import Polling from '@/commons/services/polling';
import { captureException } from '@sentry/vue';
import { onBeforeUnmount, ref } from 'vue';

export const usePolling = ({ intervalInSeconds = 5, timeoutInSeconds = null } = {}) => {
  const isPolling = ref(false);
  const pollingError = ref(null);

  const polling = new Polling({
    POLLING_INTERVAL: intervalInSeconds,
    MAX_POLLING_TIMEOUT: timeoutInSeconds
  });

  const startPolling = async (callback, { keepPollingWhile = () => true, immediate = false } = {}) => {
    isPolling.value = true;
    pollingError.value = null;

    if (immediate) {
      await callback();
    }

    polling
      .start(callback, keepPollingWhile)
      .then(() => {
        pollingError.value = null;
      })
      .catch(error => {
        pollingError.value = error;
        captureException(error);
      })
      .then(() => {
        isPolling.value = false;
      });
  };

  const stopPolling = () => {
    return polling.stop();
  };

  onBeforeUnmount(() => {
    stopPolling();
  });

  return { isPolling, pollingError, startPolling, stopPolling };
};
