<template>
  <tr-drag-and-drop-area-state
    icon-name="ic-error"
    icon-color="red-semi-dark"
    title="Falha na importação da planilha"
  >
    <div class="row vertical-offset1">
      <tr-typography variant="body">
        {{ errorMessage }}
      </tr-typography>
    </div>
    <tr-button
      classes="vertical-offset3 bottom-offset4"
      type="file"
      color="primary"
      size="large"
      accept=".xlsx, .csv"
      :on-files-select="onFileSelect"
      label="Selecione seu arquivo"
    />
  </tr-drag-and-drop-area-state>
</template>

<script>
export default {
  name: 'sheet-import-error-state',
  props: {
    errorMessage: {
      required: false,
      type: String,
      default: ''
    },
    onFileSelect: {
      required: true,
      type: Function
    }
  }
};
</script>
