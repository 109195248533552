import { onBeforeUnmount, onMounted } from 'vue';
import { onBeforeRouteLeave } from 'vue-router';

/**
 * @param {Object} options
 * @param {Ref<Boolean>} options.isDirty
 * @param {Function} [options.onConfirm]
 * @param {Function} [options.onCancel]
 */
export const useConfirmLeave = ({ isDirty, onConfirm, onCancel }) => {
  const confirmLeave = () => {
    return new Promise(resolve => {
      const answer = window.confirm('Tem certeza que deseja sair? As alterações não salvas serão perdidas.');
      if (answer) {
        onConfirm?.();
        resolve(true);
      } else {
        onCancel?.();
        resolve(false);
      }
    });
  };

  const confirmUnload = async event => {
    if (isDirty.value) {
      const answer = await confirmLeave();
      if (!answer) {
        event.preventDefault();
      }
    }
  };

  onMounted(() => {
    window.addEventListener('beforeunload', confirmUnload);
  });

  onBeforeUnmount(() => {
    window.removeEventListener('beforeunload', confirmUnload);
  });

  onBeforeRouteLeave(async () => {
    if (isDirty.value) {
      return await confirmLeave();
    }

    return true;
  });
};
