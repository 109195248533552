<template>
  <view-bank-account
    :bank-account="bankAccount"
    :full-size="fullSize"
    :bank="bank"
    :operation-type="operationType"
    :hide-name-and-document="hideNameAndDocument"
    :no-margin-on-last-line="noMarginOnLastLine"
  />
</template>

<script>
import ViewBankAccount from '@/apps/payments/payments/bank/components/presentational/ViewBankAccount';
import ListItemGetterService from '@transfeeradev/api-services/ListItemGetterService';
import CaixaService from '@transfeeradev/api-services/CaixaService';
import { banks } from '@transfeeradev/api-enums';
import bankResource from '@/apps/payments/payments/bank/resources/bank';

export default {
  name: 'view-bank-account-container',
  components: {
    ViewBankAccount
  },
  props: {
    bankAccount: {
      required: false,
      type: Object,
      default: () => ({
        Bank: {}
      })
    },
    fullSize: {
      required: false,
      type: Boolean,
      default: false
    },
    hideNameAndDocument: {
      required: false,
      type: Boolean,
      default: false
    },
    noMarginOnLastLine: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    foundBank: null
  }),
  computed: {
    bank() {
      return this.bankAccount.Bank || this.foundBank;
    },
    operationType() {
      if (this.bankAccount.operation_type) {
        return this.bankAccount.operation_type;
      }

      if (
        this.bankAccount &&
        (this.bankAccount.cpf_cnpj || this.bankAccount.cpfCnpj) &&
        this.bank &&
        this.bank.code === banks.CAIXA_ECONOMICA.code
      ) {
        return CaixaService.getOperationType(this.bankAccount);
      }

      return '';
    }
  },
  created() {
    if (this.bankAccount.bank_id) {
      return this._getBank(this.bankAccount.bank_id);
    }
  },
  methods: {
    _getBank(bankId) {
      return bankResource.get().then(allBanks => {
        const bank = ListItemGetterService.getByProp({
          propValue: bankId,
          propName: 'id',
          list: allBanks
        });
        this.foundBank = bank;
      });
    }
  }
};
</script>
