<template>
  <tr-button
    dropdown="true"
    :label="selectedFilter"
    text-variant="body"
    variant="text"
  >
    <tr-dropdown-item
      key="all"
      :on-item-click="search.bind(undefined, undefined, 'Todos os status')"
    >
      Todos os status
    </tr-dropdown-item>
    <tr-dropdown-item
      v-for="(statusName, statusKey) in transferStatusNamePageFilter"
      :key="statusKey"
      :on-item-click="search.bind(undefined, statusKey, statusName)"
    >
      {{ statusName }}
    </tr-dropdown-item>
  </tr-button>
</template>

<script>
import transferStatusNamePageFilter from '@/apps/payments/payments/transfer/constants/transferStatusNamePageFilter';

export default {
  name: 'transfer-status-dropdown-filter-container',
  props: {
    onSearch: {
      required: true,
      type: Function
    }
  },
  data: () => ({
    transferStatusNamePageFilter,
    selectedFilter: 'Todos os status'
  }),
  methods: {
    search(key, name) {
      this.selectedFilter = name;
      this.onSearch(key);
    }
  }
};
</script>
