<template>
  <tr-slimbox
    :with-border="true"
    size="large"
    :no-margin="true"
  >
    <div class="center-v">
      <div class="col-lg-3 text-center center-v-h">
        <tr-svg-icon
          name="ic-favorite-border"
          color="red-light"
          size="large"
        />
      </div>
      <div>
        <tr-typography
          classes="block bottom-offset2"
          variant="h3"
        >
          Tenha a melhor experiência nos pagamentos!
        </tr-typography>
        <tr-typography>
          Você já deu seu primeiro passo para uma experiência extraordinária na hora de realizar seus pagamentos. Para
          que seja ainda
          <tr-typography
            strong="true"
          >
            melhor, mais seguro e eficiente, precisamos que você finalize seu cadastro na Transfeera!
          </tr-typography>
        </tr-typography>
      </div>
    </div>
  </tr-slimbox>
</template>

<script>
export default {
  name: 'first-steps-explanation'
};
</script>
