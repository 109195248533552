<template>
  <div>
    <div
      ref="billetCards"
      class="row is-flex vertical-offset4"
    >
      <tr-add-card
        v-if="addNew"
        :on-add-click="addNew"
      />
      <div
        v-if="isLoading"
        class="col-lg-3 col-md-4 col-sm-12 center-h-and-v bottom-offset4"
      >
        <tr-loading />
      </div>
      <template v-for="(billet, index) in filteredBillets">
        <billet-card-container
          v-if="filteredBillets && filteredBillets.length > 0 && index <= billetsLimit - 1"
          :key="billet.id"
          :batch="batch"
          :hide-status-pill="hideStatusPill"
          :on-card-click="onBilletClick.bind(this, billet)"
          :billet="billet"
        />
      </template>
    </div>
    <tr-button
      v-if="filteredBillets.length > billetsLimit"
      classes="btn-block col-lg-12"
      block-size="true"
      :on-button-click="updateLimitation"
      size="large"
      label="Mostrar mais boletos"
      variant="outlined"
      color="secondary"
    />
    <billet-details-full-modal
      ref="BilletDetailsFullModal"
      :billet-id="clickedBillet.id"
      :on-cancel="onBilletCancel"
    />
  </div>
</template>

<script>
import BilletDetailsFullModal from '@/apps/payments/payments/billet/components/presentational/BilletDetailsFullModal';
import BilletCardContainer from '@/apps/payments/payments/billet/components/container/BilletCardContainer';
import batchResource from '@/apps/payments/payments/batch/resources/batch';
import batchModel from '@/apps/payments/payments/batch/models/batch';
import verifyRoleAccess from '@/commons/services/verifyRoleAccess';
import { batchStatus, roles } from '@transfeeradev/api-enums';

const DEFAULT_BILLETS_LIMIT = 50;

export default {
  name: 'billet-cards',
  components: {
    BilletDetailsFullModal,
    BilletCardContainer
  },
  props: {
    batch: {
      required: false,
      type: Object,
      default: () => batchModel.get()
    },
    batchId: {
      required: false,
      type: [Number, String]
    },
    onGetBillets: {
      required: false,
      type: Function
    },
    addNew: {
      required: false,
      type: Function
    },
    onEdit: {
      required: false,
      type: Function
    },
    filterBy: {
      required: false,
      type: [String, Array]
    },
    hideStatusPill: {
      required: false,
      type: Boolean
    }
  },
  data: () => ({
    billets: [],
    clickedBillet: {},
    billetsLimit: DEFAULT_BILLETS_LIMIT,
    isLoading: false
  }),
  computed: {
    filteredBillets() {
      if (!this.filterBy) {
        return this.billets.slice().sort(this._sort);
      }

      return this.billets
        .filter(billet => {
          if (Array.isArray(this.filterBy)) {
            return this.filterBy.some(filterBy => billet.status === filterBy);
          }
          return billet.status === this.filterBy;
        })
        .sort(this._sort);
    }
  },
  watch: {
    batchId() {
      this.refresh();
    }
  },
  mounted() {
    this.refresh();
  },
  methods: {
    refresh() {
      if (!this.batchId) {
        return;
      }

      this.isLoading = true;

      batchResource.getBillets(this.batchId).then(billets => {
        this.isLoading = false;
        this.billets = billets;
        if (this.onGetBillets && typeof this.onGetBillets === 'function') {
          this.onGetBillets(billets);
        }
      });
    },
    onBilletClick(clickedBillet) {
      if (
        this.batch.status === batchStatus.DRAFT &&
        !verifyRoleAccess.isLoggedUserForbiddenToAccess(roles.appRoles.ANALYST)
      ) {
        this.onEdit(clickedBillet.id);
      } else {
        this.showBilletDetailsFullModal(clickedBillet);
      }
    },
    onBilletCancel() {
      this.refresh();
    },
    updateLimitation() {
      this.billetsLimit += DEFAULT_BILLETS_LIMIT;
    },
    areCardsValid() {
      return this.billets.every(billet => !this.isBilletFailed(billet));
    },
    showBilletDetailsFullModal(clickedBillet) {
      this.clickedBillet = clickedBillet;
      this.$refs.BilletDetailsFullModal.open();
    },
    _sort(billet1, billet2) {
      if (this.isBilletFailed(billet1)) {
        return -1;
      }
      if (this.hasWarningOnBillet(billet1) && !this.isBilletFailed(billet2)) {
        return -1;
      }
      return 1;
    },
    isBilletFailed(billet) {
      return Boolean(billet._errors && billet._errors.length);
    },
    hasWarningOnBillet(billet) {
      return Boolean(billet._warnings && billet._warnings.length);
    }
  }
};
</script>
