<template>
  <tr-page-section
    title="Tipo de validação"
    class="no-margin"
  >
    <validation-type-selector-container
      v-model="type"
    />
  </tr-page-section>
  <tr-page-section
    title="Dados para validação"
    :no-margin="true"
    class="vertical-offset3"
  >
    <validation-type-selector
      v-model="internalValidationDataType"
    />
  </tr-page-section>
  <tr-form
    ref="form"
    :on-valid-submit="onSubmit"
  >
    <validation-bank-account-form-container
      v-if="internalValidationDataType === VALIDATION_DATA_TYPES.ACCOUNT"
      :bank-account="validationData"
    />
    <validation-pix-key-cpf-cnpj-form
      v-if="internalValidationDataType === VALIDATION_DATA_TYPES.CPF_CNPJ"
      :validation-data="validationData"
    />
    <validation-pix-key-form
      v-if="[
        VALIDATION_DATA_TYPES.EMAIL,
        VALIDATION_DATA_TYPES.PHONE,
        VALIDATION_DATA_TYPES.EVP
      ].includes(internalValidationDataType)"
      :key="keyType"
      :validation-data="validationData"
      :type="type"
      :key-type="keyType"
    />
    <page-footer>
      <template #right>
        <tr-button
          variant="raised"
          label="Fazer validação"
          color="primary"
          type="submit"
        />
      </template>
    </page-footer>
  </tr-form>
</template>

<script>
import merge from 'deepmerge';
import ValidationTypeSelectorContainer from './ValidationTypeSelectorContainer.vue';
import ValidationBankAccountFormContainer from './ValidationBankAccountFormContainer';
import ValidationTypeSelector from '../presentational/ValidationTypeSelector';
import ValidationPixKeyCpfCnpjForm from '../presentational/ValidationPixKeyCpfCnpjForm';
import ValidationPixKeyForm from '../presentational/ValidationPixKeyForm';
import { keyType } from '@transfeeradev/api-enums/pix';
import { isCNPJ, isCPF } from '@transfeeradev/api-services/PersonTypeService';
import getByProp from '@/commons/services/getByProp';
import bankResource from '@/apps/contacerta/resources/bank';
import VALIDATION_DATA_TYPES from '@/apps/contacerta/validations/constants/validationDataTypes';
import { contacerta } from '@transfeeradev/api-enums';
import PageFooter from '@/commons/components/presentational/PageFooter.vue';

export default {
  name: 'validation-form-container',
  components: {
    ValidationTypeSelector,
    ValidationBankAccountFormContainer,
    ValidationPixKeyCpfCnpjForm,
    ValidationPixKeyForm,
    ValidationTypeSelectorContainer,
    PageFooter
  },
  props: {
    validationData: {
      type: Object,
      required: true
    },
    validationDataType: {
      type: String,
      required: false
    }
  },
  emits: ['validate', 'update:validationDataType'],
  data() {
    return {
      VALIDATION_DATA_TYPES,
      internalValidationDataType: null,
      type: null
    };
  },
  computed: {
    keyType() {
      switch (this.internalValidationDataType) {
        case VALIDATION_DATA_TYPES.EMAIL:
          return keyType.EMAIL;
        case VALIDATION_DATA_TYPES.PHONE:
          return keyType.TELEFONE;
        case VALIDATION_DATA_TYPES.EVP:
          return keyType.CHAVE_ALEATORIA;
        case VALIDATION_DATA_TYPES.CPF_CNPJ:
          if (isCPF(this.validationData.pix_key)) {
            return keyType.CPF;
          }
          if (isCNPJ(this.validationData.pix_key)) {
            return keyType.CNPJ;
          }
          return null;
        default:
          return null;
      }
    }
  },
  watch: {
    internalValidationDataType(value) {
      this.$emit('update:validationDataType', value);
    }
  },
  mounted() {
    this.internalValidationDataType = this.validationDataType;
  },
  methods: {
    async onSubmit() {
      const payload = merge({}, this.validationData);
      if (this.keyType) {
        payload.pix_key_type = this.keyType;
        if (payload.cpf_cnpj && this.type === contacerta.type.MICRO_DEPOSIT) {
          payload.pix_key_validation = {
            cpf_cnpj: payload.cpf_cnpj
          };
        }
        delete payload.cpf_cnpj;
      }
      if (payload.bank_id) {
        const banks = await bankResource.get();
        const bank = this._getBank(banks, payload.bank_id);
        if (bank?.code) {
          payload.bank_code = bank?.code;
        } else {
          payload.bank_ispb = bank?.ispb;
        }
        delete payload.bank_id;
      }
      this.$emit('validate', this.type, payload);
    },
    _getBank(foundBanks, id) {
      return getByProp.get({
        propValue: id,
        propName: 'id',
        list: foundBanks
      });
    }
  }
};
</script>
