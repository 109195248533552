<template>
  <tr-dialog
    ref="modal"
    name="download-receipt-dialog"
    title="Você deseja baixar seus comprovantes?"
    save-button-label="Baixar comprovantes"
    :on-save="onSave"
  >
    <tr-typography variant="body">
      Você está prestes a baixar:
    </tr-typography>
    <tr-typography
      variant="h5"
      strong="true"
      class="block vertical-offset1 bottom-offset3"
    >
      {{ itemIds.length }} {{ itemIds.length === 1 ? 'comprovante' : 'comprovantes' }} de pagamento
      <span v-if="batch">referente ao lote "{{ batch.name }}"</span>
    </tr-typography>
    <tr-typography
      variant="body"
      class="block bottom-offset3"
    >
      Assim que finalizado o download, enviaremos para o seu e-mail um link com o acesso aos comprovantes.
    </tr-typography>
  </tr-dialog>
</template>

<script>
import billetResource from '@/apps/payments/payments/billet/resources/billet';
import transferResource from '@/apps/payments/payments/transfer/resources/transfer';
import { batchType } from '@transfeeradev/api-enums';
import checkpointResource from '@/apps/payments/resources/checkpoint';
import checkpoint from '@/commons/constants/checkpoint';
import { getMessage } from '@/commons/services/error';

export default {
  name: 'download-receipt-dialog',
  props: {
    batch: Object,
    batchType: {
      required: true,
      type: String
    },
    itemIds: {
      required: true,
      type: Array
    }
  },
  methods: {
    open() {
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
    async onSave() {
      try {
        if (this.batchType === batchType.TRANSFER) {
          checkpointResource
            .create(checkpoint.DOWNLOADED_BATCH_RECEIPT_TRANSFER)
            .catch(error => this.$toaster.error(getMessage(error)));

          await transferResource.downloadReceipts(this.itemIds);
        } else {
          checkpointResource
            .create(checkpoint.DOWNLOADED_BATCH_RECEIPT_BILLET)
            .catch(error => this.$toaster.error(getMessage(error)));

          await billetResource.downloadReceipts(this.itemIds);
        }

        this.$toaster.success(
          'Download iniciado com sucesso, enviaremos um e-mail assim que o download estiver concluído!'
        );
      } catch (e) {
        this.$toaster.error(getMessage(e));
      }

      this.close();
    }
  }
};
</script>
