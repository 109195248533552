import statementResource from '@/apps/payments/payments/statement/resources/statement';
import settingsResource from '@/apps/payments/resources/settings';

const hasIt = async batchValue => {
  const settings = await settingsResource.get();
  const isValidationAccount = settings.validationAccount;
  let hasEnoughBalance = false;

  if (!isValidationAccount) {
    const balance = await statementResource.getBalance();
    hasEnoughBalance = Boolean(balance.value >= batchValue);
  }

  return {
    isValid: isValidationAccount || hasEnoughBalance
  };
};

export default {
  hasIt
};
