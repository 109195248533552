<template>
  <tr-form :on-valid-submit="save">
    <tr-loading
      v-if="isLoading"
      classes="center-h-and-v width-100 vertical-offset5"
    />
    <tr-hide-for-roles
      v-if="!isLoading"
      :roles="appRoles.ANALYST"
    >
      <dba-form
        :dba="receiver"
        :on-dba-type-change="onDbaTypeChange"
        :should-make-complex-validations="true"
      />
    </tr-hide-for-roles>
    <tr-show-for-roles
      v-if="!isLoading"
      :roles="appRoles.ANALYST"
    >
      <view-dba
        v-if="!isLoading"
        :dba="receiver"
      />
    </tr-show-for-roles>
    <tr-footer-buttons-container>
      <tr-hide-for-roles :roles="appRoles.ANALYST">
        <tr-button
          class="pull-right margin-right"
          variant="raised"
          :disabled="isLoading || isSaving"
          label="Salvar"
          color="primary"
          type="submit"
        />
        <tr-button
          v-if="receiverId || receiver.id"
          class="pull-right margin-right"
          variant="raised"
          size="medium"
          :disabled="isLoading || isSaving"
          svg-icon-name="ic-delete"
          svg-icon-color="white"
          color="danger"
          :on-button-click="confirmRemoval"
        />
      </tr-hide-for-roles>
      <tr-button
        classes="pull-left"
        label="Cancelar"
        variant="raised"
        color="secondary"
        :disabled="isLoading || isSaving"
        :on-button-click="cancel"
      />
    </tr-footer-buttons-container>
    <receiver-removal-confirmation-dialog
      ref="receiverRemovalConfirmationDialog"
      :on-confirm="remove"
    />
  </tr-form>
</template>

<script>
import ReceiverRemovalConfirmationDialog from '@/apps/payments/payments/receivers/components/presentational/ReceiverRemovalConfirmationDialog.vue';
import DbaForm from '@/apps/payments/payments/dba/components/presentational/DbaForm.vue';
import dbaModel from '@/apps/payments/payments/dba/models/dba';
import receiverResource from '@/apps/payments/payments/receivers/resources/receivers';
import prepareToEditDba from '@/apps/payments/payments/dba/services/prepareToEdit';
import checkpointResource from '@/apps/payments/resources/checkpoint';
import checkpoint from '@/commons/constants/checkpoint';
import { getMessage } from '@/commons/services/error';
import { roles } from '@transfeeradev/api-enums';
import ViewDba from '@/apps/payments/payments/dba/components/presentational/ViewDba.vue';

export default {
  name: 'receiver-management-container',
  components: {
    ReceiverRemovalConfirmationDialog,
    DbaForm,
    ViewDba
  },
  props: {
    onChange: {
      required: true,
      type: Function
    },
    cancel: {
      required: false,
      type: Function
    },
    receiverId: {
      required: false,
      type: [String, Number]
    }
  },
  data: () => ({
    isLoading: false,
    isSaving: false,
    receiver: dbaModel.get(),
    appRoles: roles.appRoles
  }),
  watch: {
    receiverId() {
      return this.refresh();
    }
  },
  created() {
    this.openAccountDataIfIsNotFilled();
  },
  methods: {
    openAccountDataIfIsNotFilled() {
      checkpointResource.exists(checkpoint.HAS_FILLED_FIRST_STEPS).then(
        response => {
          if (!response.exists) {
            this.$router.push({ name: 'FirstStepsPage', state: { redirectedFrom: 'DbasPage' } });
          }
        },
        error => this.$toaster.error(getMessage(error))
      );
      checkpointResource
        .create(checkpoint.OPENED_DBA_MANAGEMENT)
        .catch(error => this.$toaster.error(getMessage(error)));
      return this.refresh();
    },
    refresh() {
      if (this.receiverId) {
        this.isLoading = true;
        return receiverResource.get(this.receiverId).then(
          receiver => {
            this.receiver = prepareToEditDba.prepare(receiver);
            this.isLoading = false;
          },
          error => this.$toaster.error(getMessage(error))
        );
      }
    },
    save() {
      this.isSaving = true;

      if (this.receiver.id) {
        return receiverResource.update(this.receiver, this.receiver.id).then(this.onSaveSuccess, this._onSaveError);
      } else {
        return receiverResource.create(this.receiver).then(this.onSaveSuccess, this._onSaveError);
      }
    },
    onSaveSuccess() {
      this.isSaving = false;
      this.$toaster.success('Recebedor salvo com sucesso');
      this.onChange();
    },
    _onSaveError(error) {
      this.isSaving = false;

      if (error && error.code) {
        return this.$toaster.error(getMessage(error));
      }

      this.$toaster.error('Ocorreu um erro ao salvar recebedor');
    },
    confirmRemoval() {
      this.$refs.receiverRemovalConfirmationDialog.open();
    },
    remove() {
      this.isSaving = true;
      return receiverResource.remove(this.receiverId).then(this.onRemoveSuccess, this.onRemoveError);
    },
    onRemoveSuccess() {
      this.isSaving = false;
      this.$toaster.success('Recebedor excluído com sucesso');
      this.onChange();
    },
    onRemoveError(error) {
      this.isSaving = false;

      if (error && error.code) {
        return this.$toaster.error(getMessage(error));
      }

      this.$toaster.error('Ocorreu um erro ao excluir recebedor');
    },
    onDbaTypeChange(type) {
      const dbaData = dbaModel.get();
      delete dbaData.id;
      dbaData.pix_key_type = type;
      dbaData.pix_key = dbaData.pix_key || null;
      this.receiver = Object.assign({}, this.receiver, dbaData);
    }
  }
};
</script>
