<template>
  <bank-autocomplete-suggestion
    :suggestion="suggestion"
    :show-ispb="true"
  />
</template>

<script>
import BankAutocompleteSuggestion from './BankAutocompleteSuggestion.vue';

export default {
  name: 'bank-autocomplete-suggestion-with-ispb',
  components: {
    BankAutocompleteSuggestion
  },
  props: {
    suggestion: {
      type: Object,
      required: true
    },
    showIspb: {
      type: Boolean,
      required: false
    }
  }
};
</script>
