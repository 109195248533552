<template>
  <tr-card
    class="pix-key-card"
    :disabled="disabled"
    :on-card-click="disabled ? () => {} : onCardClick"
    image-src="https://cdn.transfeera.com/banks/pix-circle.svg"
    :break-bigger-word="16"
    :has-warning="pixKey.status !== pixKeyStatus.REGISTERED"
    subtitle="Conta Pix"
  >
    <tr-typography
      classes="vertical-offset1 inline-block break-all-words"
      fixed-lines-qtd="2"
    >
      {{ formatedPixKey }}
    </tr-typography>
    <pix-key-status-pill
      class="vertical-offset1 bottom-offset2"
      :status="pixKey.status"
      :no-margin="true"
    />
  </tr-card>
</template>

<script>
import pixKeyRepr from '@/commons/formatters/pixKeyRepr';
import breakBiggerWord from '@/commons/formatters/breakBiggerWord';
import { pixKeyStatus } from '@transfeeradev/api-enums/pix';
import PixKeyStatusPill from '@/apps/payments/payments/settings/pixKeys/components/presentational/PixKeyStatusPill.vue';
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'pix-key-card',
  components: {
    PixKeyStatusPill
  },
  props: {
    pixKey: Object,
    onCardClick: Function
  },
  setup(props) {
    const formatedPixKey = computed(() => {
      return breakBiggerWord(pixKeyRepr(props.pixKey), 56);
    });

    const disabled = computed(() => {
      return props.pixKey.status === pixKeyStatus.PENDING_REMOVAL;
    });

    return {
      disabled,
      pixKeyStatus,
      formatedPixKey
    };
  }
});
</script>

<style scoped>
.pix-key-card[disabled='true'] {
  pointer-events: none;
  opacity: 0.6;
  user-select: none;
}
</style>
