<template>
  <main>
    <div class="payment-link-list">
      <bg-text
        type="h5"
        weight="bold"
        color="grey-dark"
      >
        Links de pagamento
      </bg-text>
      <bg-button @click="onNavigateToPaymentLink">
        Criar Link de Pagamento
      </bg-button>
    </div>
    <payment-link-list-table
      :payment-links="paymentLinks"
      :on-open-delete-dialog="openDeleteDialog"
    />
  </main>
  <payment-link-delete-dialog
    ref="deleteDialog"
    :on-delete-payment-link="onDeletePaymentLink"
  />
</template>;

<script setup>
import { ref } from 'vue';
import { BgText, BgButton } from '@transfeeradev/bridge';
import { deletePaymentLink } from '@/apps/payin/paymentLink/services/paymentLink';
import { useErrorHandler } from '@/commons/composables/useErrorHandler';
import { useToaster } from '@/commons/composables/useToaster';
import { listPaymentLink } from '@/apps/payin/paymentLink/services/paymentLink';
import { useRouter } from 'vue-router';
import checkpoint from '@/commons/constants/checkpoint';
import checkpointResource from '@/commons/resources/checkpoint';
import PaymentLinkDeleteDialog from '@/apps/payin/paymentLink/components/PaymentLinkDeleteDialog.vue';
import PaymentLinkListTable from '@/apps/payin/paymentLink/components/PaymentLinkListTable.vue';

const emit = defineEmits(['loading']);
emit('loading', true);

const { handleError } = useErrorHandler();
const toaster = useToaster();
const router = useRouter();

const deleteDialog = ref();
const paymentLinks = ref([]);

const openDeleteDialog = paymentLinkId => {
  deleteDialog.value.open(paymentLinkId);
};

const onNavigateToPaymentLink = () => {
  checkpointResource.create(checkpoint.PAYMENT_LINK_ACCESSED_PAYMENT_LINK_LIST);
  router.push({ name: 'PaymentLinkPage' });
};

const onDeletePaymentLink = async paymentLinkId => {
  try {
    await deletePaymentLink(paymentLinkId);
    toaster.success('Link de Pagamento deletado!');
    deleteDialog.value.close();
  } catch (e) {
    handleError(e, 'Ocorreu um erro ao deletar o link de pagamento.');
  }
};

const fetchPaymentLinkList = async () => {
  try {
    const response = await listPaymentLink();
    paymentLinks.value = response.items;
  } catch (e) {
    handleError(e, 'Ocorreu um erro ao buscar os links de pagamento.');
  } finally {
    emit('loading', false);
  }
};

fetchPaymentLinkList();

checkpointResource.create(checkpoint.PAYMENT_LINK_LIST_PAGE_VIEWED);
</script>

<style scoped>
main {
  margin: var(--bg-spacing-md);
  margin-top: calc(var(--bg-spacing-lg) + var(--bg-spacing-x-sm) - 1px);
}

.payment-link-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
