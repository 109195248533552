import BankAccountValidations from '@transfeeradev/bank-account-validations';
import accountType from '@/commons/constants/accountType';
import { getMessage } from '@/commons/services/error';

const get = () => {
  return {
    isValid: true,
    agency: {
      isValid: true
    },
    agency_digit: {
      isValid: true
    },
    account_type: {
      isValid: true
    },
    account: {
      isValid: true
    },
    account_digit: {
      isValid: true
    }
  };
};

const _filterAgencyDigitOnlyWhenFilled = (dba, r) =>
  (r.field === 'agency_digit' && dba.agency_digit) || r.field !== 'agency_digit';
const _filterAgencyDigitToIgnoreZeroAndx = (dba, r) =>
  r.field === 'agency_digit' &&
  (String(dba.agency_digit) === '0' || String(dba.agency_digit).toLowerCase() === 'x') &&
  r.suggestion &&
  (String(r.suggestion.agency_digit) === '0' || String(r.suggestion.agency_digit).toLowerCase() === 'x');
const _filterAccountDigitToIgnoreZeroAndx = (dba, r) =>
  r.field === 'account_digit' &&
  (String(dba.account_digit) === '0' || String(dba.account_digit).toLowerCase() === 'x') &&
  r.suggestion &&
  (String(r.suggestion.account_digit) === '0' || String(r.suggestion.account_digit).toLowerCase() === 'x');

const _getAllUsedValidations = (dba, results) =>
  results.filter(
    r =>
      _filterAgencyDigitOnlyWhenFilled(dba, r) &&
      !(_filterAgencyDigitToIgnoreZeroAndx(dba, r) || _filterAccountDigitToIgnoreZeroAndx(dba, r))
  );
const _getErrorMessage = result => {
  if (result.suggestion && result.suggestion[result.field]) {
    if (result.field === 'agency_digit' || result.field === 'account_digit') {
      return getMessage(result) + ` Nossos cálculos indicam o dígito ${result.suggestion[result.field]}`;
    } else if (result.field === 'account_type') {
      return getMessage(result) + ` Indicamos o valor: ${accountType[result.suggestion[result.field]]}`;
    } else {
      return getMessage(result) + ` Indicamos o valor: ${result.suggestion[result.field]}`;
    }
  }

  return getMessage(result);
};

const validate = dba => {
  const _dba = { ...dba };
  delete _dba.agency_digit;

  let validationResult = JSON.parse(JSON.stringify(get()));

  let results = new BankAccountValidations(_dba).validate();

  results = _getAllUsedValidations(_dba, results);

  if (!results.length) {
    return validationResult;
  }

  results.forEach(r => {
    validationResult.isValid = false;
    validationResult[r.field] = {
      isValid: false,
      errorMessage: _getErrorMessage(r)
    };
  });

  return validationResult;
};

export default {
  validate,
  get
};
