import config from '@/config';
import fetch from '@/fetch';

const BASE_URL = config.url.api.payin + '/app/payment_links';

export const paymentLinkResource = {
  /**
   * @returns {Promise<{
   * PaymentLinkResponse}>}
   */
  create: paymentData => {
    return fetch(`${BASE_URL}`, { method: 'POST', body: JSON.stringify({ ...paymentData }) });
  },
  list: () => {
    return fetch(`${BASE_URL}`, { method: 'GET' });
  },
  delete: paymentLinkId => {
    return fetch(`${BASE_URL}/${paymentLinkId}`, { method: 'DELETE' });
  }
};
