<template>
  <bg-button
    type="button"
    @click="openMfaDialog"
  >
    Ativar 2FA agora
  </bg-button>
  <tfa-activation-dialog
    ref="activationDialog"
    :qr-code-src="qrCodeSrc"
    @activate="onActivate"
    @cancel="onCancel"
    @error="emit('error', $event)"
  />
</template>

<script setup>
import { ref } from 'vue';
import TfaActivationDialog from '@/apps/my-account/settings/components/container/TfaActivationDialog.vue';
import settingsResource from '@/commons/resources/settings';
import { BgButton } from '@transfeeradev/bridge';

const emit = defineEmits(['activate', 'error', 'cancel']);
const activationDialog = ref(null);
const qrCodeSrc = ref('');

const onActivate = () => {
  activationDialog.value.close();
  emit('activate');
};

const onCancel = () => {
  activationDialog.value.close();
  emit('cancel');
};

const openMfaDialog = () => {
  activationDialog.value.open();
  settingsResource.getTwoFactor().then(tfa => {
    qrCodeSrc.value = tfa.qrcode;
  });
};
</script>
