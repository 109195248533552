import UtilsService from '@transfeeradev/api-services/UtilsService';

const format = banks => {
  let banksObj = {};

  for (let bank of banks) {
    let key = UtilsService.removeAccents(bank.name);
    key = key.split(' ').join('_').toUpperCase();

    banksObj[key] = bank;
  }

  return banksObj;
};

export default {
  format
};
