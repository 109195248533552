<template>
  <div>
    <tr-slimbox
      title="Aqui você gerencia todos seus boletos!"
      :with-border="true"
      arrow-direction="bottom-left"
    >
      Os boletos cadastrados na Transfeera aparecem nesta lista para você acompanhar os status e buscar por
      comprovantes, sempre que precisar.
    </tr-slimbox>
    <tr-slimbox
      title="Seus Boletos"
      :with-dashed-border="true"
    >
      <billets-table
        :on-change="() => {}"
        :show-fake-receipt-links="true"
        :disable-click="true"
        :billets="exampleBillets"
      />
    </tr-slimbox>
  </div>
</template>

<script>
import config from '@/config';
import BilletsTable from '@/apps/payments/payments/billet/components/presentational/BilletsTable';
import { billetStatus } from '@transfeeradev/api-enums';

export default {
  name: 'billet-first-use-blank-slate',
  components: {
    BilletsTable
  },
  data: () => ({
    exampleBillets: [
      {
        bank: {
          image: `${config.url.cdn}/banks/bradesco.svg`
        },
        due_date: '2019-12-31T00:00:00.000Z',
        payment_date: '2019-12-31T00:00:00.000Z',
        description: 'Descrição do Boleto',
        batch: {
          id: 123,
          name: 'Pagamentos à fornecedor'
        },
        status: billetStatus.PAID,
        value: 625
      },
      {
        bank: {
          image: `${config.url.cdn}/banks/sicoob.svg`
        },
        due_date: '2019-12-31T00:00:00.000Z',
        payment_date: '2019-12-31T00:00:00.000Z',
        description: 'Descrição do Boleto',
        batch: {
          id: 123,
          name: 'Pagamentos à fornecedor'
        },
        status: billetStatus.PAID,
        value: 300
      },
      {
        bank: {
          image: `${config.url.cdn}/banks/caixa.svg`
        },
        due_date: '2019-12-31T00:00:00.000Z',
        payment_date: '2019-12-31T00:00:00.000Z',
        description: 'Descrição do Boleto',
        batch: {
          id: 123,
          name: 'Pagamentos à fornecedor'
        },
        status: billetStatus.PAID,
        value: 150
      }
    ]
  })
};
</script>
