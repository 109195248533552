<template>
  <tr-button
    dropdown="true"
    :label="selectedFilter"
    text-variant="body"
    variant="text"
  >
    <tr-dropdown-item
      key="all"
      :on-item-click="search.bind(undefined, undefined, 'Todos os bancos')"
    >
      Todos os bancos
    </tr-dropdown-item>
    <tr-dropdown-item
      v-for="bank in banks"
      :key="bank.id"
      :on-item-click="search.bind(undefined, bank.id, bank.name)"
    >
      {{ bank.name }}
    </tr-dropdown-item>
  </tr-button>
</template>

<script>
import bankResource from '@/apps/payments/payments/bank/resources/bank';

export default {
  name: 'bank-dropdown-filter-container',
  props: {
    onSearch: {
      required: true,
      type: Function
    }
  },
  data: () => ({
    banks: [],
    selectedFilter: 'Todos os bancos'
  }),
  created() {
    bankResource.get().then(banks => {
      this.banks = banks;
    });
  },
  methods: {
    search(key, name) {
      this.selectedFilter = name;
      this.onSearch(key);
    }
  }
};
</script>
