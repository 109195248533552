<template>
  <tr-page-section :no-margin="true">
    <dba-container
      ref="dbaContainer"
      :hide-navigation="hideDbaNavigation"
      :transfer="transfer"
      :on-valid-after-validation="onValidAfterDbaValidation"
      :on-invalid-after-validation="onInvalidAfterDbaValidation"
      :on-bank-change="onBankChange"
      :on-reset-dba="onResetDba"
      :on-change-dba="onChangeDba"
      :on-save-cancel="onDbaSaveCancel"
      :dba="dba"
      :should-make-complex-validations="shouldMakeComplexValidations"
    />
    <div class="row vertical-offset1">
      <div class="col-lg-12">
        <transfer-document-validation-container
          :transfer="transfer"
          :dba="dba"
        />
      </div>
    </div>
  </tr-page-section>
  <tr-page-section v-if="!canEditTransferValue">
    <div class="row">
      <tr-read-only-text-field
        label="Valor"
        :no-margin="true"
        :value="$format.currency(transfer.value)"
      />
    </div>
    <div class="vertical-offset2">
      <transfer-payment-date-switch-container
        :transfer="transfer"
        :payment-date="transfer.payment_date"
        :on-change="onPaymentDateChange"
      />
    </div>
  </tr-page-section>
  <tr-page-section
    v-if="canEditTransferValue"
    :no-margin="true"
    class="vertical-offset3"
    :title="transferValueAndPaymentDateSectionTitle"
  >
    <div class="row">
      <transfer-value-field-container
        :transfer="transfer"
        :dba="dba"
      />
    </div>
    <div
      v-if="showPixMessage"
      class="row vertical-offset2"
    >
      <transfer-pix-description-field-container
        v-model="transfer.pix_description"
        classes="col-lg-5"
      />
    </div>
    <div class="vertical-offset2">
      <transfer-payment-date-switch-container
        :transfer="transfer"
        :payment-date="transfer.payment_date"
        :on-change="onPaymentDateChange"
      />
    </div>
  </tr-page-section>
  <tr-page-section
    v-if="settings && settings.hasIntegrationId"
    title="Qual o ID de integração?"
  >
    <div class="row">
      <integration-id-field-container
        :integration-id="transfer.integration_id"
        :item-id="transfer.id"
        type="TRANSFER"
        :on-change="integrationId => (transfer.integration_id = integrationId)"
      />
    </div>
  </tr-page-section>
</template>

<script>
import DbaContainer from '@/apps/payments/payments/dba/components/container/DbaContainer.vue';
import TransferValueFieldContainer from '@/apps/payments/payments/transfer/components/container/TransferValueFieldContainer.vue';
import TransferPaymentDateSwitchContainer from '@/apps/payments/payments/transfer/components/container/TransferPaymentDateSwitchContainer.vue';
import TransferPixDescriptionFieldContainer from '@/apps/payments/payments/transfer/components/container/TransferPixDescriptionFieldContainer.vue';
import IntegrationIdFieldContainer from '@/apps/payments/components/container/IntegrationIdFieldContainer.vue';
import TransferDocumentValidationContainer from '@/apps/payments/payments/transfer/components/container/TransferDocumentValidationContainer.vue';
import transferModel from '@/apps/payments/payments/transfer/models/transfer';
import dbaModel from '@/apps/payments/payments/dba/models/dba';
import { transferStatus } from '@transfeeradev/api-enums';
import TransferPixService from '@transfeeradev/api-services/TransferPixService';
import bankResource from '@/apps/payments/payments/bank/resources/bank';
import settingsResource from '@/apps/payments/resources/settings';
import getByProp from '@/commons/services/getByProp';

export default {
  name: 'transfer-form',
  components: {
    DbaContainer,
    TransferValueFieldContainer,
    TransferPaymentDateSwitchContainer,
    IntegrationIdFieldContainer,
    TransferDocumentValidationContainer,
    TransferPixDescriptionFieldContainer
  },
  props: {
    transfer: {
      required: false,
      type: Object,
      default: transferModel.get()
    },
    dba: {
      required: false,
      type: Object,
      default: dbaModel.get()
    },
    settings: {
      required: false,
      type: Object,
      default: () => ({})
    },
    onPaymentDateChange: {
      required: true,
      type: Function
    },
    onBankChange: {
      required: false,
      type: Function
    },
    onResetDba: {
      required: true,
      type: Function
    },
    onChangeDba: {
      required: true,
      type: Function
    },
    onDbaSaveCancel: {
      required: false,
      type: Function
    },
    onValidAfterDbaValidation: {
      required: true,
      type: Function
    },
    onInvalidAfterDbaValidation: {
      required: false,
      type: Function
    },
    hideDbaNavigation: {
      required: false,
      type: Boolean,
      default: false
    },
    shouldMakeComplexValidations: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    transferStatus,
    banks: [],
    bank: { edit: false },
    previousBankId: null
  }),
  computed: {
    canEditTransferValue() {
      return Boolean(!this.transfer.status || this.transfer.status === transferStatus.CREATED);
    },
    showPixMessage() {
      return TransferPixService.isPixByTransfer(this.transfer.payment_method, this.dba);
    },
    transferValueAndPaymentDateSectionTitle() {
      return 'Qual valor e quando você quer transferir?';
    }
  },
  watch: {
    dba: {
      deep: true,
      handler(value) {
        this.setDefaultPaymentMethod(value.bank_id);
      }
    }
  },
  async mounted() {
    await bankResource.getAll().then(banks => {
      this.banks = banks;
    });
    if (this.dba.bank_id) {
      const bank = this.getBankById(this.dba.bank_id);
      this.setDefaultPaymentMethod(this.dba.bank_id);
      if (bank) {
        this.bank = bank;
      }
      this.bank.edit = true;
    }
  },
  methods: {
    validateDba() {
      this.$refs.dbaContainer.validate();
    },
    async setDefaultPaymentMethod(bankId) {
      if (!bankId || this.dba.pix_key_type) {
        this.transfer.payment_method = null;
        return;
      }
      if (this.previousBankId !== bankId) {
        this.previousBankId = bankId;

        this.bank = this.getBankById(bankId);
        this.bank.edit = false;

        this.transfer.payment_method = null;
        if (this.bank) {
          const response = await settingsResource.getDefaultPaymentMethod(this.bank.code);
          this.transfer.payment_method = response.payment_method;
        }
      }
    },
    getBankById(bankId) {
      const bank = getByProp.get({
        propValue: bankId,
        propName: 'id',
        list: this.banks
      });
      return bank;
    }
  }
};
</script>
