<template>
  <chart ref="chart" />
</template>

<script>
import Chart from './Chart.vue';
import transferResource from '@/apps/payments/payments/transfer/resources/transfer';
import { transferStatus } from '@transfeeradev/api-enums';

export default {
  name: 'distribution-of-transfers-per-bank-chart',
  components: {
    Chart
  },
  props: {
    onLoad: {
      required: false,
      type: Function
    },
    height: {
      required: false,
      type: String
    },
    width: {
      required: false,
      type: String
    },
    dateRange: {
      required: true
    }
  },
  watch: {
    dateRange: {
      deep: true,
      handler() {
        this.load();
      }
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    renderChart(data, options) {
      this.$refs.chart.renderChart({
        type: 'doughnut',
        data,
        options
      });
    },
    async load() {
      const status = transferStatus.FINISHED;
      const data = await transferResource.getDistributionPerBank(this.dateRange, status);

      let labels = data.map(d => d.name);
      let quantities = data.map(d => d.percentage);

      labels = labels.slice(0, 4).concat('Outros');
      quantities = quantities
        .slice(0, 4)
        .concat(quantities.slice(4, quantities.length).reduce((acc, n) => (acc += +n), 0));

      if (this.$refs.chart) {
        this.renderChart(
          {
            labels,
            datasets: [
              {
                label: '',
                data: quantities,
                backgroundColor: ['#6CC8F7', '#00AEF3', '#0096ED', '#006CB2', '#004876']
              }
            ]
          },
          {
            legend: {
              position: 'left',
              labels: {
                usePointStyle: true
              }
            },
            title: {
              display: true,
              text: '',
              position: 'top',
              fontStyle: 'normal'
            },
            tooltips: {
              callbacks: {
                label: tooltipItems => {
                  const item = data[tooltipItems.index];

                  return `${item.name}: ${item.percentage}%`;
                },
                footer: tooltipItems => {
                  return `Quantidade: ${data[tooltipItems[0].index].quantity}`;
                }
              }
            },
            animation: {
              onComplete: () => {
                return this.onLoad && this.onLoad(data);
              }
            }
          }
        );
      }
    }
  }
};
</script>
