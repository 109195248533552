<template>
  <batches-waiting-to-receive :on-redirect="onRedirect" />
</template>

<script>
import BatchesWaitingToReceive from '@/apps/payments/payments/batch/components/presentational/BatchesWaitingToReceive.vue';

export default {
  name: 'batches-waiting-to-receive-container',
  components: {
    BatchesWaitingToReceive
  },
  props: {
    onRedirect: {
      required: false,
      type: Function
    }
  },
  created() {
    return this.refresh();
  },
  methods: {
    refresh() {
      window.dispatchEvent(new Event('topbar.refresh'));
    }
  }
};
</script>
