<template>
  <tr-dialog
    ref="modal"
    name="batch-reproval-confirmation-dialog"
    title="Reprovar lote"
  >
    <div>
      <tr-typography variant="h5">
        Este lote voltará ao status rascunho e enviaremos um alerta para o usuário que o criou.
      </tr-typography>
      <br>
      <tr-typography
        variant="h5"
        classes="vertical-offset1 bottom-offset2 block"
      >
        Se você quiser, pode adicionar observações sobre o motivo da reprovação
      </tr-typography>
      <div class="row">
        <tr-text-field
          v-model="reason"
          label="Motivo da reprovação"
          type="textarea"
          no-margin="true"
          name="reason"
          maxlength="255"
        />
      </div>
    </div>
    <template #buttons>
      <tr-button
        classes="pull-right"
        :on-button-click="reproveBatch"
        label="Reprovar lote"
        variant="raised"
        color="danger"
      />
    </template>
  </tr-dialog>
</template>

<script>
export default {
  name: 'batch-reproval-confirmation-dialog',
  props: {
    onConfirm: {
      required: true,
      type: Function
    }
  },
  data: () => ({
    reason: ''
  }),
  methods: {
    reproveBatch() {
      this.onConfirm(this.reason);
    },
    open() {
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    }
  }
};
</script>
