<template>
  <show-for-allowed-app :app="APPS.PAYMENTS.key">
    <div class="app-topbar-actions">
      <tr-button
        v-tooltip="'Enviar saldo'"
        class="small-icon-button"
        aria-label="Enviar saldo"
        size="small"
        variant="raised"
        color="primary"
        svg-icon-name="plus"
        svg-icon-color="white"
        type="button"
        :on-button-click="openCreateEntryDialog"
      />
    </div>
    <bg-topbar-item>
      <top-bar-container
        :waiting-value="waitingValue"
        :value="balanceValue"
        :validation-account="validationAccount"
        :current-app-name="appName"
      />
    </bg-topbar-item>
    <entry-wizard-dialog
      ref="entryWizardDialog"
      :on-change="refreshTopbar"
    />
  </show-for-allowed-app>
</template>

<script>
import { BgTopbarItem } from '@transfeeradev/bridge';
import TopBarContainer from '@/commons/components/container/TopBarContainer.vue';
import statementResource from '@/apps/payments/payments/statement/resources/statement';
import settingsResource from '@/apps/payments/resources/settings';
import { APPS } from '@/commons/constants/apps';
import { getMessage } from '@/commons/services/error';
import { authAs, getCurrentApp } from '@/commons/services/apps';
import EntryWizardDialog from '@/apps/payments/payments/statement/components/presentational/EntryWizardDialog.vue';
import ShowForAllowedApp from '@/commons/components/container/ShowForAllowedApp.vue';
import { useErrorHandler } from '@/commons/composables/useErrorHandler';

export default {
  name: 'payments-top-bar-container',
  components: {
    TopBarContainer,
    EntryWizardDialog,
    ShowForAllowedApp,
    BgTopbarItem
  },
  data: () => ({
    APPS,
    waitingValue: 0,
    balanceValue: 0,
    validationAccount: false,
    appName: APPS.PAYMENTS.title
  }),
  created() {
    this.setListeners();
    this.refresh();
    this.handleError = useErrorHandler().handleError;
  },
  beforeUnmount() {
    window.clearInterval(this.interval);
    window.removeEventListener('refreshLoggedUserData', this.getUserName);
    window.removeEventListener('topbar.refresh', this.refreshItems);
  },
  methods: {
    async refresh() {
      await authAs(APPS.PAYMENTS.key);

      settingsResource.get().then(settings => {
        this.validationAccount = settings.validationAccount;
      });

      return this.refreshItems();
    },
    setListeners() {
      this.interval = window.setInterval(this.refreshItems, 60000);
      window.addEventListener('refreshLoggedUserData', this.getUserName);
      window.addEventListener('topbar.refresh', this.refreshItems);
    },
    openCreateEntryDialog() {
      this.$refs.entryWizardDialog.open();
    },
    refreshTopbar() {
      window.dispatchEvent(new Event('topbar.refresh'));
    },
    refreshItems() {
      this.getBalance();
    },
    async getBalance() {
      if (getCurrentApp()?.name !== APPS.PAYMENTS.key) {
        return;
      }

      statementResource.getBalance().then(
        balance => {
          this.waitingValue = balance.waiting_value;
          this.balanceValue = balance.value;
        },
        error => this.$toaster.error(getMessage(error))
      );
    }
  }
};
</script>

<style scoped>
.app-topbar-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
}

.bg-grey-semi-light {
  color: var(--bg-grey-semi-light);
  border-radius: 4px;
  opacity: 0.5;
}
</style>
