import config from '@/config';
import fetch from '@/fetch';

const BASE_URL = config.url.api.transfeera + '/app/receivers';

const formatBody = receiver => {
  return {
    name: receiver.name,
    account: receiver.account,
    account_digit: receiver.account_digit,
    account_type: receiver.account_type,
    agency: receiver.agency,
    agency_digit: receiver.agency_digit,
    cpf_cnpj: receiver.cpf_cnpj,
    email: receiver.email,
    pix_key_type: receiver.pix_key_type,
    pix_key: receiver.pix_key,
    bank_id: receiver.bank_id
  };
};

export default {
  getFiltered: (filters = {}) => {
    let params = `?page=${filters.page}`;

    if (filters.bankId) {
      params += `&bankId=${filters.bankId}`;
    }

    if (filters.search) {
      params += `&search=${filters.search}`;
    }

    return fetch(BASE_URL + params);
  },
  get: id => {
    return fetch(`${BASE_URL}/${id}`);
  },
  create: receiver => {
    return fetch(BASE_URL, {
      method: 'POST',
      body: JSON.stringify(formatBody(receiver))
    });
  },
  remove: id => {
    return fetch(`${BASE_URL}/${id}`, {
      method: 'DELETE'
    });
  },
  update: (receiver, id) => {
    return fetch(`${BASE_URL}/${id}`, {
      method: 'PATCH',
      body: JSON.stringify(formatBody(receiver))
    });
  },
  getAutocompleteSuggestions: query => {
    return fetch(`${BASE_URL}/autocomplete?search=${query}`);
  },
  bulkDelete: ids => {
    return fetch(`${BASE_URL}/bulk-delete`, {
      method: 'POST',
      body: JSON.stringify({ ids: ids })
    });
  }
};
