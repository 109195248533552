import { accountType } from '@transfeeradev/api-enums';

export default {
  [accountType.CONTA_CORRENTE]: 'Conta corrente',
  [accountType.CONTA_POUPANCA]: 'Conta poupança',
  [accountType.CONTA_FACIL]: 'Conta fácil',
  [accountType.ENTIDADES_PUBLICAS]: 'Entidade pública',
  [accountType.CONTA_SALARIO]: 'Conta salário',
  [accountType.CONTA_PAGAMENTO]: 'Conta de pagamentos'
};
