<template>
  <tr-form
    classes="flex-end"
    name="searchForm"
    :on-valid-submit="onSearch.bind(undefined, searchTerm)"
  >
    <tr-text-field
      v-model="internalSearchTerm"
      name="search"
      no-margin="true"
      placeholder="Dados do pagamento"
      :input-search="true"
    />
  </tr-form>
</template>

<script>
export default {
  name: 'payment-search',
  props: {
    searchTerm: {
      required: true,
      type: String
    },
    onChange: {
      required: true,
      type: Function
    },
    onSearch: {
      required: true,
      type: Function
    }
  },
  computed: {
    internalSearchTerm: {
      get() {
        return this.searchTerm;
      },
      set(newValue) {
        this.onChange(newValue);
      }
    }
  }
};
</script>
