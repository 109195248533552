import getHtmlToPlainText from '@/commons/formatters/getHtmlToPlainText';
import { billetStatus } from '@transfeeradev/api-enums';

const get = billet => {
  if (billet && _shouldShowForThisStatus(billet.status)) {
    const statusDescription = billet.status_description;
    return getHtmlToPlainText.get(statusDescription);
  }
  return '';
};

const _shouldShowForThisStatus = status =>
  Boolean(status && (status === billetStatus.FAILED || status === billetStatus.RETURNED));

export default {
  get
};
