<template>
  <div>
    <tr-page-section
      class="no-margin"
      title="Para qual conta Transfeera você deseja enviar o saldo?"
    >
      <transfeera-bank-account-selection-container
        ref="transfeeraBankAccountSelection"
        :selected-bank-account="entry.bankAccount"
        :on-value-change="onChangeBankAccount"
        :full-size="true"
        @loading="() => (isLoading = false)"
      />
      <tr-loading v-if="isLoading" />
    </tr-page-section>
    <tr-page-section
      v-if="!isLoading"
    >
      <entry-confirmation :entry="entry" />
    </tr-page-section>
  </div>
</template>

<script>
import TransfeeraBankAccountSelectionContainer from '@/apps/payments/payments/bank/components/container/TransfeeraBankAccountSelectionContainer';
import EntryConfirmation from '@/apps/payments/payments/statement/components/presentational/EntryConfirmation';

export default {
  name: 'entry-form',
  components: {
    TransfeeraBankAccountSelectionContainer,
    EntryConfirmation
  },
  data: () => ({
    isLoading: true,
    entry: {
      bankAccount: {
        Bank: {}
      }
    }
  }),
  methods: {
    refresh() {
      this.$refs.transfeeraBankAccountSelection.refresh();
    },
    onChangeBankAccount(bankAccount) {
      this.entry.bankAccount = bankAccount;
    }
  }
};
</script>
