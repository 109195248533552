import { statement } from '@transfeeradev/api-enums';

const entryType = statement.entryType;

export default {
  [entryType.EXPIRED_DEPOSIT]: 'grey',
  [entryType.DEPOSIT]: 'green',
  [entryType.REFUND]: 'green',
  [entryType.WITHDRAW]: 'blue',
  [entryType.PENDING_DEPOSIT]: 'yellow',
  [entryType.DEPOSIT_REFUND]: 'red',
  [entryType.PENDING_DEPOSIT_REFUND]: 'yellow',
  [entryType.CANCELLED_DEPOSIT_REFUND]: 'grey',
  [entryType.BATCH]: 'blue',
  [entryType.BLOCKED]: 'red',
  [entryType.BLOCKED_REFUND]: 'green',
  [entryType.UNLOCKED]: 'green'
};
