<template>
  <bank-account-container
    :banks="banks"
    :bank-account="bankAccount"
    @invalidate-field="invalidateField"
  >
    <template
      #default="{
        validations,
        accountTypes,
        agencyPattern,
        accountMaxDigits,
        agencyMaxDigits,
        onChangeBank,
        onChangeAccountType,
        onChangeAgency
      }"
    >
      <validation-account-form
        ref="form"
        :account-types="accountTypes"
        :agency-pattern="agencyPattern"
        :account-max-digits="accountMaxDigits"
        :agency-max-digits="agencyMaxDigits"
        :validations="validations"
        :bank-account="bankAccount"
        @update:bank="
          value => {
            onChangeBank();
            onBankChange(value);
          }
        "
        @update:agency="onChangeAgency"
        @update:account-type="onChangeAccountType"
      />
    </template>
  </bank-account-container>
</template>

<script>
import ValidationAccountForm from '@/apps/contacerta/validations/components/presentational/ValidationAccountForm.vue';
import bankResource from '@/apps/contacerta/resources/bank';
import BankAccountContainer from '@/commons/components/container/bank/BankAccountContainer.vue';

export default {
  name: 'validation-bank-account-form-container',
  components: {
    ValidationAccountForm,
    BankAccountContainer
  },
  props: {
    bankAccount: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    banks: []
  }),
  mounted() {
    bankResource.get().then(foundBanks => {
      this.banks = foundBanks;
    });
  },
  methods: {
    onBankChange(value) {
      this.bankAccount.bank_id = value;
    },
    invalidateField(field) {
      this.$refs.form.setAsInvalid(field);
    }
  }
};
</script>
