import merge from 'deepmerge';
import UtilsService from '@transfeeradev/api-services/UtilsService';
import PersonTypeService from '@transfeeradev/api-services/PersonTypeService';
import TransferPixService from '@transfeeradev/api-services/TransferPixService';
import { pix } from '@transfeeradev/api-enums';

const DBA_AVAILABLE_PROPERTIES = [
  'name',
  'cpf_cnpj',
  'bank_id',
  'email',
  'agency',
  'account',
  'account_digit',
  'account_type'
];
const PIX_DBA_AVAILABLE_PROPERTIES = ['email', 'pix_key_type', 'pix_key', 'name'];

const prepare = (inputDba, allowDocumentToPixDba, isFromSpreadsheet) => {
  const dba = merge({}, inputDba);

  if (TransferPixService.isPix(dba)) {
    const dbaAvailableProperties = [...PIX_DBA_AVAILABLE_PROPERTIES];
    if (allowDocumentToPixDba) {
      dbaAvailableProperties.push('cpf_cnpj');
    }

    Object.keys(dba).forEach(key => {
      if (!dbaAvailableProperties.includes(key)) {
        delete dba[key];
      }
    });

    if (!isFromSpreadsheet && dba.pix_key_type === pix.keyType.CPF && PersonTypeService.isCNPJ(dba.pix_key)) {
      dba.pix_key_type = pix.keyType.CNPJ;
    }

    if (PersonTypeService.isObfuscatedCPF(dba.cpf_cnpj)) {
      delete dba.cpf_cnpj;
    }

    return dba;
  }

  Object.keys(dba).forEach(key => {
    if (!DBA_AVAILABLE_PROPERTIES.includes(key)) {
      delete dba[key];
    }
  });

  if (dba.cpf_cnpj === 'undefined' || dba.cpf_cnpj === 'null') {
    dba.cpf_cnpj = '';
  }

  dba.cpf_cnpj = UtilsService.getNumbersFromStr(dba.cpf_cnpj);
  dba.agency_digit = null;

  return dba;
};

export default {
  prepare
};
