<template>
  <tr-dialog
    ref="modal"
    name="pix-key-form-dialog"
    :min-width="620"
    :width="620"
    :title="title"
    :has-its-own-buttons="true"
    :on-cancel="cancel"
  >
    <div class="bottom-offset3 description">
      {{ message }}
    </div>
    <div class="bottom-offset4">
      <pix-key-read-only-text-field
        :pix-key="pixKeyValue"
        :pix-key-type="pixKey.key_type"
        :dont-use-col-classes="true"
        :no-margin="true"
      />
      <div class="flex-row vertical-offset2">
        <pix-key-status-pill
          :status="pixKey.status"
          no-margin="true"
        />
      </div>
    </div>
    <div class="bottom-offset3 inline-block width-100">
      <tr-button
        class="pull-left"
        variant="outlined"
        color="secondary"
        label="Voltar"
        :on-button-click="cancel"
      />
      <tr-button
        class="pull-right"
        variant="raised"
        color="primary"
        :label="confirmButtonLabel"
        type="button"
        :disabled="loading"
        :on-button-click="confirm"
      />
      <tr-button
        v-tooltip.top-center="'Excluir chave'"
        class="pull-right margin-right"
        variant="raised"
        size="medium"
        svg-icon-name="ic-delete"
        svg-icon-color="white"
        color="danger"
        :on-button-click="remove"
      />
    </div>
  </tr-dialog>
</template>

<script>
import pixKeyRepr from '@/commons/formatters/pixKeyRepr';
import breakBiggerWord from '@/commons/formatters/breakBiggerWord';
import { pix } from '@transfeeradev/api-enums';
import PixKeyStatusPill from '@/apps/payments/payments/settings/pixKeys/components/presentational/PixKeyStatusPill.vue';
import PixKeyReadOnlyTextField from '@/apps/payments/payments/settings/pixKeys/components/presentational/PixKeyReadOnlyTextField.vue';

export default {
  name: 'pix-key-claim-request-confirm',
  components: { PixKeyStatusPill, PixKeyReadOnlyTextField },
  props: ['pixKey', 'loading'],
  emits: ['cancel', 'remove', 'confirm'],
  data: () => ({
    pixKeyType: pix.keyType
  }),
  computed: {
    pixKeyValue() {
      return breakBiggerWord(pixKeyRepr(this.pixKey), 40);
    },
    title() {
      if (this.pixKey.status === pix.pixKeyStatus.WAITING_PORTABILITY) {
        return 'Chave já cadastrada em outra conta';
      }
      return 'Chave já registrada';
    },
    message() {
      if (this.pixKey.status === pix.pixKeyStatus.WAITING_PORTABILITY) {
        return 'Para utilizar esta chave em sua conta Transfeera solicite a portabilidade abaixo e confirme a solicitação na conta em que a chave está salva.';
      }
      return 'Para utilizar esta chave em sua conta Transfeera, solicite a reivindicação da chave abaixo.';
    },
    confirmButtonLabel() {
      if (this.pixKey.status === pix.pixKeyStatus.WAITING_PORTABILITY) {
        return 'Solicitar portabilidade';
      }
      return 'Reivindicar chave';
    }
  },
  mounted() {
    this.$refs.modal.open();
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    remove() {
      this.$emit('remove');
    },
    confirm() {
      this.$emit('confirm');
    }
  }
};
</script>
