<template>
  <validation-result-status
    v-if="validationData"
    :status="status"
    :is-micro-deposit="isMicroDeposit"
    :errors="errors"
    :is-new-validation="isNewValidation"
  />
</template>

<script>
import { contacerta } from '@transfeeradev/api-enums';
import VALIDATION_RESULT_STATUS from '@/apps/contacerta/validations/constants/validationResultStatus';
import ValidationResultStatus from '../presentational/ValidationResultStatus';
import { getMessage } from '@/commons/services/error';

const FIELD_LABEL_MAP = {
  name: 'favorecido',
  cpf_cnpj: 'CPF/CNPJ',
  account: 'conta',
  account_digit: 'dígito da conta',
  account_type: 'tipo da conta',
  agency: 'agência',
  pix_key: 'chave Pix'
};

export default {
  name: 'validation-result-status-container',
  components: {
    ValidationResultStatus
  },
  props: {
    loading: {
      type: Boolean,
      required: false
    },
    validationData: {
      type: Object,
      required: false
    },
    isNewValidation: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    status() {
      if (!this.loading && this.validationData.valid) {
        return VALIDATION_RESULT_STATUS.SUCCESS;
      }
      if (!this.loading && this.validationData?.errors?.length) {
        return VALIDATION_RESULT_STATUS.ERROR;
      }
      if (!this.loading && this.validationData?.valid === null) {
        return VALIDATION_RESULT_STATUS.WARNING;
      }

      return VALIDATION_RESULT_STATUS.LOADING;
    },
    isMicroDeposit() {
      return this.validationData?.type === contacerta.type.MICRO_DEPOSIT;
    },
    errors() {
      if (this.validationData?.errors?.length) {
        return this.validationData.errors.map(error => ({
          message: getMessage(error),
          suggestions: this._getErrorSuggestions(error)
        }));
      }
      return [];
    }
  },
  methods: {
    _getErrorSuggestions(error) {
      if (!error.suggestion) {
        return [];
      }

      const suggestions = [];
      if (typeof error.suggestion === 'object') {
        for (let field of Object.keys(error.suggestion)) {
          suggestions.push({
            field: FIELD_LABEL_MAP[field],
            value: error.suggestion[field]
          });
        }
      } else {
        suggestions.push({ value: error.suggestion });
      }
      return suggestions;
    }
  }
};
</script>
