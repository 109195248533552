import { transferStatus } from '@transfeeradev/api-enums';

export default {
  [transferStatus.CREATED]: 'Criada',
  [transferStatus.RECEIVED]: 'Em processamento',
  [transferStatus.SCHEDULED]: 'Agendado',
  TRANSFERRED: 'Transferida',
  [transferStatus.TRANSFERRED]: 'Transferida',
  [transferStatus.FINISHED]: 'Transferida',
  [transferStatus.FAILED]: 'Falha',
  [transferStatus.RETURNED]: 'Devolvida',
  [transferStatus.EM_REMESSA]: 'Em processamento',
  [transferStatus.EXECUTING]: 'Em processamento',
  [transferStatus.FAILED_INTERNAL]: 'Em processamento',
  [transferStatus.BEING_RETURNED]: 'Em devolução',
  [transferStatus.REQUESTED_REFUND]: 'A devolver',
  ALERTA: 'Alerta'
};
