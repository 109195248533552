import { statement } from '@transfeeradev/api-enums';

const entryType = statement.entryType;

export default {
  [entryType.EXPIRED_DEPOSIT]: 'expirado',
  [entryType.DEPOSIT]: 'recebido',
  [entryType.REFUND]: 'estornado',
  [entryType.WITHDRAW]: 'resgatado',
  [entryType.PENDING_DEPOSIT]: 'pendente',
  [entryType.DEPOSIT_REFUND]: 'devolvido',
  [entryType.PENDING_DEPOSIT_REFUND]: 'pendente',
  [entryType.CANCELLED_DEPOSIT_REFUND]: 'cancelado',
  [entryType.BATCH]: 'lote pago',
  [entryType.BLOCKED]: 'bloqueado',
  [entryType.BLOCKED_REFUND]: 'desbloqueado',
  [entryType.UNLOCKED]: 'desbloqueado'
};
