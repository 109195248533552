import { hasAccess } from '@/commons/services/routeConfigAccessValidation';
import { ref } from 'vue';
import { useRouter } from 'vue-router';

export const useAllowedRoute = routeName => {
  const show = ref(false);
  const loading = ref(true);
  const router = useRouter();
  const route = router.getRoutes().find(r => r.name === routeName);

  if (!route) {
    throw new Error(`Route ${routeName} not found`);
  }

  const checkIfShouldShow = async () => {
    show.value = await hasAccess(route.meta);
    loading.value = false;
  };

  checkIfShouldShow();

  return {
    show,
    loading
  };
};
