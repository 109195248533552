<template>
  <tr-dialog
    ref="modal"
    :on-cancel="onCancel"
    name="batch-close-confirmation-dialog"
    title="Confirmação"
  >
    <div>
      <tr-typography
        classes="vertical-offset2 inline-block"
        variant="h5"
      >
        Ao fechar esse lote você não poderá mais editar os pagamentos, exceto agendamento de boletos que poderão ser cancelados até 01 dia antes da data programada.
      </tr-typography>
    </div>
    <div>
      <tr-typography
        classes="vertical-offset2 inline-block"
        variant="h5"
      >
        O valor a ser pago é de {{ $format.currency(batchValue) }}
      </tr-typography>
    </div>
    <template #buttons>
      <tr-button
        classes="pull-right"
        :on-button-click="onConfirm"
        label="Fechar lote"
        variant="raised"
        color="primary"
      />
    </template>
  </tr-dialog>
</template>

<script>
export default {
  name: 'batch-close-confirmation-dialog',
  props: {
    onConfirm: {
      required: true,
      type: Function
    },
    onCancel: {
      required: true,
      type: Function
    },
    batchValue: {
      required: false,
      type: [Number, String]
    }
  },
  methods: {
    open() {
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    }
  }
};
</script>
