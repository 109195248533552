<template>
  <bg-badge
    :color="receivableStatusColorAndIcon[status].badgeColor"
    :size="badgeSize"
  >
    <bg-icon
      v-if="receivableStatusColorAndIcon[status].icon"
      :size="iconSize"
      :name="receivableStatusColorAndIcon[status].icon"
      :color="receivableStatusColorAndIcon[status].textColor"
      :class="isBadgeSizeMd ? '' : 'receivables-status-bagde__icon--small'"
    />
    <bg-text
      type="label"
      weight="semi-bold"
      class="receivables-status-bagde__text"
      :color="receivableStatusColorAndIcon[status].textColor"
    >
      {{ receivableStatusName[status] }}
    </bg-text>
  </bg-badge>
</template>

<script setup>
import { BgIcon, BgBadge, BgText } from '@transfeeradev/bridge';
import { computed } from 'vue';

const props = defineProps({
  status: {
    type: String,
    required: true
  },
  badgeSize: {
    type: String,
    default: 'sm'
  },
  iconSize: {
    type: String,
    default: 'sm'
  }
});

const isBadgeSizeMd = computed(() => props.badgeSize === 'md');

const receivableStatusColorAndIcon = computed(() => {
  return {
    received: { textColor: 'green-semi-dark', badgeColor: 'green-light', icon: 'check', width: '8px' },
    pending: { textColor: 'yellow-semi-dark', badgeColor: 'yellow-light', icon: 'clock', width: '10px' },
    canceled: { textColor: 'grey-semi-dark', badgeColor: 'grey-light', icon: 'cancel', width: '10px' },
    refunded: { textColor: 'blue-semi-dark', badgeColor: 'blue-light', icon: 'back-arrow', width: '10px' },
    partially_refunded: { textColor: 'blue-semi-dark', badgeColor: 'blue-light', icon: 'back-arrow', width: '10px' },
    refunding: { textColor: 'blue-semi-dark', badgeColor: 'blue-light', icon: 'back-arrow', width: '10px' },
    expired: { textColor: 'grey-semi-dark', badgeColor: 'grey-light', icon: 'info', width: '8px' },
    active: { textColor: 'green-semi-dark', badgeColor: 'green-light', width: '8px' },
    authorized: { textColor: 'green-semi-dark', badgeColor: 'green-light', icon: 'credit-score', width: '16px' },
    unauthorized: { textColor: 'red-semi-dark', badgeColor: 'red-light', icon: 'credit-score', width: '16px' }
  };
});

const receivableStatusName = computed(() => {
  return {
    received: 'Recebido',
    pending: 'Pendente',
    canceled: 'Cancelado',
    refunded: 'Devolvido',
    partially_refunded: 'Devolvido',
    refunding: 'Processando',
    expired: 'Expirado',
    active: 'Ativo',
    authorized: 'Autorizado',
    unauthorized: 'Negado'
  };
});
</script>

<style scoped>
.receivables-status-bagde__text {
  margin-left: var(--bg-spacing-2x-sm);
}

.receivables-status-bagde__icon--small {
  width: v-bind(receivableStatusColorAndIcon[status].width);
}
</style>
