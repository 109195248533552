<template>
  <span>{{ dbaDocument }}</span>
</template>

<script>
import dbaModel from '@/apps/payments/payments/dba/models/dba';
import dbaDocumentRepr from '@/commons/formatters/dbaDocumentRepr';

export default {
  name: 'dba-document-view',
  props: {
    dba: {
      required: true,
      type: Object,
      default: dbaModel.get
    }
  },
  computed: {
    dbaDocument() {
      return dbaDocumentRepr(this.dba);
    }
  }
};
</script>
