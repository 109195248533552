<template>
  <tr-table :disable-click="!onItemClick">
    <tr-thead>
      <tr-tr>
        <tr-th
          v-tooltip.top-center="'Selecionar todos dessa página'"
          classes="cursor-pointer"
          width="4%"
        >
          <input
            id="select-all"
            v-model="selectAll"
            type="checkbox"
            class="cursor-pointer"
            style="margin-left: 10px"
            @click.stop="selectAll = !selectAll"
          >
        </tr-th>
        <tr-th width="30%">
          Nome
        </tr-th>
        <tr-th width="20%">
          Documento
        </tr-th>
        <tr-th width="30%">
          Pix ou Conta
        </tr-th>
      </tr-tr>
    </tr-thead>
    <tr-tbody>
      <tr-tr
        v-for="receiver in receivers"
        :key="receiver.id"
        :on-item-click="onItemClick && onItemClick.bind(undefined, receiver)"
      >
        <tr-td @click.stop>
          <input
            :id="`receiver-${receiver.id}`"
            v-model="checkedReceivers"
            type="checkbox"
            class="cursor-pointer"
            style="margin-left: 10px"
            :value="receiver.id"
          >
        </tr-td>
        <tr-td>
          <tr-typography
            v-tooltip.top-center="getReceiverBankName(receiver)"
            class="margin-right-10"
          >
            <bank-image
              :is-pix="!!receiver.pix_key"
              :bank="receiver.Bank"
            />
          </tr-typography>
          <tr-typography v-tooltip.top-center="getReceiverName(receiver)">
            {{ breakBiggerWord(getReceiverName(receiver), 40) }}
          </tr-typography>
        </tr-td>
        <tr-td>
          <tr-typography>
            <dba-document-view :dba="receiver" />
          </tr-typography>
        </tr-td>
        <tr-td>
          <tr-typography v-tooltip.top-center="dbaAccountRepr(receiver)">
            {{ breakBiggerWord(dbaAccountRepr(receiver), 40) }}
          </tr-typography>
        </tr-td>
      </tr-tr>
    </tr-tbody>
  </tr-table>
</template>

<script>
import breakBiggerWord from '@/commons/formatters/breakBiggerWord';
import capitalize from '@/commons/formatters/capitalize';
import DbaDocumentView from '@/apps/payments/payments/dba/components/presentational/DbaDocumentView.vue';
import BankImage from '@/apps/payments/payments/bank/components/presentational/BankImage.vue';
import dbaAccountRepr from '@/commons/formatters/dbaAccountRepr';

export default {
  name: 'receivers-table',
  components: {
    DbaDocumentView,
    BankImage
  },
  props: {
    receivers: {
      required: false,
      type: Array,
      default: () => []
    },
    onItemClick: {
      required: false,
      type: Function
    }
  },
  emits: ['receivers-checked'],
  data: () => ({
    clickedReceivers: {},
    selectAll: false,
    checkedReceivers: []
  }),
  watch: {
    selectAll(enabled) {
      if (enabled) {
        this.checkedReceivers = this.receivers.map(r => r.id);
      } else {
        this.checkedReceivers = [];
      }
    },
    checkedReceivers: {
      deep: true,
      handler(receiversIds) {
        this.$emit('receivers-checked', receiversIds);
      }
    }
  },
  methods: {
    breakBiggerWord,
    dbaAccountRepr,
    getReceiverBankName(receiver) {
      if (receiver.pix_key) {
        return 'Pix';
      }

      return receiver.Bank ? receiver.Bank.code + ' - ' + receiver.Bank.name : '';
    },
    getReceiverName(receiver) {
      if (!receiver.name) {
        return '---';
      }

      return capitalize(receiver.name);
    }
  }
};
</script>
