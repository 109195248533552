<template>
  <validation-form-container
    v-if="showValidationForm"
    v-model:validation-data-type="validationDataType"
    :validation-data="validationData"
    @validate="onValidate"
  />
  <validation-result-container
    v-else
    :validation-id="validationId"
    :should-polling="true"
    :show-validation-buttons="true"
    :is-new-validation="true"
    @new-validation="onNewValidation"
    @redo-validation="onRedoValidation"
  />
</template>

<script>
import ValidationFormContainer from './ValidationFormContainer.vue';
import ValidationResultContainer from './ValidationResultContainer.vue';
import validationResource from '@/apps/contacerta/resources/validation';
import VALIDATION_DATA_TYPES from '@/apps/contacerta/validations/constants/validationDataTypes';
import { getMessage } from '@/commons/services/error';

export default {
  name: 'new-validation-container',
  components: {
    ValidationFormContainer,
    ValidationResultContainer
  },
  data() {
    return {
      companySettings: {},
      activeTab: null,
      validationData: {},
      validationDataType: VALIDATION_DATA_TYPES.ACCOUNT,
      validationId: null,
      showValidationForm: true
    };
  },
  watch: {
    activeTab(newValue, oldValue) {
      if (newValue !== oldValue) {
        this._clearValidationData();
      }
    },
    validationDataType(newValue, oldValue) {
      if (newValue !== oldValue) {
        this._clearValidationData();
      }
    }
  },
  methods: {
    onValidate(type, payload) {
      this.showValidationForm = false;
      validationResource.createValidation(type, payload).then(
        result => {
          this.validationId = result.id;
        },
        error => {
          this.$toaster.error(`Ocorreu um erro ao realizar a validação ${getMessage(error)}`);
        }
      );
    },
    onNewValidation() {
      this._clearValidationData();
      this.validationId = null;
      this.validationDataType = VALIDATION_DATA_TYPES.ACCOUNT;
      this.showValidationForm = true;
    },
    onRedoValidation() {
      this.validationId = null;
      this.showValidationForm = true;
    },
    _clearValidationData() {
      this.validationData = {};
    }
  }
};
</script>
