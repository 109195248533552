<template>
  <tr-table :disable-click="disableClick">
    <tr-thead>
      <tr-tr>
        <tr-th
          v-tooltip.top-center="'Selecionar todos dessa página'"
          class="cursor-pointer"
          @click.stop="selectAll = !selectAll"
        >
          <input
            id="select-all"
            v-model="selectAll"
            type="checkbox"
            class="cursor-pointer"
            style="margin-left: 10px"
          >
        </tr-th>
        <tr-th width="7%">
          Banco
        </tr-th>
        <tr-th width="10%">
          Vencimento
        </tr-th>
        <tr-th width="12%">
          Agendamento
        </tr-th>
        <tr-th width="18%">
          Descrição
        </tr-th>
        <tr-th width="14%">
          Lote de boletos
        </tr-th>
        <tr-th width="18%">
          Status
        </tr-th>
        <tr-th width="11%">
          Valor
        </tr-th>
        <tr-th width="10%" />
      </tr-tr>
    </tr-thead>
    <tr-tbody>
      <tr-tr
        v-for="billet in billets"
        :key="billet.id"
        :disable-click="disableClick"
        :on-item-click="showBilletDetailsFullModal.bind(undefined, billet)"
      >
        <tr-td @click.stop>
          <input
            :id="`billet-${billet.id}`"
            v-model="checkedBillets"
            type="checkbox"
            class="cursor-pointer"
            style="margin-left: 10px"
            :disabled="!hasReceiptToDownload(billet)"
            :value="billet.id"
          >
        </tr-td>
        <tr-td>
          <tr-typography>
            <bank-image :bank="billet.bank" />
          </tr-typography>
        </tr-td>
        <tr-td>
          <tr-typography>
            {{ formatDate(billet.due_date) }}
          </tr-typography>
        </tr-td>
        <tr-td>
          <tr-typography>
            {{ formatDate(billet.payment_date) }}
          </tr-typography>
        </tr-td>
        <tr-td>
          <tr-typography>
            {{ breakBiggerWord(billet.description, 22) }}
          </tr-typography>
        </tr-td>
        <tr-td>
          <tr-typography>
            {{ breakBiggerWord(billet.batch && billet.batch.name, 23) }}
          </tr-typography>
        </tr-td>
        <tr-td>
          <billet-status-pill
            :no-margin="true"
            size="medium"
            :status="billet.status"
          />
        </tr-td>
        <tr-td>
          <tr-typography>
            <span class="inline-block">{{ $format.currency(billet.value) }}</span>
          </tr-typography>
        </tr-td>
        <tr-td>
          <billet-receipt-download-links-container
            :show-fake-links="showFakeReceiptLinks"
            :should-always-show="true"
            :billet="billet"
          />
        </tr-td>
      </tr-tr>
    </tr-tbody>
    <billet-details-full-modal
      ref="BilletDetailsFullModal"
      :show-batch-fullscreen-modal="showBatchFullscreenModal"
      :on-hidden="onHiddenBilletDetailsFullModal"
      :on-cancel="onBilletCancel"
      :billet-id="clickedBillet.id"
    />
    <batch-fullscreen-modal
      ref="batchFullscreenModal"
      :on-change="onChange"
      :unique-name="true"
    />
  </tr-table>
</template>

<script>
import formatDate from '@/commons/formatters/formatDate';
import breakBiggerWord from '@/commons/formatters/breakBiggerWord';
import BilletStatusPill from '@/apps/payments/payments/billet/components/presentational/BilletStatusPill';
import BilletReceiptDownloadLinksContainer from '@/apps/payments/payments/billet/components/container/BilletReceiptDownloadLinksContainer';
import BilletDetailsFullModal from '@/apps/payments/payments/billet/components/presentational/BilletDetailsFullModal';
import BankImage from '@/apps/payments/payments/bank/components/presentational/BankImage';
import BatchFullscreenModal from '@/apps/payments/payments/batch/pages/BatchFullscreenModal';
import checkpointResource from '@/apps/payments/resources/checkpoint';
import checkpoint from '@/commons/constants/checkpoint';
import { getMessage } from '@/commons/services/error';

export default {
  name: 'billets-table',
  components: {
    BilletStatusPill,
    BilletReceiptDownloadLinksContainer,
    BilletDetailsFullModal,
    BatchFullscreenModal,
    BankImage
  },
  props: {
    billets: {
      required: false,
      type: [Object, Array]
    },
    disableClick: {
      required: false,
      type: Boolean,
      default: false
    },
    showFakeReceiptLinks: {
      required: false,
      type: Boolean,
      default: false
    },
    onChange: {
      required: false,
      type: Function
    }
  },
  emits: ['on-billet-checked'],
  data: () => ({
    clickedBillet: {},
    selectAll: false,
    checkedBillets: []
  }),
  watch: {
    selectAll(enabled) {
      if (enabled) {
        this.checkedBillets = this.billets.filter(t => this.hasReceiptToDownload(t)).map(t => t.id);
      } else {
        this.checkedBillets = [];
      }
    },
    checkedBillets: {
      deep: true,
      handler(billetIds) {
        this.$emit('on-billet-checked', billetIds);
      }
    }
  },
  methods: {
    breakBiggerWord,
    formatDate,
    showBilletDetailsFullModal(clickedBillet) {
      checkpointResource
        .create(checkpoint.OPENED_BILLET_FROM_BILLETS_PAGE)
        .catch(error => this.$toaster.error(getMessage(error)));
      this.clickedBillet = clickedBillet;
      this.$refs.BilletDetailsFullModal.open();
    },
    onHiddenBilletDetailsFullModal() {
      this.clickedBillet = {};
    },
    onBilletCancel() {
      this.clickedBillet = {};
      this.onChange(true);
    },
    showBatchFullscreenModal() {
      const clickedBillet = this.clickedBillet;
      this.$refs.BilletDetailsFullModal.close();
      this.$refs.batchFullscreenModal.open(clickedBillet.batch && clickedBillet.batch.id);
    },
    hideBatchFullscreenModal() {
      this.$refs.batchFullscreenModal.close();
      this.clickedBillet = {};
    },
    hasReceiptToDownload(billet) {
      return billet.bank_receipt_url || billet.receipt_url;
    }
  }
};
</script>
