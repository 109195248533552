<template>
  <tr-form>
    <pix-key-selection
      ref="pixKeyForm"
      :go-to-bank-selection="goToBankSelection"
      @hasPixKeys="(value) => (hasPixKey = value)"
    />
    <tr-dialog-buttons-container>
      <tr-button
        classes="pull-left"
        :label="'Fechar'"
        variant="raised"
        color="secondary"
        :on-button-click="close"
      />
      <tr-button
        v-if="hasPixKey"
        classes="pull-right"
        :label="'Ver minhas chaves Pix'"
        variant="raised"
        color="primary"
        :on-button-click="goToPixPage"
      />
      <tr-button
        v-if="!hasPixKey"
        classes="pull-right"
        :label="'Adicionar chave Pix'"
        variant="raised"
        color="primary"
        :on-button-click="openPixKeyWizardDialog"
      />
    </tr-dialog-buttons-container>
  </tr-form>
  <pix-key-wizard-dialog
    v-if="!hasPixKey"
    ref="pixKeyWizardDialog"
    @close="onClosePixKeysWizard"
  />
</template>

<script>
import PixKeySelection from '@/apps/payments/payments/bank/components/presentational/PixKeySelection';
import PixKeyWizardDialog from '@/apps/payments/payments/settings/pixKeys/components/presentational/PixKeyWizardDialog';

export default {
  name: 'pix-selection-container',
  components: {
    PixKeySelection,
    PixKeyWizardDialog
  },
  props: {
    goToBankSelection: {
      required: false,
      type: Function
    },
    close: {
      required: false,
      type: Function
    }
  },
  data: () => ({
    hasPixKey: false
  }),
  methods: {
    openPixKeyWizardDialog() {
      this.$refs.pixKeyWizardDialog.open();
    },
    onClosePixKeysWizard() {
      this.$refs.pixKeyForm.refreshKeys();
    },
    goToPixPage() {
      this.close?.();
      this.$router.push({ name: 'PaymentsSettings', state: { redirectedFrom: 'StatementPage' } });
    }
  }
};
</script>
