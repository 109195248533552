<template>
  <tr-button
    dropdown="true"
    :label="selectedFilter"
    text-variant="body"
    variant="text"
  >
    <tr-dropdown-item
      key="all"
      :on-item-click="search.bind(undefined, undefined, 'Todos os status')"
    >
      Todos os status
    </tr-dropdown-item>
    <tr-dropdown-item
      v-for="(statusName, statusKey) in VALIDATION_STATUS"
      :key="statusKey"
      :on-item-click="search.bind(undefined, statusKey, statusName)"
    >
      {{ statusName }}
    </tr-dropdown-item>
  </tr-button>
</template>

<script>
import VALIDATION_STATUS from '@/apps/contacerta/validations/constants/validationStatus';

export default {
  name: 'validation-history-status-dropdown-filter-container',
  emits: ['search'],
  data: () => ({
    VALIDATION_STATUS,
    selectedFilter: 'Todos os status'
  }),
  methods: {
    search(key, name) {
      this.selectedFilter = name;
      this.$emit('search', key);
    }
  }
};
</script>
