import { roles } from '@transfeeradev/api-enums';
import ValidationsLayout from './Layout';
import NewValidationPage from './pages/NewValidationPage';
import ValidationHistoryPage from './pages/ValidationHistoryPage';
import loggedUser from '@/commons/resources/loggedUser';

export default {
  path: 'validacoes',
  component: ValidationsLayout,
  name: 'ContaCertaValidations',
  redirect: () => {
    return {
      name: loggedUser.isAnalyst() ? 'ContaCertaValidationHistoryPage' : 'ContaCertaNewValidationPage'
    };
  },
  children: [
    {
      path: 'nova',
      name: 'ContaCertaNewValidationPage',
      component: NewValidationPage,
      meta: {
        forbidForRoles: [roles.appRoles.ANALYST]
      }
    },
    {
      path: 'historico',
      name: 'ContaCertaValidationHistoryPage',
      component: ValidationHistoryPage
    }
  ]
};
