<template>
  <div>
    <div class="row">
      <batch-status-pill
        classes="col-lg-3"
        :batch-status="batch.status"
      />
      <slot />
    </div>
    <div class="row">
      <tr-read-only-text-field
        label="Criado por"
        :value="batch.created_by && batch.created_by.name"
      />
    </div>
    <div class="row">
      <tr-read-only-text-field
        classes="col-lg-4"
        no-margin="true"
        label="Valor do lote"
        :value="$format.currency(batch.value)"
      />
    </div>
  </div>
</template>

<script>
import BatchStatusPill from '@/apps/payments/payments/batch/components/presentational/BatchStatusPill';
import batchModel from '@/apps/payments/payments/batch/models/batch';

export default {
  name: 'batch-with-any-waiting-status-top-info',
  components: {
    BatchStatusPill
  },
  props: {
    batch: {
      required: false,
      type: Object,
      default: batchModel.get
    },
    companyName: {
      required: false,
      type: String
    }
  }
};
</script>
