<template>
  <billing-closed-container />
  <tr-loading
    v-if="isLoading"
    classes="center-h-and-v width-100 vertical-offset5"
  />
  <template
    v-if="billing && billing.length && !isLoading"
  >
    <billing-table
      :billing="billing"
      :get-monthly-report="getMonthlyReport"
    />
    <tr-typography classes="vertical-offset4">
      Caso você tenha dúvidas ou existam informações incorretas, entre em contato pelo e-mail suporte@transfeera.com.
    </tr-typography>
  </template>
  <billing-blankslate v-if="!billing.length && !isLoading" />
</template>

<script>
import BillingBlankslate from '@/apps/admin/billing/components/presentational/BillingBlankslate.vue';
import BillingTable from '@/apps/admin/billing/components/presentational/BillingTable.vue';
import BillingClosedContainer from '@/apps/admin/billing/components/container/BillingClosedContainer.vue';
import billingResource from '@/apps/admin/billing/resources/billing';
import { getMessage } from '@/commons/services/error';

export default {
  name: 'billing-container',
  components: {
    BillingBlankslate,
    BillingTable,
    BillingClosedContainer
  },
  data: () => ({
    billing: [],
    isLoading: false
  }),
  created() {
    this.isLoading = true;
    return billingResource.get().then(
      billing => {
        this.billing = billing;
        this.isLoading = false;
      },
      error => this.$toaster.error(getMessage(error))
    );
  },
  methods: {
    async getMonthlyReport(billingStartPeriod) {
      try {
        await billingResource.getMonthlyReport(billingStartPeriod);
        this.$toaster.success({
          message: 'Relatório está sendo gerado, quando concluído será enviado para o seu email.',
          duration: 10000
        });
      } catch (error) {
        this.$toaster.error(getMessage(error));
      }
    }
  }
};
</script>
