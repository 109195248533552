<template>
  <bank-account-selection
    :selected-bank-account="selectedBankAccount"
    :on-select="onSelect"
    :bank-accounts="bankAccounts"
    :full-size="fullSize"
  />
</template>

<script>
import BankAccountSelection from '@/apps/payments/payments/bank/components/presentational/BankAccountSelection';
import bankAccountResource from '@/apps/payments/payments/bank/resources/bankAccount';
import { getMessage } from '@/commons/services/error';

export default {
  name: 'transfeera-bank-account-selection-container',
  components: {
    BankAccountSelection
  },
  props: {
    defaultBankAccount: {
      required: false
    },
    onValueChange: {
      required: true,
      type: Function
    },
    selectedBankAccount: {
      required: true,
      type: Object,
      default: () => ({
        Bank: {}
      })
    },
    fullSize: {
      required: false,
      type: Boolean
    }
  },
  emits: ['loading'],
  data: () => ({
    bankAccounts: []
  }),
  created() {
    return this.refresh();
  },
  methods: {
    async refresh() {
      try {
        this.bankAccounts = await bankAccountResource.getTransfeeraBankAccounts();

        // FIXME: Remove bank filter
        this.bankAccounts = this.bankAccounts.filter(
          ba =>
            ![
              '033', // Santander
              '748', // Sicredi
              '077', // Inter
              '756', // Sicoob
              '104', // Caixa
              '237', // Bradesco
              '001' // Banco do Brasil
            ].includes(ba.Bank.code)
        );

        this.onValueChange(this.bankAccounts[0]);
        this.$emit('loading', false);
      } catch (error) {
        this.$toaster.error(getMessage(error));
      }
    },
    onSelect(selected) {
      this.onValueChange(selected);
    }
  }
};
</script>
