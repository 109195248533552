export function getMaxLengthIgnoringZeros(value, maxLength) {
  if (!value) {
    return maxLength;
  }

  const valueWithoutZeros = value.replace(/^0+/, '');
  if (valueWithoutZeros.length <= maxLength) {
    return;
  }

  return maxLength;
}
