<template>
  <div class="row">
    <div class="row">
      <div class="col-xs-7 vertical-offset1">
        <tr-read-only-text-field
          classes="col-xs-6 pix-cashin-key-visualization"
          label="Chave Pix"
          :value="pixKeyValue"
        />
      </div>
      <div class="col-xs-5">
        <img
          :src="pixQRCodeImage"
          class="pix-qrcode-image-cashin"
        >
        <tr-button
          v-if="hasPixImage"
          :on-button-click="() => copyToClipboard(pixKey.emv_payload)"
          class="pix-copy-qrcode-emv"
          variant="raised"
          size="small"
          svg-icon-name="ic-content-copy"
          svg-icon-color="white"
          title="Copiar código"
        />
      </div>
      <div
        v-if="!hasPixImage"
        class="col-xs-5 pull-right margin-top"
      >
        <tr-typography variant="h6">
          QRCode não disponível
        </tr-typography>
      </div>
    </div>
    <div class="row vertical-offset2">
      <div class="col-xs-7">
        <tr-read-only-text-field
          label="CNPJ"
          :value="cpfCnpj"
        />
      </div>
      <div class="col-xs-5">
        <tr-read-only-text-field
          label="Agência"
          :value="pixKey.agency"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-xs-7">
        <tr-read-only-text-field
          :no-margin="noMarginOnLastLine"
          label="Razão social"
          :value="pixKey.name"
        />
      </div>
      <div class="col-xs-5">
        <tr-read-only-text-field
          :dont-use-col-classes="true"
          classes="col-xs-5"
          :no-margin="noMarginOnLastLine"
          label="Conta"
          :value="pixKey.account"
        />
      </div>
    </div>
  </div>
</template>

<script>
import formatCpfCnpj from '@/commons/formatters/cpfCnpj';
import phone from '@/commons/formatters/phone';
import breakBiggerWord from '@/commons/formatters/breakBiggerWord';
import { pix } from '@transfeeradev/api-enums';

export default {
  name: 'view-pix-key',
  props: {
    pixKey: {
      required: false,
      type: Object,
      default: () => ({})
    },
    noMarginOnLastLine: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  computed: {
    cpfCnpj() {
      return formatCpfCnpj(this.pixKey.cnpj);
    },
    pixKeyValue() {
      if (this.pixKey.keyType === pix.keyType.CNPJ) {
        return formatCpfCnpj(this.pixKey.key);
      }
      if (this.pixKey.keyType === pix.keyType.TELEFONE) {
        return phone(this.pixKey.key);
      }
      return breakBiggerWord(this.pixKey.key, 46);
    },
    pixQRCodeImage() {
      if (this.hasPixImage) {
        return 'data:image/png;base64,' + this.pixKey.qrcode_image;
      }

      return '/assets/img/pix/qrcode-unavailable.png';
    },
    hasPixImage() {
      return !!this.pixKey.qrcode_image;
    }
  },
  methods: {
    copyToClipboard(content) {
      navigator.clipboard.writeText(content);
    }
  }
};
</script>
