<template>
  <div>
    <tr-loading
      v-if="isLoading"
      class="bottom-offset4"
    />
    <div v-else>
      <new-pix-key-form
        v-if="!pixKey.id"
        :pix-key="pixKey"
        :company-cnpj="company.cpfCnpj"
        :loading="pendingRequest"
        @cancel="close"
        @submit="createPixKey"
      />
      <pix-key-verification-form
        v-if="pixKey.id && pixKey.status === pixKeyStatus.VALIDATING"
        :pix-key="pixKey"
        :verification-data="verificationData"
        :loading="pendingRequest"
        @cancel="close"
        @submit="verifyPixKey"
        @resend="resendVerificationCode"
        @remove="openRemovalDialog"
      />
      <pix-key-claim-request-confirm
        v-if="pixKey.status === pixKeyStatus.WAITING_PORTABILITY || pixKey.status === pixKeyStatus.WAITING_CLAIM"
        :pix-key="pixKey"
        :loading="pendingRequest"
        @remove="openRemovalDialog"
        @cancel="close"
        @confirm="confirmPixKeyClaim"
      />
      <pix-key-claim-request-in-progress
        v-if="
          pixKey.status === pixKeyStatus.PORTABILITY_REQUESTED ||
            pixKey.status === pixKeyStatus.PORTABILITY_IN_PROGRESS ||
            pixKey.status === pixKeyStatus.CLAIM_REQUESTED ||
            pixKey.status === pixKeyStatus.CLAIM_IN_PROGRESS
        "
        :pix-key="pixKey"
        :loading="pendingRequest"
        @cancel="cancelPixKeyClaim"
        @back="close"
      />
      <pix-key-claim-request-approval
        v-if="
          pixKey.status === pixKeyStatus.WAITING_PORTABILITY_APPROVAL ||
            pixKey.status === pixKeyStatus.WAITING_CLAIM_APPROVAL
        "
        :pix-key="pixKey"
        :loading="pendingRequest"
        @keep-key="cancelPixKeyClaim"
        @leave-key="approvePixKeyClaim"
      />
      <pix-key-details
        v-if="shouldShowDetails"
        :pix-key="pixKey"
        @back="close"
      >
        <template #action-button>
          <pix-key-wizard-action-button-container
            :status="pixKey.status"
            @keep-using="close"
            @remove="openRemovalDialog"
          />
        </template>
      </pix-key-details>
    </div>
    <pix-key-removal-confirmation-dialog
      v-if="pixKey.id"
      ref="pixKeyRemovalConfirmationDialog"
      :pix-key="pixKey"
      :loading="pendingRequest"
      @remove="removePixKey"
    />
  </div>
</template>

<script>
import NewPixKeyForm from '@/apps/payments/payments/settings/pixKeys/components/presentational/NewPixKeyForm';
import PixKeyVerificationForm from '@/apps/payments/payments/settings/pixKeys/components/presentational/PixKeyVerificationForm';
import PixKeyClaimRequestConfirm from '@/apps/payments/payments/settings/pixKeys/components/presentational/PixKeyClaimRequestConfirm';
import PixKeyClaimRequestInProgress from '@/apps/payments/payments/settings/pixKeys/components/presentational/PixKeyClaimRequestInProgress';
import PixKeyClaimRequestApproval from '@/apps/payments/payments/settings/pixKeys/components/presentational/PixKeyClaimRequestApproval';
import PixKeyDetails from '@/apps/payments/payments/settings/pixKeys/components/presentational/PixKeyDetails';
import PixKeyRemovalConfirmationDialog from '@/apps/payments/payments/settings/pixKeys/components/presentational/PixKeyRemovalConfirmationDialog.vue';
import pixKeyResource from '@/apps/payments/payments/settings/pixKeys/resources/pixKey';
import companyResource from '@/apps/payments/payments/settings/company/resources/company';
import countdownTimer from '@/commons/services/countdownTimer';
import PixKeyWizardActionButtonContainer from './PixKeyWizardActionButtonContainer';
import { pix } from '@transfeeradev/api-enums';
import { usePolling } from '@/commons/composables/usePolling';
import { defineComponent } from 'vue';
import { getMessage } from '@/commons/services/error';
import { formatPhoneKey } from '@transfeeradev/api-services/PixKeyFormatterService';
export default defineComponent({
  name: 'pix-key-wizard-container',
  components: {
    NewPixKeyForm,
    PixKeyVerificationForm,
    PixKeyClaimRequestConfirm,
    PixKeyClaimRequestInProgress,
    PixKeyClaimRequestApproval,
    PixKeyDetails,
    PixKeyRemovalConfirmationDialog,
    PixKeyWizardActionButtonContainer
  },
  props: ['pixId'],
  emits: ['close'],
  setup() {
    const { startPolling } = usePolling({
      intervalInSeconds: 2
    });

    return {
      startPolling
    };
  },
  data: () => ({
    isLoading: true,
    pixKeyStatus: pix.pixKeyStatus,
    pixKey: {
      id: null,
      key_type: pix.keyType.TELEFONE,
      key: '',
      status: pix.pixKeyStatus.VALIDATING,
      pix_qrcode_image: null
    },
    company: {},
    verificationData: {
      code: '',
      blockedTime: 0
    },
    pendingRequest: false
  }),
  computed: {
    shouldShowDetails() {
      return (
        this.pixKey.id &&
        this.pixKey.status !== pix.pixKeyStatus.VALIDATING &&
        this.pixKey.status !== pix.pixKeyStatus.REMOVED &&
        this.pixKey.status !== pix.pixKeyStatus.WAITING_PORTABILITY &&
        this.pixKey.status !== pix.pixKeyStatus.PORTABILITY_REQUESTED &&
        this.pixKey.status !== pix.pixKeyStatus.PORTABILITY_IN_PROGRESS &&
        this.pixKey.status !== pix.pixKeyStatus.WAITING_PORTABILITY_APPROVAL &&
        this.pixKey.status !== pix.pixKeyStatus.WAITING_CLAIM &&
        this.pixKey.status !== pix.pixKeyStatus.CLAIM_REQUESTED &&
        this.pixKey.status !== pix.pixKeyStatus.CLAIM_IN_PROGRESS &&
        this.pixKey.status !== pix.pixKeyStatus.WAITING_CLAIM_APPROVAL
      );
    }
  },
  mounted() {
    this.refresh();
  },
  methods: {
    pollPixKey(pixId) {
      const keepPollingWhile = () => {
        return [pix.pixKeyStatus.PENDING, pix.pixKeyStatus.PENDING_REMOVAL].includes(this.pixKey.status);
      };

      const fetchPixKey = async () => {
        this.pixKey = (await pixKeyResource.getById(pixId)) || {};
      };

      return this.startPolling(fetchPixKey, {
        keepPollingWhile,
        immediate: true
      });
    },
    close() {
      this.$emit('close');
    },
    async refresh() {
      if (!this.pixId) {
        this.pixKey = {
          id: null,
          key_type: pix.keyType.TELEFONE,
          key: '',
          status: pix.pixKeyStatus.VALIDATING
        };
        try {
          this.company = await companyResource.get();
        } catch (error) {
          this.$toaster.error(getMessage(error));
        }
        this.isLoading = false;
        return;
      }

      this.isLoading = true;
      try {
        this.company = await companyResource.get();

        await this.pollPixKey(this.pixId);
      } catch (error) {
        this.$toaster.error(getMessage(error));
      }
      this.isLoading = false;
    },
    openRemovalDialog() {
      this.$refs.pixKeyRemovalConfirmationDialog.open();
    },
    async createPixKey() {
      let pixKeyRequest = this.pixKey.key;
      try {
        this.pendingRequest = true;
        if (this.pixKey.key_type === pix.keyType.TELEFONE) {
          pixKeyRequest = formatPhoneKey(pixKeyRequest);
        }
        let pixKey = await pixKeyResource.create({
          key: pixKeyRequest
        });

        this.pollPixKey(pixKey.id);
      } catch (error) {
        if (error.code && error.message) {
          this.$toaster.error(getMessage(error));
        } else {
          throw error;
        }
      }
      this.pendingRequest = false;
    },
    async removePixKey() {
      try {
        this.pendingRequest = true;
        await pixKeyResource.remove(this.pixKey.id);
        this.close();
      } catch (error) {
        if (error.code && error.message) {
          this.$toaster.error(getMessage(error));
        } else {
          throw error;
        }
      }
      this.pendingRequest = false;
    },
    async resendVerificationCode() {
      try {
        countdownTimer(remaining => (this.verificationData.blockedTime = remaining), 10, 1000);
        await pixKeyResource.resendVerificationCode(this.pixKey.id);
      } catch (error) {
        if (error.code && error.message) {
          this.$toaster.error(getMessage(error));
        } else {
          throw error;
        }
      }
    },
    async verifyPixKey() {
      try {
        this.pendingRequest = true;
        await pixKeyResource.verify(this.pixKey.id, { code: this.verificationData.code });
        await this.pollPixKey(this.pixKey.id);
      } catch (error) {
        if (error.code && error.message) {
          this.$toaster.error(getMessage(error));
        } else {
          throw error;
        }
      }
      this.pendingRequest = false;
    },
    async confirmPixKeyClaim() {
      try {
        this.pendingRequest = true;
        await pixKeyResource.claim(this.pixKey.id);

        let successMessage;
        if (this.pixKey.status === pix.pixKeyStatus.WAITING_PORTABILITY) {
          successMessage =
            'Solicitação de portabilidade iniciada com sucesso, confirme a solicitação em sua conta de origem.';
        } else {
          successMessage = 'Solicitação de reivindicação iniciada com sucesso, aguarde a confirmação do processo.';
        }

        this.$toaster.success(successMessage);
        this.close();
      } catch (error) {
        if (error.code && error.message) {
          this.$toaster.error(getMessage(error));
        } else {
          throw error;
        }
      }
      this.pendingRequest = false;
    },
    async cancelPixKeyClaim() {
      try {
        this.pendingRequest = true;
        let successMessage;
        switch (this.pixKey.status) {
          case pix.pixKeyStatus.WAITING_PORTABILITY_APPROVAL:
            successMessage = 'Chave Pix mantida na Transfeera com sucesso.';
            break;
          case pix.pixKeyStatus.WAITING_CLAIM_APPROVAL:
            successMessage = 'Chave Pix mantida com sucesso.';
            break;
          case pix.pixKeyStatus.CLAIM_REQUESTED:
          case pix.pixKeyStatus.CLAIM_IN_PROGRESS:
            successMessage = 'Solicitação de reivindicação cancelada com sucesso.';
            break;
          case pix.pixKeyStatus.PORTABILITY_REQUESTED:
          case pix.pixKeyStatus.PORTABILITY_IN_PROGRESS:
            successMessage = 'Solicitação de portabilidade cancelada com sucesso.';
        }

        await pixKeyResource.cancelClaim(this.pixKey.id);
        this.$toaster.success(successMessage);
        this.close();
      } catch (error) {
        if (error.code && error.message) {
          this.$toaster.error(getMessage(error));
        } else {
          throw error;
        }
      }
      this.pendingRequest = false;
    },
    async approvePixKeyClaim() {
      try {
        this.pendingRequest = true;
        await pixKeyResource.confirmClaim(this.pixKey.id);
        this.$toaster.success('Chave Pix liberada com sucesso.');
        this.close();
      } catch (error) {
        if (error.code && error.message) {
          this.$toaster.error(getMessage(error));
        } else {
          throw error;
        }
      }
      this.pendingRequest = false;
    }
  }
});
</script>
