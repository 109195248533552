import { createApp } from 'vue';
import router from '@/router';
import App from '@/App';
import { Formatters, Toaster, Tooltip } from '@/commons/plugins';
import VueComponents from '@transfeeradev/vue-components';
import { DialogStack, DialogStackIK } from '@transfeeradev/bridge';
import PDFObjectPlugin from 'pdfobject-vue';

import '@transfeeradev/bridge/dist/style.css';
import '@/less/main.less';
import '@/svgLoader';

const app = createApp(App);

app.use(router);
app.use(Toaster);
app.use(Tooltip);
app.use(Formatters);
app.use(DialogStack, { router });
app.use(PDFObjectPlugin);

app.use(VueComponents, {
  dialogStack: {
    injectionKey: DialogStackIK
  }
});

app.mount('#app');
