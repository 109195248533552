<template>
  <div>
    <tr-slimbox
      title="Aqui você gerencia todas suas transferências!"
      :with-border="true"
      arrow-direction="bottom-left"
    >
      As transferências cadastradas na Transfeera aparecem nesta lista para você acompanhar os status e buscar por
      comprovantes, sempre que precisar.
    </tr-slimbox>
    <tr-slimbox
      title="Suas Transferências"
      :with-dashed-border="true"
    >
      <transfers-table
        :show-fake-receipt-links="true"
        :disable-click="true"
        :transfers="exampleTransfers"
      />
    </tr-slimbox>
  </div>
</template>

<script>
import config from '@/config';
import TransfersTable from '@/apps/payments/payments/transfer/components/presentational/TransfersTable';

export default {
  name: 'transfer-first-use-blank-slate',
  components: {
    TransfersTable
  },
  data: () => ({
    exampleTransfers: [
      {
        created_at: '2019-12-31T00:00:00.000Z',
        value: 625,
        DestinationBankAccount: {
          Bank: {
            image: `${config.url.cdn}/banks/bradesco.svg`
          },
          name: 'Nome do recebedor',
          agency: '1234',
          agency_digit: '',
          account: '01002713',
          account_digit: '9'
        },
        status: {
          name: 'Exemplo transferido',
          color: 'blue'
        }
      },
      {
        created_at: '2019-12-31T00:00:00.000Z',
        value: 2500,
        DestinationBankAccount: {
          Bank: {
            image: `${config.url.cdn}/banks/sicoob.svg`
          },
          name: 'Nome do recebedor',
          agency: '1234',
          agency_digit: '',
          account: '01002713',
          account_digit: '9'
        },
        status: {
          name: 'Exemplo falha',
          color: 'red'
        }
      },
      {
        created_at: '2019-12-31T00:00:00.000Z',
        value: 1000,
        DestinationBankAccount: {
          Bank: {
            image: `${config.url.cdn}/banks/caixa.svg`
          },
          name: 'Nome do recebedor',
          agency: '1234',
          agency_digit: '',
          account: '01002713',
          account_digit: '9'
        },
        status: {
          name: 'Exemplo transferido',
          color: 'blue'
        }
      },
      {
        created_at: '2019-12-31T00:00:00.000Z',
        value: 2000,
        DestinationBankAccount: {
          Bank: {
            image: `${config.url.cdn}/banks/itau.svg`
          },
          name: 'Nome do recebedor',
          agency: '1234',
          agency_digit: '',
          account: '01002713',
          account_digit: '9'
        },
        status: {
          name: 'Exemplo transferido',
          color: 'blue'
        }
      }
    ]
  })
};
</script>
