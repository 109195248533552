import { transferStatus } from '@transfeeradev/api-enums';
import transferStatusName from '@/apps/payments/payments/transfer/constants/transferStatusName';

export default {
  [transferStatus.CREATED]: transferStatusName[transferStatus.CREATED],
  [transferStatus.SCHEDULED]: transferStatusName[transferStatus.SCHEDULED],
  [transferStatus.RECEIVED]: transferStatusName[transferStatus.RECEIVED],
  [transferStatus.TRANSFERRED]: transferStatusName[transferStatus.TRANSFERRED],
  [transferStatus.FAILED]: transferStatusName[transferStatus.FAILED],
  [transferStatus.RETURNED]: transferStatusName[transferStatus.RETURNED]
};
