<template>
  <tr-dialog
    ref="modal"
    name="download-without-receipt-dialog"
    title="Download dos comprovantes"
    cancel-button-label="Voltar"
  >
    <tr-typography
      variant="h3"
      class="block vertical-offset1 bottom-offset1"
    >
      <span v-if="batch">Nenhum comprovante está disponível para o lote "{{ batch.name }}".</span>
      <span v-else>Nenhum comprovante está disponível para o filtro atual.</span>
    </tr-typography>
  </tr-dialog>
</template>

<script>
export default {
  name: 'download-without-receipt-dialog',
  props: {
    batch: Object
  },
  methods: {
    open() {
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    }
  }
};
</script>
