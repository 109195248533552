<template>
  <balance-info
    :balance="balance"
    :no-margin="noMargin"
    :open-withdraw-dialog="openWithdrawDialog"
    :open-bank-account-to-send-balance-dialog="openBankAccountToSendBalanceDialog"
    :value-waiting-to-be-received="waitingToBeReceived"
  />
  <bank-account-to-send-balance-dialog ref="bankAccountToSendBalanceDialog" />
  <withdraw-dialog
    ref="withdrawDialog"
    :on-change="refresh"
  />
</template>

<script>
import BalanceInfo from '@/apps/payments/payments/statement/components/presentational/BalanceInfo.vue';
import BankAccountToSendBalanceDialog from '@/apps/payments/payments/bank/components/presentational/BankAccountToSendBalanceDialog.vue';
import WithdrawDialog from '@/apps/payments/payments/statement/components/presentational/WithdrawDialog.vue';
import statementResource from '@/apps/payments/payments/statement/resources/statement';
import { getMessage } from '@/commons/services/error';

export default {
  name: 'balance-info-container',
  components: {
    BalanceInfo,
    WithdrawDialog,
    BankAccountToSendBalanceDialog
  },
  props: {
    noMargin: {
      required: false,
      type: Boolean,
      default: true
    }
  },
  emits: ['refresh'],
  data: () => ({
    balance: 0,
    waitingToBeReceived: 0
  }),
  created() {
    return this.refresh();
  },
  methods: {
    refresh() {
      return statementResource.getBalance().then(
        balance => {
          this.balance = balance && balance.value;
          this.waitingToBeReceived = balance && balance.waiting_value;
          this.$emit('refresh');
        },
        error => this.$toaster.error(getMessage(error))
      );
    },
    openWithdrawDialog() {
      this.$refs.withdrawDialog.open();
    },
    openBankAccountToSendBalanceDialog() {
      this.$refs.bankAccountToSendBalanceDialog.open();
    }
  }
};
</script>
