import { pix } from '@transfeeradev/api-enums';
import PersonTypeService from '@transfeeradev/api-services/PersonTypeService';
import TransferPixService from '@transfeeradev/api-services/TransferPixService';

export default dba => {
  if (!dba) {
    return '';
  }

  const isDbaPix = TransferPixService.isPix(dba);
  const isPixDocumentKey = [pix.keyType.CPF, pix.keyType.CNPJ].includes(dba.pix_key_type) && Boolean(dba.pix_key);
  const hasDocumentToShow = isPixDocumentKey || Boolean(dba.cpf_cnpj);

  if (!hasDocumentToShow) {
    return '';
  }

  if (PersonTypeService.isObfuscatedCPF(dba.cpf_cnpj)) {
    return dba.cpf_cnpj;
  }

  if (isDbaPix) {
    if (isPixDocumentKey) {
      return PersonTypeService.format(dba.pix_key);
    }
    return PersonTypeService.obfuscateCPF(PersonTypeService.format(dba.cpf_cnpj));
  }

  return PersonTypeService.format(dba.cpf_cnpj);
};
