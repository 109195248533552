import merge from 'deepmerge';
import DateService from '@transfeeradev/api-services/DateService';

const AVAILABLE_PROPERTIES = ['id', 'value', 'payment_date', 'barcode', 'description', 'integration_id'];

const prepare = inputBillet => {
  const billet = merge({}, inputBillet);

  if (!billet.id) {
    delete billet.id;
  }

  Object.keys(billet).forEach(key => {
    if (!AVAILABLE_PROPERTIES.includes(key)) {
      delete billet[key];
    }
  });

  billet.payment_date = DateService.getFormatted(billet.payment_date);

  return billet;
};

export default {
  prepare
};
