import getHtmlToPlainText from '@/commons/formatters/getHtmlToPlainText';
import { transferStatus } from '@transfeeradev/api-enums';

const get = transfer => {
  if (transfer && _shouldShowForThisTransferStatus(transfer.status)) {
    const statusDescription = transfer.status_description;
    const dbaStatusDescription = _getDbaStatusDescription(transfer);
    const reason = statusDescription || dbaStatusDescription || '';
    return getHtmlToPlainText.get(reason);
  }
  return '';
};

const _shouldShowForThisTransferStatus = status =>
  Boolean(
    status &&
      (status === transferStatus.FAILED ||
        status === transferStatus.RETURNED ||
        status === transferStatus.BEING_RETURNED ||
        status === transferStatus.REQUESTED_REFUND)
  );

const _getDbaStatusDescription = transfer =>
  transfer.destination_bank_account && transfer.destination_bank_account.status_description;

export default {
  get
};
