<template>
  <div class="bg-footer vertical-offset4">
    <div class="bg-footer__left">
      <slot name="left" />
    </div>
    <div class="bg-footer__right">
      <slot name="right" />
    </div>
  </div>
</template>

<script setup>

</script>

<style scoped>
.bg-footer {
  display: flex;
}

.bg-footer__left,
.bg-footer__right {
  display: flex;
  gap: 16px;
}

.bg-footer__right {
  margin-left: auto;
}
</style>
