<template>
  <div>
    <!-- eslint-disable vue/no-v-html -->
    <div
      class="bottom-offset3 description"
      v-html="message"
    />
    <div class="bottom-offset4">
      <pix-key-read-only-text-field
        :pix-key="pixKeyValue"
        :pix-key-type="pixKey.key_type"
        :dont-use-col-classes="true"
        :no-margin="true"
      />
      <div class="flex-row vertical-offset2">
        <pix-key-status-pill
          :status="pixKey.status"
          no-margin="true"
        />
      </div>
    </div>
    <div class="bottom-offset3 inline-block width-100">
      <tr-button
        class="pull-left"
        variant="outlined"
        color="secondary"
        label="Voltar"
        :on-button-click="back"
      />
      <tr-button
        v-if="!shouldDisableCancelButton"
        class="pull-right"
        variant="raised"
        color="danger"
        label="Cancelar solicitação"
        type="button"
        :on-button-click="cancel"
      />
    </div>
  </div>
</template>

<script>
import pixKeyRepr from '@/commons/formatters/pixKeyRepr';
import breakBiggerWord from '@/commons/formatters/breakBiggerWord';
import { pix } from '@transfeeradev/api-enums';
import PixKeyReadOnlyTextField from '@/apps/payments/payments/settings/pixKeys/components/presentational/PixKeyReadOnlyTextField.vue';
import PixKeyStatusPill from '@/apps/payments/payments/settings/pixKeys/components/presentational/PixKeyStatusPill.vue';

export default {
  name: 'pix-key-claim-request-in-progress',
  components: { PixKeyReadOnlyTextField, PixKeyStatusPill },
  props: ['pixKey', 'loading'],
  emits: ['cancel', 'back'],
  data: () => ({
    pixKeyType: pix.keyType,
    pixKeyStatus: pix.pixKeyStatus
  }),
  computed: {
    pixKeyValue() {
      return breakBiggerWord(pixKeyRepr(this.pixKey), 40);
    },
    shouldDisableCancelButton() {
      return (
        this.loading ||
        this.pixKey.status === this.pixKeyStatus.PORTABILITY_REQUESTED ||
        this.pixKey.status === this.pixKeyStatus.CLAIM_REQUESTED
      );
    },
    message() {
      const keyStatus = this.pixKey.status;
      if (
        keyStatus === this.pixKeyStatus.PORTABILITY_REQUESTED ||
        keyStatus === this.pixKeyStatus.PORTABILITY_IN_PROGRESS
      ) {
        let message =
          'O processo de <strong>portabilidade</strong> de chave já foi iniciado. Confirme a solicitação na conta em que a chave está salva';
        if (keyStatus === this.pixKeyStatus.PORTABILITY_IN_PROGRESS) {
          message += ', ou cancele a solicitação abaixo:';
        } else {
          message += '.';
        }
        return message;
      }

      if (keyStatus === this.pixKeyStatus.CLAIM_IN_PROGRESS || keyStatus === this.pixKeyStatus.CLAIM_REQUESTED) {
        let message =
          'O processo de <strong>reivindicação</strong> de chave já foi iniciado. Aguarde a resposta da pessoa detentora da chave, o processo tem um prazo de até 7 dias para ser concluído.';
        if (keyStatus === this.pixKeyStatus.CLAIM_IN_PROGRESS) {
          message += ' Caso contrário, cancele a solicitação abaixo:';
        }
        return message;
      }

      return '';
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    back() {
      this.$emit('back');
    }
  }
};
</script>
