<template>
  <slot
    :validations="validations"
    :account-types="accountTypes"
    :agency-pattern="agencyPattern"
    :operation-type="operationType"
    :bank-account="bankAccount"
    :complex-validations="complexValidations"
    :account-max-digits="accountMaxDigits"
    :agency-max-digits="agencyMaxDigits"
    :validate="validate"
    :on-change-bank="onChangeBank"
    :on-change-agency="onChangeAgency"
    :on-change-account-type="onChangeAccountType"
  />
</template>

<script>
import ValidationSchemaFactory from '@transfeeradev/bank-account-validations/lib/ValidationSchemaFactory';
import SchemaValidation from '@transfeeradev/bank-account-validations/lib/SchemaValidation';
import BankValidation from '@transfeeradev/bank-account-validations';
import getByProp from '@/commons/services/getByProp';
import { banks, caixaOperationType } from '@transfeeradev/api-enums';
import CaixaService from '@transfeeradev/api-services/CaixaService';
import PersonTypeService from '@transfeeradev/api-services/PersonTypeService';
import { getMaxLengthIgnoringZeros } from '@/commons/services/getMaxLengthIgnoringZeros';
import bankAccountValidations from '@/commons/services/dba/validations/bankAccount';

export default {
  name: 'bank-account-container',
  provide() {
    return {
      tfFormInputs: {
        register: vm => {
          const id = Symbol();
          this.formInputs[id] = vm;
          return () => delete this.formInputs[id];
        }
      }
    };
  },
  props: [
    'bankAccount',
    'shouldShowOperationType',
    'cpfCnpj',
    'banks',
    'shouldMakeComplexValidations',
    'acceptContaCertaSuggestions'
  ],
  emits: ['invalidate-field'],
  data: () => ({
    formInputs: {},
    validations: {
      agency: {
        digit: {}
      },
      account: {
        digit: {}
      },
      accountType: {}
    },
    accountTypes: [],
    complexValidations: bankAccountValidations.get(),
    operationType: ''
  }),
  computed: {
    agencyPattern() {
      if (this.validations.agency.hasSuggestions && this.acceptContaCertaSuggestions) {
        return this.validations.agency.moreOpenPattern;
      }
      return this.validations.agency.pattern;
    },
    accountMaxDigits() {
      const maxLength = this.validations.account.maxLength || this.validations.account.exactLength;
      return getMaxLengthIgnoringZeros(this.bankAccount.account, maxLength);
    },
    agencyMaxDigits() {
      const maxLength = this.validations.agency.maxLength || this.validations.agency.exactLength;
      return getMaxLengthIgnoringZeros(this.bankAccount.agency, maxLength);
    }
  },
  watch: {
    bankAccount() {
      this.refresh();
    },
    banks() {
      this.refresh();
    },
    cpfCnpj(cpfCnpj) {
      if (PersonTypeService.getType(cpfCnpj)) {
        this._refreshOperationType();
      }
    }
  },
  mounted() {
    this.refresh();
    if (this.bankAccount.id && this.shouldMakeComplexValidations) {
      this._markFormFieldsAsDirty();
    }
  },
  methods: {
    refresh() {
      const bankId = this.bankAccount && this.bankAccount.bank_id;
      if (bankId) {
        this.onChangeBank();
      }
    },
    onChangeBank() {
      this._revalidateFields();
    },
    onChangeAgency() {
      this._revalidateFields();
    },
    onChangeAccountType() {
      this.validate('account_type');
      this._refreshOperationType();
    },
    getValidations(bankCode, bankAccount) {
      this.validations = new ValidationSchemaFactory(bankCode, bankAccount).build();
    },
    validate(field) {
      if (!this.shouldMakeComplexValidations) {
        return true;
      }

      const bankAccount = Object.assign({}, this.bankAccount);
      delete bankAccount.agency_digit;
      bankAccount.bank_code = this._getBankCode(this.banks, bankAccount.bank_id);
      bankAccount.bank_ispb = this._getBankISPB(this.banks, bankAccount.bank_id);

      const validationErrors = new BankValidation(bankAccount).validateSchema();
      if (!validationErrors.length) {
        this.complexValidations = bankAccountValidations.validate(bankAccount);

        if (field) {
          return this.complexValidations[field].isValid;
        }
      }
      return true;
    },
    _refreshOperationType() {
      const bankId = this.bankAccount && this.bankAccount.bank_id;
      let bankCode = null;
      if (bankId) {
        bankCode = this._getBankCode(this.banks, bankId);
      }

      this.operationType = this._getOperationType(bankCode);
    },
    _getOperationType(bankCode) {
      if (this.shouldShowOperationType && bankCode === banks.CAIXA_ECONOMICA.code) {
        const operationType = CaixaService.getOperationType(this.bankAccount);

        if (operationType === caixaOperationType.POUPANCA) {
          return `${caixaOperationType.POUPANCA}/${caixaOperationType.POUPANCA_NOVA_VARIACAO}`;
        }

        return operationType;
      } else {
        return '';
      }
    },
    _markFormFieldsAsDirty() {
      this._getInputs().forEach(input => {
        input.setAsDirty();
      });
    },
    _getInputs() {
      return Object.getOwnPropertySymbols(this.formInputs)
        .map(key => this.formInputs[key])
        .filter(vm => vm.$options.name === 'TrTextField');
    },
    _getBankCode(foundBanks, bankId) {
      const bank = getByProp.get({
        propValue: bankId,
        propName: 'id',
        list: foundBanks
      });
      return bank && bank.code;
    },
    _getBankISPB(foundBanks, bankId) {
      const bank = getByProp.get({
        propValue: bankId,
        propName: 'id',
        list: foundBanks
      });
      return bank && bank.ispb;
    },
    _revalidateFields() {
      const bankId = this.bankAccount && this.bankAccount.bank_id;
      const bankCode = this._getBankCode(this.banks, bankId);

      this.getValidations(bankCode, this.bankAccount);
      this.validate();
      this.accountTypes = this.validations.accountType.allowedTypes;
      this._refreshOperationType();

      if (this.bankAccount.account_type === '') {
        this.bankAccount.account_type = undefined;
      }

      if (this.bankAccount.account_type) {
        try {
          new SchemaValidation(this.bankAccount, bankCode).validateAccountType();
        } catch (e) {
          this.bankAccount.account_type = undefined;
          this.$emit('invalidate-field', 'account_type');
        }
      }
    }
  }
};
</script>
