<template>
  <page title="Validações">
    <new-validation-container />
  </page>
</template>

<script>
import Page from '@/commons/components/presentational/Page.vue';
import NewValidationContainer from '../components/container/NewValidationContainer.vue';

export default {
  name: 'contacerta-new-validation',
  components: {
    NewValidationContainer,
    Page
  }
};
</script>
