<template>
  <tr-page-header
    title="Validações"
    :on-search="onSearchByText"
    search-placeholder="Nome, Documento, Chave Pix, etc."
    :on-add-click="goToNewValidationPage"
  >
    <template #left-filter>
      <validation-history-status-dropdown-filter-container @search="onSearchByStatus" />
    </template>
    <template #center-filter>
      <tr-month-picker-button :on-filter="onSearchByDatePeriod" />
    </template>
    <template #right-filter>
      <validation-history-types-dropdown-filter-container @search="onSearchByType" />
    </template>
  </tr-page-header>
  <validation-history-container
    :search-text="searchText"
    :search-status="searchStatus"
    :search-type="searchType"
    :period-filter="periodFilter"
  />
</template>

<script>
import ValidationHistoryContainer from '../components/container/ValidationHistoryContainer.vue';
import ValidationHistoryStatusDropdownFilterContainer from '../components/container/ValidationHistoryStatusDropdownFilterContainer.vue';
import ValidationHistoryTypesDropdownFilterContainer from '../components/container/ValidationHistoryTypesDropdownFilterContainer.vue';
import moment from '@transfeeradev/moment';

export default {
  name: 'contacerta-validation-history',
  components: {
    ValidationHistoryContainer,
    ValidationHistoryStatusDropdownFilterContainer,
    ValidationHistoryTypesDropdownFilterContainer
  },
  data() {
    return {
      searchText: null,
      searchStatus: null,
      searchType: null,
      periodFilter: {
        initialDate: moment().startOf('month'),
        endDate: moment().endOf('month')
      }
    };
  },
  methods: {
    onSearchByText(text) {
      this.searchText = text;
    },
    onSearchByStatus(value) {
      this.searchStatus = value;
    },
    onSearchByType(value) {
      this.searchType = value;
    },
    goToNewValidationPage() {
      this.$router.push({ name: 'ContaCertaNewValidationPage' });
    },
    onSearchByDatePeriod(initialDate, endDate) {
      this.periodFilter = {
        initialDate,
        endDate
      };
    }
  }
};
</script>
