<template>
  <tr-dialog
    ref="modal"
    :unique-name="true"
    name="batch-edition-confirmation-dialog"
    title="Editar lote"
  >
    <div>
      <tr-typography
        variant="h5"
        strong="true"
      >
        A edição do lote o tornará um Rascunho.
      </tr-typography>
      <br>
      <tr-typography
        v-if="waitingToReceive"
        variant="h5"
        classes="vertical-offset1 block"
      >
        A Transfeera não estará mais aguardando o valor referente a este lote, até ele ser fechado. Você confirma a
        alteração?
      </tr-typography>
      <tr-typography
        v-if="!waitingToReceive"
        variant="h5"
        classes="vertical-offset1 block"
      >
        Você confirma a edição deste lote?
      </tr-typography>
    </div>
    <template #buttons>
      <tr-button
        classes="pull-right"
        :on-button-click="onConfirm"
        label="Transformar em Rascunho"
        variant="raised"
        color="primary"
      />
    </template>
  </tr-dialog>
</template>

<script>
export default {
  name: 'batch-edition-confirmation-dialog',
  props: {
    waitingToReceive: {
      required: false,
      type: Boolean,
      default: false
    },
    onConfirm: {
      required: true,
      type: Function
    }
  },
  methods: {
    open() {
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    }
  }
};
</script>
