function timer(callback, amount = 10, interval = 1000) {
  callback(amount);
  const internvalRef = setInterval(function () {
    amount--;
    try {
      callback(amount);
    } catch (error) {
      // pass
    }
    if (amount <= 0) {
      clearInterval(internvalRef);
    }
  }, interval);
}

module.exports = timer;
