<template>
  <tr-dialog
    ref="modal"
    :unique-name="true"
    name="batch-removal-confirmation-dialog"
    title="Excluir lote"
  >
    <tr-typography variant="h5">
      Você tem certeza que deseja excluir esse lote?
    </tr-typography>
    <template #buttons>
      <tr-button
        classes="pull-right"
        :on-button-click="onConfirm"
        label="Excluir"
        variant="raised"
        color="danger"
      />
    </template>
  </tr-dialog>
</template>

<script>
export default {
  name: 'batch-removal-confirmation-dialog',
  props: {
    onConfirm: {
      required: true,
      type: Function
    }
  },
  methods: {
    open() {
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    }
  }
};
</script>
