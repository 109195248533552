<template>
  <receipt-download-links
    v-if="mightHaveReceipt"
    :classes="classes"
    :show-fake-links="showFakeLinks"
    :get-receipt="getReceipt"
    :item="transfer"
  />
</template>

<script>
import ReceiptDownloadLinks from '@/commons/components/presentational/ReceiptDownloadLinks';
import transferModel from '@/apps/payments/payments/transfer/models/transfer';
import { transferStatus } from '@transfeeradev/api-enums';

export default {
  name: 'transfer-receipt-download-links-container',
  components: {
    ReceiptDownloadLinks
  },
  props: {
    transfer: {
      required: false,
      type: Object,
      default: transferModel.get
    },
    classes: {
      required: false,
      type: String
    },
    shouldAlwaysShow: {
      required: false,
      type: Boolean,
      default: false
    },
    showFakeLinks: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  computed: {
    mightHaveReceipt() {
      return Boolean(
        this.shouldAlwaysShow ||
          (this.transfer &&
            (this.transfer.status === transferStatus.TRANSFERRED || this.transfer.status === transferStatus.FINISHED))
      );
    }
  },
  methods: {
    getReceipt(transfer, urlProperty, event) {
      event.stopPropagation();
      if (this._hasReceipt(transfer, urlProperty)) {
        window.open(transfer[urlProperty], '_blank');
      }
    },
    _hasReceipt(transfer, urlProperty) {
      return Boolean(transfer && urlProperty && transfer[urlProperty]);
    }
  }
};
</script>
