<template>
  <div
    v-if="isEndDateOutOfBounds"
    class="receivables-table-message"
  >
    <bg-icon
      size="sm"
      name="open-folder"
      class="receivables-table__gears-icon"
    />
    <bg-text
      type="h6"
      color="grey-dark"
      weight="bold"
      class="receivables-table__empty-title"
    >
      Dados disponíveis a partir de Janeiro de 2024
    </bg-text>
    <bg-text
      type="body-md"
      color="grey-semi-dark"
    >
      A interface exibe dados a partir de Janeiro de 2024.
      Gostaria de dados anteriores? Entre em contato com
      o suporte para entendermos suas necessidades.
    </bg-text>
  </div>
  <tr-table
    v-else
    disable-click
  >
    <tr-thead>
      <tr-tr>
        <tr-th width="5%">
          Status
        </tr-th>
        <tr-th width="15%">
          Data
          <bg-icon
            v-tooltip="`
              A data exibida varia conforme o método: </br></br>

              <b>Cartão de crédito</b>: Data da transação
              </br>
              <b>Boleto</b>: Data da emissão
              </br>
              <b>QR Code</b>: Data da criação
            `"
            style="vertical-align: sub;"
            name="info"
            size="sm"
          />
        </tr-th>
        <tr-th width="18%">
          Data de recebimento
        </tr-th>
        <tr-th width="18%">
          Pagador
        </tr-th>
        <tr-th width="15%">
          Valor original
        </tr-th>
        <tr-th width="15%">
          Valor recebido
        </tr-th>
        <tr-th width="5%">
          Método
        </tr-th>
      </tr-tr>
    </tr-thead>
    <tr-tbody>
      <tr-tr
        v-for="receivable in receivables"
        :key="receivable.id"
        class="receivables-table__row"
        @click="onOpenDetailsDialog(receivable.id)"
      >
        <tr-td>
          <status-badge
            :status="receivable.status"
            badge-size="sm"
            icon-size="sm"
          />
        </tr-td>
        <tr-td>
          {{ formatDateTime(receivable.created_at, formatDateTimeShape) || '---' }}
        </tr-td>
        <tr-td>
          <receivable-received-at
            :received-at="receivable.received_at"
            :payment-method="receivable.payment_type"
          />
        </tr-td>
        <tr-td>
          {{ receivable.payer ? receivable.payer.name : '---' }}
        </tr-td>
        <tr-td>
          <bg-text>
            {{ receivable.original_amount ? centsToReal(receivable.original_amount) : '---' }}
          </bg-text>
          <bg-text
            v-if="receivable.details?.installments"
            weight="regular"
            type="label"
          >
            {{
              buildInstallmentDescription({
                amount: receivable.original_amount,
                installments: receivable.details.installments
              })
            }}
          </bg-text>
        </tr-td>
        <tr-td>
          <bg-text>
            {{ receivable.received_amount ? centsToReal(receivable.received_amount) : '---' }}
          </bg-text>
        </tr-td>
        <tr-td
          class="receivables-table-td__payment-method"
          align="right"
        >
          <bg-badge
            v-if="!!receivable.payment_link_id"
            v-tooltip.top-center="`Pagamento via Link de Pagamento`"
            color="grey-light"
            size="sm"
          >
            <bg-icon
              size="sm"
              name="payment-link"
              color="grey-semi-dark"
              class="receivables-table__payment-link-icon"
            />
          </bg-badge>
          <receivables-payment-method-icon
            :payment-method="receivable.payment_type"
          />
        </tr-td>
        <tr-td>
          <div class="receivables-table__actions-container">
            <button
              v-tooltip.top-center="refundButtonStatus(receivable)"
              class="reset-button"
              :disabled="!isRefundable(receivable)"
              aria-label="Devolver"
              @click.stop="onOpenRefundDialog(receivable)"
            >
              <bg-icon
                size="sm"
                name="refund"
                :color="isRefundable(receivable) ? 'blue-normal' : 'grey-semi-light'"
                class="receivables-table__actions-icon"
              />
            </button>
            <button
              v-tooltip.top-center="'Ver mais'"
              class="reset-button"
              aria-label="Ver mais"
              @click.stop="onOpenDetailsDialog(receivable.id)"
            >
              <bg-icon
                size="sm"
                name="eye"
                color="blue-normal"
                class="receivables-table__actions-icon"
              />
            </button>
          </div>
        </tr-td>
      </tr-tr>
    </tr-tbody>
  </tr-table>
</template>

<script setup>
import { BgIcon, BgText, BgBadge } from '@transfeeradev/bridge';
import StatusBadge from '../../components/StatusBadge.vue';
import { formatDateTime } from '@/commons/formatters/formatDate';
import formatDateTimeShape from '@/apps/payin/receivables/constants/formatDateTimeShape';
import { refundButtonStatus } from '@/apps/payin/receivables/constants/refundButtonStatus';
import { centsToReal } from '@/commons/formatters/currency';
import { safeDivide } from '@/apps/payin/receivables/helper/math';
import ReceivableReceivedAt from '@/apps/payin/receivables/components/ReceivableReceivedAt.vue';
import ReceivablesPaymentMethodIcon from '@/apps/payin/receivables/components/ReceivablesPaymentMethodIcon.vue';

defineProps({
  receivables: {
    required: true,
    type: Array
  },
  isEndDateOutOfBounds: {
    type: Boolean
  },
  onOpenRefundDialog: {
    type: Function
  },
  onOpenDetailsDialog: {
    type: Function
  }
});

const buildInstallmentDescription = ({ installments, amount }) => {
  return installments > 1 ? `${installments}x ${centsToReal(safeDivide(amount, installments))}` : ' à vista';
};

const isRefundable = receivable => receivable?.refundable_amount > 0;
</script>

<style scoped>
table {
  margin-top: var(--bg-spacing-sm) !important;
}

.receivables-table-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: var(--bg-spacing-lg);
  gap: var(--bg-spacing-sm);
  max-width: 400px;
  margin-inline: auto;

  & p,
  h6 {
    text-align: center;
  }

  & h6 span {
    display: block;
  }
}

.receivables-table__empty-title {
  max-width: 275px;
}

.receivables-table__row {
  cursor: pointer;
}

.receivables-table__status-icon {
  margin-left: var(--bg-spacing-2x-sm);
}

.receivables-table__actions-container {
  display: flex;
}

.receivables-table__actions-container button {
  display: flex;
  align-items: center;
  padding: var(--bg-spacing-2x-sm);
}

.receivables-table__actions-container button:nth-last-child(1) {
  margin-left: var(--bg-spacing-2x-sm);
}

.receivables-table__actions-icon:focus {
  outline: none;
}

.receivables-table-td__payment-method {
  display: flex;
  align-items: center;
  gap: var(--bg-padding-sm);
  justify-content: flex-end;
}

.receivables-table__gears-icon {
  height: 40px;
  width: 50px;
}
</style>
