import { createRouter, createWebHistory } from 'vue-router';
import routeConfigAccessValidation from '@/commons/services/routeConfigAccessValidation';
import * as appService from '@/commons/services/apps';
import { logout } from '@/commons/services/auth';
import { routes } from './routes';
import { captureException } from '@sentry/vue';
import { tfaState } from '@/commons/services/tfa';

const router = createRouter({
  routes,
  history: createWebHistory(),
  linkActiveClass: 'active',
  linkExactActiveClass: 'active'
});

export const beforeEachs = [
  async to => {
    const authAs = to?.meta?.authAs;

    try {
      if (authAs) {
        const allowedApp = appService.firstAllowedApp([authAs].flat());

        if (allowedApp?.name) {
          await appService.authAs(allowedApp.name);
        } else {
          return { name: appService.getAppRoute() };
        }
      }
    } catch (e) {
      captureException(e, { fingerprint: 'authAs' });
      logout();
    }
  },
  async (to, from) => {
    const beforeEnter = to?.meta?.beforeEnter;
    if (!beforeEnter) {
      return;
    }

    let callbacks = beforeEnter;
    if (!Array.isArray(callbacks)) {
      callbacks = [beforeEnter];
    }

    for (const cb of callbacks) {
      const route = await cb(to, from);
      if (route) {
        return route;
      }
    }
  },
  async (to, from, next) => {
    const ACCESS_DENIED_URL = '/acesso-negado';

    if (!to.meta || (await routeConfigAccessValidation.hasAccess(to.meta))) {
      return next();
    }

    return next(ACCESS_DENIED_URL);
  },
  async to => {
    const allowedRoutes = ['MyAccountSecuritySettingsPage', 'LogoutPage'];

    if (!allowedRoutes.includes(to.name) && tfaState.shouldForceTfa) {
      return { name: 'MyAccountSecuritySettingsPage' };
    }
  }
];

beforeEachs.forEach(cb => {
  router.beforeEach(cb);
});

export default router;
