<template>
  <tr-text-field
    ref="transferValue"
    v-model="transfer.value"
    type="currency"
    name="transferValue"
    size="large"
    :required="true"
    :custom-validation="validate"
    :no-margin="true"
    input-classes="input-width--sm"
    :error-message="transferValueValidationResult.errorMessage"
  >
    <template #label>
      Qual o valor? (máximo de {{ $format.currency(maxValue) }})
    </template>
  </tr-text-field>
</template>

<script>
import transferValueValidations from '@/apps/payments/payments/transfer/services/validations/value';
import transferValidations from '@/apps/payments/payments/transfer/services/validations/transfer';
import transferValue from '@/apps/payments/payments/transfer/services/value';
import dbaModel from '@/apps/payments/payments/dba/models/dba';

export default {
  name: 'transfer-value-field-container',
  props: {
    transfer: {
      required: false,
      type: Object,
      default: () => ({})
    },
    dba: {
      required: false,
      type: Object,
      default: dbaModel.get
    }
  },
  data: () => ({
    transferValueValidationResult: {},
    maxValue: 0
  }),
  computed: {
    hasChangedPaymentMethod() {
      return this.dba.pix_key_type || this.transfer.payment_method;
    }
  },
  watch: {
    hasChangedPaymentMethod() {
      this.getMaxValue();
    }
  },
  mounted() {
    this.getMaxValue();
  },
  methods: {
    async validate() {
      const type = await transferValidations.getPaymentTypeByTransfer(this.transfer.payment_method, this.dba);
      return transferValueValidations.validate(this.transfer.value, type).then(result => {
        this.transferValueValidationResult = result;
        return result.isValid;
      });
    },
    async getMaxValue() {
      const type = await transferValidations.getPaymentTypeByTransfer(this.transfer.payment_method, this.dba);
      transferValue.getMaxValue(type).then(maxValue => {
        this.maxValue = maxValue;
        this.$refs.transferValue.forceValidation();
      });
    }
  }
};
</script>
