<template>
  <div class="flex-row flex-start">
    <bank-image
      :bank="bank"
      :size="size"
      class="margin-right-10 center-h-and-v"
    />
    {{ bankName }}
  </div>
</template>

<script>
import BankImage from '@/apps/payments/payments/bank/components/presentational/BankImage';
import breakBiggerWord from '@/commons/formatters/breakBiggerWord';

export default {
  name: 'bank-name',
  components: {
    BankImage
  },
  props: {
    bank: {
      required: false,
      type: Object,
      default: () => ({})
    },
    size: {
      required: false,
      type: String,
      default: 'small'
    }
  },
  computed: {
    bankName() {
      if (this.size === 'small') {
        return breakBiggerWord((this.bank && this.bank.name) || '...', 12);
      }
      return `${this.bank.code} - ` + breakBiggerWord((this.bank && this.bank.name) || '...', 25);
    }
  }
};
</script>
