<template>
  <tr-drag-and-drop-area-state
    icon-name="ic-description"
    icon-color="green"
    title="Arraste e solte aqui sua planilha =)"
  >
    <tr-typography classes="vertical-offset1 block">
      Baixe aqui a planilha modelo para <tr-typography :strong="true">
        Transferências
      </tr-typography> (<a
        href="https://s3.amazonaws.com/transfeeradata/planilha-padrao.xlsx?v=1.3.0"
      >xlsx</a>
      ou <a href="https://s3.amazonaws.com/transfeeradata/planilha-padrao.csv?v=1.3.0">csv</a>),
      <tr-typography :strong="true">
        Transferências com chave Pix
      </tr-typography> (<a
        href="https://s3.amazonaws.com/transfeeradata/planilha-padrao-chave-pix.xlsx?v=1.0.0"
      >xlsx</a>
      ou <a href="https://s3.amazonaws.com/transfeeradata/planilha-padrao-chave-pix.csv?v=1.0.0">csv</a>) ou para
      <tr-typography :strong="true">
        Boletos
      </tr-typography> (<a
        href="https://s3.amazonaws.com/transfeeradata/planilha-padrao-boletos.xlsx?v=1.0.0"
      >xlsx</a>
      ou <a href="https://s3.amazonaws.com/transfeeradata/planilha-padrao-boletos.csv?v=1.0.0">csv</a>)
    </tr-typography>
    <tr-button
      classes="vertical-offset3 bottom-offset4"
      type="file"
      color="primary"
      size="large"
      accept=".xlsx, .csv"
      :on-files-select="onFileSelect"
      label="Selecione seu arquivo"
    />
  </tr-drag-and-drop-area-state>
</template>

<script>
export default {
  name: 'sheet-import-drag-state',
  props: {
    onFileSelect: {
      required: true,
      type: Function
    }
  }
};
</script>
