<template>
  <batch-fullscreen-modal
    ref="batchFullscreenModal"
    :unique-name="true"
    :on-change="onChange"
  />
</template>

<script>
import jwtDecode from 'jwt-decode';
import BatchFullscreenModal from '@/apps/payments/payments/batch/pages/BatchFullscreenModal';

export default {
  name: 'proxy-modal-container',
  components: {
    BatchFullscreenModal
  },
  mounted() {
    const currentRoute = this.$router.currentRoute.value;
    const modalToken = history.state?.modalToken || currentRoute.query.modalToken;

    if (modalToken) {
      const decodedToken = jwtDecode(modalToken);

      if (decodedToken.id === 'openBatch') {
        this.$refs.batchFullscreenModal.open(decodedToken.data.id);
      }
    }
  },
  methods: {
    onChange() {
      this.$router.go();
    }
  }
};
</script>
