<template>
  <component
    :is="pill.loading ? LoadingStatusPill : 'tr-pill'"
    v-if="pill"
    :color="pill.color"
  >
    {{ pill.label }}
  </component>
</template>

<script setup>
import LoadingStatusPill from '@/commons/components/presentational/LoadingStatusPill.vue';
import { pixKeyStatus } from '@transfeeradev/api-enums/pix';
import { computed } from 'vue';

const props = defineProps({
  status: {
    type: String,
    required: true,
    validator: value => Object.values(pixKeyStatus).includes(value)
  }
});

const pills = {
  [pixKeyStatus.PENDING]: {
    label: 'Vinculando chave',
    color: 'yellow',
    loading: true
  },
  [pixKeyStatus.PENDING_REMOVAL]: {
    label: 'Removendo chave',
    color: 'yellow',
    loading: true
  },
  [pixKeyStatus.REGISTERED]: {
    label: 'Chave vinculada',
    color: 'blue'
  },
  [pixKeyStatus.ERROR]: {
    label: 'Chave não vinculada',
    color: 'red'
  },
  [pixKeyStatus.REMOVED]: {
    label: 'Removida',
    color: 'red'
  },
  [pixKeyStatus.VALIDATING]: {
    label: 'Validação pendente',
    color: 'yellow'
  },
  [pixKeyStatus.WAITING_CLAIM]: {
    label: 'Reivindicação pendente',
    color: 'yellow'
  },
  [pixKeyStatus.WAITING_PORTABILITY]: {
    label: 'Portabilidade pendente',
    color: 'yellow'
  },
  [pixKeyStatus.CLAIM_REQUESTED]: {
    label: 'Reivindicação solicitada',
    color: 'yellow'
  },
  [pixKeyStatus.PORTABILITY_REQUESTED]: {
    label: 'Portabilidade solicitada',
    color: 'yellow'
  },
  [pixKeyStatus.PORTABILITY_IN_PROGRESS]: {
    label: 'Portabilidade em andamento',
    color: 'yellow'
  },
  [pixKeyStatus.CLAIM_IN_PROGRESS]: {
    label: 'Reivindicação em andamento',
    color: 'yellow'
  },
  [pixKeyStatus.WAITING_CLAIM_APPROVAL]: {
    label: 'Aguardando reivindicação',
    color: 'yellow'
  },
  [pixKeyStatus.WAITING_PORTABILITY_APPROVAL]: {
    label: 'Aguardando portabilidade',
    color: 'yellow'
  }
};

const pill = computed(() => pills[props.status]);
</script>
