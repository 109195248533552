<template>
  <form @submit.prevent="onSave">
    <tr-dialog
      ref="modal"
      :unique-name="true"
      name="batch-name-edit-dialog"
      title="Editar nome do lote"
      :has-its-own-buttons="true"
      class="batch-name-edit-dialog"
    >
      <tr-text-field
        v-model="newName"
        class="batch-name-edit-dialog__input"
        label="Nome do lote"
        name="batchName"
        placeholder="Nome do lote"
        :required="true"
        :maxlength="200"
        error-message="Digite um nome para o lote"
      />
      <tr-dialog-buttons-container>
        <tr-button
          classes="pull-left"
          label="Cancelar"
          variant="outlined"
          color="secondary"
          @click="close"
        />
        <tr-button
          classes="pull-right"
          label="Salvar"
          variant="raised"
          color="primary"
          :disabled="!newName?.length"
          type="submit"
        />
      </tr-dialog-buttons-container>
    </tr-dialog>
  </form>
</template>

<script>
import batchResource from '@/apps/payments/payments/batch/resources/batch';

export default {
  name: 'batch-name-edit-dialog',
  props: {
    name: {
      type: String
    },
    id: {
      type: [String, Number]
    }
  },
  emits: ['save'],

  data() {
    return {
      newName: this.name
    };
  },

  watch: {
    name(value) {
      this.newName = value;
    }
  },

  methods: {
    open() {
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
    async onSave() {
      await batchResource.updateName(this.id, this.newName);
      this.$toaster.success('Nome do lote editado com sucesso!');
      this.close();
      this.$emit('save');
    }
  }
};
</script>

<style scoped>
.batch-name-edit-dialog__input {
  width: 100%;
}
</style>