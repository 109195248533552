<template>
  <div>
    <tr-page-section title="Conta para saque">
      <template #description>
        Configure uma conta bancária para realizar seus saques
      </template>
      <div
        v-if="isLoading"
        class="center-h-and-v"
      >
        <tr-loading />
      </div>
      <div
        v-else
        class="row vertical-offset4"
      >
        <bank-account-card-container
          :bank-account="bankAccount"
          :on-card-click="openFormDialog"
          hide-name="true"
        />
      </div>
    </tr-page-section>
    <company-bank-account-form-dialog
      ref="companyBankAccountFormDialog"
      :on-save="refresh"
    />
  </div>
</template>

<script>
import { accountSource } from '@transfeeradev/api-enums';
import CompanyBankAccountFormDialog from '@/apps/payments/payments/settings/bankAccounts/components/presentational/CompanyBankAccountFormDialog';
import BankAccountCardContainer from '@/apps/payments/payments/settings/bankAccounts/components/container/BankAccountCardContainer';
import companyBankAccountResource from '@/apps/payments/payments/settings/bankAccounts/resources/bankAccount';
import { getMessage } from '@/commons/services/error';

export default {
  name: 'bank-accounts-container',
  components: {
    BankAccountCardContainer,
    CompanyBankAccountFormDialog
  },
  data: () => ({
    isLoading: true,
    bankAccount: {}
  }),
  mounted() {
    this.refresh();
  },
  methods: {
    async refresh() {
      this.isLoading = true;
      try {
        this.bankAccount = (await companyBankAccountResource.get(accountSource.CUSTOMER)) || {};
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.$toaster.error(getMessage(error));
      }
    },
    openFormDialog() {
      this.$refs.companyBankAccountFormDialog.open();
    }
  }
};
</script>
