<template>
  <div class="row">
    <tr-typography
      classes="col-lg-9 col-md-9 text-right col-sm-6 vertical-offset1"
      :strong="true"
    >
      Total dos lotes aguardando recebimento
    </tr-typography>
    <tr-typography
      classes="col-lg-3 col-sm-3 text-right vertical-offset1"
      :strong="true"
    >
      {{ $format.currency(batchesTotal) }}
    </tr-typography>
    <tr-typography
      classes="col-lg-9 col-md-9 text-right col-sm-6 vertical-offset1"
      :strong="true"
    >
      Saldo atual
    </tr-typography>
    <tr-typography
      classes="col-lg-3 col-sm-3 text-right vertical-offset1 blue"
      :strong="true"
    >
      {{ $format.currency(balance) }}
    </tr-typography>
    <tr-typography
      classes="col-lg-9 col-md-9 text-right col-sm-6 vertical-offset1"
      :strong="true"
    >
      Valor a enviar
    </tr-typography>
    <tr-typography
      classes="col-lg-3 col-sm-3 text-right vertical-offset1 yellow"
      :strong="true"
    >
      {{ $format.currency(valueWaitingToBeReceived) }}
    </tr-typography>
  </div>
</template>

<script>
export default {
  name: 'batches-waiting-balance-totals',
  props: {
    batchesTotal: {
      required: true,
      type: Number
    },
    balance: {
      required: true,
      type: Number
    },
    valueWaitingToBeReceived: {
      required: true,
      type: Number
    }
  }
};
</script>
