<template>
  <tr-fullscreen-modal
    ref="modal"
    :unique-name="uniqueName"
    name="batch-fullscreen-modal"
    :on-close="onClose"
  >
    <tr-loading
      v-if="isLoading"
      class="vertical-offset5 width-100 center-h-and-v"
    />
    <div
      v-if="!isLoading"
      :key="batch?.id"
    >
      <div
        v-if="hasFailedOrReturnedTransfers"
        class="row top-offset"
      >
        <transfer-error-banner
          :transfer-status="transferStatus.FAILED"
          :on-close="onCloseAlert"
          label="Alguns pagamentos não foram realizados!"
        >
          <bg-text>
            Para entender os motivos detalhadamente, é só <batch-download-link-container
              :batch="batch"
              label="baixar o relatório de pagamentos"
              link-style="true"
            />.
          </bg-text>
        </transfer-error-banner>
      </div>
      <batch-wizard-container
        v-if="pageToBeOpened === batchModalNameConstant.BATCH_WIZARD"
        :batch="batch"
        :type="type"
        :close="close"
        :on-batch-close="onBatchClose"
        :was-just-imported="wasJustImported"
      />
      <view-batch-to-approve-container
        v-if="pageToBeOpened === batchModalNameConstant.VIEW_BATCH_TO_APPROVE"
        :batch="batch"
        :on-change="onChange"
        :on-close="close"
        :on-edit="onChangeToEditMode"
      />
      <view-batch-waiting-to-receive-container
        v-if="pageToBeOpened === batchModalNameConstant.VIEW_BATCH_WAITING_TO_RECEIVE"
        :batch="batch"
        :on-close="close"
        :on-remove="onChange"
        :on-edit="onChangeToEditMode"
      />
      <default-view-batch-container
        v-if="pageToBeOpened === batchModalNameConstant.DEFAULT_VIEW_BATCH"
        :batch="batch"
        :hide="close"
        :on-change="onChange"
        :on-edit-click="showbatchEditDialog"
        :on-cancel-click="onCancelClick"
        @is-all-transfers-scheduled-event="isAllTransfersScheduledEvent"
      />
      <batch-name-edit-dialog
        :id="batch.id"
        ref="batchEditDialog"
        :name="batch.name"
        @save="onSaveEdit"
      />
    </div>
  </tr-fullscreen-modal>
</template>

<script>
import BatchWizardContainer from '@/apps/payments/payments/batch/components/container/BatchWizardContainer';
import ViewBatchToApproveContainer from '@/apps/payments/payments/batch/components/container/ViewBatchToApproveContainer';
import ViewBatchWaitingToReceiveContainer from '@/apps/payments/payments/batch/components/container/ViewBatchWaitingToReceiveContainer';
import DefaultViewBatchContainer from '@/apps/payments/payments/batch/components/container/DefaultViewBatchContainer';
import getBatchModalName from '@/apps/payments/payments/batch/services/getBatchModalName';
import batchModalNameConstant from '@/apps/payments/payments/batch/constants/batchModalName';
import batchResource from '@/apps/payments/payments/batch/resources/batch';
import BatchDownloadLinkContainer from '@/apps/payments/payments/batch/components/container/BatchDownloadLinkContainer';
import { BgText } from '@transfeeradev/bridge';
import TransferErrorBanner from '@/apps/payments/payments/transfer/components/presentational/TransferErrorBanner.vue';
import { getCurrentInstance, onMounted, ref } from 'vue';
import { usePolling } from '@/commons/composables/usePolling';
import { batchStatus, transferStatus } from '@transfeeradev/api-enums';
import { getMessage } from '@/commons/services/error';
import BatchNameEditDialog from '../components/presentational/BatchNameEditDialog.vue';

export default {
  name: 'batch-fullscreen-modal',
  components: {
    BatchWizardContainer,
    BgText,
    ViewBatchToApproveContainer,
    ViewBatchWaitingToReceiveContainer,
    DefaultViewBatchContainer,
    BatchDownloadLinkContainer,
    TransferErrorBanner,
    BatchNameEditDialog
  },
  props: {
    uniqueName: {
      required: false,
      type: Boolean,
      default: false
    },
    type: {
      required: false,
      type: String
    },
    wasJustImported: {
      required: false,
      type: Boolean,
      default: false
    },
    onChange: {
      required: false,
      type: Function
    },
    onHidden: {
      required: false,
      type: Function
    },
    onBatchClose: {
      required: false,
      type: Function
    },
    onEditClick: {
      type: Function,
      required: false
    },
    onCancelClick: {
      type: Function,
      required: false
    }
  },
  emits: ['is-all-transfers-scheduled-event'],
  setup() {
    const isLoading = ref(true);
    const { ctx } = getCurrentInstance();
    const batch = ref({
      created_by: {},
      received_bank_account: {
        Bank: {}
      }
    });

    const { startPolling, stopPolling } = usePolling({
      intervalInSeconds: 2
    });

    const refresh = async () => {
      startPolling(ctx.fetchBatch, {
        immediate: true,
        keepPollingWhile: () => batch.value?.status === batchStatus.PENDING
      });
    };

    onMounted(() => {
      refresh().then(() => {
        isLoading.value = false;
      });
    });

    return {
      batch,
      refresh,
      stopPolling,
      isLoading
    };
  },
  data: () => ({
    batchId: null,
    batchStatus: null,
    pageToBeOpened: null,
    batchModalNameConstant,
    transferStatus,
    hasFailedOrReturnedTransfers: false
  }),
  methods: {
    isAllTransfersScheduledEvent(isAllTransfersScheduledEvent) {
      this.$emit('is-all-transfers-scheduled-event', isAllTransfersScheduledEvent);
    },
    async fetchBatch() {
      try {
        if (!this.batchId) {
          this.pageToBeOpened = batchModalNameConstant.BATCH_WIZARD;
          return;
        }
        this.batch = await batchResource.get(this.batchId);
        this.pageToBeOpened = getBatchModalName.get({
          status: this.batch.status
        });
        this.getExtraInfosForBatches();
      } catch (error) {
        this.$toaster.error(getMessage(error));
      }
    },
    onClose() {
      if (this.pageToBeOpened === batchModalNameConstant.BATCH_WIZARD && !this.wasJustImported) {
        this.onChange();
      }
      if (this.onHidden) {
        this.onHidden();
      }

      this.stopPolling();
    },
    onCloseAlert() {
      this.hasFailedOrReturnedTransfers = false;
    },
    open(batchId) {
      this.batchId = batchId;
      this.refresh();
      this.$refs.modal.open();
    },
    close() {
      this.stopPolling();
      this.$refs.modal.close();
    },
    onChangeToEditMode() {
      this.pageToBeOpened = batchModalNameConstant.BATCH_WIZARD;
      this.close();
      if (this.onChange) {
        this.onChange();
      }
    },
    getExtraInfosForBatches() {
      batchResource.hasFailedOrReturnedTransfers(this.batch.id).then(response => {
        this.hasFailedOrReturnedTransfers = response && response.hasFailedOrReturnedTransfers;
      });
    },
    showbatchEditDialog(batch) {
      this.$refs.batchEditDialog.open(batch);
    },
    closeBatchEditDialog() {
      this.$refs.batchEditDialog.close();
    },
    async onSaveEdit() {
      try {
        this.refresh();
      } catch (e) {
        this.$toaster.error('Houve um problema na edição!');
      }
    }
  }
};
</script>

<style scoped>
/* TODO - create a new alert component */
.top-offset {
  margin-top: var(--bg-spacing-md);
}
</style>
