<template>
  <router-view
    v-slot="{ Component }"
  >
    <component
      :is="Component"
      @loading="$emit('loading', $event)"
    />
  </router-view>
</template>

<script>
export default {
  name: 'developer-area-layout',
  inheritAttrs: false,
  emits: ['loading']
};
</script>
