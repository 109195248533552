<template>
  <config-container />
</template>

<script>
import ConfigContainer from '@/apps/payments/payments/settings/config/components/container/ConfigContainer.vue';

export default {
  name: 'config-page',
  components: {
    ConfigContainer
  }
};
</script>
