export const Formatters = app => {
  app.config.globalProperties.$format = {
    currency(value) {
      if (value === null || value === undefined || value === '') {
        return value;
      }

      return (
        'R$ ' +
        Intl.NumberFormat('pt-BR', {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        }).format(value)
      );
    }
  };
};
