import config from '@/config';
import fetch from '@/fetch';
import moment from '@transfeeradev/moment';

const BASE_URL = config.url.api.transfeera + '/statement';
const APP_BASE_URL = config.url.api.transfeera + '/app/statement';
const DATE_FORMAT = 'YYYY-MM-DD';

export default {
  getFiltered: (initialDate, endDate, page) => {
    const formattedInitialDate = moment(initialDate).format(DATE_FORMAT);
    const formattedEndDate = moment(endDate).format(DATE_FORMAT);

    let url = `${BASE_URL}?endDate=${formattedEndDate}&initialDate=${formattedInitialDate}&page=${page}`;

    return fetch(url);
  },
  getBalance: () => fetch(`${BASE_URL}/balance`),
  deposit: deposit =>
    fetch(`${BASE_URL}/deposit`, {
      method: 'POST',
      body: JSON.stringify(deposit)
    }),
  withdraw: value =>
    fetch(`${APP_BASE_URL}/withdraw`, {
      method: 'POST',
      body: JSON.stringify({ value })
    }),
  getXlsxEntriesReport: (initialDate, endDate) => {
    const formattedInitialDate = moment(initialDate).format(DATE_FORMAT);
    const formattedEndDate = moment(endDate).format(DATE_FORMAT);
    let url = `${APP_BASE_URL}/entry/report/xlsx?initialDate=${formattedInitialDate}&endDate=${formattedEndDate}`;

    return fetch(url, {
      method: 'POST'
    });
  },
  getXlsxEntriesReportV2: (initialDate, endDate) => {
    const formattedInitialDate = moment(initialDate).format(DATE_FORMAT);
    const formattedEndDate = moment(endDate).format(DATE_FORMAT);
    let url = `${APP_BASE_URL}/entry/report/xlsx_v2?initialDate=${formattedInitialDate}&endDate=${formattedEndDate}`;

    return fetch(url, {
      method: 'POST'
    });
  },
  getCSVEntriesReport: (initialDate, endDate) => {
    const formattedInitialDate = moment(initialDate).format(DATE_FORMAT);
    const formattedEndDate = moment(endDate).format(DATE_FORMAT);
    let url = `${APP_BASE_URL}/entry/report/csv?initialDate=${formattedInitialDate}&endDate=${formattedEndDate}`;

    return fetch(url, {
      method: 'POST'
    });
  },
  getPdfBatchReport: (initialDate, endDate) => {
    const formattedInitialDate = moment(initialDate).format(DATE_FORMAT);
    const formattedEndDate = moment(endDate).format(DATE_FORMAT);
    let url = `${APP_BASE_URL}/batch/report/pdf?initialDate=${formattedInitialDate}&endDate=${formattedEndDate}`;

    return fetch(url);
  },
  getOFXEntriesReport: (initialDate, endDate) => {
    const formattedInitialDate = moment(initialDate).format(DATE_FORMAT);
    const formattedEndDate = moment(endDate).format(DATE_FORMAT);
    let url = `${APP_BASE_URL}/entry/report/ofx?initialDate=${formattedInitialDate}&endDate=${formattedEndDate}`;

    return fetch(url);
  }
};
