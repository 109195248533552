<template>
  <tr-fullscreen-modal
    ref="modal"
    :unique-name="true"
    name="dba-management-fullscreen-modal"
  >
    <tr-page-title :title="title" />
    <tr-page-section>
      <receiver-management-container
        :receiver-id="receiverId"
        :cancel="close"
        :on-change="change"
      />
    </tr-page-section>
  </tr-fullscreen-modal>
</template>

<script>
import ReceiverManagementContainer from '@/apps/payments/payments/receivers/components/container/ReceiverManagementContainer.vue';
import verifyRoleAccess from '@/commons/services/verifyRoleAccess';
import { roles } from '@transfeeradev/api-enums';

export default {
  name: 'receiver-management-fullscreen-modal',
  components: {
    ReceiverManagementContainer
  },
  props: {
    onChange: {
      required: true,
      type: Function
    },
    receiverId: {
      required: false,
      type: [String, Number]
    }
  },
  computed: {
    title() {
      const isAnalyst = verifyRoleAccess.isLoggedUserAllowedToAccess(roles.appRoles.ANALYST);
      if (isAnalyst) {
        return 'Recebedor';
      }

      if (this.receiverId) {
        return 'Editar recebedor';
      }

      return 'Cadastrar novo recebedor';
    }
  },
  methods: {
    open() {
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
    change() {
      this.close();
      this.onChange();
    }
  }
};
</script>
