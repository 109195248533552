<template>
  <tr-loading
    v-if="loadingUsers"
    classes="center-h-and-v width-100 vertical-offset5"
  />
  <template v-else>
    <tr-table disable-click>
      <tr-thead>
        <tr-tr>
          <tr-th width="30%">
            Nome
          </tr-th>
          <tr-th width="30%">
            E-mail
          </tr-th>
          <tr-th
            width="10%"
          >
            Segurança
          </tr-th>
          <tr-th width="30%">
            Perfil
          </tr-th>
          <tr-th width="30%">
            Produtos
          </tr-th>
        </tr-tr>
      </tr-thead>
      <tr-tbody>
        <tr-tr
          v-for="user in users"
          :key="user.id"
          :on-item-click="() => $emit('click-user', user)"
        >
          <tr-td>
            {{ user.name }}
          </tr-td>
          <tr-td>
            {{ user.email }}
          </tr-td>
          <tr-td class="center-h-and-v">
            <tfa-icon :has-two-factor-enabled="user.has_two_factor_enabled" />
          </tr-td>
          <tr-td>
            {{ roles.appRoles[user.profile].name }}
          </tr-td>
          <tr-td>
            <div :style="{ gap: '8px', display: 'flex' }">
              <tr-pill
                v-for="app in user.applications"
                :key="app.id"
                :style="{ whiteSpace: 'nowrap' }"
                :no-margin="true"
                size="small"
                color="blue"
              >
                {{ getAppTitleByTitle(app.name) }}
              </tr-pill>
            </div>
          </tr-td>
        </tr-tr>
      </tr-tbody>
    </tr-table>
    <tr-numeric-pagination
      v-if="paginationProps"
      v-bind="paginationProps"
    />
  </template>
</template>

<script>
// @ts-check
import TfaIcon from '@/commons/components/presentational/TfaIcon.vue';
import { getAppTitleByTitle } from '@/commons/services/apps';
import { roles } from '@transfeeradev/api-enums';
import { defineComponent } from 'vue';
import { useUsers } from './useUsers';

export default defineComponent({
  components: {
    TfaIcon
  },
  props: {
    searchText: {
      type: String
    },
    appFilter: {
      type: String
    },
    profileFilter: {
      type: String
    }
  },
  emits: ['click-user'],
  setup() {
    const { users, loadingUsers, paginationProps } = useUsers();
    return {
      roles,
      users,
      getAppTitleByTitle,
      paginationProps,
      loadingUsers
    };
  }
});
</script>
