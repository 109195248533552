import moment from '@transfeeradev/moment';
import { receivableStatus } from '@/apps/payin/receivables/constants/receivableStatus';

const receivableRefundButtonStatus = {
  notReceivedYet: 'Cobrança ainda não foi recebida',
  refundDeadlineExceeded: 'Tempo para devolução excedido',
  canceled: 'Cobrança cancelada',
  returned: 'Devolvido',
  refund: 'Devolver'
};

export const refundButtonStatus = receivable => {
  if (receivable.status === receivableStatus.PENDING) {
    return receivableRefundButtonStatus.notReceivedYet;
  }
  if (receivable.status === receivableStatus.CANCELED) {
    return receivableRefundButtonStatus.canceled;
  }
  if (moment(receivable.refund_expires_at).isAfter(moment())) {
    return receivableRefundButtonStatus.refundDeadlineExceeded;
  }
  if (receivable.status === receivableStatus.RETURNED) {
    return receivableRefundButtonStatus.returned;
  } else {
    return receivableRefundButtonStatus.refund;
  }
};
