import { paymentMethod as paymentMethods } from '@transfeeradev/api-enums/payinApi';

export const paymentMethodNames = {
  [paymentMethods.pixKey]: 'Chave Pix',
  [paymentMethods.pixQrCodeStatic]: 'Pix QR Code Estático',
  [paymentMethods.pixQrCodeDynamic]: 'Pix QR Code Dinâmico',
  [paymentMethods.chargeBoleto]: 'Boleto',
  [paymentMethods.chargeBoletoQrCode]: 'Boleto QR Code',
  [paymentMethods.chargePixQrCodeDynamic]: 'Pix QR Code Dinâmico',
  [paymentMethods.manual]: 'Manual',
  [paymentMethods.creditCard]: 'Cartão de Crédito'
};

export { paymentMethods };
