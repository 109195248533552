<template>
  <tr-text-field
    :label="label"
    classes="col-lg-3"
    type="currency"
    name="billet-value"
    :error-message="errorMessage"
    required="true"
    input-classes="input-width--sm"
    :no-margin="noMargin"
    :custom-validation="validate"
    :model-value="internalValue"
    :on-value-change="onChange"
  />
</template>

<script>
import { Value } from '@transfeeradev/billet-validation';
import accountLimitsValidations from '@/apps/payments/payments/billet/services/validations/value';
import { paymentType } from '@transfeeradev/api-enums';
import { getMessage } from '@/commons/services/error';

const DEFAULT_ERROR_MESSAGE = 'Valor inválido';

export default {
  name: 'billet-value-field-container',
  props: {
    value: {
      required: false,
      type: [String, Number]
    },
    label: {
      required: false,
      type: String,
      default: 'Valor a pagar'
    },
    onChange: {
      required: true,
      type: Function
    },
    noMargin: {
      required: false,
      type: Boolean,
      default: false
    },
    barcode: {
      required: false,
      type: String
    }
  },
  data: () => ({
    validationResult: {}
  }),
  computed: {
    internalValue() {
      return this.value;
    },
    errorMessage() {
      const isInvalid = this._isInvalid();
      if (isInvalid) {
        return this.validationResult.errorMessage;
      }
      return DEFAULT_ERROR_MESSAGE;
    }
  },
  watch: {
    value() {
      this.validate();
    }
  },
  mounted() {
    this.validate().then(result => {
      if (!result && this.value) {
        this.$refs.field.forceValidation();
      }
    });
  },
  methods: {
    validateService() {
      const validation = new Value(
        {
          barcode: this.barcode,
          value: this.value
        },
        {
          disallowOverdue: true
        }
      ).validate();

      if (!validation.isValid) {
        throw getMessage(validation.errorMessage);
      }
    },
    async validateAccountLimits() {
      const response = await accountLimitsValidations.validate(this.value, paymentType.BILLET);
      if (!response.isValid) {
        throw response.errorMessage;
      }
    },
    async validate() {
      try {
        this.validateService();
        await this.validateAccountLimits();
        return true;
      } catch (error) {
        this.validationResult = this._createError(error);
        return false;
      }
    },
    _createError(message) {
      return {
        isValid: false,
        errorMessage: message
      };
    },
    _isInvalid() {
      return Boolean(this.validationResult && !this.validationResult.isValid);
    }
  }
};
</script>
