<template>
  <transfeera-bank-accounts-container
    :full-size="fullSize"
    :no-margin-on-first-line="noMarginOnFirstLine"
    :selection-label="transfeeraBankAccountSelectionLabel"
    :selected-label="transfeeraBankAccountSelectedLabel"
    :on-select-bank-account="onSelectBankAccount"
    :selected-bank-account="selectedBankAccount"
    :default-bank-account="defaultBankAccount"
  />
</template>

<script>
import TransfeeraBankAccountsContainer from '@/apps/payments/payments/bank/components/container/TransfeeraBankAccountsContainer';

export default {
  name: 'bank-account-to-send-balance-container',
  components: {
    TransfeeraBankAccountsContainer
  },
  props: {
    defaultBankAccount: {
      required: false
    },
    noMarginOnFirstLine: {
      required: false,
      type: Boolean,
      default: true
    },
    fullSize: {
      required: false,
      type: Boolean,
      default: false
    },
    onSelectBankAccount: {
      required: false,
      type: Function
    },
    selectedBankAccount: {
      required: false,
      type: Object
    },
    transfeeraBankAccountSelectionLabel: {
      required: false,
      type: String
    },
    transfeeraBankAccountSelectedLabel: {
      required: false,
      type: String
    }
  }
};
</script>
