import { APPS } from '@/commons/constants/apps';
import Layout from './Layout';
import CredentialsPage from './pages/CredentialsPage.vue';
import loggedUser from '@/commons/resources/loggedUser';
import { applications } from '@transfeeradev/api-enums';
import settingsResource from '@/commons/resources/settings';

export default {
  path: APPS.DEVELOPER_AREA.path,
  component: Layout,
  name: 'DeveloperArea',
  redirect: {
    name: 'DeveloperAreaCredentialsPage'
  },
  meta: {
    isAllowed: async () => {
      if (!loggedUser.isAdmin() && !loggedUser.isOperator()) {
        return false;
      }

      if (loggedUser.hasApplicationAccess(applications.CONTACERTA)) {
        return true;
      }

      const settings = await settingsResource.get(true);

      return !!settings.hasApiAccess;
    }
  },
  children: [
    {
      path: 'credenciais',
      name: 'DeveloperAreaCredentialsPage',
      component: CredentialsPage
    }
  ]
};
