<template>
  <tr-pill
    :color="statusColor"
    :no-margin="noMargin"
    :size="size"
    :classes="classes"
  >
    {{ statusName }}
  </tr-pill>
</template>

<script>
import billingStatusColor from '@/apps/admin/billing/constants/billingStatusColor';
import billingStatusName from '@/apps/admin/billing/constants/billingStatusName';

export default {
  name: 'billing-status-pill',
  props: {
    status: {
      required: false,
      type: String
    },
    noMargin: {
      required: false,
      type: Boolean,
      default: false
    },
    classes: {
      required: false,
      type: String
    },
    size: {
      required: false,
      type: String
    }
  },
  computed: {
    statusColor() {
      if (this.status && this.status.color) {
        return this.status.color;
      }
      return billingStatusColor[this.status];
    },
    statusName() {
      if (this.status && this.status.name) {
        return this.status.name;
      }
      return billingStatusName[this.status];
    }
  }
};
</script>
