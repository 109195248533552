<template>
  <tr-fullscreen-modal
    ref="modal"
    name="billet-details-full-modal"
    title="Detalhes do boleto"
    :on-close="onHidden"
    cancel-button-label="Voltar"
  >
    <billet-details-container
      :show-batch-fullscreen-modal="showBatchFullscreenModal"
      :hide="close"
      :billet-id="billetId"
      :on-cancel="onCancel"
    />
  </tr-fullscreen-modal>
</template>

<script>
import billetDetailsContainer from '@/apps/payments/payments/billet/components/container/BilletDetailsContainer';

export default {
  name: 'billet-details-full-modal',
  components: {
    billetDetailsContainer
  },
  props: {
    showBatchFullscreenModal: {
      required: false,
      type: Function
    },
    billetId: {
      required: false,
      type: String
    },
    onHidden: {
      required: false,
      type: Function
    },
    onCancel: {
      required: false,
      type: Function
    }
  },
  methods: {
    open() {
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    }
  }
};
</script>
