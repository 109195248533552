<template>
  <tr-show-for-roles :roles="appRoles.ADMIN">
    <billing-closed
      v-if="billing"
      :billing-month="billingMonth"
      :amount-of-transfers="billing.amount_of_transfers"
      :billing-total="billing.total"
      :no-margin="noMargin"
      :ticket-url="billing.ticket_url"
      :eletronic-invoice-url="billing.electronic_invoice_url"
      :get-monthly-report="getMonthlyReport"
    />
  </tr-show-for-roles>
</template>

<script>
import BillingClosed from '@/apps/admin/billing/components/presentational/BillingClosed.vue';
import billingResource from '@/apps/admin/billing/resources/billing';
import { getMessage } from '@/commons/services/error';
import { roles } from '@transfeeradev/api-enums';
import moment from '@transfeeradev/moment';

export default {
  name: 'billing-closed-container',
  components: {
    BillingClosed
  },
  props: {
    noMargin: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    appRoles: roles.appRoles,
    billing: null,
    billingMonth: ''
  }),
  created() {
    return billingResource.payRequest().then(
      response => {
        if (response && response.billing_period_start) {
          this.billing = response;
          this.billingMonth = moment(this.billing.billing_period_start).format('MMMM');
        }
      },
      error => this.$toaster.error(getMessage(error))
    );
  },
  methods: {
    async getMonthlyReport() {
      try {
        await billingResource.getMonthlyReport(this.billing.billing_period_start);
        this.$toaster.success({
          message: 'Relatório está sendo gerado, quando concluído será enviado para o seu email.',
          duration: 10000
        });
      } catch (error) {
        this.$toaster.error(getMessage(error));
      }
    }
  }
};
</script>
