<template>
  <div>
    <div
      v-for="(appRole, index) in appRoles"
      :key="appRole.value"
      class="row"
    >
      <tr-text-field
        v-model="user.profile"
        name="profile"
        type="radio"
        classes="col-lg-12 col-sm-12 col-md-6"
        :no-margin="index + 1 === Object.keys(appRoles).length"
        :disabled="disabled"
        :required="true"
        :radio-value="appRole.value"
      >
        <template #label>
          <strong class="margin-right-10">{{ appRole.name }}</strong>
          <span>({{ descriptions[appRole.value] }})</span>
        </template>
      </tr-text-field>
    </div>
  </div>
</template>

<script>
import { APPS } from '@/commons/constants/apps';
import { getValidAppRoles } from '@/commons/services/roles';
import { roles } from '@transfeeradev/api-enums';
import { computed, defineComponent } from 'vue';

const makeIndex = list => [...list].sort().join(',');

export default defineComponent({
  props: {
    user: {
      type: Object
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const appSelection = computed(() =>
      makeIndex(props.user.applications.filter(app => [APPS.CONTACERTA.key, APPS.PAYMENTS.key].includes(app)))
    );

    const appSelectionOptions = {
      PAYMENTS: makeIndex([APPS.PAYMENTS.key]),
      CONTACERTA: makeIndex([APPS.CONTACERTA.key]),
      BOTH: makeIndex([APPS.CONTACERTA.key, APPS.PAYMENTS.key])
    };

    const descriptionOptions = {
      [appSelectionOptions.PAYMENTS]: {
        [roles.appRoles.ADMIN.value]: 'Gerencia dados da empresa, cria, edita e aprova pagamentos',
        [roles.appRoles.OPERATOR.value]: 'Cria, edita e envia pagamentos para aprovação',
        [roles.appRoles.ANALYST.value]: 'Apenas visualiza pagamentos e indicadores'
      },
      [appSelectionOptions.CONTACERTA]: {
        [roles.appRoles.ADMIN.value]: 'Gerencia dados da empresa e efetua validações bancárias',
        [roles.appRoles.OPERATOR.value]: 'Efetua validações bancárias',
        [roles.appRoles.ANALYST.value]: 'Apenas visualiza validações bancárias'
      },
      [appSelectionOptions.BOTH]: {
        [roles.appRoles.ADMIN.value]:
          'Gerencia dados da empresa, efetua validações bancárias, cria, edita e aprova pagamentos',
        [roles.appRoles.OPERATOR.value]: 'Efetua validações bancárias, cria, edita e envia pagamentos para aprovação',
        [roles.appRoles.ANALYST.value]: 'Apenas visualiza validações bancárias, pagamentos e indicadores'
      }
    };

    const descriptions = computed(() => descriptionOptions[appSelection.value]);

    return {
      descriptions,
      appRoles: getValidAppRoles()
    };
  }
});
</script>
