import { inject } from 'vue';

/**
 * @return {{
 *  success: (message: string) => void;
 *  error: (message: string) => void;
 * }}
 */
export const useToaster = () => {
  return inject('$toaster');
};
