<template>
  <div class="balance-card-container">
    <div class="balance-card">
      <tr-read-only-text-field
        :dont-use-col-classes="true"
        label="Seu saldo atual"
        value-color="blue"
        :no-margin="noMargin"
        :value="$format.currency(balance)"
      />
      <tr-hide-for-roles :roles="hideActionForRoles">
        <tr-button
          v-if="balance"
          class="vertical-offset1"
          :on-button-click="openWithdrawDialog"
          variant="text"
          :link-color="true"
          text-variant="body"
          label="Resgatar saldo"
        />
      </tr-hide-for-roles>
    </div>
    <div class="balance-card">
      <tr-read-only-text-field
        :dont-use-col-classes="true"
        label="Aguardando recebimento"
        value-color="yellow"
        :no-margin="noMargin"
        :value="$format.currency(valueWaitingToBeReceived)"
      />
      <tr-button
        class="vertical-offset1"
        :on-button-click="openBankAccountToSendBalanceDialog"
        variant="text"
        :link-color="true"
        text-variant="body"
        label="Ver dados bancários para o envio"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'balance-info',
  props: {
    balance: {
      required: true,
      type: Number
    },
    valueWaitingToBeReceived: {
      required: true,
      type: Number
    },
    noMargin: {
      required: false,
      type: Boolean,
      default: true
    },
    openWithdrawDialog: {
      required: true,
      type: Function
    },
    openBankAccountToSendBalanceDialog: {
      required: true,
      type: Function
    },
    hideActionForRoles: {
      type: Object
    }
  }
};
</script>

<style scoped>
.balance-card-container {
  display: flex;
  flex-direction: row;
  gap: 32px;
  flex-wrap: wrap;
}

.balance-card {
  background-color: white;
  padding: 16px;
  border-radius: 16px;
  min-width: 300px;
}
</style>
