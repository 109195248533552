import contacertaCompanyResource from '@/apps/contacerta/resources/company';
import appCompanyResource from '@/apps/payments/payments/settings/company/resources/company';
import { resourceGateway } from './resourceGateway';

const getResource = () => {
  return resourceGateway({
    appResource: appCompanyResource,
    contacertaResource: contacertaCompanyResource
  });
};

export default {
  get: (...args) => getResource().get(...args),
  update: (...args) => getResource().update(...args),
  uploadLogo: (...args) => getResource().uploadLogo(...args)
};
