<template>
  <tr-dialog
    ref="modal"
    name="download-receipt-error-dialog"
    title="Não foi possível baixar os comprovantes"
    cancel-button-label="Voltar"
  >
    <tr-typography variant="body">
      {{ message }}
    </tr-typography>
  </tr-dialog>
</template>

<script>
export default {
  name: 'download-receipt-error-dialog',
  props: {
    message: {
      required: true,
      type: String
    }
  },
  methods: {
    open() {
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    }
  }
};
</script>
