import { accountType } from '@transfeeradev/api-enums';

export default {
  [accountType.CONTA_CORRENTE]: 'corrente',
  [accountType.CONTA_POUPANCA]: 'poupança',
  [accountType.CONTA_FACIL]: 'fácil',
  [accountType.ENTIDADES_PUBLICAS]: 'pública',
  [accountType.CONTA_SALARIO]: 'salário',
  [accountType.CONTA_PAGAMENTO]: 'pagamento'
};
