<template>
  <batch-form-alerts
    :classes="classes"
    :has-time-error="hasTimeError"
    :has-time-warning="hasTimeWarning"
    :has-duplicated-integration-id="hasDuplicatedIntegrationId"
  />
</template>

<script>
import BatchFormAlerts from '@/apps/payments/payments/batch/components/presentational/BatchFormAlerts';
import batchResource from '@/apps/payments/payments/batch/resources/batch';
import { batchType } from '@transfeeradev/api-enums';

export default {
  name: 'batch-form-alerts-container',
  components: {
    BatchFormAlerts
  },
  props: {
    classes: {
      required: false,
      type: String,
      default: ''
    },
    batchId: {
      required: false,
      type: [Number, String]
    },
    batchType: {
      required: false,
      type: String
    }
  },
  data: () => ({
    hasTimeError: false,
    hasTimeWarning: false,
    hasDuplicatedIntegrationId: false
  }),
  watch: {
    batchId() {
      this.refresh();
    }
  },
  created() {
    this.refresh();
  },
  methods: {
    refresh() {
      if (this.batchId && this.batchType === batchType.TRANSFER) {
        return batchResource.getTransferBatchFlags(this.batchId).then(flags => {
          this.hasTimeError = flags.hasTimeError;
          this.hasTimeWarning = flags.hasTimeWarning;
          this.hasDuplicatedIntegrationId = flags.hasDuplicatedIntegrationId;
        });
      }
    }
  }
};
</script>
