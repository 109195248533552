import { accountType } from '@transfeeradev/api-enums';

const format = accountTypeInput => {
  const formattedAccountType = String(accountTypeInput).toLowerCase();

  if (formattedAccountType.indexOf('corrente') > -1) {
    return accountType.CONTA_CORRENTE;
  }

  if (formattedAccountType.indexOf('poupanca') > -1 || formattedAccountType.indexOf('poupança') > -1) {
    return accountType.CONTA_POUPANCA;
  }

  if (formattedAccountType.indexOf('fácil') > -1 || formattedAccountType.indexOf('facil') > -1) {
    return accountType.CONTA_FACIL;
  }

  if (formattedAccountType.indexOf('pagamento') > -1) {
    return accountType.CONTA_PAGAMENTO;
  }

  if (
    formattedAccountType.indexOf('entidade') > -1 ||
    formattedAccountType.indexOf('pública') > -1 ||
    formattedAccountType.indexOf('publica') > -1
  ) {
    return accountType.ENTIDADES_PUBLICAS;
  }

  return '';
};

export default {
  format
};
