import config from '@/config';
import fetch from '@/fetch';

const BASE_URL = config.url.contacerta + '/app/user';

export default {
  getAll: ({ page = 0, search, application, profile } = {}) => {
    const query = { page };

    if (search) {
      query.search = search;
    }

    if (application) {
      query.application = application;
    }

    if (profile) {
      query.profile = profile;
    }

    return fetch(`${BASE_URL}?${new URLSearchParams(query)}`);
  },
  get: id => fetch(`${BASE_URL}/${id}`),
  getFreshLoggedUser: () => fetch(`${BASE_URL}/me`),
  create: user =>
    fetch(BASE_URL, {
      body: JSON.stringify(user),
      method: 'POST'
    }),
  update: user =>
    fetch(`${BASE_URL}/me`, {
      body: JSON.stringify(user),
      method: 'PUT'
    }),
  updateUser: (id, { profile, applications }) =>
    fetch(`${BASE_URL}/${id}`, {
      body: JSON.stringify({
        profile,
        applications
      }),
      method: 'PUT'
    }),
  remove: id =>
    fetch(`${BASE_URL}/${id}`, {
      method: 'DELETE'
    }),
  updatePassword: (oldPassword, newPassword) =>
    fetch(`${BASE_URL}/password`, {
      method: 'PUT',
      body: JSON.stringify({
        oldPassword,
        newPassword
      })
    })
};
