<template>
  <div>
    <tr-page-section class="no-margin">
      <template #title>
        <tr-typography
          class="no-margin"
          variant="h5"
        >
          <b>Seu saldo atual é de {{ $format.currency(balance) }}</b>, qual o valor que você deseja resgatar?
        </tr-typography>
      </template>
    </tr-page-section>
    <tr-page-section class="no-margin">
      <div class="row bottom-offset3">
        <tr-text-field
          ref="withdrawValue"
          v-model="withdrawValue"
          name="withdrawValue"
          size="large"
          type="currency"
          input-classes="input-width--sm"
          :required="true"
          :custom-validation="validate"
          :error-message="errorMessage"
        >
          <template #label>
            Valor a resgatar
          </template>
        </tr-text-field>
      </div>
    </tr-page-section>
    <tr-page-section
      title="Dados da sua conta cadastrada:"
      class="no-margin"
    >
      <view-company-bank-account-container />
    </tr-page-section>
  </div>
</template>

<script>
import ViewCompanyBankAccountContainer from '@/apps/payments/payments/bank/components/container/ViewCompanyBankAccountContainer';
import { errors } from '@transfeeradev/api-enums';

export default {
  name: 'withdraw',
  components: {
    ViewCompanyBankAccountContainer
  },
  props: {
    balance: {
      required: true,
      type: Number
    }
  },
  data: () => ({
    withdrawValue: 0,
    validationResult: {},
    errorMessage: null
  }),
  watch: {
    withdrawValue(value) {
      if (!value) {
        this.errorMessage = errors.ptBr.sta.WITHDRAW_VALUE_REQUIRED.message;
      }
    }
  },
  methods: {
    validate() {
      return new Promise(resolve => {
        this.errorMessage = null;

        if (this.balance < this.withdrawValue) {
          this.errorMessage = errors.ptBr.sta.INSUFFICIENT_BALANCE.message;
          return resolve(false);
        }

        return resolve(true);
      });
    },
    getWithdrawValue() {
      return this.withdrawValue;
    },
    focusField(ref) {
      this.$refs[ref].focus();
    }
  }
};
</script>
