<template>
  <reconciled-button
    :classes="classes"
    :is-reconciled="isReconciled"
    :on-button-click="toggleReconciled"
  />
</template>

<script>
import batchResource from '@/apps/payments/payments/batch/resources/batch';
import ReconciledButton from '@/apps/payments/payments/batch/components/presentational/ReconciledButton';

export default {
  name: 'reconciled-button-container',
  components: {
    ReconciledButton
  },
  props: {
    classes: {
      required: false,
      type: String
    },
    batchId: {
      required: false,
      type: [Number, String]
    },
    isReconciled: {
      required: false,
      type: Boolean,
      default: false
    },
    onChange: {
      required: true,
      type: Function
    }
  },
  methods: {
    toggleReconciled() {
      batchResource
        .setReconciled(this.batchId, !this.isReconciled)
        .then(this.onToggleReconciled, this.onToggleReconciledError);
    },
    onToggleReconciled() {
      if (!this.isReconciled) {
        this.$toaster.success('Este lote foi definido como "Conciliado"');
      } else {
        this.$toaster.success('Este lote foi definido como "Não conciliado"');
      }
      this.onChange(!this.isReconciled);
    },
    onToggleReconciledError() {
      this.$toaster.error(`Ocorreu um erro ao definir ${this.isReconciled ? 'conciliado' : 'não conciliado'}`);
    }
  }
};
</script>
