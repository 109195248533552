<template>
  <div>
    <tr-form
      :on-valid-submit="withdraw"
      :on-invalid-submit="onInvalidSubmit"
    >
      <withdraw
        ref="withdraw"
        :balance="balance"
      />
      <tr-dialog-buttons-container>
        <tr-button
          classes="pull-left"
          label="Cancelar"
          variant="raised"
          color="secondary"
          :on-button-click="close"
        />
        <tr-button
          type="submit"
          class="pull-right margin-right"
          variant="raised"
          :disabled="isLoading"
          label="Resgatar"
          color="primary"
        />
      </tr-dialog-buttons-container>
    </tr-form>
  </div>
</template>

<script>
import statementResource from '@/apps/payments/payments/statement/resources/statement';
import Withdraw from '@/apps/payments/payments/statement/components/presentational/Withdraw';
import { getMessage } from '@/commons/services/error';

export default {
  name: 'withdraw-container',
  components: {
    Withdraw
  },
  props: {
    onChange: {
      required: false,
      type: Function
    },
    close: {
      required: false,
      type: Function
    }
  },
  data: () => ({
    balance: 0,
    isLoading: false
  }),
  created() {
    this.getBalance();
  },
  methods: {
    getBalance() {
      statementResource.getBalance().then(
        balance => {
          this.balance = balance.value;
        },
        error => this.$toaster.error(getMessage(error))
      );
    },
    withdraw() {
      this.isLoading = true;
      const withdrawValue = this.$refs.withdraw.getWithdrawValue();

      statementResource.withdraw(withdrawValue).then(
        () => {
          this.isLoading = false;
          this.$toaster.success('Saque realizado com sucesso');
          window.dispatchEvent(new Event('topbar.refresh'));
          if (this.onChange) {
            this.onChange();
          }
          if (this.close) {
            this.close();
          }
        },
        error => {
          this.isLoading = false;
          if (error && error.code) {
            return this.$toaster.error(getMessage(error));
          }

          this.$toaster.error('Ocorreu um erro ao fazer o saque');
        }
      );
    },
    onInvalidSubmit() {
      this.$refs.withdraw.focusField('withdrawValue');
    }
  }
};
</script>
