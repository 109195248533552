<template>
  <tr-dialog
    ref="modal"
    name="user-removal-confirmation-dialog"
    title="Excluir usuário"
  >
    <div class="text-center">
      <tr-typography variant="h5">
        Você tem certeza que deseja excluir o usuário
      </tr-typography>
      <br>
      <tr-typography
        variant="h5"
        strong="true"
      >
        {{ user.name }}
      </tr-typography>
      <tr-typography variant="h5">
        ?
      </tr-typography>
    </div>
    <template #buttons>
      <tr-button

        classes="pull-right"
        :on-button-click="onRemove"
        label="Excluir usuário"
        variant="raised"
        color="danger"
      />
    </template>
  </tr-dialog>
</template>

<script>
export default {
  name: 'user-removal-confirmation-dialog',
  props: ['onRemove', 'user'],
  methods: {
    open() {
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    }
  }
};
</script>
