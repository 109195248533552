import config from '@/config';
import fetch from '@/fetch';

const BASE_URL = config.url.api.payin + '/app/payins';

export const refundResource = {
  refund: (id, amount) => fetch(`${BASE_URL}/${id}/refunds`, { method: 'POST', body: JSON.stringify({ amount }) }),
  /**
   * @returns {Promise<{
   * items: Refund[],
   * metadata: {pagination: {itemsPerPage: number, totalItems: number}}}>}
   */
  getRefundsByPayinId: id => fetch(`${BASE_URL}/${id}/refunds`)
};
