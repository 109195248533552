<template>
  <loading-status-pill
    v-if="batchStatus === batchStatuses.PENDING"
    color="grey-semi-light"
    icon-color="white"
    :no-margin="noMargin"
    :size="size"
    :classes="noColLimit ? '' : 'col-lg-3'"
  >
    {{ batchStatusName[batchStatus] }}
  </loading-status-pill>
  <tr-pill
    v-else
    :color="batchStatusColor[batchStatus]"
    :no-margin="noMargin"
    :size="size"
    :classes="noColLimit ? '' : 'col-lg-3'"
  >
    {{ batchStatusName[batchStatus] }}
  </tr-pill>
</template>

<script>
import batchStatusColor from '@/apps/payments/payments/batch/constants/batchStatusColor';
import batchStatusName from '@/apps/payments/payments/batch/constants/batchStatusName';
import LoadingStatusPill from '@/commons/components/presentational/LoadingStatusPill.vue';
import { batchStatus as batchStatuses } from '@transfeeradev/api-enums';

export default {
  name: 'batch-status-pill',
  components: {
    LoadingStatusPill
  },
  props: ['batchStatus', 'noMargin', 'size', 'noColLimit'],
  data: () => ({
    batchStatusColor,
    batchStatusName,
    batchStatuses
  })
};
</script>
