<template>
  <tr-pill
    :color="statusColor"
    :no-margin="noMargin"
    :size="size"
    :classes="classes"
  >
    {{ statusName }}
  </tr-pill>
</template>

<script>
import transferStatusColor from '@/apps/payments/payments/transfer/constants/transferStatusColor';
import transferStatusName from '@/apps/payments/payments/transfer/constants/transferStatusName';

export default {
  name: 'batch-status-pill',
  props: ['transferStatus', 'noMargin', 'classes', 'size'],
  computed: {
    statusColor() {
      if (this.transferStatus && this.transferStatus.color) {
        return this.transferStatus.color;
      }
      return transferStatusColor[this.transferStatus];
    },
    statusName() {
      if (this.transferStatus && this.transferStatus.name) {
        return this.transferStatus.name;
      }
      return transferStatusName[this.transferStatus];
    }
  }
};
</script>
