<template>
  <div>
    <tr-page-title :title="batch.name">
      <template #description>
        <batch-with-any-waiting-status-top-info
          :batch="batch"
          :company-name="companyName"
        >
          <slot />
        </batch-with-any-waiting-status-top-info>
      </template>
    </tr-page-title>
    <tr-page-section title="Pagamentos deste lote">
      <transfer-cards-container
        v-if="isTransfer"
        :hide-status-pill="true"
        :batch="batch"
      />
      <billet-cards-container
        v-if="isBillet"
        :batch-id="batch.id"
        :hide-status-pill="true"
        :batch="batch"
      />
    </tr-page-section>
    <tr-footer-buttons-container>
      <tr-button
        class="pull-left"
        variant="outlined"
        color="secondary"
        label="Voltar"
        :on-button-click="onClose"
      />
    </tr-footer-buttons-container>
  </div>
</template>

<script>
import BatchWithAnyWaitingStatusTopInfo from '@/apps/payments/payments/batch/components/presentational/BatchWithAnyWaitingStatusTopInfo';
import TransferCardsContainer from '@/apps/payments/payments/transfer/components/container/TransferCardsContainer';
import BilletCardsContainer from '@/apps/payments/payments/billet/components/container/BilletCardsContainer';
import loggedUserResource from '@/commons/resources/loggedUser';
import batchModel from '@/apps/payments/payments/batch/models/batch';
import { batchType } from '@transfeeradev/api-enums';

export default {
  name: 'view-batch-with-any-waiting-status',
  components: {
    BatchWithAnyWaitingStatusTopInfo,
    TransferCardsContainer,
    BilletCardsContainer
  },
  props: {
    batch: {
      required: false,
      type: Object,
      default: batchModel.get
    },
    onClose: {
      required: false,
      type: Function
    }
  },
  computed: {
    isTransfer() {
      return Boolean(!this.batch.type || this.batch.type === batchType.TRANSFER);
    },
    isBillet() {
      return Boolean(this.batch.type && this.batch.type === batchType.BILLET);
    }
  },
  created() {
    this.companyName = loggedUserResource.get().company.name;
  }
};
</script>
