<template>
  <tr-dialog
    ref="modal"
    name="billet-cancel-confirmation-dialog"
    title="Você deseja cancelar o agendamento do seu boleto?"
  >
    <div>
      <tr-typography
        classes="vertical-offset2 inline-block"
        variant="body"
      >
        <p>Você está prestes a cancelar o agendamento</p>
        <p>Ao cancelar  este agendamento, o pagamento não será realizado.</p>
        <p>Essa operação pode ser realizada até o dia anterior à data de pagamento agendada.</p>
        <p>O valor total a ser cancelado é de: <strong>{{ $format.currency(billet.value) }}</strong></p>
        <tr-typography
          variant="h4"
        >
          Deseja continuar ?
        </tr-typography>
      </tr-typography>
    </div>
    <template #buttons>
      <tr-button
        classes="pull-right"
        :on-button-click="onConfirm"
        label="Continuar"
        variant="raised"
        color="primary"
      />
    </template>
  </tr-dialog>
</template>

<script>
import billetModel from '@/apps/payments/payments/billet/models/billet';
export default {
  name: 'billet-cancel-confirmation-dialog',
  props: {
    billet: {
      required: false,
      type: Object,
      default: billetModel.get
    },
    onConfirm: {
      required: true,
      type: Function
    }
  },
  methods: {
    open() {
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    }
  }
};
</script>
