<template>
  <div class="row margin-left-10 margin-right-10">
    <div class="col-lg-5 text-limit">
      <img
        class="ic--mini margin-right-10"
        width="18"
        :src="bankImage"
      >
      {{ suggestion.name || '---' }}
    </div>
    <div class="col-lg-3">
      {{ dbaAccountRepr(suggestion) }}
    </div>
    <div class="col-lg- text-right">
      {{ dbaDocumentRepr(suggestion) }}
    </div>
  </div>
</template>

<script>
import config from '@/config';
import dbaAccountRepr from '@/commons/formatters/dbaAccountRepr';
import dbaDocumentRepr from '@/commons/formatters/dbaDocumentRepr';

export default {
  name: 'receivers-autocomplete-suggestion',
  props: ['suggestion'],
  computed: {
    bankImage: function () {
      if (this.suggestion.pix_key) {
        return `${config.url.cdn}/banks/pix-color.svg`;
      }
      if (this.suggestion && this.suggestion.Bank && this.suggestion.Bank.image) {
        return this.suggestion.Bank.image;
      }

      return `${config.url.cdn}/banks/default_bank.svg`;
    }
  },
  methods: {
    dbaAccountRepr,
    dbaDocumentRepr
  }
};
</script>
