<template>
  <blocked-user-notification-container />
  <billing-overdue-notification-container />
  <status-page-incident-notification-container />
</template>

<script>
import BlockedUserNotificationContainer from '@/apps/payments/payments/settings/company/components/container/BlockedUserNotificationContainer.vue';
import BillingOverdueNotificationContainer from '@/apps/admin/billing/components/container/BillingOverdueNotificationContainer.vue';
import StatusPageIncidentNotificationContainer from '@/commons/components/container/StatusPageIncidentNotificationContainer.vue';

export default {
  name: 'top-notifications',
  components: {
    BlockedUserNotificationContainer,
    BillingOverdueNotificationContainer,
    StatusPageIncidentNotificationContainer
  }
};
</script>
