<template>
  <div>
    <div class="row">
      <batch-status-pill :batch-status="batch.status" />
      <reconciled-button-container
        v-if="canReconcile"
        classes="vertical-offset1 col-lg-offset-1"
        :batch-id="batch && batch.id"
        :is-reconciled="batch.reconciled"
        :on-change="onReconciledChange"
      />
    </div>
    <div class="row">
      <tr-read-only-text-field
        classes="col-lg-4"
        :no-margin="!batch.received_date"
        label="Valor do lote"
        :value="$format.currency(batch.value)"
      />
      <tr-read-only-text-field
        classes="col-lg-2"
        :no-margin="!batch.received_date"
        label="Total devolvido"
      >
        <div>{{ $format.currency(returnedValue) }}</div>
      </tr-read-only-text-field>
    </div>
    <div
      v-if="batch.received_date"
      class="row"
    >
      <tr-read-only-text-field
        classes="col-lg-4"
        no-margin="true"
        label="Recebido em"
        :value="formatDateTime(batch.received_date)"
      />
    </div>
  </div>
</template>

<script>
import BatchStatusPill from '@/apps/payments/payments/batch/components/presentational/BatchStatusPill';
import ReconciledButtonContainer from '@/apps/payments/payments/batch/components/container/ReconciledButtonContainer';
import { formatDateTime } from '@/commons/formatters/formatDate';
import batchModel from '@/apps/payments/payments/batch/models/batch';

export default {
  name: 'default-batch-top-info',
  components: {
    BatchStatusPill,
    ReconciledButtonContainer
  },
  props: {
    batch: {
      required: false,
      type: Object,
      default: batchModel.get
    },
    returnedValue: {
      required: false,
      type: Number,
      default: 0
    },
    canReconcile: {
      required: false,
      type: Boolean,
      default: false
    },
    companyName: {
      required: false,
      type: String
    }
  },
  methods: {
    formatDateTime,
    onReconciledChange(isReconciled) {
      this.batch.reconciled = isReconciled;
    }
  }
};
</script>
