import merge from 'deepmerge';
import { pix } from '@transfeeradev/api-enums';

const prepare = inputDba => {
  const dba = merge({}, inputDba);

  if (dba.pix_key_type) {
    if (dba.pix_key_type === pix.keyType.TELEFONE && dba.pix_key[0] === '+') {
      dba.pix_key = dba.pix_key.slice(3);
    }
  }

  return dba;
};

export default {
  prepare
};
