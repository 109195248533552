import merge from 'deepmerge';

const model = {
  id: null,
  type: null,
  name: null,
  value: 0,
  transfers: [],
  billets: []
};

const get = () => merge({}, model);

const set = inputModel => merge.all([{}, model, inputModel]);

export default {
  get,
  set
};
