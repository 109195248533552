<template>
  <div class="row">
    <cpf-cnpj-field-container
      ref="documentField"
      v-model:cpf-cnpj="validationData.pix_key"
      label="Chave Pix (CPF/CNPJ)"
      name="pix_key"
      classes="col-sm-12 col-md-6 col-lg-4"
      :on-change="value => (validationData.pix_key = value)"
      error-message="CPF ou CNPJ inválido"
      :required="true"
    />
  </div>
</template>

<script>
import CpfCnpjFieldContainer from '@/commons/components/container/CpfCnpjFieldContainer';

export default {
  name: 'validation-pix-key-cpf-cnpj-form',
  components: { CpfCnpjFieldContainer },
  props: {
    validationData: {
      type: Object,
      required: true
    }
  }
};
</script>
