<template>
  <div>
    <div class="vertical-offset2">
      <validation-receipt-container
        v-if="validationData?.type === type.MICRO_DEPOSIT"
        :validation-data="validationData"
      />
    </div>
    <hr class="vertical-offset2">
    <div class="flex-row spaced-beteewen vertical-offset2">
      <tr-read-only-text-field
        label="Tipo de validação"
        value-color="grey-dark"
        :dont-use-col-classes="true"
        :no-margin="true"
        :value="validationType"
      />
      <tr-read-only-text-field
        v-if="isPix(validationData?.data)"
        label="Tipo de chave"
        :value-color="getValueColor(['pix_key_type'])"
        :dont-use-col-classes="true"
        :no-margin="true"
        :value="pixKeyType"
      />
      <tr-read-only-text-field
        v-if="isPix(validationData?.data)"
        label="Chave Pix"
        :value-color="getValueColor(['pix_key'])"
        :dont-use-col-classes="true"
        :no-margin="true"
        :value="pixKey"
      />
    </div>
    <div
      v-if="shouldShowAccountData"
      class="row vertical-offset3"
    >
      <div class="col-lg-12">
        <tr-typography
          variant="h4"
          color="grey-dark"
        >
          <div class="pull-left">
            Dados bancários completos
          </div>
          <div class="pull-left margin-left-10">
            <tr-svg-icon
              v-if="isPix(validationData?.data)"
              v-tooltip.top-center="'Informações completas da conta bancária vinculada a chave Pix validada'"
              class="input-label-icon"
              color="blue"
              name="ic-info-outline"
            />
          </div>
        </tr-typography>
      </div>
    </div>
    <div
      v-if="shouldShowAccountData"
      class="row vertical-offset2"
    >
      <div
        v-if="bank"
        class="col-md-2"
      >
        <img
          class="ic--medium"
          :src="bankImage"
          :alt="bank.name"
        >
        <tr-typography
          variant="h4"
          classes="block vertical-offset1"
        >
          {{ bank.code || bank.ispb }} - {{ bank.name }}
        </tr-typography>
      </div>
      <div class="col-md-10">
        <div class="flex-row spaced-beteewen">
          <tr-read-only-text-field
            label="Agência"
            :value-color="getValueColor(['agency'])"
            :dont-use-col-classes="true"
            :value="validationData?.data?.agency || '---'"
          />
          <tr-read-only-text-field
            label="Conta"
            :value-color="getValueColor(['account', 'account_digit'])"
            :dont-use-col-classes="true"
            :value="validationData?.data?.account ? `${validationData?.data?.account}-${validationData?.data?.account_digit}` : '---'"
          />
          <tr-read-only-text-field
            label="Tipo de conta"
            :value-color="getValueColor(['account_type'])"
            :dont-use-col-classes="true"
            :value="accountType[validationData?.data?.account_type] || '---'"
          />
        </div>
        <tr-read-only-text-field
          label="CPF/CNPJ"
          :value-color="getValueColor(['cpf_cnpj'])"
          :dont-use-col-classes="true"
          :value="cpfCnpj"
        />
        <tr-read-only-text-field
          label="Nome"
          :value-color="getValueColor(['name'])"
          :no-margin="true"
          :dont-use-col-classes="true"
          :value="validationData?.data?.name || '---'"
        />
      </div>
    </div>
    <hr class="vertical-offset2">
    <div class="flex-row spaced-beteewen">
      <div>
        <tr-typography variant="h5">
          ID da validação:
        </tr-typography>
        <tr-typography
          variant="h5"
          color="grey-dark"
          classes="horizontal-offset1"
        >
          {{ validationData.id }}
        </tr-typography>
      </div>
      <div>
        <tr-typography variant="h5">
          Data/hora da validação:
        </tr-typography>
        <tr-typography
          variant="h5"
          color="grey-dark"
          classes="horizontal-offset1"
        >
          {{ validationDate }}
        </tr-typography>
      </div>
    </div>
  </div>
</template>

<script>
import { contacerta } from '@transfeeradev/api-enums';
import moment from 'moment';
import formatCpfCnpj from '@/commons/formatters/cpfCnpj';
import formatPhone from '@/commons/formatters/phone';
import { keyType } from '@transfeeradev/api-enums/pix';
import { isPix } from '@transfeeradev/api-services/TransferPixService';
import config from '@/config';
import accountType from '@/commons/constants/accountType';
import ValidationReceiptContainer from '../container/ValidationReceiptContainer.vue';
import { isObfuscatedCPF } from '@transfeeradev/api-services/PersonTypeService';

const KEY_TYPE_LABEL_MAP = {
  [keyType.CPF]: 'CPF',
  [keyType.CNPJ]: 'CNPJ',
  [keyType.TELEFONE]: 'Telefone',
  [keyType.EMAIL]: 'E-mail',
  [keyType.CHAVE_ALEATORIA]: 'Aleatória'
};
export default {
  name: 'validation-result',
  components: { ValidationReceiptContainer },
  props: {
    validationData: {
      type: Object,
      required: true
    },
    bank: {
      type: Object,
      required: false
    },
    errorFields: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data() {
    return {
      accountType,
      type: contacerta.type
    };
  },
  computed: {
    pixKeyType() {
      return KEY_TYPE_LABEL_MAP[this.validationData?.data?.pix_key_type] || '';
    },
    pixKey() {
      const pixKeyType = this.validationData?.data?.pix_key_type;
      if (pixKeyType === keyType.CPF || pixKeyType === keyType.CNPJ) {
        return formatCpfCnpj(this.validationData?.data?.pix_key);
      }
      if (pixKeyType === keyType.TELEFONE) {
        return formatPhone(this.validationData?.data?.pix_key);
      }
      return this.validationData?.data?.pix_key;
    },
    validationType() {
      return this.validationData.type === contacerta.type.MICRO_DEPOSIT ? 'Micro depósito' : 'Básica';
    },
    validationDate() {
      return this.validationData?.validated_at
        ? moment(this.validationData.validated_at).format('DD/MM/YYYY HH:mm:ss')
        : '---';
    },
    cpfCnpj() {
      const accountData = this.validationData?.data;
      if (isObfuscatedCPF(accountData.cpf_cnpj)) {
        return accountData.cpf_cnpj;
      }

      return formatCpfCnpj(accountData.cpf_cnpj || accountData.pix_key_validation?.cpf_cnpj) || '---';
    },
    shouldShowAccountData() {
      const accountData = this.validationData?.data;
      return (
        accountData?.account ||
        accountData?.account_type ||
        accountData?.agency ||
        accountData?.cpf_cnpj ||
        accountData?.name ||
        accountData?.pix_key_validation?.cpf_cnpj
      );
    },
    bankImage() {
      if (isPix(this.validationData?.data) && !this.bank) {
        return `${config.url.cdn}/banks/pix-circle.svg`;
      }

      if (this.bank?.image) {
        return this.bank.image;
      }

      return `${config.url.cdn}/banks/default_bank.svg`;
    }
  },
  methods: {
    isPix,
    getValueColor(fields) {
      return fields.some(field => this.errorFields.includes(field)) ? 'red' : 'grey-dark';
    }
  }
};
</script>
