<template>
  <div :class="`${classes} no-padding flex-row flex-start`">
    <div class="margin-right flex-row">
      <tr-svg-icon
        :classes="`transfers-lot-status-icon margin-right-10 bg-${batchStatusColor[batchStatus.DRAFT]}`"
        :name="batchStatusIcon[batchStatus.DRAFT]"
        color="white"
      />
      <tr-typography> Rascunho </tr-typography>
    </div>
    <div class="margin-right flex-row">
      <tr-svg-icon
        :classes="`transfers-lot-status-icon margin-right-10 bg-${batchStatusColor[batchStatus.WAITING_APPROVAL]}`"
        :name="batchStatusIcon[batchStatus.WAITING_APPROVAL]"
        color="white"
      />
      <tr-typography> Aguardando aprovação </tr-typography>
    </div>
    <div class="margin-right flex-row">
      <tr-svg-icon
        :classes="`transfers-lot-status-icon margin-right-10 bg-${batchStatusColor[batchStatus.WAITING]}`"
        :name="batchStatusIcon[batchStatus.WAITING]"
        color="white"
      />
      <tr-typography> Aguardando Recebimento </tr-typography>
    </div>
    <div class="margin-right flex-row">
      <tr-svg-icon
        :classes="`transfers-lot-status-icon margin-right-10 bg-${batchStatusColor[batchStatus.RECEIVED]}`"
        :name="batchStatusIcon[batchStatus.RECEIVED]"
        color="white"
      />
      <tr-typography> Recebido </tr-typography>
    </div>
    <div class="margin-right flex-row">
      <tr-svg-icon
        :classes="`transfers-lot-status-icon margin-right-10 bg-${batchStatusColor[batchStatus.FINISHED]}`"
        :name="batchStatusIcon[batchStatus.FINISHED]"
        color="white"
      />
      <tr-typography> Finalizado </tr-typography>
    </div>
  </div>
</template>

<script>
import batchStatusColor from '@/apps/payments/payments/batch/constants/batchStatusColor';
import batchStatusIcon from '@/apps/payments/payments/batch/constants/batchStatusIcon';
import { batchStatus } from '@transfeeradev/api-enums';

export default {
  name: 'batch-status-legend',
  props: {
    classes: {
      required: false,
      type: String,
      default: ''
    }
  },
  data: () => ({
    batchStatusColor,
    batchStatusIcon,
    batchStatus
  })
};
</script>
