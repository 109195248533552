import pixKeyRepr from '@/commons/formatters/pixKeyRepr';

export default dba => {
  if (dba.pix_key) {
    return pixKeyRepr({ key: dba.pix_key, key_type: dba.pix_key_type });
  }

  if (!dba.agency || !dba.account) {
    return '---';
  }

  let accountData = dba.agency;

  if (dba.agency_digit) {
    accountData += '-' + dba.agency_digit;
  }

  accountData += ' / ' + dba.account;

  if (dba.account_digit) {
    accountData += '-' + dba.account_digit;
  }

  return accountData;
};
