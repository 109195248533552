import resourceSettings from '@/apps/payments/resources/settings';
const { paymentType, defaults } = require('@transfeeradev/api-enums');

const get = type => {
  return resourceSettings.get().then(settings => {
    if (!type) {
      return settings.accountLimits[paymentType.PIX];
    }

    if (settings.accountLimits[type]) {
      return settings.accountLimits[type];
    }

    if (settings.accountLimits[type] === paymentType.BILLET) {
      return defaults.billet.MAX_VALUE;
    } else {
      return defaults.transfers.MAX_VALUE;
    }
  });
};

export default {
  get
};
