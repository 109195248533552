<template>
  <tr-fullscreen-modal
    ref="modal"
    name="billet-form-fullscreen-modal"
    :on-close="onCancel"
  >
    <billet-form-container
      :on-remove="remove"
      :on-save="save"
      :batch-id="batchId"
      :billet-id="billetId"
    />
    <tr-button
      classes="pull-left"
      label="Cancelar"
      variant="raised"
      color="secondary"
      :on-button-click="close"
    />
  </tr-fullscreen-modal>
</template>

<script>
import BilletFormContainer from '@/apps/payments/payments/billet/components/container/BilletFormContainer';

export default {
  name: 'billet-form-fullscreen-modal',
  components: {
    BilletFormContainer
  },
  props: {
    onRemove: {
      required: false,
      type: Function
    },
    onSave: {
      required: false,
      type: Function
    },
    billetId: {
      required: false,
      type: [Number, String]
    },
    onCancel: {
      required: false,
      type: Function
    },
    batchId: {
      required: false,
      type: [Number, String]
    }
  },
  methods: {
    remove() {
      this.close();

      if (this.onRemove) {
        this.onRemove();
      }
    },
    save(batchId) {
      this.close();

      if (this.onSave) {
        this.onSave(batchId);
      }
    },
    open() {
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    }
  }
};
</script>
