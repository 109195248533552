<template>
  <div class="row">
    <tr-loading
      v-if="isLoading"
      class="col-md-12 vertical-offset1 text-center"
    />
    <div v-if="!isLoading">
      <div
        v-if="!hasPixKeys"
        class="row col-lg-12"
      >
        <p class="col-md-12 bottom-offset3">
          <tr-typography strong="true">
            Adicione uma chave Pix para operar seus recursos com mais eficiência!
          </tr-typography>
        </p>
      </div>
      <div
        v-if="hasPixKeys"
        class="row"
      >
        <div class="col-lg-12">
          <p class="col-md-12 bottom-offset2">
            <tr-typography strong="true">
              Utilize sua Conta Pix
            </tr-typography>
            para enviar recursos:
          </p>
        </div>
        <div class="col-md-12 col-lg-6 vertical-offset1">
          <div
            v-for="pixKey in pixKeys.slice(0, 3)"
            :key="pixKey.id"
            class="row col-md-12"
          >
            <tr-text-field
              v-model="selectedPixKey"
              name="pix_key"
              type="radio"
              classes="col-lg-10 bottom-offset3"
              :radio-value="pixKey"
              @update:model-value="selectPixKey"
            >
              <template #label>
                <span class="pix-key-radio">{{ pixKeyValue(pixKey) }}</span>
              </template>
            </tr-text-field>
            <tr-svg-icon
              v-tooltip.top-center="'Copiar'"
              name="copy-icon"
              size="small"
              class="radio-copy-icon"
              color="blue"
              :on-button-click="() => copyToClipboard(pixKey.key)"
            />
          </div>
        </div>
        <div
          v-if="selectedPixKey.key"
          class="col-md-12 col-lg-6 text-right"
        >
          <img
            :src="pixQRCodeImage"
            class="pix-qrcode-image-cashin bottom-offset1"
            alt="QRCode pix"
          >
          <tr-typography class="copy-icon">
            <tr-svg-icon
              v-if="hasPixImage"
              v-tooltip.top-center="'Copiar'"
              name="copy-icon"
              size="small"
              color="blue"
              :on-button-click="() => copyToClipboard(selectedPixKey.emv_payload)"
            />
          </tr-typography>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formatCpfCnpj from '@/commons/formatters/cpfCnpj';
import phone from '@/commons/formatters/phone';
import breakBiggerWord from '@/commons/formatters/breakBiggerWord';
import { pix } from '@transfeeradev/api-enums';
import pixKeysResource from '@/apps/payments/payments/settings/pixKeys/resources/pixKey';

export default {
  name: 'pix-key-selection',
  props: {
    goToBankSelection: {
      required: false,
      type: Function
    }
  },
  emits: ['hasPixKeys'],
  data: () => ({
    pixKeys: [],
    selectedPixKey: null,
    isLoading: true,
    hasPixKeys: true
  }),
  computed: {
    pixQRCodeImage() {
      if (this.hasPixImage) {
        return 'data:image/png;base64,' + this.selectedPixKey.qrcode_image;
      }

      return '/assets/img/pix/qrcode-unavailable.png';
    },
    hasPixImage() {
      return !!this.selectedPixKey?.qrcode_image;
    }
  },
  created() {
    this.refreshKeys();
  },
  methods: {
    async refreshKeys() {
      try {
        this.pixKeys = (await pixKeysResource.getList())
          .filter(pixKey => !!pixKey.ownership_date)
          .map(pixKey => ({
            key: pixKey.key,
            keyType: pixKey.key_type,
            qrcode_image: pixKey.pix_qrcode_image,
            emv_payload: pixKey.pix_qrcode_emv_payload
          }));

        this.hasPixKeys = this.pixKeys.length;
        this.selectedPixKey = this.pixKeys[0];
      } catch (error) {
        this.hasPixKeys = false;
      } finally {
        this.isLoading = false;
        this.$emit('hasPixKeys', this.hasPixKeys);
      }
    },
    pixKeyValue(pixKey) {
      if (pixKey.keyType === pix.keyType.CNPJ) {
        return formatCpfCnpj(pixKey.key);
      }
      if (pixKey.keyType === pix.keyType.TELEFONE) {
        return phone(pixKey.key);
      }
      return breakBiggerWord(pixKey.key, 46);
    },
    selectPixKey(value) {
      this.selectedPixKey = value;
    },
    copyToClipboard(content) {
      navigator.clipboard.writeText(content);
      this.$toaster.success('Código copiado e pronto para uso!');
    }
  }
};
</script>
