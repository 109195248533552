import merge from 'deepmerge';
import dbaPrepareToSave from '@/apps/payments/payments/dba/services/prepareToSave';
import moment from '@transfeeradev/moment';
import DateService from '@transfeeradev/api-services/DateService';
import TransferPixService from '@transfeeradev/api-services/TransferPixService';

const TRANSFER_AVAILABLE_PROPERTIES = [
  'id',
  'value',
  'integration_id',
  'destination_bank_account',
  'payment_date',
  'pix_key_validation',
  'pix_description'
];

const prepare = (inputTransfer, isFromSpreadsheet) => {
  const transfer = merge({}, inputTransfer);

  if (!transfer.id) {
    delete transfer.id;
  }

  Object.keys(transfer).forEach(key => {
    if (!TRANSFER_AVAILABLE_PROPERTIES.includes(key)) {
      delete transfer[key];
    }
  });

  transfer.payment_date = _formatPaymentDate(transfer.payment_date);
  transfer.integration_id = transfer.integration_id || null;
  transfer.destination_bank_account = dbaPrepareToSave.prepare(
    transfer.destination_bank_account,
    true,
    isFromSpreadsheet
  );

  if (TransferPixService.isPix(transfer.destination_bank_account)) {
    if (transfer.pix_key_validation && transfer.pix_key_validation.cpf_cnpj === '') {
      transfer.pix_key_validation.cpf_cnpj = null;
    }
  } else {
    delete transfer.pix_key_validation;
  }

  return transfer;
};

const _formatPaymentDate = paymentDate => {
  const formattedDate = DateService.getFormatted(paymentDate);

  if (!formattedDate || !moment(formattedDate).isValid) {
    return paymentDate || null;
  }

  return formattedDate;
};

export default {
  prepare
};
