<template>
  <tr-form
    ref="form"
    :on-valid-submit="save"
  >
    <transfer-form
      ref="transferForm"
      :transfer="transfer"
      :dba="transfer.destination_bank_account"
      :settings="settings"
      :on-valid-after-dba-validation="saveTransfer"
      :on-invalid-after-dba-validation="onDbaValidationError"
      :on-payment-date-change="onPaymentDateChange"
      :on-reset-dba="onResetDba"
      :on-change-dba="onChangeDba"
    />
    <tr-footer-buttons-container>
      <tr-button
        class="pull-right margin-right"
        variant="raised"
        :disabled="isLoading"
        label="Salvar"
        color="primary"
        type="submit"
      />
      <tr-button
        v-if="transfer.id"
        class="pull-right margin-right"
        variant="raised"
        :disabled="isLoading"
        size="medium"
        svg-icon-name="ic-delete"
        svg-icon-color="white"
        color="danger"
        :on-button-click="showTransferRemovalConfirmationDialog"
      />
      <tr-button
        classes="pull-left"
        label="Cancelar"
        variant="raised"
        color="secondary"
        :disabled="isLoading"
        :on-button-click="cancel"
      />
    </tr-footer-buttons-container>
  </tr-form>
  <transfer-removal-confirmation-dialog
    ref="transferRemovalConfirmationDialog"
    :on-confirm="remove"
  />
</template>

<script>
import batchResource from '@/apps/payments/payments/batch/resources/batch';
import TransferRemovalConfirmationDialog from '@/apps/payments/payments/transfer/components/presentational/TransferRemovalConfirmationDialog.vue';
import TransferForm from '@/apps/payments/payments/transfer/components/presentational/TransferForm.vue';
import prepareToSave from '@/apps/payments/payments/transfer/services/prepareToSave';
import prepareToEditDba from '@/apps/payments/payments/dba/services/prepareToEdit';
import transferValidations from '@/apps/payments/payments/transfer/services/validations/transfer';
import batchModel from '@/apps/payments/payments/batch/models/batch';
import transferModel from '@/apps/payments/payments/transfer/models/transfer';
import settingsResource from '@/apps/payments/resources/settings';
import transfersLotModelFactory from '@/apps/payments/payments/transfer/services/transfersLotModelFactory';
import merge from 'deepmerge';
import { getMessage } from '@/commons/services/error';

export default {
  name: 'transfer-form-container',
  components: {
    TransferRemovalConfirmationDialog,
    TransferForm
  },
  props: {
    transferEdit: {
      required: false,
      type: Object,
      default: () => ({})
    },
    batchId: {
      required: false,
      type: [Number, String]
    },
    onSave: {
      required: false,
      type: Function
    },
    cancel: {
      required: false,
      type: Function
    },
    onRemove: {
      required: false,
      type: Function
    }
  },
  data: () => ({
    settings: {},
    isLoading: false,
    transfer: transferModel.get()
  }),
  watch: {
    'transfer.destination_bank_account.pix_key_type': {
      handler() {
        this.$refs?.form?.resetSubmit?.();
      }
    }
  },
  created() {
    if (this.transferEdit && this.transferEdit.id) {
      this.transfer = merge({}, this.transferEdit);
    }

    this.transfer.destination_bank_account = prepareToEditDba.prepare(this.transfer.destination_bank_account);

    settingsResource.get().then(settings => {
      this.settings = settings;
    });
  },
  mounted() {
    if (this.transferEdit && this.transferEdit.id) {
      this.setFormAsSubmitted();
      this.validate();
    }
  },
  methods: {
    showTransferRemovalConfirmationDialog() {
      this.$refs.transferRemovalConfirmationDialog.open();
    },
    setFormAsSubmitted() {
      if (this.$refs.form) {
        this.$refs.form.setAsSubmitted();
      }
    },
    onResetDba() {
      this.$refs.form.resetSubmit();
    },
    remove() {
      this.isLoading = true;
      batchResource.removeTransfer(this.batchId, this.transfer.id).then(() => {
        this.isLoading = false;
        if (this.onRemove) {
          this.onRemove();
        }
      }, this._onFailedTransferRemove);
    },
    onPaymentDateChange(paymentDate) {
      this.transfer.payment_date = paymentDate;
    },
    onChangeDba(dba, { clear } = {}) {
      const transfer = merge({}, this.transfer);
      transfer.destination_bank_account = dba;

      this.transfer = transfer;

      if (clear && this.transfer?.pix_key_validation?.cpf_cnpj) {
        this.transfer.pix_key_validation.cpf_cnpj = '';
      }
    },
    validate(shouldShowToaster) {
      return new Promise(resolve => {
        transferValidations.validateEntidadePublica(this.transfer.destination_bank_account).then(isValid => {
          if (!isValid) {
            if (shouldShowToaster) {
              this.$toaster.error('Esse tipo de conta somente permite pagamento para pessoa jurídica');
            }
            return resolve(false);
          }

          transferValidations.validate(this.transfer).then(validationResult => {
            if (!validationResult) {
              if (shouldShowToaster) {
                this.$toaster.error('Há dados inválidos na transferência');
              }
              return resolve(false);
            }
            resolve(true);
          });
        });
      });
    },
    onDbaValidationError() {
      this.isLoading = false;
    },
    save() {
      this.isLoading = true;

      this.validate(true).then(isValid => {
        if (isValid) {
          return this.$refs.transferForm.validateDba();
        }

        this.isLoading = false;
      });
    },
    saveTransfer() {
      const dbaId = this.transfer.destination_bank_account.id;
      const transfer = prepareToSave.prepare(this.transfer);

      if (dbaId) {
        transfer.destination_bank_account.id = dbaId;
      }

      if (transfer.id) {
        this.saveEditTransfer(transfer);
      } else {
        this.saveNewTransfer(transfer);
      }
    },
    saveEditTransfer(transfer) {
      const transferId = transfer.id;
      delete transfer.id;
      batchResource
        .updateTransfer(this.batchId, transfer, transferId)
        .then(savedTransfer => this.onTransferSaved(savedTransfer), this._onFailedTransferSave);
    },
    saveNewTransfer(transfer) {
      if (this.batchId) {
        batchResource
          .createTransfer(this.batchId, transfer)
          .then(savedTransfer => this.onTransferSaved(savedTransfer), this._onFailedTransferSave);
      } else {
        this.saveBatch(transfer);
      }
    },
    saveBatch(transfer) {
      const batch = transfersLotModelFactory.prepareToSave(batchModel.get());
      batch.transfers.push(transfer);
      batchResource.create(batch).then(this.onSaveBatch.bind(null, batch, transfer), this._onFailedTransferSave);
    },
    onSaveBatch(batch, transfer, savedBatch) {
      this.onTransferSaved(null, savedBatch.id);
    },
    onTransferSaved(savedTransfer, savedBatchId) {
      this.isLoading = false;
      this.onSave(savedBatchId);
    },
    _onFailedTransferSave(error) {
      this.isLoading = false;
      if (error && error.code) {
        return this.$toaster.error(getMessage(error));
      }

      this.$toaster.error('Ocorreu um erro ao salvar a transferência');
    },
    _onFailedTransferRemove(error) {
      this.isLoading = false;
      if (error && error.code) {
        return this.$toaster.error(getMessage(error));
      }

      this.$toaster.error('Ocorreu um erro ao excluir a transferência');
    }
  }
};
</script>
