import bankResource from '@/apps/payments/payments/bank/resources/bank';
import dbaFormatter from '@/apps/payments/payments/spreadsheetImport/services/formatters/dba';
import pixDataValidationFormatter from '@/apps/payments/payments/spreadsheetImport/services/formatters/pixDataValidation';
import transferModel from '@/apps/payments/payments/transfer/models/transfer';
import simpleRowFormattingFormatter from '@/apps/payments/payments/spreadsheetImport/services/formatters/simpleRowFormatting';
import { TRANSFER_HEADERS } from '@transfeeradev/sheets-import';
import MoneyService from '@transfeeradev/api-services/MoneyService';

const build = rows =>
  bankResource.getAll().then(banks => {
    const transfers = [];

    rows.map(row => {
      const transfer = transferModel.get();

      simpleRowFormattingFormatter.format(row);

      transfer.value = MoneyService.formatBRToNumber(row[TRANSFER_HEADERS.value.description], true);
      transfer.integration_id = row[TRANSFER_HEADERS.integrationId.description];
      transfer.payment_date = row[TRANSFER_HEADERS.paymentDate.description];
      transfer.destination_bank_account = dbaFormatter.format(row, banks);
      transfer.pix_key_validation = pixDataValidationFormatter.format(row);
      transfer.pix_description = row[TRANSFER_HEADERS.pixDescription.description];
      transfers.push(transfer);
    });

    return transfers;
  });

export default {
  build
};
