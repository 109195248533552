<template>
  <div class="vertical-offset2">
    <tr-text-field
      :model-value="modelValue"
      name="validation_type_bank_account"
      type="radio"
      classes="inline-block margin-right"
      :dont-use-col-classes="true"
      :required="true"
      :radio-value="VALIDATION_DATA_TYPES.ACCOUNT"
      @update:modelValue="$emit('update:modelValue', $event)"
    >
      <template #label>
        <span>Conta bancária</span>
      </template>
    </tr-text-field>
    <tr-text-field
      :model-value="modelValue"
      name="validation_type_cpf_cnpj"
      type="radio"
      classes="inline-block margin-right"
      :dont-use-col-classes="true"
      :required="true"
      :radio-value="VALIDATION_DATA_TYPES.CPF_CNPJ"
      @update:modelValue="$emit('update:modelValue', $event)"
    >
      <template #label>
        <span>CPF/CNPJ</span>
      </template>
    </tr-text-field>
    <tr-text-field
      :model-value="modelValue"
      name="validation_type_email"
      type="radio"
      classes="inline-block margin-right"
      :dont-use-col-classes="true"
      :required="true"
      :radio-value="VALIDATION_DATA_TYPES.EMAIL"
      @update:modelValue="$emit('update:modelValue', $event)"
    >
      <template #label>
        <span>E-mail</span>
      </template>
    </tr-text-field>
    <tr-text-field
      :model-value="modelValue"
      name="validation_type_phone"
      type="radio"
      classes="inline-block margin-right"
      :dont-use-col-classes="true"
      :required="true"
      :radio-value="VALIDATION_DATA_TYPES.PHONE"
      @update:modelValue="$emit('update:modelValue', $event)"
    >
      <template #label>
        <span>Telefone</span>
      </template>
    </tr-text-field>
    <tr-text-field
      :model-value="modelValue"
      name="validation_type_evp"
      type="radio"
      classes="inline-block margin-right"
      :dont-use-col-classes="true"
      :required="true"
      :radio-value="VALIDATION_DATA_TYPES.EVP"
      @update:modelValue="$emit('update:modelValue', $event)"
    >
      <template #label>
        <span>Chave aleatória</span>
      </template>
    </tr-text-field>
  </div>
</template>

<script>
import VALIDATION_DATA_TYPES from '@/apps/contacerta/validations/constants/validationDataTypes';

export default {
  name: 'validation-type-selector',
  props: ['modelValue'],
  emits: ['update:modelValue'],
  data() {
    return {
      VALIDATION_DATA_TYPES
    };
  }
};
</script>
