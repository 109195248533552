import config from '@/config';
import fetch from '@/fetch';

const BASE_URL = config.url.api.transfeera + '/companyBankAccount';
export default {
  get: accountSource => fetch(`${BASE_URL}?accountSource=${accountSource}`),
  update: bankAccount =>
    fetch(`${BASE_URL}`, {
      method: 'PUT',
      body: JSON.stringify(bankAccount)
    })
};
