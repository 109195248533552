<template>
  <tr-dialog
    ref="modal"
    name="pix-key-form-dialog"
    :min-width="620"
    :width="620"
    :title="title"
    :has-its-own-buttons="true"
    :on-cancel="close"
  >
    <pix-key-wizard-container
      :pix-id="pixId"
      @changed="pixIdChanged"
      @close="close"
    />
  </tr-dialog>
</template>

<script>
import PixKeyWizardContainer from '@/apps/payments/payments/settings/pixKeys/components/container/PixKeyWizardContainer';

export default {
  name: 'pix-key-wizard-dialog',
  components: {
    PixKeyWizardContainer
  },
  emits: ['close'],
  data: () => {
    return {
      pixId: null
    };
  },
  computed: {
    title() {
      if (!this.pixId) {
        return 'Adicionar chave Pix';
      }
      return 'Chave Pix';
    }
  },
  methods: {
    open(id) {
      this.pixId = id || null;
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
      this.$emit('close');
    },
    pixIdChanged(id) {
      this.pixId = id;
    }
  }
};
</script>
