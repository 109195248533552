<template>
  <tr-card
    :has-error="isReturned || isFailed"
    :has-warning="hasWarning"
    :on-card-click="onCardClick"
    :image-src="bankImage"
    :break-bigger-word="13"
    :title="$format.currency(transfer.value)"
  >
    <div :style="{ overflowWrap: 'anywhere' }">
      <div class="row vertical-offset2 center-block">
        <transfer-status-pill
          v-if="!hideStatusPill"
          classes="col-lg-12 bottom-offset2"
          size="medium"
          no-margin="true"
          :transfer-status="transfer.status"
        />
      </div>
      <tr-typography
        v-if="!isEmv || finishedEmv"
        break-all-words="true"
        fixed-lines-qtd="2"
        strong="true"
        :classes="dbaNameClass"
      >
        {{ breakBiggerWord(dbaName, 42) }}
      </tr-typography>
      <tr-typography
        v-if="finishedEmv"
        break-all-words="true"
        fixed-lines-qtd="2"
        strong="true"
      >
        {{ breakBiggerWord(dbaDocument, 18) }}
      </tr-typography>
      <tr-typography
        v-if="isPix && !isEmv"
        classes="inline-block"
        fixed-lines-qtd="2"
        strong="true"
      >
        {{ breakBiggerWord(pixKey, 42) }}
      </tr-typography>
      <tr-typography
        v-if="!isPix && !isEmv"
        classes="inline-block"
      >
        Ag:
        <tr-typography strong="true">
          {{ breakBiggerWord(transfer.destination_bank_account.agency, 10) }}
        </tr-typography>
        <br>
        CC:
        <tr-typography strong="true">
          {{ breakBiggerWord(transfer.destination_bank_account.account, 10) }} -
          {{ breakBiggerWord(transfer.destination_bank_account.account_digit, 1) }}
        </tr-typography>
      </tr-typography>
      <tr-typography
        classes="block vertical-offset1"
        strong="true"
      >
        <tr-svg-icon
          classes="vertical-align-sub"
          name="ic-calendar"
        />
        <span class="vertical-align-top">
          {{ formattedPaymentDate }}
        </span>
      </tr-typography>
    </div>
    <transfer-receipt-download-links-container
      classes="vertical-offset1 center-block"
      :transfer="transfer"
    />
  </tr-card>
</template>

<script>
import breakBiggerWord from '@/commons/formatters/breakBiggerWord';
import capitalize from '@/commons/formatters/capitalize';
import dbaDocumentRepr from '@/commons/formatters/dbaDocumentRepr';
import dbaAccountRepr from '@/commons/formatters/dbaAccountRepr';
import TransferStatusPill from '@/apps/payments/payments/transfer/components/presentational/TransferStatusPill';
import TransferReceiptDownloadLinksContainer from '@/apps/payments/payments/transfer/components/container/TransferReceiptDownloadLinksContainer';
import { destinationBankAccountStatus, transferStatus } from '@transfeeradev/api-enums';

export default {
  name: 'transfer-card',
  components: {
    TransferStatusPill,
    TransferReceiptDownloadLinksContainer
  },
  props: [
    'transfer',
    'onCardClick',
    'getReceipt',
    'mightHaveReceipt',
    'isFailed',
    'isReturned',
    'hideStatusPill',
    'hasWarning',
    'formattedPaymentDate',
    'bankImage'
  ],
  computed: {
    isEmv() {
      return !!this.transfer.emv;
    },
    finishedEmv() {
      return this.isEmv && this.transfer.status === transferStatus.FINISHED;
    },
    isPix() {
      return !!this.transfer.destination_bank_account.pix_key;
    },
    pixKey() {
      const dba = this.transfer.destination_bank_account;
      if (!dba.pix_key) {
        return '';
      }

      return dbaAccountRepr(dba);
    },
    dbaName() {
      const dba = this.transfer.destination_bank_account;
      if (!dba.name) {
        if (dba.status === destinationBankAccountStatus.FAILED) {
          return 'Chave Pix inválida';
        }
        return '---';
      }

      return capitalize(dba.name);
    },
    dbaDocument() {
      return dbaDocumentRepr(this.transfer.destination_bank_account);
    },
    dbaNameClass() {
      const dba = this.transfer.destination_bank_account;
      if (!this.isPix) {
        return '';
      }

      if (dba.status === destinationBankAccountStatus.FAILED) {
        return 'red';
      }
      return '';
    }
  },
  methods: {
    breakBiggerWord
  }
};
</script>
