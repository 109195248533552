import * as appService from '@/commons/services/apps';
import loggedUserResource from '@/commons/resources/loggedUser';
import { computed, ref } from 'vue';
import { APPS } from '@/commons/constants/apps';

const PAYMENTS_KEY = APPS.PAYMENTS.key;
const CONTACERTA_KEY = APPS.CONTACERTA.key;

export const useAppSwitcher = () => {
  const currentApp = ref(loggedUserResource.getCurrentApplication().name);

  const nextApp = computed(() => {
    return {
      [PAYMENTS_KEY]: CONTACERTA_KEY,
      [CONTACERTA_KEY]: PAYMENTS_KEY
    }[currentApp.value];
  });

  const switchApp = async () => {
    if (appService.isAppAllowed(nextApp.value)) {
      await appService.authAs(nextApp.value);
      currentApp.value = nextApp.value;
    }
  };

  return {
    currentApp,
    switchApp
  };
};
