<template>
  <div>
    <tr-read-only-text-field
      label="Chave Pix"
      :value="dbaAccountRepr(dba)"
      :no-margin="noMarginOnLastLine"
    />
  </div>
</template>

<script>
import dbaAccountRepr from '@/commons/formatters/dbaAccountRepr';

export default {
  name: 'view-dba-pix-key',
  props: {
    dba: {
      required: false,
      type: Object,
      default: () => ({})
    },
    noMarginOnLastLine: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    dbaAccountRepr
  })
};
</script>
