<template>
  <dba-wizard-container
    ref="dbaWizard"
    :dba="dba"
    :transfer="transfer"
    :on-change-dba="onChangeDba"
    :hide-navigation="hideNavigation"
    :on-bank-change="onBankChange"
    :on-reset-dba="onResetDba"
    :should-make-complex-validations="shouldMakeComplexValidations"
  />
</template>

<script>
import DbaWizardContainer from '@/apps/payments/payments/dba/components/container/DbaWizardContainer.vue';
import dbaModel from '@/apps/payments/payments/dba/models/dba';
import transferModel from '@/apps/payments/payments/transfer/models/transfer';
import { getMessage } from '@/commons/services/error';

export default {
  name: 'dba-container',
  components: {
    DbaWizardContainer
  },
  props: {
    dba: {
      required: false,
      type: Object,
      default: dbaModel.get
    },
    transfer: {
      required: false,
      type: Object,
      default: transferModel.get
    },
    onChangeDba: {
      required: true,
      type: Function
    },
    onBankChange: {
      required: false,
      type: Function
    },
    hideNavigation: {
      required: false,
      type: Boolean,
      default: false
    },
    onResetDba: {
      required: false,
      type: Function
    },
    shouldMakeComplexValidations: {
      required: false,
      type: Boolean,
      default: false
    },
    onValidAfterValidation: {
      required: false,
      type: Function
    },
    onInvalidAfterValidation: {
      required: false,
      type: Function
    }
  },
  methods: {
    validate() {
      return this.onValidAfterValidation();
    },
    onDbaValidationError(error) {
      if (error && error.message) {
        this.$toaster.error(getMessage(error));
      }

      if (this.onInvalidAfterValidation) {
        this.onInvalidAfterValidation();
      }
    }
  }
};
</script>
