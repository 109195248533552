import formatBanksToObject from '@/commons/services/bank/formatBanksToObject';
import bankResource from '@/apps/payments/payments/bank/resources/bank';
import valueValidations from '@/apps/payments/payments/transfer/services/validations/value';
import BankValidation from '@transfeeradev/bank-account-validations';
import { accountType, pix, regexes, paymentType, transfeeraData } from '@transfeeradev/api-enums';
import cpfCnpjResource from '@/apps/payments/payments/dba/resources/cpfCnpj';
import PersonTypeService from '@transfeeradev/api-services/PersonTypeService';
import CpfCnpjBlacklistService from '@transfeeradev/api-services/CpfCnpjBlacklistService';
import TransferPixService from '@transfeeradev/api-services/TransferPixService';
import settingsResource from '@/apps/payments/resources/settings';
import TransferValidation from '@transfeeradev/transfer-validation';
import getByProp from '@/commons/services/getByProp';
import errorMessage from '@/apps/payments/payments/transfer/constants/errorMessageTransfer';

class TransferValidationError extends Error {}

const validate = async transfer => {
  const foundBanks = await bankResource.getAll();
  const dba = Object.assign({}, transfer.destination_bank_account || transfer.DestinationBankAccount);
  const isPix = dba.pix_key || dba.pix_key_type;
  delete dba.agency_digit;
  let dbaCpfCnpj = dba.cpf_cnpj;

  try {
    if (isPix) {
      validatePixDba(dba);
      dbaCpfCnpj = getCpfCnpjByPixDba(dba);
    } else {
      validateBankAccountDba(dba);
      dba.bank_code = getBankCodeByBankId(dba.bank_id, foundBanks);
      if (!dba.bank_code) {
        dba.bank_ispb = getBankISPBByBankId(dba.bank_id, foundBanks);
      }
    }
    validateEmail(dba.email);
    await validateCpfCnpjBlacklist(dbaCpfCnpj);
    await validateMaxValueSettings(transfer, dba);
    validateTransferValidation(transfer);
    await validateBankAndEntidadePublica(isPix, dba, foundBanks);
    return true;
  } catch (error) {
    if (error instanceof TransferValidationError) {
      return false;
    }
    throw error;
  }
};

const validateTransferValidation = transfer => {
  const transferValidationResult = new TransferValidation({
    transfer
  }).validate();

  if (transferValidationResult.length) {
    throw new TransferValidationError(errorMessage.TRANFER_INVALID);
  }
};

const validateBankAndEntidadePublica = async (isPix, dba, foundBanks) => {
  if (isPix) {
    return true;
  }

  const bankId = dba.bank_id || null;
  const settings = await settingsResource.get();
  const ignoreErrorsWithSuggestions = settings.acceptContaCertaSuggestions;
  const dbaSchemaValidationErrors = new BankValidation(dba).validateSchema(ignoreErrorsWithSuggestions);
  const isValid =
    !dbaSchemaValidationErrors.length && isEntidadePublicaValid(foundBanks, bankId, dba.account_type, dba.cpf_cnpj);
  if (!isValid) {
    throw new TransferValidationError(errorMessage.ENTIDADE_PUBLICA_INVALID);
  }
};

const validateMaxValueSettings = async (transfer, dba) => {
  const type = await getPaymentTypeByTransfer(transfer.payment_method, dba);
  const valueValidationResult = await valueValidations.validate(transfer.value, type);
  if (!valueValidationResult.isValid) {
    throw new TransferValidationError(valueValidationResult.errorMessage);
  }
};

const validateEmail = email => {
  if (email && !regexes.EMAIL.test(email)) {
    throw new TransferValidationError(errorMessage.PIX_KEY_EMAIL_INVALID);
  }
};

const getCpfCnpjByPixDba = dba => {
  if ([pix.keyType.CPF, pix.keyType.CNPJ].includes(dba.pix_key_type)) {
    return dba.cpf_cnpj || dba.pix_key;
  }

  return dba.cpf_cnpj;
};

const validatePixDba = dba => {
  if (!Object.values(pix.keyType).includes(dba.pix_key_type)) {
    throw new TransferValidationError(errorMessage.PIX_KEY_INVALID);
  }
  if (dba.pix_key_type === pix.keyType.CPF) {
    if (!PersonTypeService.isValidCPF(dba.pix_key)) {
      throw new TransferValidationError(errorMessage.PIX_KEY_CPF_INVALID);
    }
  } else if (dba.pix_key_type === pix.keyType.CNPJ) {
    if (!PersonTypeService.isValidCNPJ(dba.pix_key)) {
      throw new TransferValidationError(errorMessage.PIX_KEY_CNPJ_INVALID);
    }
  } else if (dba.pix_key_type === pix.keyType.EMAIL && !regexes.PIX_EMAIL.test(dba.pix_key)) {
    throw new TransferValidationError(errorMessage.PIX_KEY_EMAIL_INVALID);
  } else if (dba.pix_key_type === pix.keyType.TELEFONE && !regexes.MOBILE_PHONE.test(dba.pix_key)) {
    throw new TransferValidationError(errorMessage.PIX_KEY_TELEFONE_INVALID);
  }
};

const getBankCodeByBankId = (bankId, foundBanks) => {
  const bank = getByProp.get({
    propValue: bankId,
    propName: 'id',
    list: foundBanks
  });

  return bank && bank.code;
};

const getBankISPBByBankId = (bankId, foundBanks) => {
  const bank = getByProp.get({
    propValue: bankId,
    propName: 'id',
    list: foundBanks
  });

  return bank && bank.ispb;
};

const validateBankAccountDba = dba => {
  const bankId = dba.bank_id || null;
  const isValidDbaName = dba.name && regexes.FIRST_NAME.test(dba.name);
  const isValidDbaCpfCnpj = PersonTypeService.isValidCPF(dba.cpf_cnpj) || PersonTypeService.isValidCNPJ(dba.cpf_cnpj);
  const isValid = !!(isValidDbaName && bankId && dba.account_type && dba.cpf_cnpj && isValidDbaCpfCnpj);

  if (!isValid) {
    throw new TransferValidationError(errorMessage.BANK_ACCOUNT_INVALID);
  }
};

const validateCpfCnpjBlacklist = async cpfCnpj => {
  const blacklist = await cpfCnpjResource.getBlacklist();
  const blacklistValidationResult = CpfCnpjBlacklistService.validate(cpfCnpj, blacklist);

  if (cpfCnpj && !blacklistValidationResult.isValid) {
    throw new TransferValidationError(errorMessage.CPF_CNPJ_ON_BLACKLIST);
  }
};

const validateEntidadePublica = async dba => {
  const banks = await bankResource.get();
  return isEntidadePublicaValid(banks, dba.bank_id, dba.account_type, dba.cpf_cnpj);
};

const isEntidadePublicaValid = (banks, bankId, account_type, cpf_cnpj) => {
  const banksObj = formatBanksToObject.format(banks);
  return !(
    (!_bankHasEntidadePublica(banksObj, bankId) || !PersonTypeService.isCNPJ(cpf_cnpj)) &&
    account_type === accountType.ENTIDADES_PUBLICAS
  );
};

const _bankHasEntidadePublica = (banksObj, bankId) =>
  Boolean(
    banksObj.CAIXA_ECONOMICA && banksObj.CAIXA_ECONOMICA instanceof Object && banksObj.CAIXA_ECONOMICA.id === bankId
  );

const getPaymentTypeByTransfer = async (paymentMethod, dba) => {
  const isTev = await _isTev(dba.bank_id);
  const isPix = TransferPixService.isPixByTransfer(paymentMethod, dba);

  if (isPix) {
    return paymentType.PIX;
  }

  if (isTev) {
    return paymentType.TEV;
  }

  return paymentType.TED;
};

const _isTev = async bankId => {
  const banks = await bankResource.getAll();
  const bank = getByProp.get({
    propValue: bankId,
    propName: 'id',
    list: banks
  });

  return bank && bank.ispb === transfeeraData.ISPB;
};

export default {
  validate,
  validateEntidadePublica,
  validatePixDba,
  validateBankAccountDba,
  validateEmail,
  validateCpfCnpjBlacklist,
  validateMaxValueSettings,
  validateTransferValidation,
  validateBankAndEntidadePublica,
  getCpfCnpjByPixDba,
  getBankCodeByBankId,
  getPaymentTypeByTransfer
};
