import config from '@/config';
import fetch from '@/fetch';

const BASE_URL = config.url.api.payin + '/app/payins';

export const receivablesResource = {
  /**
   * @returns {Promise<{
   * items: Receivable[],
   * metadata: {pagination: {itemsPerPage: number, totalItems: number}}}>}
   */
  get: ({ page = 0, page_size = 10, status__in, payment_type__in, search, created_at__gte, created_at__lte }) => {
    const query = new URLSearchParams({ page, page_size });

    for (const [key, value] of Object.entries({
      search,
      created_at__gte,
      created_at__lte
    })) {
      if (value) {
        query.append(key, value);
      }
    }

    if (status__in) {
      for (let filterStatus of status__in) {
        filterStatus === 'canceled'
          ? (query.append('status__in', 'canceled_by_user'), query.append('status__in', 'canceled_by_psp'))
          : query.append('status__in', filterStatus);
      }
    }

    if (payment_type__in) {
      for (let filterPaymentType of payment_type__in) {
        query.append('payment_type__in', filterPaymentType);
      }
    }

    return fetch(`${BASE_URL}?${query}`);
  },
  /**
   * @param {number} id
   * @returns {Promise<{ReceivableDetail>}}
   */
  getById: id => {
    return fetch(`${BASE_URL}/${id}`);
  }
};
