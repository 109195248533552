<template>
  <div :class="`col-lg-3 ${classes || ''}`">
    <tr-button
      v-if="isReconciled"
      :on-button-click="onButtonClick"
      svg-href="check-fill"
      svg-color="blue"
      svg-size="small"
      svg-position="left"
      label="Conciliado"
      variant="text"
      text-variant="body"
    />
    <tr-button
      v-else
      :on-button-click="onButtonClick"
      svg-href="check-border"
      svg-size="small"
      svg-position="left"
      label="Não conciliado"
      variant="text"
      text-variant="body"
    />
  </div>
</template>

<script>
export default {
  name: 'reconciled-button',
  props: {
    classes: {
      required: false,
      type: String
    },
    isReconciled: {
      required: false,
      type: Boolean,
      default: false
    },
    onButtonClick: {
      required: true,
      type: Function
    }
  }
};
</script>
