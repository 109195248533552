import { ref } from 'vue';

/**
 * This composable is used to delay the loading spinner for a certain amount of time.
 * If the promise is resolved before the delay time, the loading spinner won't be shown,
 * otherwise, it will be shown after the delay time.
 *
 * @param {Object} options
 * @param {number} options.delay Time in milliseconds to wait before showing the loading spinner
 * @param {Ref<boolean>} options.loading Ref to the loading state
 * @returns {Object} Object with the loading state and a function to delay the loading spinner
 */
export const useDelayedLoading = ({ delay = 500, loading = ref(false) } = {}) => {
  const delayLoading = async (promise, then) => {
    const timeout = setTimeout(() => {
      loading.value = true;
      then();
    }, delay);

    loading.value = false;

    return promise.then(res => {
      clearTimeout(timeout);
      loading.value = false;
      return then(res);
    });
  };

  return { loading, delayLoading };
};
