<template>
  <chart ref="chart" />
</template>

<script>
import Chart from './Chart.vue';
import transferResource from '@/apps/payments/payments/transfer/resources/transfer';

export default {
  name: 'failed-transfers-per-status-description-chart',
  components: {
    Chart
  },
  props: {
    height: {
      required: false,
      type: String
    },
    width: {
      required: false,
      type: String
    },
    onLoad: {
      required: false,
      type: Function
    },
    dateRange: {
      required: true
    }
  },
  watch: {
    dateRange: {
      deep: true,
      handler() {
        this.load();
      }
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    renderChart(data, options) {
      this.$refs.chart.renderChart({
        type: 'doughnut',
        data,
        options
      });
    },
    async load() {
      const data = await transferResource.getFailedPerStatusDescription(this.dateRange);

      let labels = data.map(d => {
        if (d.description && d.description.length > 10) {
          return d.description.slice(0, 10) + '...';
        }

        return d.description;
      });
      let quantities = data.map(d => d.percentage);

      labels = labels.slice(0, 4).concat('Outros');
      quantities = quantities
        .slice(0, 4)
        .concat(quantities.slice(4, quantities.length).reduce((acc, n) => (acc += +n), 0));

      if (this.$refs.chart) {
        this.renderChart(
          {
            labels,
            datasets: [
              {
                label: '',
                data: quantities,
                backgroundColor: ['#FF7D9C', '#FF3569', '#FF003A', '#CB0026', '#870017']
              }
            ]
          },
          {
            legend: {
              position: 'left',
              labels: {
                usePointStyle: true
              }
            },
            title: {
              display: true,
              text: '',
              position: 'top',
              fontStyle: 'normal'
            },
            tooltips: {
              enabled: true,
              callbacks: {
                label: tooltipItems => {
                  const item = data[tooltipItems.index];
                  return `${item.description}: ${item.percentage}%`;
                },
                footer: tooltipItems => {
                  const item = data[tooltipItems[0].index];
                  return `Quantidade: ${item.quantity}`;
                }
              }
            },
            animation: {
              onComplete: () => {
                return this.onLoad && this.onLoad(data);
              }
            }
          }
        );
      }
    }
  }
};
</script>
