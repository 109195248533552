<template>
  <bank-account-container
    v-if="banks?.length"
    ref="bankAccountContainer"
    :banks="banks"
    :should-make-complex-validations="shouldMakeComplexValidations"
    :should-show-operation-type="shouldShowOperationType"
    :accept-conta-certa-suggestions="acceptContaCertaSuggestions"
    :bank-account="bankAccount"
    :cpf-cnpj="cpfCnpj"
    @invalidate-field="invalidateField"
  >
    <template
      #default="{
        validations,
        accountTypes,
        agencyPattern,
        operationType,
        complexValidations,
        accountMaxDigits,
        agencyMaxDigits,
        onChangeBank,
        onChangeAccountType,
        onChangeAgency,
        validate: validateForm
      }"
    >
      <bank-account-form
        ref="form"
        :account-types="accountTypes"
        :agency-pattern="agencyPattern"
        :operation-type="operationType"
        :complex-validations="complexValidations"
        :account-max-digits="accountMaxDigits"
        :agency-max-digits="agencyMaxDigits"
        :validations="validations"
        :bank-label="bankLabel"
        :full-size="fullSize"
        :error-field="errorField"
        :bank-account="bankAccount"
        :no-margin="noMargin"
        @validate="validateForm"
        @update:bank="
          value => {
            onChangeBank();
            if (onBankChange) {
              onBankChange(value);
            }
          }
        "
        @update:agency="onChangeAgency"
        @update:account-type="onChangeAccountType"
      />
    </template>
  </bank-account-container>
</template>

<script>
import BankAccountForm from '@/apps/payments/payments/bank/components/presentational/BankAccountForm.vue';
import bankResource from '@/apps/payments/payments/bank/resources/bank';
import settingsResource from '@/apps/payments/resources/settings';
import BankAccountContainer from '@/commons/components/container/bank/BankAccountContainer.vue';

export default {
  name: 'bank-account-form-container',
  components: {
    BankAccountForm,
    BankAccountContainer
  },
  props: [
    'bankAccount',
    'fullSize',
    'errorField',
    'onBankChange',
    'bankLabel',
    'shouldMakeComplexValidations',
    'shouldShowOperationType',
    'cpfCnpj',
    'noMargin'
  ],
  data: () => ({
    banks: [],
    acceptContaCertaSuggestions: false
  }),
  async created() {
    bankResource.get().then(foundBanks => {
      this.banks = foundBanks;
    });

    const settings = await settingsResource.get();
    this.acceptContaCertaSuggestions = settings.acceptContaCertaSuggestions;
  },
  methods: {
    invalidateField(field) {
      this.$refs.form.setAsInvalid(field);
    },
    validate() {
      this.$refs.bankAccountContainer.validate();
    }
  }
};
</script>
