<template>
  <div>
    <tr-page-section
      :title="selectionLabel"
      :class="noMarginOnFirstLine ? 'no-margin' : ''"
    >
      <transfeera-bank-account-selection-container
        :on-value-change="onSelectBankAccount"
        :selected-bank-account="selectedBankAccount"
        :default-bank-account="defaultBankAccount"
        :full-size="fullSize"
        @loading="() => (isLoading = false)"
      />
    </tr-page-section>
    <tr-loading v-if="isLoading" />
    <tr-page-section
      v-else
      :title="selectedLabel"
    >
      <view-bank-account-container
        v-if="!selectedBankAccount.isPix"
        :full-size="fullSize"
        :no-margin-on-last-line="true"
        :bank-account="selectedBankAccount"
      />
      <view-pix-key
        v-if="selectedBankAccount.isPix"
        :full-size="fullSize"
        :no-margin-on-last-line="true"
        :pix-key="selectedBankAccount"
      />
    </tr-page-section>
  </div>
</template>

<script>
import TransfeeraBankAccountSelectionContainer from '@/apps/payments/payments/bank/components/container/TransfeeraBankAccountSelectionContainer';
import ViewBankAccountContainer from '@/apps/payments/payments/bank/components/container/ViewBankAccountContainer';
import ViewPixKey from '@/apps/payments/payments/bank/components/presentational/ViewPixKey';

export default {
  name: 'transfeera-bank-accounts',
  components: {
    TransfeeraBankAccountSelectionContainer,
    ViewBankAccountContainer,
    ViewPixKey
  },
  props: {
    defaultBankAccount: {
      required: false
    },
    onSelectBankAccount: {
      required: true,
      type: Function
    },
    selectedBankAccount: {
      required: true,
      type: Object
    },
    noMarginOnFirstLine: {
      required: false,
      type: Boolean,
      default: true
    },
    fullSize: {
      required: false,
      type: Boolean
    },
    selectionLabel: {
      required: false,
      type: String,
      default: 'Qual conta Transfeera você quer saber os dados?'
    },
    selectedLabel: {
      required: false,
      type: String,
      default: 'Dados bancários para o envio do saldo'
    }
  },
  data: () => {
    return { isLoading: true };
  }
};
</script>
