<template>
  <tr-dialog
    ref="modal"
    :unique-name="true"
    name="payment-link-success-dialog"
    title=""
    :has-its-own-buttons="true"
    :before-close="beforeClose"
  >
    <div class="payment-link-success-dialog">
      <div class="payment-link-success-dialog__check-icon">
        <bg-icon
          size="md"
          name="check"
          color="green-dark"
        />
      </div>
      <bg-text
        type="h6"
        weight="bold"
        color="grey-dark"
      >
        Link criado com sucesso!
      </bg-text>
    </div>
    <div
      class="payment-link-success-dialog__link-copy"
      @click="copyToClipboard(payment.link)"
    >
      <input
        :value="payment.link"
        name="payment-link"
        readonly
      >
      <bg-icon
        size="md"
        name="copy"
        color="blue-normal"
      />
    </div>
    <tr-dialog-buttons-container>
      <bg-button
        class="pull-left"
        variant="outline"
        @click="router.push({ name: 'PaymentLinkListPage' })"
      >
        Fechar
      </bg-button>
      <bg-button
        size="md"
        class="pull-right"
        @click="router.push({ name: 'PaymentLinkListPage' })"
      >
        Ir para Links de pagamento
      </bg-button>
    </tr-dialog-buttons-container>
  </tr-dialog>
</template>

<script setup>
import { ref } from 'vue';
import { BgText, BgButton, BgIcon } from '@transfeeradev/bridge';
import checkpoint from '@/commons/constants/checkpoint';
import checkpointResource from '@/commons/resources/checkpoint';
import { useRouter } from 'vue-router';
import { useToaster } from '@/commons/composables/useToaster';

const toaster = useToaster();
const router = useRouter();

const modal = ref();
const payment = ref({});

const open = paymentData => {
  payment.value = paymentData;
  modal.value.open(paymentData);
};

const beforeClose = () => {
  router.push({ name: 'PaymentLinkListPage' });
};

const copyToClipboard = valueToCopy => {
  navigator.clipboard.writeText(valueToCopy);
  toaster.success('Link de Pagamento copiado!');
  checkpointResource.create(checkpoint.PAYMENT_LINK_COPIED_ON_PAYMENT_LINK_SUCCESS_MODAL);
};

defineExpose({
  open,
  close
});
</script>

<style scoped>
input {
  width: 100%;
  border: 1px solid var(--bg-grey-light);
  border-radius: var(--bg-border-radius-md);
  padding: var(--bg-spacing-sm);

  &:focus-visible {
    outline: none;
    border: 1px solid var(--bg-blue-semi-dark);
  }
}

.payment-link-success-dialog__link-copy {
  position: relative;
  margin-top: var(--bg-spacing-sm);

  input {
    cursor: pointer;
  }

  svg {
    position: absolute;
    right: var(--bg-spacing-sm);
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
}

.payment-link-success-dialog {
  display: flex;
  flex-direction: column;
  justify-content: center;

  h6 {
    align-self: center;
    margin-top: var(--bg-spacing-sm);
  }
}

.payment-link-success-dialog__check-icon {
  background-color: var(--bg-green-light);
  border-radius: var(--bg-border-radius-x-lg);
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
}

.payment-link-success-dialog__description {
  display: flex;
  margin-top: var(--bg-spacing-sm);
}
</style>