import { computed } from 'vue';

export const useTfaIcon = ({ hasTwoFactorEnabled = false } = {}) => {
  const icons = {
    [true]: {
      name: 'ic-lock-close',
      text: 'Autenticação em 2 fatores ativada'
    },
    [false]: {
      name: 'ic-lock-open',
      text: 'Autenticação em 2 fatores desativada'
    }
  };

  return {
    icon: computed(() => icons[hasTwoFactorEnabled])
  };
};
