<template>
  <div :class="$style.container">
    <bg-stack space="sm">
      <bg-text
        type="h5"
        weight="bold"
      >
        Aceite de Termos de Uso de adquirência
      </bg-text>
      <bg-text type="body-md">
        Para utilizar adquirência, você precisa aceitar os Termos de Uso do nosso parceiro:
      </bg-text>
    </bg-stack>
    <div :class="$style.viewer">
      <pdf-object
        aria-label="Termos de Uso"
        url="/assets/Termos de Uso.pdf"
        :options="{
          fallbackLink,
        }"
      />
    </div>
    <bg-stack
      :class="$style.bottom"
      space="md"
    >
      <tr-button
        variant="raised"
        color="primary"
        :label="isUpdating ? 'Liberando acesso...' : 'Aceitar termos de uso'"
        type="button"
        :disabled="isUpdating"
        @click="acceptTermsOfUse"
      />
    </bg-stack>
  </div>
</template>

<script setup>
import checkpoint from '@/commons/constants/checkpoint';
import checkpointResource from '@/commons/resources/checkpoint';
import { BgStack, BgText } from '@transfeeradev/bridge';
import { useToaster } from '@/commons/composables/useToaster';
import { useRouter } from 'vue-router';
import { useErrorHandler } from '@/commons/composables/useErrorHandler';
import { ref } from 'vue';

const emit = defineEmits(['loading']);
const toaster = useToaster();
const router = useRouter();
const isUpdating = ref(false);
const { handleError } = useErrorHandler();

const fallbackLink =
  'Não consegue visualizar o PDF? <a href="/assets/Termos de Uso.pdf" target="_blank">Clique aqui</a> para baixar.';

const acceptTermsOfUse = async () => {
  try {
    isUpdating.value = true;
    await checkpointResource.create(checkpoint.CREDIT_CARD_ACCEPTED_TERMS_OF_USE);
    toaster.success('Cartão de crédito está liberado e pronto para uso');
    await router.push({ name: 'ReceivablesPage' });
  } catch (e) {
    handleError(e, 'Ocorreu um erro ao habilitar o produto de adquirência');
  }
};

emit('loading', false);
</script>

<style module scoped>
.container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 96px - 64px);
  margin-top: 40px;
  gap: 32px;
}

.viewer {
  height: calc(100% - 64px - 96px);
}

.bottom {
  position: fixed;
  height: 96px;
  bottom: 0;
  right: 0;
  left: 200px;
  background-color: white;
  align-items: center;
  justify-content: center;
}
</style>

<style>
.pdfobject-container {
  height: 100%;
  width: 100%;
}
</style>
