<template>
  <div>
    <div class="row">
      <bank-autocomplete
        :label="bankLabel || 'Banco'"
        name="bank"
        :classes="fullSize ? 'col-lg-8' : 'col-lg-3'"
        :required="true"
        :error-to-force-until-is-dirty="errorField === 'bank'"
        :ignore-error-on-dirty="true"
        :bank-id="bankAccount.bank_id"
        :on-bank-change="changeBank"
        :show-ispb="true"
      />
      <tr-text-field
        ref="agency"
        v-model="bankAccount.agency"
        label="Agência"
        name="agency"
        :error="!complexValidations.agency.isValid"
        :error-to-force-until-is-dirty="errorField === 'agency'"
        :classes="fullSize ? 'col-lg-3' : 'col-lg-2'"
        :required="validations.agency.required"
        :pattern="agencyPattern"
        :error-message="complexValidations.agency.errorMessage || validations.agency.errorMessage"
        :maxlength="agencyMaxDigits"
        :on-value-change="() => onValidate('agency')"
        :on-blur="onChangeAgency"
      />
    </div>
    <div class="row">
      <tr-text-field
        ref="account_type"
        v-model="bankAccount.account_type"
        label="Tipo da conta"
        name="account_type"
        :no-margin="typeof noMargin !== 'boolean' ? true : noMargin"
        :run-on-change-on-watch="true"
        :error="!complexValidations.account_type.isValid"
        :error-to-force-until-is-dirty="errorField === 'account_type'"
        :classes="fullSize ? 'col-lg-8 bottom-offset2' : 'col-lg-3'"
        :error-message="complexValidations.account_type.errorMessage || validations.accountType.errorMessage"
        select="true"
        :required="true"
        :on-value-change="onChangeAccountType"
      >
        <option
          disabled
          selected
          value=""
        >
          Escolha um tipo de conta
        </option>
        <option
          v-for="accountTypeOption in accountTypes"
          :key="accountTypeOption"
          :selected="accountTypeOption === bankAccount.account_type"
          :value="accountTypeOption"
        >
          {{ accountType[accountTypeOption] }}
        </option>
      </tr-text-field>
      <tr-text-field
        v-if="!fullSize"
        ref="account"
        v-model="bankAccount.account"
        :label="`Conta ${accountTypeLastName[bankAccount.account_type] || ''}`"
        name="account"
        :error="!complexValidations.account.isValid"
        :error-to-force-until-is-dirty="errorField === 'account'"
        :no-margin="typeof noMargin !== 'boolean' ? true : noMargin"
        :addon-content="operationType"
        classes="bank-account-form__account-input"
        :required="validations.account.required"
        :pattern="validations.account.pattern"
        :error-message="complexValidations.account.errorMessage || validations.account.errorMessage"
        :maxlength="accountMaxDigits"
        :on-value-change="() => onValidate('account')"
      />
      <tr-text-field
        v-if="!fullSize"
        ref="account_digit"
        v-model="bankAccount.account_digit"
        label="Dígito"
        name="account_digit"
        :no-margin="typeof noMargin !== 'boolean' ? true : noMargin"
        :error="!complexValidations.account_digit.isValid"
        :error-to-force-until-is-dirty="errorField === 'account_digit'"
        classes="col-lg-2"
        input-classes="input-width--xs"
        :required="validations.account.digit.required"
        :pattern="validations.account.digit.pattern"
        :error-message="complexValidations.account_digit.errorMessage || validations.account.digit.errorMessage"
        :maxlength="validations.account.digit.maxLength || validations.account.digit.exactLength"
        :on-value-change="() => onValidate('account_digit')"
      />
    </div>
    <div
      v-if="fullSize"
      class="row"
    >
      <tr-text-field
        ref="account"
        v-model="bankAccount.account"
        :label="`Conta ${accountTypeLastName[bankAccount.account_type] || ''}`"
        name="account"
        :error="!complexValidations.account.isValid"
        :error-to-force-until-is-dirty="errorField === 'account'"
        :no-margin="typeof noMargin !== 'boolean' ? true : noMargin"
        :addon-content="operationType"
        classes="col-lg-6"
        :required="validations.account.required"
        :pattern="validations.account.pattern"
        :error-message="complexValidations.account.errorMessage || validations.account.errorMessage"
        :maxlength="accountMaxDigits"
        :on-value-change="() => onValidate('account')"
      />
      <tr-text-field
        ref="account_digit"
        v-model="bankAccount.account_digit"
        label="Dígito"
        name="account_digit"
        :no-margin="typeof noMargin !== 'boolean' ? true : noMargin"
        :error="!complexValidations.account_digit.isValid"
        :error-to-force-until-is-dirty="errorField === 'account_digit'"
        classes="col-lg-3"
        input-classes="input-width--xs"
        :required="validations.account.digit.required"
        :pattern="validations.account.digit.pattern"
        :error-message="complexValidations.account_digit.errorMessage || validations.account.digit.errorMessage"
        :maxlength="validations.account.digit.maxLength || validations.account.digit.exactLength"
        :on-value-change="() => onValidate('account_digit')"
      />
    </div>
  </div>
</template>

<script>
import accountType from '@/commons/constants/accountType';
import BankAutocomplete from '@/apps/payments/payments/bank/components/presentational/BankAutocomplete';
import accountTypeLastName from '@/commons/constants/accountTypeLastName';

export default {
  name: 'bank-account-form',
  components: {
    BankAutocomplete
  },
  props: [
    'validations',
    'bankAccount',
    'accountTypes',
    'fullSize',
    'errorField',
    'bankLabel',
    'complexValidations',
    'operationType',
    'agencyPattern',
    'noMargin',
    'agencyMaxDigits',
    'accountMaxDigits'
  ],
  emits: ['update:agency', 'update:account-type', 'update:bank', 'validate'],
  data: () => ({
    accountType,
    accountTypeLastName
  }),
  methods: {
    changeBank(bank) {
      this.bankAccount.bank_id = bank.id;
      this.$emit('update:bank', bank.id);
    },
    onChangeAgency(value) {
      this.$emit('update:agency', value);
    },
    onChangeAccountType(value) {
      this.$emit('update:account-type', value);
    },
    onValidate(field) {
      this.$emit('validate', field);
    },
    setAsInvalid(field) {
      if (this.$refs[field]) {
        this.$refs[field].setAsInvalid();
      }
    }
  }
};
</script>
