import config from '@/config';
import fetch from '@/fetch';
import moment from '@transfeeradev/moment';

const BASE_URL = config.url.api.transfeera + '/transfer';
const APP_BASE_URL = config.url.api.transfeera + '/app/transfer';
export default {
  downloadReceipts: transferIds =>
    fetch(`${APP_BASE_URL}/downloadReceipts`, {
      method: 'POST',
      body: JSON.stringify(transferIds)
    }),
  getFiltered: (initialDate, endDate, page, search, status, bank) => {
    const DATE_FORMAT = 'YYYY-MM-DD';
    const formattedInitialDate = moment(initialDate).format(DATE_FORMAT);
    const formattedEndDate = moment(endDate).format(DATE_FORMAT);
    let url = `${BASE_URL}?endDate=${formattedEndDate}&initialDate=${formattedInitialDate}&page=${page}`;

    if (search) {
      const encodedSearch = encodeURIComponent(search);
      url += `&search=${encodedSearch}`;
    }

    if (status) {
      url += `&status=${status}`;
    }

    if (bank) {
      url += `&bankId=${bank}`;
    }

    return fetch(url);
  },
  getAllWithReceipt: (initialDate, endDate, search, status, bank) => {
    const DATE_FORMAT = 'YYYY-MM-DD';
    const formattedInitialDate = moment(initialDate).format(DATE_FORMAT);
    const formattedEndDate = moment(endDate).format(DATE_FORMAT);
    let url = `${APP_BASE_URL}/withReceipts?initialDate=${formattedInitialDate}&endDate=${formattedEndDate}`;

    if (search) {
      const encodedSearch = encodeURIComponent(search);
      url += `&search=${encodedSearch}`;
    }

    if (status) {
      url += `&status=${status}`;
    }

    if (bank) {
      url += `&bankId=${bank}`;
    }

    return fetch(url);
  },
  getDetails: id => fetch(`${APP_BASE_URL}/${id}/details`),
  hasBlankslate: () => fetch(`${BASE_URL}/hasBlankslate`),
  duplicatedIntegrationIds: id => fetch(`${BASE_URL}/${id}/duplicatedIntegrationId`),
  getQuantityPerMonth: ({ startDate, endDate }, status) =>
    fetch(`${APP_BASE_URL}/quantityPerMonth?startDate=${startDate}&endDate=${endDate}&status=${status}`),
  getDistributionPerBank: ({ startDate, endDate }, status) =>
    fetch(`${APP_BASE_URL}/distributionPerBank?startDate=${startDate}&endDate=${endDate}&status=${status}`),
  getCountByStatus: ({ startDate, endDate }, status) =>
    fetch(`${APP_BASE_URL}/countByStatus?startDate=${startDate}&endDate=${endDate}&status=${status}`),
  getQuantityPerDay: ({ startDate, endDate }, status) =>
    fetch(`${APP_BASE_URL}/quantityPerDay?startDate=${startDate}&endDate=${endDate}&status=${status}`),
  getFailedPerStatusDescription: ({ startDate, endDate }) =>
    fetch(`${APP_BASE_URL}/failedPerStatusDescription?startDate=${startDate}&endDate=${endDate}`)
};
