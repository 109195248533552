<template>
  <tr-text-field
    ref="field"
    v-model="internalCpfCnpj"
    :label="label"
    :classes="classes"
    :name="name"
    :required="required"
    :pattern="CPF_CNPJ"
    :mask-format="DOCUMENT"
    :custom-validation="validate"
    :error-message="errorMessage"
  >
    <template
      v-if="tooltipInfo"
      #label
    >
      <span>{{ label }}</span>
      <tr-svg-icon
        v-tooltip.top-center="tooltipInfo"
        class="input-label-icon"
        color="blue"
        name="ic-info-outline"
      />
    </template>
  </tr-text-field>
</template>

<script>
import masksContants from '@/commons/constants/masks';
import PersonTypeService from '@transfeeradev/api-services/PersonTypeService';
import CpfCnpjBlacklistService from '@transfeeradev/api-services/CpfCnpjBlacklistService';
import { regexes } from '@transfeeradev/api-enums';

const DEFAULT_ERROR_MESSAGE = 'Dados incorretos';

export default {
  name: 'cpf-cnpj-field-container',
  props: {
    cpfCnpj: {
      required: false,
      type: String,
      default: ''
    },
    label: {
      required: false,
      type: String,
      default: 'CPF ou CNPJ'
    },
    classes: {
      required: false,
      type: String,
      default: ''
    },
    onChange: {
      required: false,
      type: Function
    },
    name: {
      required: false,
      type: String,
      default: 'cpf_cnpj'
    },
    defaultErrorMessage: {
      required: false,
      type: String,
      default: DEFAULT_ERROR_MESSAGE
    },
    checkBlacklist: {
      required: false,
      type: Boolean,
      default: false
    },
    required: {
      required: false,
      type: Boolean,
      default: true
    },
    getBlacklist: {
      required: false,
      type: Function
    },
    tooltipInfo: {
      type: String,
      required: false
    }
  },
  emits: ['update:cpfCnpj'],
  data: () => ({
    validationResult: {},
    DOCUMENT: masksContants.DOCUMENT,
    CPF_CNPJ: regexes.CPF_CNPJ
  }),
  computed: {
    internalCpfCnpj: {
      get() {
        return this.cpfCnpj;
      },
      set(newCpfCnpj) {
        this.$emit('update:cpfCnpj', newCpfCnpj);
      }
    },
    errorMessage() {
      const isInvalid = this._isInvalid();
      if (isInvalid) {
        return this.validationResult.errorMessage;
      } else {
        return this.defaultErrorMessage;
      }
    }
  },
  mounted() {
    this.validate().then(result => {
      if (!result && this.cpfCnpj) {
        this.$refs.field.forceValidation();
      }
    });
  },
  methods: {
    focus() {
      this.$refs.field.focus();
    },
    validate() {
      return new Promise(resolve => {
        const isValid = Boolean(
          PersonTypeService.isValidCPF(this.cpfCnpj) || PersonTypeService.isValidCNPJ(this.cpfCnpj)
        );

        if (!isValid) {
          this.validationResult = {
            isValid: false,
            errorMessage: this.defaultErrorMessage
          };
          return resolve(false);
        }

        if (this.checkBlacklist && typeof this.getBlacklist === 'function') {
          return this.getBlacklist().then(blacklist => {
            this.validationResult = CpfCnpjBlacklistService.validate(this.cpfCnpj, blacklist);
            return resolve(this.validationResult.isValid);
          });
        }
        return resolve(true);
      });
    },
    _isInvalid() {
      return Boolean(this.validationResult && !this.validationResult.isValid);
    }
  }
};
</script>
