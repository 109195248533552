import moment from '@transfeeradev/moment';
import { realToCents } from '@/commons/formatters/currency';
import { paymentLinkResource } from '@/apps/payin/paymentLink/resources/paymentLink';
import { paymentLinkCheckpoint } from '@/apps/payin/paymentLink/helper/paymentLinkCheckpoint';

const paymentMethodsParser = paymentData => {
  const paymentMethodDetails = {};

  for (const method of paymentData.paymentMethods) {
    if (method === 'boleto') {
      paymentMethodDetails.boleto = { due_days: parseInt(paymentData.boletoExpirationDays) };
    } else if (method === 'credit_card') {
      paymentMethodDetails.credit_card = { max_installments: parseInt(paymentData.installments) };
    }
  }

  return Object.keys(paymentMethodDetails).length > 0 ? paymentMethodDetails : null;
};

export async function createPaymentLink(paymentData) {
  paymentLinkCheckpoint(paymentData.paymentMethods);
  const payment = {
    payment_methods: paymentData.paymentMethods,
    amount: realToCents(paymentData.amount),
    name: paymentData.name,
    description: paymentData.description,
    expires_at: moment(paymentData.expirationDate, 'DD-MM-YYYY').endOf('day').toISOString(),
    payment_method_details: paymentMethodsParser(paymentData)
  };
  return paymentLinkResource.create(payment);
}

export async function listPaymentLink() {
  return paymentLinkResource.list();
}

export async function deletePaymentLink(paymentLinkId) {
  return paymentLinkResource.delete(paymentLinkId);
}
