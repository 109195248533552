<template>
  <tr-dialog
    ref="modal"
    name="bank-account-to-send-balance-dialog"
    cancel-button-label="Voltar"
    title="Conta bancária"
  >
    <bank-account-to-send-balance-container
      :default-bank-account="defaultBankAccount"
      :full-size="true"
    />
  </tr-dialog>
</template>

<script>
import BankAccountToSendBalanceContainer from '@/apps/payments/payments/bank/components/container/BankAccountToSendBalanceContainer';

export default {
  name: 'bank-account-to-send-balance-dialog',
  components: {
    BankAccountToSendBalanceContainer
  },
  props: {
    defaultBankAccount: {
      required: false
    }
  },
  methods: {
    open() {
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    }
  }
};
</script>
