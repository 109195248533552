import { getMessage } from '@/commons/services/error';
import getMaxValueByPaymentType from '@/commons/services/getMaxValueByPaymentType';
import BilletValidationService from '@transfeeradev/api-services/BilletValidationService';

const validate = (value, type) =>
  getMaxValueByPaymentType.get(type).then(maxValue => {
    try {
      new BilletValidationService({
        value,
        maxValue
      }).validate();
      return {
        isValid: true
      };
    } catch (error) {
      return {
        isValid: false,
        errorMessage: getMessage(error)
      };
    }
  });

export default {
  validate
};
