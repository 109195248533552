import checkpointResource from '@/commons/resources/checkpoint';
import checkpoint from '@/commons/constants/checkpoint';
import settingsResource from '@/commons/resources/settings';
import loggedUserResource from '@/commons/resources/loggedUser';
import { reactive, readonly } from 'vue';
import Polling from '@/commons/services/polling';

const state = reactive({
  hasTfaEnabled: null,
  shouldForceTfa: null
});

export const enableTfa = async code => {
  const { new_token } = await settingsResource.setTwoFactor({
    action: 'enable',
    code
  });

  await checkpointResource.create(checkpoint.ADMIN_USING_TWO_FACTOR_WITH_APP);
  loggedUserResource.set(new_token);

  await waitForEnabled(true);
};

export const disableTfa = async code => {
  const { new_token } = await settingsResource.setTwoFactor({
    action: 'disable',
    code
  });

  loggedUserResource.set(new_token);

  await waitForEnabled(false);
};

export const fetchTfaState = async () => {
  const settings = await settingsResource.get(true);

  state.hasTfaEnabled = !!settings.hasTwoFactorEnabled;
  state.shouldForceTfa =
    process.env.VUE_APP_MODE === 'production' && loggedUserResource.isAdmin() && !state.hasTfaEnabled;
};

const waitForEnabled = async waitFor => {
  await fetchTfaState();

  if (state.hasTfaEnabled !== waitFor) {
    const polling = new Polling({ POLLING_INTERVAL: 1 });
    await polling.start(fetchTfaState, () => waitFor);
  }
};

export const tfaState = readonly(state);
