<template>
  <tr-dialog
    ref="modal"
    :unique-name="true"
    name="payment-link-cancel-dialog"
    title="Tem certeza que deseja cancelar a criação do Link de Pagamentos?"
    :has-its-own-buttons="true"
    :on-close="close"
  >
    <bg-text
      type="body-md"
      color="grey-dark"
    >
      Ao cancelar, qualquer informação adicionada será perdida.
    </bg-text>
    <tr-dialog-buttons-container>
      <bg-button
        class="pull-left"
        variant="outline"
        @click="close()"
      >
        Voltar
      </bg-button>
      <bg-button
        size="md"
        class="pull-right"
        @click="router.push({ name: 'PaymentLinkListPage' })"
      >
        Cancelar
      </bg-button>
    </tr-dialog-buttons-container>
  </tr-dialog>
</template>

<script setup>
import { ref } from 'vue';
import { BgText, BgButton } from '@transfeeradev/bridge';
import { useRouter } from 'vue-router';

const router = useRouter();
const modal = ref();

const open = () => {
  modal.value.open();
};

const close = () => {
  modal.value.close();
};

defineExpose({
  open,
  close
});
</script>