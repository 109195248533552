import SettingsPage from './pages/SettingsPage.vue';
import SettingsLayout from './Layout';
import { roles } from '@transfeeradev/api-enums';

export default {
  path: 'configuracoes',
  component: SettingsLayout,
  name: 'PaymentsSettings',
  redirect: { name: 'PaymentsBankAccountsPage' },
  meta: {
    allowForRoles: roles.appRoles.ADMIN
  },
  children: [
    {
      path: 'dados-bancarios',
      name: 'PaymentsBankAccountsPage',
      component: SettingsPage
    },
    {
      path: 'geral',
      name: 'PaymentsGeneralSettingsPage',
      component: SettingsPage
    }
  ]
};
