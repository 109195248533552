<template>
  <password-change-form
    :on-save="save"
    :on-cancel="onCancel"
    :password-confirmation-validation="isNewPasswordAndItsConfimartionEqual"
    :password-pattern="passwordPattern"
  />
</template>

<script>
import PasswordChangeForm from '@/commons/components/presentational/company/PasswordChangeForm';
import userResource from '@/commons/resources/companyUser';
import loggedUserResource from '@/commons/resources/loggedUser';
import { getMessage } from '@/commons/services/error';
import { regexes } from '@transfeeradev/api-enums';

export default {
  name: 'password-change-form-container',
  components: {
    PasswordChangeForm
  },
  props: {
    onCancel: {
      required: false,
      type: Function
    },
    onSave: {
      required: true,
      type: Function
    }
  },
  data: () => ({
    passwordPattern: regexes.PASSWORD
  }),
  methods: {
    save(passwords) {
      userResource.updatePassword(passwords.oldPassword, passwords.newPassword).then(
        data => {
          loggedUserResource.set(data.new_token);
          this.onSave();
          this.$toaster.success('Senha alterada com sucesso!');
        },
        error => {
          this.$toaster.error(getMessage(error));
        }
      );
    },
    isNewPasswordAndItsConfimartionEqual({ newPassword, newPasswordConfirmation }) {
      return String(newPassword) === String(newPasswordConfirmation);
    }
  }
};
</script>
