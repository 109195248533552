<template>
  <page
    v-model:active-tab="activeTab"
    title="Minha conta"
  >
    <template #header>
      <info-action-box
        v-if="tfaState.shouldForceTfa && !tfaState.hasTfaEnabled"
        type="error"
      >
        <bg-text>
          Para continuar navegando na plataforma, reative a Autenticação em 2 fatores.
        </bg-text>
      </info-action-box>
    </template>
    <template #tabs>
      <bg-tab :key="accountDataRoute">
        Dados da conta
      </bg-tab>
      <bg-tab :key="securityRoute">
        Segurança
      </bg-tab>
    </template>
    <template #panels>
      <bg-tab-panel :key="accountDataRoute">
        <account-data-container />
      </bg-tab-panel>
      <bg-tab-panel :key="securityRoute">
        <security-container />
      </bg-tab-panel>
    </template>
  </page>
</template>

<script setup>
import { BgTab, BgTabPanel, BgText } from '@transfeeradev/bridge';
import { ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import Page from '@/commons/components/presentational/Page.vue';
import SecurityContainer from '@/apps/my-account/settings/components/container/SecurityContainer.vue';
import AccountDataContainer from '@/apps/my-account/settings/components/container/AccountDataContainer.vue';
import { tfaState } from '@/commons/services/tfa';
import InfoActionBox from '@/commons/components/presentational/InfoActionBox.vue';

const emit = defineEmits(['loading']);
const router = useRouter();
const activeTab = ref(router.currentRoute.value.name);
const accountDataRoute = 'MyAccountAccountDataPage';
const securityRoute = 'MyAccountSecuritySettingsPage';

emit('loading', false);

watch(activeTab, name => {
  router.push({ name });
});
</script>

<style scoped>
.top-offset {
  margin-top: var(--bg-spacing-md);
}
</style>
