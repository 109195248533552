<template>
  <tr-form
    name="passwordChangeForm"
    :on-valid-submit="save"
  >
    <div class="row">
      <div class="col-md-6 col-lg-6">
        <div class="row">
          <tr-text-field
            v-model="oldPassword"
            classes="col-md-12 col-lg-12"
            label="Senha antiga"
            type="password"
            :required="true"
            name="old-password"
          />
          <tr-text-field
            v-model="newPassword"
            classes="col-md-12 col-lg-12"
            label="Sua nova senha"
            type="password"
            :required="true"
            :pattern="passwordPattern"
            :error-message="userErrors.WRONG_PASSWORD_FORMAT.message"
            name="new-password"
          />
          <tr-text-field
            v-model="newPasswordConfirmation"
            classes="col-md-12 col-lg-12"
            label="Confirmação de senha"
            type="password"
            :no-margin="true"
            :required="true"
            :custom-validation="passwordConfirmationValidation.bind(this, { newPassword, newPasswordConfirmation })"
            :error-message="userErrors.CONFIRMATION_DOES_NOT_MATCH_PASSWORD.message"
            name="new-password2"
          />
        </div>
      </div>
      <div
        class="col-md-6 col-lg-6"
        style="margin-top: 30px"
      >
        <div class="bottom-offset1">
          <tr-typography
            classes="block bottom-offset1"
            variant="body"
            strong="true"
          >
            Sua nova senha deve ter pelo menos:
          </tr-typography>
          <ul
            class="no-margin"
            style="padding-inline-start: 20px"
          >
            <li>Uma letra minúscula</li>
            <li>Uma letra Maiúscula</li>
            <li>Um núm3ro</li>
            <li>Um caracter especi@!</li>
            <li>No mínimo 8 caracteres</li>
          </ul>
        </div>
        <div class="block">
          <tr-typography
            classes="block bottom-offset1"
            variant="body"
            strong="true"
          >
            Dica, conte uma "boa" história:
          </tr-typography>
          <tr-typography
            classes="block"
            :no-margin="true"
            variant="body"
          >
            ex: bobComeu2me!as, felixTem3pat@s
          </tr-typography>
        </div>
      </div>
    </div>
    <tr-footer-buttons-container>
      <tr-button
        v-if="onCancel"
        class="pull-left"
        variant="outlined"
        color="secondary"
        label="Voltar"
        :on-button-click="onCancel"
      />
      <tr-button
        class="pull-right"
        variant="raised"
        color="primary"
        label="Salvar"
        type="submit"
      />
    </tr-footer-buttons-container>
  </tr-form>
</template>

<script>
import { errors } from '@transfeeradev/api-enums';

export default {
  name: 'password-change-form',
  props: {
    onCancel: {
      required: false,
      type: Function
    },
    onSave: {
      required: true,
      type: Function
    },
    passwordConfirmationValidation: {
      required: true,
      type: Function
    },
    passwordPattern: {
      required: true,
      type: RegExp
    }
  },
  data: () => ({
    oldPassword: '',
    newPassword: '',
    newPasswordConfirmation: '',
    userErrors: errors.ptBr.use
  }),
  methods: {
    save() {
      const passwords = {
        oldPassword: this.oldPassword,
        newPassword: this.newPassword,
        newPasswordConfirmation: this.newPasswordConfirmation
      };
      this.onSave(passwords);
    }
  }
};
</script>
