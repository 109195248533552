<template>
  <div class="bottom-offset2">
    <template v-if="pixKey.status === pixKeyStatus.ERROR">
      <p v-if="pixKey?.error?.message">
        {{ getMessage(pixKey.error) }}
      </p>
      <p v-else>
        Essa chave não pode ser vinculada. Utilize uma nova chave, excluindo essa e vinculando uma nova.
      </p>
    </template>
    <template v-else>
      Utilizando esta chave, você poderá receber e enviar pagamentos instantâneos em sua conta Transfeera!
    </template>
  </div>
  <div
    v-if="pixQRCodeImageAvailable"
    class="text-center flex-column"
  >
    <div class="bottom-offset1">
      <img
        :src="pixQRCodeImage"
        class="pix-qrcode-image"
        alt="QR Code"
      >
    </div>
    <tr-read-only-text-field
      class="bottom-offset1"
      :value="pixKeyValue"
      :no-margin="true"
    />
    <pix-key-status-pill
      :status="pixKey.status"
    />
  </div>
  <div
    v-else
    class="bottom-offset4"
  >
    <pix-key-read-only-text-field
      :pix-key="pixKeyValue"
      :pix-key-type="pixKey.key_type"
      :dont-use-col-classes="true"
      :no-margin="true"
    />
    <div class="flex-row vertical-offset2">
      <pix-key-status-pill
        :status="pixKey.status"
        no-margin="true"
      />
    </div>
  </div>
  <div class="bottom-offset3 inline-block width-100">
    <tr-button
      class="pull-left"
      variant="outlined"
      color="secondary"
      label="Voltar"
      :on-button-click="back"
    />
    <div class="pull-right">
      <slot name="action-button" />
    </div>
  </div>
</template>

<script>
import pixKeyRepr from '@/commons/formatters/pixKeyRepr';
import breakBiggerWord from '@/commons/formatters/breakBiggerWord';
import { pix } from '@transfeeradev/api-enums';
import PixKeyStatusPill from '@/apps/payments/payments/settings/pixKeys/components/presentational/PixKeyStatusPill.vue';
import PixKeyReadOnlyTextField from './PixKeyReadOnlyTextField.vue';
import { getMessage } from '@/commons/services/error';

export default {
  name: 'pix-key-details',
  components: {
    PixKeyStatusPill,
    PixKeyReadOnlyTextField
  },
  props: {
    pixKey: {
      type: Object
    }
  },
  emits: ['back'],
  data: () => ({
    pixKeyType: pix.keyType,
    pixKeyStatus: pix.pixKeyStatus
  }),
  computed: {
    pixKeyValue() {
      return breakBiggerWord(pixKeyRepr(this.pixKey), 40);
    },
    pixQRCodeImageAvailable() {
      return !!this.pixKey.pix_qrcode_image;
    },
    pixQRCodeImage() {
      if (this.pixKey.pix_qrcode_image) {
        return 'data:image/png;base64,' + this.pixKey.pix_qrcode_image;
      }

      return '/assets/img/pix/qrcode-unavailable.png';
    }
  },
  methods: {
    getMessage,
    back() {
      this.$emit('back');
    }
  }
};
</script>
