<template>
  <div>
    <tr-text-field
      v-model="dbaType"
      name="dba_type_cpf_cnpj"
      type="radio"
      :disabled="disabled"
      classes="inline-block margin-right"
      :dont-use-col-classes="true"
      :required="true"
      :radio-value="DBA_TYPES.CPF_CNPJ"
      @update:model-value="dbaTypeChange"
    >
      <template #label>
        <span>CPF/CNPJ</span>
      </template>
    </tr-text-field>
    <tr-text-field
      v-model="dbaType"
      name="dba_type_email"
      type="radio"
      :disabled="disabled"
      classes="inline-block margin-right"
      :dont-use-col-classes="true"
      :required="true"
      :radio-value="DBA_TYPES.EMAIL"
      @update:model-value="dbaTypeChange"
    >
      <template #label>
        <span>E-mail</span>
      </template>
    </tr-text-field>
    <tr-text-field
      v-model="dbaType"
      name="dba_type_phone"
      type="radio"
      :disabled="disabled"
      classes="inline-block margin-right"
      :dont-use-col-classes="true"
      :required="true"
      :radio-value="DBA_TYPES.PHONE"
      @update:model-value="dbaTypeChange"
    >
      <template #label>
        <span>Telefone</span>
      </template>
    </tr-text-field>
    <tr-text-field
      v-model="dbaType"
      name="dba_type_evp"
      type="radio"
      :disabled="disabled"
      classes="inline-block margin-right"
      :dont-use-col-classes="true"
      :required="true"
      :radio-value="DBA_TYPES.EVP"
      @update:model-value="dbaTypeChange"
    >
      <template #label>
        <span>Chave aleatória</span>
      </template>
    </tr-text-field>
    <tr-text-field
      v-model="dbaType"
      name="dba_type_bank_account"
      type="radio"
      :disabled="disabled"
      classes="inline-block"
      :dont-use-col-classes="true"
      :required="true"
      :radio-value="DBA_TYPES.BANK_ACCOUNT"
      @update:model-value="dbaTypeChange"
    >
      <template #label>
        <span>Conta Bancária</span>
      </template>
    </tr-text-field>
  </div>
</template>

<script>
import { pix } from '@transfeeradev/api-enums';

const DBA_TYPES = {
  PHONE: pix.keyType.TELEFONE,
  CPF_CNPJ: pix.keyType.CPF,
  EMAIL: pix.keyType.EMAIL,
  EVP: pix.keyType.CHAVE_ALEATORIA,
  BANK_ACCOUNT: 'BANK_ACCOUNT'
};

export default {
  name: 'dba-type-selector',
  props: {
    modelValue: {
      type: [String, Number]
    },
    disabled: {
      type: Boolean
    }
  },
  emits: ['update:modelValue'],
  data: () => ({
    DBA_TYPES,
    dbaType: DBA_TYPES.CPF_CNPJ
  }),
  watch: {
    modelValue: {
      immediate: true,
      handler(modelValue) {
        if (!modelValue) {
          this.dbaType = DBA_TYPES.BANK_ACCOUNT;
        } else if (modelValue === pix.keyType.CNPJ) {
          this.dbaType = DBA_TYPES.CPF_CNPJ;
        } else {
          this.dbaType = modelValue;
        }
      }
    }
  },
  methods: {
    dbaTypeChange(value) {
      if (value === DBA_TYPES.BANK_ACCOUNT) {
        value = null;
      }
      this.$emit('update:modelValue', value);
    }
  }
};
</script>
