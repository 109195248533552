<template>
  <tr-slimbox
    title="Aqui você controla suas faturas mensais"
    :with-border="true"
    arrow-direction="bottom-left"
  >
    Seu histórico de pagamentos fica organizado e você pode baixar suas Notas Fiscais emitidas pela Transfeera sempre
    que precisar. Você também consegue acompanhar a evolução mensal da quantidade de pagamentos realizadas pela sua
    empresa.
  </tr-slimbox>
  <tr-slimbox
    title="Histórico de faturas"
    :no-margin="true"
    :with-dashed-border="true"
  >
    <billing-table :billing="billingExample" />
  </tr-slimbox>
</template>

<script>
import BillingTable from '@/apps/admin/billing/components/presentational/BillingTable';
import { billing } from '@transfeeradev/api-enums';

const invoiceStatus = billing.invoiceStatus;

export default {
  name: 'billing-blankslate',
  components: {
    BillingTable
  },
  data: () => ({
    billingExample: [
      {
        billing_period_start: '2019-01-01T00:00:00.000Z',
        total: 300,
        amount_of_transfers: 100,
        status: invoiceStatus.OPEN
      },
      {
        billing_period_start: '2019-02-01T00:00:00.000Z',
        total: 300,
        amount_of_transfers: 100,
        status: invoiceStatus.OVERDUE
      },
      {
        billing_period_start: '2019-03-01T00:00:00.000Z',
        total: 300,
        amount_of_transfers: 100,
        status: invoiceStatus.PAID
      }
    ]
  })
};
</script>
