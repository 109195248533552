export default {
  PIX_KEY_INVALID: 'Chave PIX inválida',
  PIX_KEY_CPF_INVALID: 'CPF inválido',
  PIX_KEY_CNPJ_INVALID: 'CNPJ inválido',
  PIX_KEY_EMAIL_INVALID: 'E-mail inválido',
  PIX_KEY_TELEFONE_INVALID: 'Telefone inválido',
  TRANFER_INVALID: 'Transferência inválida',
  BANK_ACCOUNT_INVALID: 'Conta bancária inválida',
  ENTIDADE_PUBLICA_INVALID: 'Entidade pública inválida',
  CPF_CNPJ_ON_BLACKLIST: 'CPF ou CNPJ bloqueado'
};
