<template>
  <tr-dialog
    ref="modal"
    name="api-credential-generation-confirmation-dialog"
    title="Gerar novas credenciais de acesso"
    :on-save="onConfirm"
    :save-button-label="isContaCertaCredential ? 'Gerar novas credenciais' : 'Gerar novas credenciais e inutilizar antiga'"
  >
    <div>
      <tr-typography classes="inline-block">
        Gerando uma nova credencial de acesso você inutilizará sua antiga e deverá alterá-la na sua integração.
      </tr-typography>
      <tr-typography classes="vertical-offset2 inline-block">
        Armazene o client secret que será gerado pois ao deixar está página ele não estará mais disponível para consulta.
      </tr-typography>
      <tr-typography
        v-if="isContaCertaCredential"
        classes="vertical-offset2 inline-block"
      >
        <b>Atenção:</b>
        Esta credencial segue o formato mais atualizado de autenticação.
        Caso não tenha atualizado ainda, após gerar uma nova chave de autenticação
        você precisa alterar a sua integração para o novo formato até o dia
        <b>26/02/2024</b>.
        Siga a nossa <a
          href="https://docs.transfeera.dev/#/authentication"
          target="_blank"
          rel="noopener"
        >documentação</a>
        para atualizar o seu sistema. Até lá sua autenticação atual continuará funcionando normalmente.
      </tr-typography>
    </div>
  </tr-dialog>
</template>

<script>
export default {
  name: 'api-credential-generation-confirmation-dialog',
  props: {
    onConfirm: {
      required: true,
      type: Function
    },
    isContaCertaCredential: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    open() {
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    }
  }
};
</script>
