<template>
  <bg-stack space="md">
    <tr-typography classes="vertical-offset1 block">
      Sugerimos o uso dos aplicativos: <a
        href="https://authy.com/download/"
        rel="noopener"
        target="_blank"
      >Authy</a> ou
      <a
        href="https://support.google.com/accounts/answer/1066447?src=soctw"
        rel="noopener"
        target="_blank"
      >Google Authenticator</a>.
    </tr-typography>
    <bg-columns>
      <bg-column
        width="content"
      >
        <tfa-activation-button
          v-if="!tfaState.hasTfaEnabled"
          @activate="$emit('activate')"
        />
        <bg-button
          v-if="tfaState.hasTfaEnabled"
          @click.prevent="openDeactivationMfaDialog"
        >
          Desativar 2FA agora
        </bg-button>
      </bg-column>
      <bg-column width="content">
        <bg-button
          v-if="!tfaState.shouldForceTfa && !tfaState.hasTfaEnabled"
          variant="outline"
          @click.prevent="onActivateLater"
        >
          Ativar mais tarde
        </bg-button>
      </bg-column>
    </bg-columns>
    <tfa-deactivation-dialog
      ref="deactivationDialog"
      @deactivate="onDeactivate"
    />
  </bg-stack>
</template>

<script>
import TfaActivationButton from '@/apps/my-account/settings/components/TfaActivationButton.vue';
import TfaDeactivationDialog from '@/apps/my-account/settings/components/container/TfaDeactivationDialog.vue';
import loggedUserResource from '@/commons/resources/loggedUser';
import { BgButton, BgColumns, BgColumn, BgStack } from '@transfeeradev/bridge';
import { roles } from '@transfeeradev/api-enums';
import { tfaState } from '@/commons/services/tfa';

export default {
  components: {
    TfaDeactivationDialog,
    TfaActivationButton,
    BgButton,
    BgColumns,
    BgColumn,
    BgStack
  },
  emits: ['activate', 'deactivate'],
  data() {
    return {
      isAdmin: false,
      qrCodeSrc: '',
      tfaState
    };
  },
  created() {
    const loggedUser = loggedUserResource.get();
    this.isAdmin = loggedUser?.profile === roles.appRoles.ADMIN.value;
  },
  methods: {
    onDeactivate() {
      this.$refs.deactivationDialog.close();
      this.$emit('deactivate');
    },
    openDeactivationMfaDialog() {
      this.$refs.deactivationDialog.open();
    },
    onActivateLater() {
      this.$router.push({ name: 'BatchesPage' });
    }
  }
};
</script>
