<template>
  <slot v-if="show" />
</template>

<script setup>
import { APPS } from '@/commons/constants/apps';
import { isAppAllowed } from '@/commons/services/apps';
import { ref } from 'vue';

const props = defineProps({
  app: {
    type: String,
    required: true,
    validator: value => [APPS.CONTACERTA.key, APPS.PAYMENTS.key].includes(value)
  }
});

const show = ref(isAppAllowed(props.app));
</script>
