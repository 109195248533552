<template>
  <validation-result-status-container
    :loading="isLoading || isLoadingStatus"
    :validation-data="validationResult"
    :is-new-validation="isNewValidation"
  />
  <validation-result
    v-if="!isLoading"
    :validation-data="validationResult"
    :error-fields="errorFields"
    :bank="bank"
  />
  <page-footer>
    <template #left>
      <tr-button
        v-if="showBackButton"
        variant="raised"
        color="secondary"
        label="Voltar"
        type="button"
        @click="onBack"
      />
    </template>
    <template #right>
      <tr-button
        v-if="showValidationButtons"
        class="horizontal-offset2"
        variant="raised"
        label="Nova validação"
        color="primary"
        type="button"
        @click="onNewValidation"
      />
      <tr-button
        v-if="showValidationButtons"
        variant="raised"
        color="secondary"
        label="Refazer validação"
        type="button"
        :disabled="isLoading || isLoadingStatus"
        @click="onRedoValidation"
      />
    </template>
  </page-footer>
</template>

<script>
import validationResource from '@/apps/contacerta/resources/validation';
import ValidationResult from '../presentational/ValidationResult';
import ValidationResultStatusContainer from './ValidationResultStatusContainer';
import { contacerta } from '@transfeeradev/api-enums';
import Polling from '@/commons/services/polling';
import getByProp from '@/commons/services/getByProp';
import bankResource from '@/apps/contacerta/resources/bank';
import PageFooter from '@/commons/components/presentational/PageFooter.vue';

const MAX_POLLING_TIMEOUT = 30;
const POLLING_INTERVAL = 2;
const polling = new Polling({ MAX_POLLING_TIMEOUT, POLLING_INTERVAL });

export default {
  name: 'validation-result-container',
  components: {
    ValidationResult,
    ValidationResultStatusContainer,
    PageFooter
  },
  props: {
    validationId: {
      type: String,
      required: false
    },
    shouldPolling: {
      type: Boolean,
      required: false
    },
    showBackButton: {
      type: Boolean,
      required: false
    },
    showValidationButtons: {
      type: Boolean,
      required: false
    },
    isNewValidation: {
      type: Boolean,
      required: false
    }
  },
  emits: ['new-validation', 'redo-validation', 'back'],
  data() {
    return {
      validationResult: {},
      isLoading: true,
      isLoadingStatus: false,
      banks: []
    };
  },
  computed: {
    bank() {
      if (this.validationResult.bank_code || this.validationResult.bank_ispb) {
        return getByProp.get({
          propValue: this.validationResult.bank_code || this.validationResult.bank_ispb,
          propName: this.validationResult.bank_code ? 'code' : 'ispb',
          list: this.banks
        });
      }
      return null;
    },
    errorFields() {
      if (!this.validationResult?.errors) {
        return [];
      }
      return this.validationResult.errors.map(error => error.field);
    }
  },
  watch: {
    validationId() {
      this.fetchData();
    }
  },
  created() {
    bankResource.get().then(banks => {
      this.banks = banks;
    });
    this.fetchData();
  },
  beforeUnmount() {
    polling.stop();
  },
  methods: {
    fetchData() {
      if (!this.validationId) {
        return;
      }
      this.getValidation().then(() => {
        this.isLoading = false;
        if (this._pollingCondition()) {
          this.polling();
        }
      });
    },
    async getValidation() {
      const result = await validationResource.getValidation(this.validationId);
      this.validationResult = result;
    },
    onRedoValidation() {
      this.$emit('redo-validation');
    },
    onNewValidation() {
      this.$emit('new-validation');
    },
    onBack() {
      this.$emit('back');
    },
    polling() {
      this.isLoadingStatus = true;
      polling.start(this.getValidation, this._pollingCondition).then(() => {
        this.isLoadingStatus = false;
      });
    },
    _pollingCondition() {
      return (
        this.shouldPolling &&
        this.validationResult.id &&
        this.validationResult.type === contacerta.type.MICRO_DEPOSIT &&
        !this.validationResult.micro_deposit_status &&
        !this.validationResult.errors?.length
      );
    }
  }
};
</script>
