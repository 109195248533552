import config from '@/config';
import fetch from '@/fetch';

const BASE_URL = config.url.api.transfeera + '/companySettings';

export default {
  update: settings =>
    fetch(
      BASE_URL,
      {
        body: JSON.stringify(settings),
        method: 'PUT'
      },
      {
        isText: true
      }
    )
};
