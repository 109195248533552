<template>
  <tr-text-field
    v-model="internalCurrentStatus"
    placeholder="Filtrar por status"
    name="transfer-filter-by-status"
    no-margin="true"
    classes="col-lg-3"
    select="true"
  >
    <option value="">
      Todos
    </option>
    <option
      v-for="(transferStatusOption, key) in transferStatusToShowForCustomerContant"
      :key="key"
      :value="transferStatusOption"
    >
      {{ transferStatusNameConstant[transferStatusOption] || transferStatusNameConstant[key] }}
    </option>
  </tr-text-field>
</template>

<script>
import { batchTransferSearchStatus } from '@transfeeradev/api-enums';
import transferStatusNameConstant from '@/apps/payments/payments/transfer/constants/transferStatusName';

export default {
  name: 'transfer-filter-by-status',
  props: {
    onFilterByChange: {
      required: true,
      type: Function
    },
    batchStatus: {
      required: false,
      type: String,
      default: ''
    },
    currentStatus: {
      required: false,
      type: [String, Array],
      default: ''
    }
  },
  data: () => ({
    transferStatusNameConstant
  }),
  computed: {
    transferStatusToShowForCustomerContant() {
      return batchTransferSearchStatus[this.batchStatus] || {};
    },
    internalCurrentStatus: {
      get() {
        return this.currentStatus;
      },
      set(newCurrentStatus) {
        this.onChange(newCurrentStatus);
      }
    }
  },
  methods: {
    onChange(newValue) {
      if (newValue.includes(',')) {
        newValue = newValue.split(',');
      }
      this.onFilterByChange(newValue);
    }
  }
};
</script>
