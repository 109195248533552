export default text => {
  if (typeof text === 'string' && text !== '') {
    text = text.toLowerCase();
    return text
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase())
      .join(' ');
  } else {
    return '';
  }
};
