import moment from 'moment';

export default class Polling {
  _pollingStartTime = null;
  _timeout = null;
  _config = {};
  constructor({ MAX_POLLING_TIMEOUT, POLLING_INTERVAL }) {
    this._config = {
      POLLING_INTERVAL,
      MAX_POLLING_TIMEOUT
    };

    if (typeof this._config.POLLING_INTERVAL !== 'number') {
      throw Error('POLLING_INTERVAL option is required and must be a number');
    }
  }

  start(callback, pollingCondition) {
    this._pollingStartTime = moment().format('X');
    return this._polling(callback, pollingCondition);
  }

  stop() {
    if (this._timeout) {
      clearTimeout(this._timeout);
    }
  }

  _polling(callback, pollingCondition = () => true, prevResponse) {
    return new Promise((resolve, reject) => {
      if (
        !pollingCondition(prevResponse) ||
        (this._pollingStartTime &&
          this._config.MAX_POLLING_TIMEOUT &&
          moment().isSameOrAfter(moment(this._pollingStartTime, 'X').add(this._config.MAX_POLLING_TIMEOUT, 's')))
      ) {
        this.stop();
        return resolve();
      }

      this._timeout = setTimeout(async () => {
        try {
          const response = await callback();
          await this._polling(callback, pollingCondition, response);
          resolve();
        } catch (error) {
          this.stop();
          reject(error);
        }
      }, this._config.POLLING_INTERVAL * 1000);
    });
  }
}
