<template>
  <tr-read-only-text-field
    v-if="pixKeyType"
    :label="label"
    :value="pixKey"
  />
</template>

<script setup>
import { keyType } from '@transfeeradev/api-enums/pix';
import { computed } from 'vue';

const props = defineProps({
  pixKey: String,
  pixKeyType: String
});

const labels = {
  [keyType.CHAVE_ALEATORIA]: 'Chave Pix (Aleatória)',
  [keyType.TELEFONE]: 'Chave Pix (Celular)',
  [keyType.EMAIL]: 'Chave Pix (E-mail)',
  [keyType.CNPJ]: 'Chave Pix (CNPJ)',
  [keyType.CPF]: 'Chave Pix (CPF)'
};

const label = computed(() => labels[props.pixKeyType]);
</script>
