<template>
  <component
    :is="button"
    v-if="button"
  />
</template>

<script lang="jsx" setup>
import { pixKeyStatus } from '@transfeeradev/api-enums/pix';
import { computed } from 'vue';

const props = defineProps({
  status: {
    type: String,
    validator: value => Object.values(pixKeyStatus).includes(value)
  }
});

const emit = defineEmits(['keep-using', 'remove']);

const removeButton = () => {
  return (
    <tr-button
      v-tooltip={['Excluir chave', ['topCenter']]}
      variant="raised"
      size="medium"
      svg-icon-name="ic-delete"
      svg-icon-color="white"
      color="danger"
      onButtonClick={() => emit('remove')}
    />
  );
};

const keepUsingButton = () => {
  return (
    <tr-button
      variant="raised"
      size="medium"
      color="primary"
      label="Continuar usando a Transfeera"
      onButtonClick={() => emit('keep-using')}
    />
  );
};

const button = computed(() => {
  return {
    [pixKeyStatus.REGISTERED]: removeButton,
    [pixKeyStatus.ERROR]: removeButton,
    [pixKeyStatus.PENDING]: keepUsingButton,
    [pixKeyStatus.PENDING_REMOVAL]: keepUsingButton
  }[props.status];
});
</script>
